<div class="flex flex-col w-full max-w-lg font-poppins p-12 gap-12 bg-primaryWhite items-center justify-center border border-gray-200 rounded-lg shadow sm:p-8">
    <div class="flex flex-col gap-4 w-full justify-center items-center">
        <h5 class="heading-h3 text-greyScaleTextTitle">{{planName}}</h5>
        <p class="text-greyScaleBorderDarker big">{{subtitle}}</p>
    </div>
    <div class="flex flex-col w-full items-center justify-center gap-4">
        <div class="flex items-baseline text-gray-900 dark:text-white">
            @if (cost !== 0) {
                <span class="heading-h2 text-greyScaleTextTitle">$</span>
            }
            @if (cost !== 0) {
                <span class="heading-h2 text-greyScaleTextTitle">{{cost}}</span>
            } @else {
                <span class="heading-h2 text-greyScaleTextTitle">Free</span>
            }
        </div>
        @if (billedMonthly) {
            <p class="text-greyScaleBorderDarker big">Billed monthly</p>
        }
    </div>
    <hr class="w-full text-greyScaleBorderDefault">
    <ul role="list" class="space-y-6 my-7 flex flex-col gap-2 w-full justify-start">
        @for (available of availableOptions; track $index) {
            <li class="flex items-center gap-6">
                <img src="../../../assets/icons/CheckMarkIcon.png" class="size-8"/>
                <span class="text-greyScaleTextTitle big">{{available}}</span>
            </li>
        }
    </ul>
    <button class="w-full items-center justify-center py-[1rem] rounded-1 bg-brandSurfaceDefault text-greyScaleSurfaceSubtle transition-[0.5s] hover:bg-brandBorderDarker"
            (click)="openPlanPreview()">
        Get started now
    </button>    
    @if (footer !== '') {
        <ul class="list-disc list-inside">
            <li class="global text-greyScaleBorderDarker">
                {{footer}}
            </li>
        </ul>    
    }
</div>



    