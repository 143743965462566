<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <div class="flex flex-row gap-4 items-center">
            <p class="text-[24px] font-semibold text-primaryBlack">Finance</p> 
        </div>
    </div>
    <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="me-2" (click)="toggleWindow('General')">
                <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : activeWindow === 'General',
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : activeWindow !== 'General'}">
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen': activeWindow === 'General',
                            'group-hover:text-bg-primaryBlack/60' : activeWindow === 'General'}"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                    </svg>
                    General
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleWindow('Incomes')">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : activeWindow === 'Incomes',
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : activeWindow !== 'Incomes'}">                    
                    <svg class="w-4 h-4 me-2 rotate-[90deg]" 
                        [ngClass]="{'text-lightGreen': activeWindow === 'Incomes',
                                    'group-hover:text-bg-primaryBlack/60' : activeWindow === 'Incomes'}"  
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-labelledby="arrowRightTopIconTitle" stroke="currentColor" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="currentColor" color="currentColor"> 
                        <title id="arrowRightTopIconTitle">Arrow Right Top</title> 
                        <path d="M19 13V5h-8"/> 
                        <path stroke-linecap="round" d="M19 5l-1 1"/> <path d="M18 6L5 19"/> 
                    </svg>
                    Incomes
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleWindow('Expenses')">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : activeWindow === 'Expenses',
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : activeWindow !== 'Expenses'}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-lightGreen': activeWindow === 'Expenses',
                                    'group-hover:text-bg-primaryBlack/60' : activeWindow === 'Expenses'}"  
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-labelledby="arrowRightTopIconTitle" stroke="currentColor" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="currentColor" color="currentColor"> 
                        <title id="arrowRightTopIconTitle">Arrow Right Top</title> 
                        <path d="M19 13V5h-8"/> 
                        <path stroke-linecap="round" d="M19 5l-1 1"/> <path d="M18 6L5 19"/> 
                    </svg>
                    Expenses
                </a>
            </li>
        </ul>
    </div>
    @if (activeWindow === 'General') {
        <div class="flex flex-col w-full h-full overflow-x-hidden gap-4">
            <div id="cashflow-chart" #cashflowChart></div>
            <div class="flex flex-col md:flex-row w-full justify-between p-4 bg-primaryWhite rounded-md">
                <p class="heading-h3 text-greyScaleTextTitle">Profit</p>
                <p [ngClass]="{'text-greyScaleTextTitle' : profit === 0,
                                'text-lightGreen' : profit > 0,
                                'text-lightRed' : profit < 0}"
                    class="heading-h3">${{profit}}</p>
            </div>
            <div class="flex flex-col md:flex-row w-full items-center gap-4">
                <div class="flex flex-col w-full p-4 gap-4 bg-primaryWhite rounded-md">
                    <p class="small text-greyScaleTextCaption tracking-wide">Income</p>
                    <p class="heading-h3 text-lightGreen">${{totalIncome}}</p>
                </div>
                <div class="flex flex-col w-full p-4 gap-4 bg-primaryWhite rounded-md">
                    <p class="small text-greyScaleTextCaption tracking-wide">Expense</p>
                    <p class="heading-h3 text-lightRed">- ${{totalExpense}}</p>
                </div>
                <div class="flex flex-col w-full p-4 gap-4 bg-primaryWhite rounded-md">
                    <p class="small text-greyScaleTextCaption tracking-wide">Salaries</p>
                    <p class="heading-h3 text-greyScaleTextTitle">${{totalSalariesExpense}}</p>
                </div>
            </div>

        </div>
    }
    @if (activeWindow === 'Incomes') {
        <app-incomes-section class="w-full h-full"
            [incomes]="incomes"
        />
    }
    @if (activeWindow === 'Expenses') {
        <app-expenses-section class="w-full h-full"
            [expenses]="expenses"
            (expenseAdded)="handleExpenseAdded()"
            (expenseDeleted)="handleExpenseAdded()"
        />
    }
</div>