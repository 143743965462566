<div class="w-full max-w-md font-poppins p-4 bg-primaryWhite border border-gray-200 rounded-lg shadow sm:p-8">
    <h5 class="mb-4 text-xl font-medium">{{planName}} plan</h5>
    <div class="flex items-baseline text-gray-900 dark:text-white">
    <span class="text-[32px] font-semibold">$</span>
    <span class="text-[32px] font-extrabold tracking-tight">{{cost}}</span>
    <span class="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/month</span>
    </div>
    <ul role="list" class="space-y-5 my-7 flex flex-col gap-2">
        @for (available of availableOptions; track $index) {
            <li class="flex items-center">
                <svg class="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#39b36a" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">{{available}}</span>
            </li>
        }
        @for (unavailable of unavailableOptions; track $index) {
            <li class="flex line-through decoration-gray-500">
                <svg class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 ms-3">{{unavailable}}</span>
            </li>
        }
    </ul>
    <button type="button" class="text-white bg-lightGreen hover:bg-lightHoverGreen transition-[0.5s] font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Choose plan</button>
</div>
    