<div class="flex flex-row gap-2 p-2 w-full h-full">
    <div class="grid grid-cols-3 border rounded-md">
        <div class="flex flex-col bg-greyScaleSurfaceDefault border-r gap-2 py-2 items-center overflow-y-auto">
            <div class="flex h-[45px] relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 2xl:w-[450px] h-[45px] bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search">
            </div>
      
            <div class="flex flex-col w-full h-full overflow-y-auto">
                @for (chat of chats; track $index) {
                    <div (click)="openChat('test')">
                        <app-chat-item/>
                    </div>
                }
                <!-- <input [(ngModel)]="activeChatId">
                <button (click)="openChat()" class="p-2 bg-brandSurfaceDefault">Open chat</button> -->
            </div>
      
            <div class="flex flex-row w-full items-center justify-between p-2">
                <app-button
                    icon="../../../../assets/icons/NewChatIcon.png"
                    [primary]="true"
                    [secondary]="false"
                    text="Add new chat"
                />
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 text-greyScaleTextSubtitle hover:text-brandSurfaceDefault cursor-pointer" fill="currentColor"  viewBox="0 0 16 16">
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
            </div>
        </div>
        <div class="flex flex-col bg-greyScaleSurfaceSubtle gap-2 justify-between col-span-2 overflow-y-auto">
            @if (activeChatId === '') {

            } @else {
                <div class="flex flex-row w-full border-b py-2 px-4 justify-between items-center">
                    <div class="flex flex-col">
                        <p class="heading-h4 text-greyScaleTextTitle">Chat name {{activeChatId}}</p>
                        <div class="flex flex-row items-center gap-2">
                            <div class="flex size-2 rounded-full bg-lightGreen"></div>
                            <p class="small text-lightGreen">Offline</p>
                        </div>
                    </div>
                    <div class="flex flex-row items-center gap-4 text-greyScaleTextSubtitle transition-[0.5s]">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 hover:text-brandSurfaceDefault cursor-pointer" fill="currentColor" viewBox="0 0 1024 1024" version="1.1">
                            <path d="M724.032 564.608v-105.6c0-10.56 3.52-22.72 8.384-27.776l115.584-118.976c37.824-38.912 81.344-49.856 81.344-8.128v415.424c0 41.664-43.52 30.784-81.344-8.128l-115.584-118.976c-4.864-4.992-8.384-17.216-8.384-27.84zM669.376 681.792a64 64 0 0 1-64 64h-446.72a64 64 0 0 1-64-64V341.76a64 64 0 0 1 64-64h446.72a64 64 0 0 1 64 64v340.032z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 hover:text-brandSurfaceDefault cursor-pointer" fill="currentColor" viewBox="0 0 1025 1024" version="1.1">
                            <path d="M377.05902 307.596699 273.912422 410.856091C273.912422 410.856091 359.544473 654.596967 602.855935 740.411597L693.873355 649.251739 931.750961 705.279079C931.750961 705.279079 973.378353 716.64978 972.602059 770.393293 972.602059 770.393293 988.467044 895.713792 826.275582 964.083661 544.34478 1046.253875-8.503552 528.887707 56.460391 193.112373 56.460391 193.112373 118.561843 36.643996 255.718656 52.485174 255.718656 52.485174 291.669555 39.899702 308.747468 93.497397L377.05902 307.596699 377.05902 307.596699Z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 hover:text-brandSurfaceDefault cursor-pointer" fill="currentColor"  viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col w-full h-full overflow-y-auto py-2 px-4">
                    @for (message of messages; track $index) {
                        <app-chat-message
                            [received]="message.sender === 'participant'"
                            [content]="message.content"
                        />
                    }
                </div>
                <div class="flex flex-row gap-2 py-2 px-4">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="text-greyScaleTextSubtitle w-5 hover:text-brandSurfaceDefault cursor-pointer" fill="currentColor" viewBox="0 0 45.402 45.402" xml:space="preserve">
                        <g>
                            <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141   c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27   c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435   c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"/>
                        </g>
                    </svg>                   
                    <input [(ngModel)]="messageString" (keydown.enter)="sendMessage()" class="flex flex-wrap w-full rounded-md border border-greyScaleBorderDefault text-wrap p-2 text-greyScaleTextTitle placeholder:italic" placeholder="Type a message">
                </div>
            }
        </div>
    </div>
    @if (activeChatId !== '') {
        @if (groupChat) {
            <div class="flex flex-col w-full h-full gap-2">
                <div class="flex flex-col w-full max-w-xs h-full bg-greyScaleSurfaceDefault rounded-md p-2">
                    <p>Group info</p>
                </div>   
                <div class="flex flex-col w-full max-w-xs h-full bg-greyScaleSurfaceDefault rounded-md p-2">
                    <p>Participants</p>
                </div>   
            </div>
        }@else {
            <div class="flex flex-col w-full max-w-xs h-full bg-greyScaleSurfaceDefault rounded-md p-4 gap-4">
                <p class="heading-h4 text-greyScaleTextTitle">Contact info</p>
                <div class="flex flex-col w-full items-center gap-2">
                    <div class="flex size-32 rounded-full bg-brandSurfaceDefault"></div>
                    <p class="big text-greyScaleTextTitle">Firstname Lastname</p>
                </div>
            </div> 
        }
    }
</div>