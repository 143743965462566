import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { ProjectService } from 'src/app/services/project.service';
import { Task } from 'src/app/models/task';
import { TasksService } from 'src/app/services/tasks.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-add-new-task-modal',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './add-new-task-modal.component.html',
  styleUrl: './add-new-task-modal.component.css'
})
export class AddNewTaskModalComponent implements OnInit{

  @Input() project : any;

  public form! : FormGroup;

  @Output() taskCreated = new EventEmitter<void>();

  public projects : any[] = [];
  public employees : any[] = [];

  public activeProjectID : string = '';
  public selectedProject : any;
  public activeAssigneeID : string = '';
  public selectedAsignee : any;

  constructor(public modalService : NgbActiveModal,
    private fb : FormBuilder,
    private projectService : ProjectService,
    private taskService : TasksService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.initializeForm();
    this.getAllProjects();

    if(this.project !== null){
      this.selectedProject = this.project;
      this.getAllEmployeesForProject(this.selectedProject.ID);
    }
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      description : ['', Validators.required],
      priority: ['', Validators.required],
    })
  }

  public getAllEmployeesForProject(projectId : string) : void {
    this.projectService.getAllEmployeesOnProject(projectId).subscribe(
      (response : any) => {
        console.log("RADNICI NA PROJEKTU: ", response)
        this.employees = response.employees;
      }
    )
  }

  public addTask() : void {
    if(this.form.valid){
      const formValue = this.form.value;

      const newTask = {
        Description : formValue.description,
        Priority : formValue.priority,
        Status : "TO DO",
        ProjectId : this.selectedProject.ID,
        AssigneeId : this.selectedAsignee.EmployeeID
      };

      console.log(newTask);

      this.taskService.create(newTask).subscribe(
        (response : any) => {
          this.modalService.close(),
          this.taskCreated.emit();
        }
      )
    }
  }

  public getAllProjects() : void {
    this.projectService.getAll().subscribe(
      (response : any) => {
        this.projects = response;
        console.log("Projects: ", this.projects)
      }
    )
  }

  public setActive(project: any): void {
    if(project.ID) {
      this.activeProjectID = project.ID;
      console.log("Setovan project id: ", this.activeProjectID);
      this.getAllEmployeesForProject(this.activeProjectID);
    }
    this.selectedProject = project;
  }

  public isActive(projectId: string): boolean {
    return this.activeProjectID === projectId;
  }

  public setActiveAssignee(assigne: any): void {
    if(assigne.EmployeeID) {
      this.activeAssigneeID = assigne.EmployeeID;
      console.log("Setovan project id: ", this.activeProjectID);
    }
    this.selectedAsignee = assigne;
  }

  public isActiveAssignee(assigneId: string): boolean {
    return this.activeAssigneeID === assigneId;
  }
}
