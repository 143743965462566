<input class="w-[200px] rounded-md h-[45px]"
    [style.background]="colorList[0].value" 
    [(colorPicker)]="colorList[0].value"
    [cpOKButton] = "true"
    [cpOKButtonText] = "'Select'"
    [cpCancelButton] = "true"
    [cpSaveClickOutside] = "true"
    [cpDisableInput]="false"
    [cpAlphaChannel]="'disabled'" 
    [cpOutputFormat]="'hex'"
    [cpPresetColors]="presetValues"
    [cpAddColorButton]="true"     
    (cpSliderDragEnd)="colorSelected($event)"
    [cpOutputFormat]="'hex'"
/> 