import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownMenuComponent } from '../../dropdown-menu/dropdown-menu.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeService } from '../../../services/employee.service';

@Component({
  selector: 'app-update-skill-modal',
  standalone: true,
  imports: [DropdownMenuComponent, ButtonComponent],
  templateUrl: './update-skill-modal.component.html',
  styleUrl: './update-skill-modal.component.css'
})
export class UpdateSkillModalComponent implements OnInit{

  @Input() skillId : string = '';
  @Input() employeeId : string = '';
  @Input() selectedTech : string = '';

  @Output() skillUpdated = new EventEmitter();

  skills : any[] = [];
  levels : any[] = [];

  skillNames : string[] = [];
  levelNames : string[] = [];

  selectedSkillName : string = '';
  selectedLevelName : string = '';

  selectedSkill : any;
  selectedLevel : any;

  constructor(public modalService : NgbActiveModal,
    private companyService : CompanyService,
    private employeeService: EmployeeService
  ){}

  ngOnInit(): void {
    this.fetchCompany()
    if(this.selectedTech !== ''){
      this.selectedSkillName = this.selectedTech;
      this.selectedSkill = this.skills.find((skill : any) => skill.Name == this.selectedSkillName);
    }
  } 

  public fetchCompany() : void {
    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.skills = response.TechStack;
        this.skillNames = this.skills.map((skill : any) => skill.Name);

        this.levels = response.ExperienceLevels;
        this.levelNames = this.levels.map((level : any) => level.Name);

        console.log(response);
      }
    )
  }

  public editSkill() : void {
    this.employeeService.editSkill(this.skillId, this.selectedLevel.ID, this.employeeId).subscribe(
      (response : any) => {
        console.log(response);
        this.skillUpdated.emit();
        this.modalService.close();
      }
    )
  }

  onSkillSelected(skill: string) {
    this.selectedSkillName = skill;
    this.selectedSkill = this.skills.find((skill : any) => skill.Name == this.selectedSkillName);
  }

  onLevelSelected(level : string) {
    this.selectedLevelName = level;
    this.selectedLevel = this.levels.find((level : any) => level.Name == this.selectedLevelName);
  }
}
