import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-stack-item',
  standalone: true,
  imports: [],
  templateUrl: './tech-stack-item.component.html',
  styleUrl: './tech-stack-item.component.css'
})
export class TechStackItemComponent implements OnInit{
  @Input() tehnologyName : string = '';
  @Input() levelName : string = '';

  icon : String = ''

  ngOnInit(): void {
    if(this.tehnologyName != ''){
      this.icon = this.tehnologyName + "Icon.png";
    }
  }
}
