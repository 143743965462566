import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsService } from '../../../services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-delete-more-assets-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule],
  templateUrl: './delete-more-assets-modal.component.html',
  styleUrl: './delete-more-assets-modal.component.css'
})
export class DeleteMoreAssetsModalComponent implements OnInit{

  @Input() assetId : string = '';

  @Output() assetsDeleted = new EventEmitter<void>();

  public form! : FormGroup

  constructor(private fb : FormBuilder,
    public modalService : NgbActiveModal,
    private assetService : AssetsService
  ){}

  ngOnInit() : void {
    this.initializeForm();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      Counter : [0, [Validators.min(0), Validators.min(9999)]],
      DeleteAll: [false],
    });
  }

  public deleteAssets() {
    if(this.form.value.DeleteAll){
      this.assetService.deleteAsset(this.assetId).subscribe(
        (response : any) => {
          console.log("Assets deleted", response);
          this.assetsDeleted.emit();
          this.modalService.close();
        }
      )
    } else {
      this.assetService.deleteMoreAssets(this.assetId, this.form.value.Counter).subscribe(
        (response : any) => {
          console.log("Assets deleted", response);
          this.assetsDeleted.emit();
          this.modalService.close();
        }
      )
    }
  }

  
}
