import { Component, OnInit, TemplateRef } from '@angular/core';
import { EmployeeCardComponent } from '../../../components/employee-card/employee-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEmployeeModalComponent } from '../../../components/modals/add-employee-modal/add-employee-modal.component';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonComponent } from '../../../components/button/button.component';
import { NgToastService } from 'ng-angular-popup';
import { FormsModule } from '@angular/forms';
import { SkeletonEmployeeCardComponent } from '../../../components/loaders/skeleton-employee-card/skeleton-employee-card.component';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {CdkDragDrop, CdkDrag, CdkDragPreview,  CdkDropList, CdkDragPlaceholder, moveItemInArray, DragDropModule} from '@angular/cdk/drag-drop';
import { EmployeeListCardComponent } from '../../../components/employee-list-card/employee-list-card.component';
import {MatIconModule} from '@angular/material/icon';
import { AddAdministratorModalComponent } from '../../../components/modals/add-administrator-modal/add-administrator-modal.component';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [EmployeeCardComponent, FormsModule, SkeletonEmployeeCardComponent, 
            CommonModule, MatButtonToggleModule, EmployeeListCardComponent, MatIconModule],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.css'
})
export class EmployeesComponent implements OnInit{

  companyId : any = sessionStorage.getItem('company');

  public isLoading : boolean = true;
  public employeesCount = new Array(12);

  public filterOwners : boolean = false;
  public filterManagers : boolean = false;
  public filterEmployee : boolean = false;
  public filterAdmin : boolean = false;

  public employees : any[] = [];
  public filteredEmployees: any[] = [];
  public searchText: string = '';

  public selectedDisplay:string = 'boxes';

  constructor(private modalService : NgbModal,
    private employeeService : EmployeeService,
    private toast : NgToastService,
    private authService : AuthService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.loadEmployees();
  }

  public loadEmployees() : void {
    this.isLoading = true;
    this.filteredEmployees = [];
    this.employeeService.getAll().subscribe(
      (response : any[]) => {
        if(response) {
          this.employees = response.map(employee => {
            const requiresEditing = employee.Skills.some((skill: { Level: string; }) => skill.Level === "000000000000000000000000");
            return {
              ...employee, 
              FullName: employee.FirstName + " " + employee.LastName,
              requiresEditing
            };
          });
          this.filteredEmployees = this.employees
          this.isLoading = false;
          console.log(this.employees);
        } else {
          this.isLoading = false;
        }
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
      }
    );  
  }


  public searchEmployees(): void {
    if (!this.searchText) {
      this.filteredEmployees = this.employees;
    } else {
      this.filteredEmployees = this.employees.filter(employee =>
        employee.FullName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  public filterEmployees() : void {
    this.filteredEmployees = [];
    if(this.filterAdmin || this.filterEmployee || this.filterManagers || this.filterOwners) {
      this.filteredEmployees = this.employees.filter(employee => 
        (this.filterAdmin && employee.Role === 'Admin') || (this.filterEmployee && employee.Role === 'Employee') ||
        (this.filterManagers && employee.Role === 'ProjectManager')
      );
    } else {
      this.filteredEmployees = this.employees;
      console.log(this.filteredEmployees);
    }
  }

  openAddEmployeeModal() {
    this.goToAddEmployee();
  }

  public goToAddEmployee(): void {
    const modalRef = this.modalService.open(
      AddEmployeeModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.employeeAdded.subscribe(
      (response : any) => {
        this.loadEmployees();
        this.toast.success("Employee created successfully!", "Success!", 3000)
      }
    );
  }

  public trackByEmployeeId(index: number, employee: any): any {
    return employee.EmployeeID;
  }

  public changeDisplay(displayType:string): void {
    console.log("selected display:", displayType);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.filteredEmployees, event.previousIndex, event.currentIndex);
    this.filteredEmployees = [...this.filteredEmployees];
  }
  

  public addAdministrator() : void {
    const modalRef = this.modalService.open(
      AddAdministratorModalComponent,
      { backdrop : 'static', keyboard : true }
    );
  }
  
}
