import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { WarningModalComponent } from '../../modals/warning-modal/warning-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ApexOptions } from 'apexcharts';
//import { ConsoleReporter } from 'jasmine';

export class jsonResponse{
  numberOfAssets : number = 0;
  occupiedAssets : number = 0;
  freeAssets : number = 0;
}

@Component({
  selector: 'app-asset-availability-widget',
  standalone: true,
  imports: [],
  templateUrl: './asset-availability-widget.component.html',
  styleUrl: './asset-availability-widget.component.css'
})
export class AssetAvailabilityWidgetComponent implements OnInit{
  @Input() paramsString : string = '';
  @Input() description : string = '';
  @Input() widgetId : string = '';

  @Output() widgetDeleted = new EventEmitter<void>();

  public data : jsonResponse = new jsonResponse();

  public chartLabels : any[] = [];
  public numberOfAssets : number[] = [];

  constructor(private adminService:AdminService,
    private modalService:NgbModal,
    private toast: NgToastService
  ) { }

  ngOnInit(): void {
    this.getWidgetData();
  }


  public getWidgetData() : void {
    //console.log("nesto");
    this.adminService.getWidget(this.paramsString).subscribe(
      (response : jsonResponse) => {
        this.data = response;
        this.chartLabels.push("Free assets");
        this.chartLabels.push("Occupied assets");
        //console.log(this.chartLabels)
        //console.log(this.data.freeAssets)
        this.numberOfAssets.push(this.data.freeAssets);
        this.numberOfAssets.push(this.data.occupiedAssets);
        console.log(this.numberOfAssets);

        this.loadChart();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching data for filtered projects widget, ", error.message);
      }
    )
  }

 public loadChart(): void {
    const options: ApexOptions = {
      series: this.numberOfAssets,
      chart: {
        height: 360,
        width: "100%",
        type: 'pie',
      },
      labels: this.chartLabels,
      tooltip: {
        enabled: true,
        y: {
          formatter: (val, opts) => {
            const index = opts.dataPointIndex;
            const label = this.chartLabels[index];
            const budget = this.numberOfAssets[index];
            return `${budget.toLocaleString()}`;
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontSize: '10px',
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '20px',
        fontFamily: 'Poppins, sans-serif',
      }
    };

    // Clear previous chart instance, if any
    const chartContainer = document.querySelector('#pie-chart-asset-availability');

    const chart = new ApexCharts(chartContainer, options);
    chart.render();
  }

  
  public deleteWidget() : void {
    const modalRef = this.modalService.open(
      WarningModalComponent, 
      { backdrop : "static", keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.adminService.deleteWidget(this.widgetId).subscribe(
          (response : any) => {
            console.log(response);
            this.widgetDeleted.emit();
            this.toast.success("Widget successfully deleted!", "Success!", 3000);
          },
          (error : HttpErrorResponse) => {
            console.log(error);
            this.toast.danger("Error while deleting widget!", "Error!", 3000);
          }
        )
      }
    )
  }
}
