import { Component, OnInit } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import { CashflowService } from '../../../services/cashflow.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-cashflow-widget',
  standalone: true,
  imports: [],
  templateUrl: './cashflow-widget.component.html',
  styleUrl: './cashflow-widget.component.css'
})
export class CashflowWidgetComponent implements OnInit{

  constructor(private cashflowService : CashflowService){}

  public data : any[] = [];
  public totalIncome : number = 0;
  public totalExpense : number = 0;
  public totalSalariesExpense : number = 0;
  public profit : number = 0;

  ngOnInit(): void {
    this.getCashFlowWidgetData();
  }

  public displayCashFlow() : void {
    const months = this.data.map(data => data.month);
    const incomes = this.data.map(data => data.incomes);
    const expenses = this.data.map(data => data.expenses + data.salaries);
    console.log("MESECI: ", months);
    const options = {
      series: [
        {
          name: "Income",
          color: "#31C48D",
          // data: ["1420", "1620", "1820", "1420", "1650", "2120"],
          data : incomes
        },
        {
          name: "Expense",
          // data: ["788", "810", "866", "788", "1100", "1200"],
          color: "#F05252",
          data : expenses
        }
      ],
      chart: {
        sparkline: {
          enabled: false,
        },
        type: "bar",
        width: "100%",
        height: "100%",
        toolbar: {
          show: false,
        }
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "100%",
          borderRadiusApplication: "end",
          borderRadius: 6,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        formatter: function (value: string) {
          return "$" + value
        }
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
          },
          formatter: function(value: string) {
            return "$" + value
          }
        },
        categories: months,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
          }
        }
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -20
        },
      },
    }

    if(document.getElementById("bar-chart") && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(document.getElementById("bar-chart"), options);
      chart.render();
    }
  }


  public getCashFlowWidgetData() : void {
    this.cashflowService.getWidgetData().subscribe(
      (response : any) => {
        this.data = response;
        console.log("CASHFLOW WIDGET DATA: ", this.data);
        this.displayCashFlow();
        this.calculateTotalIncomeAndExpense();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching cashflow widget data, ", error);
      }
    )
  }

  public calculateTotalIncomeAndExpense() :  void {
    this.data.forEach(data => {
      this.totalIncome = this.totalIncome + data.incomes;
      this.totalExpense = this.totalExpense + data.expenses + data.salaries;
      this.totalSalariesExpense = this.totalSalariesExpense + data.salaries;
    })

    this.profit = this.totalIncome - this.totalExpense;
  }
  
}
