import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Project } from '../models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projectSource = new Subject<any>();
  createdPositions$ = this.projectSource.asObservable();

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient) { }

  public addPosition(position: any) {
    this.projectSource.next(position);
  }

  public getAll() : Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiServerUrl}/project/getAll`)
  }

  public get(projectId : any) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/getById/${projectId}`);
  }

  public createPosition(body : any) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/project/addPosition`, body)
  }

  public create(body : any) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/project/create`, body)
  }

  public assignEmployeeToPosition(positionId: string, employeeId: string): Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/project/setEmployeeOnPosition/${positionId}/${employeeId}`, {});
  }

  public removePositionFromProject(projectId : string, positionId : string) : Observable<any> {
    return this.http.patch<any>(`${this.apiServerUrl}/project/removePosition/${projectId}/${positionId}`, {});
  }

  public edit(params : string, projectId : string) : Observable<any>{
    return this.http.patch<any>(`${this.apiServerUrl}/project/edit/${projectId}?${params}`, {});
  }

  public editPosition(params : string, projectId : string, positionId : string) : Observable<any> {
    return this.http.patch<any>(`${this.apiServerUrl}/project/editPosition/${projectId}/${positionId}?${params}`, {});
  }

  public deleteProject(projectId : string) : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/project/delete/${projectId}`);
  }

  public deletePosition(projectId : string, positionId : string) : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/project/${projectId}/deletePosition/${positionId}`);
  } 

  public setManager(projectId: string, employeeId : string) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/project/${employeeId}/${projectId}`, {});
  }

  public getAvailableEmployees(projectId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/project/find/employees/for/project?projectID=${projectId}`);
  }

  public removeMemberFromProject(employeeId : string, projectId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/project/delete/employee/from/project?employeeID=${employeeId}&projectID=${projectId}`);
  }

  public getAllEmployeesOnProject(projectId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/project/get/all/employees/on/project?projectID=${projectId}`)
  }
}
