<div class="flex items-center w-[800px] h-auto justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Add members']}}</p>
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <div class="flex flex-col w-full h-full gap-4">
            <div class="flex flex-row w-full gap-2 items-center bg-greyScaleSurfaceDisabled border-[1px] border-greyScaleBorderDefault rounded-md px-4 py-2">
                <img src="../../../../assets/icons/SupportIcon.png" class="size-[20px]"/>
                <p class="small text-greyScaleBorderDarker">
                    {{translationService.translations?.variables['Choose one of the following employees to add him to the project as individual.']}}
                </p>
            </div>
            <div class="flex flex-col w-full rounded-md border-[1px] border-primaryBlack/20 h-full p-4 gap-4">
                <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Available employees']}}</p>
                <div class="flex flex-row flex-wrap justify-evenly items-center max-h-[600px] overflow-y-auto gap-4">
                    @for (employee of employees; track $index) {
                        <div class="flex flex-row min-w-[300px] max-w-auto items-center justify-center p-4 border-[1px] rounded-md gap-4
                                    hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                            [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(employee),
                                    'border-[1px]':!isActive(employee)}"
                            (click)="setActive(employee)">
                            <app-small-employee-card-content
                                [memberID]="employee.EmployeeID"
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="flex w-full justify-end">
            <button [disabled]="!selected" (click)="select()"
                    [ngClass]="{'bg-greyScaleBorderDisabled text-greyScaletTextBody': !selected, 'bg-brandSurfaceDefault hover:bg-brandBorderDarker text-greyScaleSurfaceDefault': selected}"
                    class="flex items-center justify-center px-8 py-2 w-[220px] rounded-md transition-[0.5s] ">
                {{translationService.translations?.variables['Add']}}
            </button>
        </div>
    </div>
</div>