<div class="flex flex-col w-full h-auto items-start font-poppins gap-2 overflow-y-auto py-2 px-4">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Settings']}}</p> 
    </div>
    <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="me-2" (click)="toggleGeneral()">
                <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showGeneral,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showGeneral}">
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen':showGeneral,
                            'group-hover:text-bg-primaryBlack/60' : showGeneral}"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                    </svg>
                    {{translationService.translations?.variables['General']}}
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleTech()">
                @if(company.TechStack.length === 0){
                    <div class="size-[15px] bg-lightYellow rounded-full absolute top-4 left-4"></div>
                }
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showTechStack,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showTechStack}">                    
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen':showTechStack,
                                    'group-hover:text-bg-primaryBlack/60' : showTechStack}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V3zM0 5h16v3H0V5zm0 4h16v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-3zm0 0v3v-3z"/>
                    </svg>
                    {{translationService.translations?.variables['Tech stack']}}
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleExperience()">
                @if(company.ExperienceLevels.length === 0){
                    <div class="size-[15px] bg-lightYellow rounded-full absolute top-4 left-4"></div>
                }
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showExperienceLevels,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showExperienceLevels}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-lightGreen':showExperienceLevels,
                                    'group-hover:text-bg-primaryBlack/60' : showExperienceLevels}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.612 15.443c-.387.198-.824-.149-.746-.592l.78-4.295-3.148-3.065c-.329-.321-.154-.888.283-.94l4.34-.63 1.938-3.932c.176-.356.653-.356.829 0l1.939 3.932 4.34.63c.438.052.612.619.283.94l-3.148 3.065.78 4.295c.078.443-.359.79-.746.592L8 13.187l-3.862 2.256z"/>
                    </svg>
                    {{translationService.translations?.variables['Experience levels']}}
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleProfessions()">
                @if(company.Professions.length === 0){
                    <div class="size-[15px] bg-lightYellow rounded-full absolute top-4 left-4"></div>
                }
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showProfessions,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showProfessions}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-lightGreen':showProfessions,
                                    'group-hover:text-bg-primaryBlack/60' : showProfessions}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path xmlns="http://www.w3.org/2000/svg" d="m14.25 9.25v-3.25l-6.25-3.25-6.25 3.25 6.25 3.25 3.25-1.5v3.5c0 1-1.5 2-3.25 2s-3.25-1-3.25-2v-3.5"/>                    
                    </svg>
                    {{translationService.translations?.variables['Professions']}}
                </a>
            </li>
            <li class="me-2" (click)="toggleApprovers()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showApprovers,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showApprovers}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-lightGreen':showApprovers,
                                    'group-hover:text-bg-primaryBlack/60' : showApprovers}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M13.854 3.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 9.793l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    {{translationService.translations?.variables['Administration']}}
                </a>
            </li>
        </ul>
    </div>
    @if (!settingsCompleted) {
        <div class="flex flex-row w-full items-center p-4 gap-4 rounded-md bg-lightYellow/30">
            <img src="../../../../assets/icons/SupportIcon.png" class="size-[25px]"/>
            <div class="flex flex-col w-full">
                <p class="text-primaryBlack text-[18px] font-medium">{{translationService.translations?.variables['Setup your company']}}</p>
                <p class="text-primaryBlack text-[14px] font-light">{{translationService.translations?.variables['In order to be able to continue using the application and use its full potential, please fill in the techstack, experience levels and professions. You will need these values ​​in order to be able to use the other functionalities of the application']}}.<br>
                    {{translationService.translations?.variables['This message will disappear the moment you add at least one item to each of the sections']}}.
                </p>
            </div>
        </div>
    }
    @if (showGeneral) {
        <section class="flex flex-col w-full h-auto gap-4">
            <div class="flex flex-col w-full h-auto rounded-md bg-primaryWhite p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['General']}}</p> 
                    <button class="flex items-center justify-center group" (click)="toggleEdit()">
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                            <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Edit']}}</p>
                        </div>
                    </button>
                </div>
                <div class="flex flex-col w-full gap-4">
                    <div class="flex flex-col md:flex-row w-full items-center gap-4">
                        <div class="flex items-center justify-start w-15">
                            <div class="flex 4xl:size-[250px] 2xl:size-[140px] 3xl:size-[160px] sm:size-[70px] lg:size-[100px] xl:size-[120px] img-container rounded-full border-[1px] border-primaryBlack/20">
                                <img [src]="companyLogoLink" class="rounded-full size-full"/>
                            </div>
                        </div>
                        <div class="flex flex-col w-full items-center md:items-start">
                            <p class="heading-h1 text-greyScaleTextTitle">{{company?.Name}}</p>
                            <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-light text-primaryBlack">{{translationService.translations?.variables['Foundation date']}}: <span class="font-medium">{{displayDate}}</span></p>                        
                        </div>
                    </div>
                    <hr class="text-primaryBlack/40 w-full">
                    <div class="flex flex-col md:flex-row w-full items-center md:items-start justify-start">
                        <div class="flex flex-col items-start justify-start w-full gap-4">
                            <div class="flex flex-col w-full">
                                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Tax ID']}}</p>
                                <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">{{company?.TaxID}}</p>
                            </div>
                            <div class="flex flex-col w-full">
                                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Employee portal']}}</p>
                                <div class="flex flex-row w-full items-center gap-4">
                                    <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">https://dev.portal.firmegenerale.cloud/{{company?.Domain}}</p>
                                    <button (click)="copyToClipboard()">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" class="hover:text-brandBorderDefault size-6" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet">
                                            <title>copy-to-clipboard-line</title>
                                            <path d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z" class="clr-i-outline clr-i-outline-path-1"/><path d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z" class="clr-i-outline clr-i-outline-path-2"/><path d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z" class="clr-i-outline clr-i-outline-path-3"/><path d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z" class="clr-i-outline clr-i-outline-path-4"/>
                                            <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full h-full justify-start gap-4">
                            <div class="flex flex-col items-start justify-start w-full gap-4">
                                <div class="flex flex-col w-full">
                                    <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Email address']}}</p>
                                    <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">{{company?.Email}}</p>
                                </div>
                            </div>
                            <div class="flex flex-col items-start justify-start w-full gap-4">
                                <div class="flex flex-col w-full">
                                    <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Language']}}</p>

                                    <app-dropdown-menu class="z-[1]"
                                        [inputPlaceHolder]="dropDownInputPlaceholder"
                                        [data]="languages"
                                        width="50%"
                                        (selectionChanged)="onCountrySelected($event)"
                                    /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
            <div class="flex flex-col md:max-4xl:flex-row items-center justify-between py-2 w-full px-4 bg-brandBorderSubtle border-[1px] border-brandBorderDefault rounded-md">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../../assets/icons/Star.png" class="size-3"/>
                    <p class="global text-brandBorderDefault">You are currently using [PLAN_NAME] plan</p>
                </div>
                <app-button 
                    text="Update plan"
                    [function]="navigateToPlans.bind(this)"
                />
            </div>
            <div class="flex flex-col w-full gap-2">
                <p class="text-[24px] text-primaryBlack">{{translationService.translations?.variables['Danger zone']}}</p>
                <div class="flex flex-col w-full rounded-md border-[1px] border-lightRed p-4">
                    <div class="flex flex-row w-full items-center justify-between">
                        <div class="flex flex-col w-full gap-2">
                            <p class="text-greyScaleTextTitle big-bold">{{translationService.translations?.variables['Delete company']}}</p>
                            <p class="text-greyScaleTextTitle global">{{translationService.translations?.variables['This action implies the complete deletion of the company from the system, as well as all projects, employees and everything else that has anything to do with the company']}}.</p>
                        </div>
                        <button class="flex px-4 py-2 items-center justify-center text-lightRed rounded-md border-[1px] border-lightRed w-[220px]
                                        hover:bg-lightRed hover:text-primaryWhite transition-[0.5s]"
                                (click)="deleteCompany()">
                            <p>{{translationService.translations?.variables['Delete company']}}</p>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    } @else if (showTechStack) {
        <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center gap-4">
                <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Tech stack']}}</p>
                <button class="py-2 px-4 rounded-md text-greyScaleSurfaceDefault hover:bg-brandBorderDarker transition-[0.5s] bg-brandSurfaceDefault small"
                        (click)="openAddTechStackModal()">
                    <p class="text-[12px] text-primaryWhite">{{translationService.translations?.variables['Add new']}}</p>
                </button>
            </div>
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 overflow-y-auto max-h-[600px]">
                @if(techStacks.length === 0){
                    <div class="flex w-full gap-2 flex-col col-span-full">
                        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no tech stacks to display']}}</p>
                        <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first tech stack by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                    </div>
                } @else {
                    @for (Tech of techStacks; track $index) {
                        <div class="flex w-[180px] md:w-[250px] flex-col p-4 rounded-md border-[1px] relative hover:border-lightGreen transition-[0.5s]">
                            <button class="flex flex-row gap-2 items-center group absolute top-4 right-4" (click)="deleteStack('TechStack', Tech.ID, $event)">
                                <img src="../../../assets/icons/TrashIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Delete</p>
                            </button>
                            <div class="flex flex-row w-full items-center justify-start">
                                <img src="../../../assets/icons/{{Tech.Name}}Icon.png" class="size-[40px]" onerror="this.onerror=null; this.src='../../../assets/icons/GeneralTechIcon.png'"/>
                            </div>
                            <div class="flex flex-row w-full items-center justify-start mt-4">
                                <p class="text-[16px] font-regular text-primaryBlack truncate text-ellipsis">{{Tech.Name}}</p>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
    } @else if (showExperienceLevels) {
        <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center gap-4">
                <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Experience levels']}}</p>
                <button class="py-2 px-4 rounded-md text-greyScaleSurfaceDefault hover:bg-brandBorderDarker transition-[0.5s] bg-brandSurfaceDefault small"
                        (click)="openAddExperienceLevelModal()">
                    <p class="text-[12px] text-primaryWhite">Add new</p>
                </button>
            </div>
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 overflow-y-auto max-h-[600px]">
                @if (experienceLevels.length === 0) {
                    <div class="flex w-full gap-2 flex-col col-span-full">
                        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no experience levels to display']}}</p>
                        <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first experience level by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                    </div>
                } @else {
                    @for (Level of experienceLevels; track $index) {
                        <div class="flex w-[180px] md:w-[250px] flex-col px-4 py-12 rounded-md border-[1px] relative hover:border-lightGreen items-center justify-center transition-[0.5s]"
                            [ngStyle]="{'background-color': getBackgroundColor($index)}">
                            <button class="flex flex-row gap-2 items-center group absolute top-4 right-4" (click)="deleteStack('ExperienceLevels', Level.ID, $event)">
                                <img src="../../../assets/icons/TrashIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Delete</p>
                            </button>
                            <p class="text-[24px] font-medium truncate text-ellipsis" [ngStyle]="{'color': getColor($index)}">{{Level.Name}}</p>
                        </div>
                    }
                }
            </div>
        </div>
    } @else if (showProfessions) {
        <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center gap-4">
                <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Professions']}}</p>
                <button class="py-2 px-4 rounded-md text-greyScaleSurfaceDefault hover:bg-brandBorderDarker transition-[0.5s] bg-brandSurfaceDefault small"
                        (click)="openAddProfessionModal()">
                    <p class="text-[12px] text-primaryWhite">{{translationService.translations?.variables['Add new']}}</p>
                </button>
            </div>
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 overflow-y-auto max-h-[600px]">
                @if (professions.length === 0) {
                    <div class="flex w-full gap-2 flex-col col-span-full">
                        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no professions to display']}}</p>
                        <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first profession by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                    </div>
                } @else {
                    @for (Profession of professions; track $index) {
                        <div class="flex flex-col p-4 w-[180px] md:w-[250px] rounded-md border-[1px] relative hover:border-lightGreen transition-[0.5s]">
                            <button class="flex flex-row gap-2 items-center group absolute top-4 right-4"
                                    (click)="deleteStack('Professions', Profession.ID, $event)">
                                <img src="../../../assets/icons/TrashIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Delete']}}</p>
                            </button>
                            <div class="flex flex-row w-full items-center justify-start">
                                <img src="../../../assets/icons/ProfessionIcon.png" class="size-[40px]"/>
                            </div>
                            <div class="flex flex-row w-full items-center justify-start mt-4">
                                <p class="text-[16px] font-regular text-primaryBlack truncate text-ellipsis">{{Profession.Name}}</p>
                            </div>
                        </div>
                    }
                }
            </div>
        </div> 
    } @else if(showApprovers){
        <div class="flex flex-col w-full gap-4">
            <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center gap-4">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Vacation approvers']}}</p>
                    <button class="py-2 px-4 rounded-md text-greyScaleSurfaceDefault hover:bg-brandBorderDarker transition-[0.5s] bg-brandSurfaceDefault small"
                            (click)="addApprover()">
                        <p class="text-[12px] text-primaryWhite">{{translationService.translations?.variables['Add new']}}</p>
                    </button>
                </div>
                <div class="flex flex-col w-full gap-2">
                    <div class="relative flex w-full flex-col border-[1px] border-primaryBlack/20 rounded-md font-poppins">
                        @if (isLoading) {
                            <div role="status" class="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                                @for (skeleton of approversCount; track $index) {
                                    <div class="flex h-20 items-center justify-between">
                                        <div class="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-48"></div>
                                        <div class="w-32 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                    </div>
                                }
                                <span class="sr-only">Loading...</span>
                            </div>            
                        } @else {
                            @if (company.VacationApprovers != null) {
                                @if(company.VacationApprovers.length > 0) {
                                    <table class="w-full text-sm text-left rounded-md rtl:text-right text-primaryBlack">
                                        <thead class="sm:text-sm md:text-md xl:text-md text-primaryBlack rounded-md uppercase bg-primaryBlack/5">
                                            <tr>
                                                <th scope="col" class="px-6 py-3">
                                                    {{translationService.translations?.variables['Email']}}
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                    {{translationService.translations?.variables['Action']}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @for (approver of company.VacationApprovers; track $index){
                                                <tr class="bg-primaryWhite border-b transition-[0.5s] items-center">
                                                    <th scope="row" class="flex items-center px-6 py-2 text-primaryBlack whitespace-nowrap dark:text-white gap-4">
                                                        <div class="py-4 sm:text-sm md:text-md xl:text-md font-semibold">{{approver}}</div>
                                                    </th>
                                                    <td class="px-6 py-4 gap-2">
                                                        <div class="flex flex-row w-full justify-center items-center gap-2 group cursor-pointer transition-[0.5s] p-2 bg-lightRed/20 rounded-md hover:bg-lightRed/35" (click)="deleteApprover(approver)">
                                                            <img src="../../../assets/icons/TrashIcon.png" class="size-[20px] icon-filter-red"/> 
                                                            <p class="text-darkRed text-[16px]">{{translationService.translations?.variables['Delete']}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                } @else {  
                                    <div class="flex w-full gap-2 p-4 flex-col">
                                        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no vacation approvers']}}</p>
                                        <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first vacation approver by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                                    </div>
                                }
                            } @else {  
                                <div class="flex w-full gap-2 p-4 flex-col">
                                    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no vacation approvers']}}</p>
                                    <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first vacation approver by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                                </div>
                            }
                        }
                    </div>
                </div> 
            </div> 
            <div class="flex flex-col w-full bg-primaryWhite rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center gap-4">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Admins']}}</p>
                    <button class="py-2 px-4 rounded-md text-greyScaleSurfaceDefault hover:bg-brandBorderDarker transition-[0.5s] bg-brandSurfaceDefault small"
                            (click)="addAdministrator()">
                        {{translationService.translations?.variables['Invite']}}
                    </button>
                </div>
                <div class="flex flex-col w-full gap-2">
                    <div class="relative flex w-full flex-col border-[1px] border-primaryBlack/20 rounded-md font-poppins">
                        @if (isLoading) {
                            <div role="status" class="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                                @for (skeleton of approversCount; track $index) {
                                    <div class="flex h-20 items-center justify-between">
                                        <div class="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-48"></div>
                                        <div class="w-32 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                    </div>
                                }
                                <span class="sr-only">Loading...</span>
                            </div>            
                        } @else {
                            @if (company.CompanyAdministrators != null) {
                                @if(company.CompanyAdministrators.length > 0) {
                                    <table class="w-full text-sm text-left rounded-md rtl:text-right text-primaryBlack">
                                        <thead class="sm:text-sm md:text-md xl:text-md text-primaryBlack rounded-md uppercase bg-primaryBlack/5">
                                            <tr>
                                                <th scope="col" class="px-6 py-3">
                                                    {{translationService.translations?.variables['Email']}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @for (administrator of company.CompanyAdministrators; track $index){
                                                <tr class="bg-primaryWhite border-b transition-[0.5s] items-center">
                                                    <th scope="row" class="flex items-center px-6 py-2 text-primaryBlack whitespace-nowrap dark:text-white gap-4">
                                                        <div class="py-4 sm:text-sm md:text-md xl:text-md font-semibold">{{administrator}}</div>
                                                    </th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                } @else {  
                                    <div class="flex w-full gap-2 p-4 flex-col">
                                        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no administrators']}}</p>
                                        <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first administrator by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Invite']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                                    </div>
                                }
                            } @else {  
                                <div class="flex w-full gap-2 p-4 flex-col">
                                    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no administrators']}}</p>
                                    <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first administrator by pressing the']}}  <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Invite']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                                </div>
                            }
                        }
                    </div>
                </div> 
            </div> 
        </div>
    }
</div>