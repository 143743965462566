import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { ActivityService } from 'src/app/services/activity.service';
import { GenerateActivityDropdownComponent } from '../../generate-activity-dropdown/generate-activity-dropdown.component';
import { start } from '@popperjs/core';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-generate-report-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, GenerateActivityDropdownComponent],
  templateUrl: './generate-report-modal.component.html',
  styleUrl: './generate-report-modal.component.css'
})
export class GenerateReportModalComponent implements OnInit {

  @Input() employeeId: string = "";

  public selectedReportType: string = "";
  public isDropdownOpen: boolean = false;

  public startDate: number = 0;
  public endDate: number = 0;

  public buttonDisabled: boolean = true;

  public companyDomain : string = "";

  constructor(
    public modalService: NgbActiveModal,
    private activityService: ActivityService,
    private ngToast: NgToastService,
    private companyService : CompanyService
  ) { }

  ngOnInit(): void {
    //console.log("This is employee ID: ", this.employeeId);
    const companyId = sessionStorage.getItem("company");
    this.companyService.getMyCompaines().subscribe(
      (response : any) => {
        const ownedCompanies = response.owned;
        const company = ownedCompanies.find((comp : {id : string}) => comp.id === companyId);
        this.companyDomain = company.domain;
        console.log("THIS IS THE COMPANY DOMAIN:", this.companyDomain);
      }
    );
  }

  public onStartDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const selectedDate = input.value;
    this.startDate = new Date(selectedDate).getTime();
    console.log("This is the start date:", this.startDate);
  }

  public onEndDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const selectedDate = input.value;
    this.endDate = new Date(selectedDate).getTime();
    console.log("This is the end date:", this.endDate);
  }

  public generatePDFReport() {
    console.log('Generating PDF report...');
    this.activityService.getFilteredActivities(this.employeeId, this.startDate, this.endDate).subscribe(
      (response: any) => {
        //console.log("ACTIVITIJI: ", response)
        const activities: any[] = response;
        if (activities == null) {
          console.error("Activities are empty.");
          this.ngToast.danger("There are no activities for selected date range.");
        } else {
          this.activityService.reportPdf(this.employeeId, activities, this.startDate, this.endDate, this.companyDomain).subscribe(
            (res: any) => {
              let file = new Blob([res], { type: 'application/pdf' });
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            }
          )
        }
      },
      (error: HttpErrorResponse) => {
        console.log("ERROR", error);
        if (error.status === 400) {
          this.ngToast.danger("An error has occurred while generating PDF report.");
        }
      }
    );
    this.isDropdownOpen = false;
  }

  public generateCSVReport() {
    console.log('Generating CSV report...');
    this.activityService.getFilteredActivities(this.employeeId, this.startDate, this.endDate).subscribe(
      (response: any) => {
        const activities: any[] = response;
        if (activities == null) {
          console.error("Activities are empty.");
          this.ngToast.danger("There are no activities for selected date range.");
        } else {
          this.activityService.reportCsv(this.employeeId, activities, this.startDate, this.endDate, this.companyDomain).subscribe(
            (res: any) => {
              // let file = new Blob([res], { type: 'text/csv' });
              // let reader = new FileReader();
              // reader.onload = function (event: any) {
              //   let csvContent = event.target.result;
              //   var newWindow = window.open();
              //   if (newWindow) {
              //     newWindow.document.write(
              //       '<pre>' + csvContent + '</pre>'
              //     );
              //   }
              // };
              // reader.readAsText(file);
              // console.log(res);
            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        console.log("Hey again this is another error response: ", error.error.message);
        if (error.error === "The activities you supplied are not valid.") {
          this.ngToast.danger("There are no activities for specified date range");
        } else if (error.status === 400) {
          this.ngToast.danger("An error has occurred while generating CSV report.");
        }
        console.log('ERROR', error);
      }
    );
    this.isDropdownOpen = false;
  }



  public generateXLSXReport() {
    console.log('Generating XLSX report...');
    this.activityService.getFilteredActivities(this.employeeId, this.startDate, this.endDate).subscribe(
      (response: any) => {
        const activities: any[] = response;
        //console.log("These are activities:",activities);
        if (activities == null) {
          console.error("Activities are empty.");
          this.ngToast.danger("There are no activities for selected date range.");
        } else {
          console.log("These are the activities in xlsx report: ", activities);
          this.activityService.reportXls(this.employeeId, activities, this.startDate, this.endDate, this.companyDomain).subscribe(
            (res: any) => {
              const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'report.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);

            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        if (error.error === "The activities you supplied are not valid.") {
          this.ngToast.danger("There are no activites for selected date range");
        } else if (error.status === 400) {
          this.ngToast.danger("An error has occurred while generating XLSX report.");
        }
        console.log('ERROR', error);
      }
    );
    this.isDropdownOpen = false;
  }

  public handleReportType(selectedType: string) {
    this.selectedReportType = selectedType;
    this.buttonDisabled = false;
    //console.log("This is the selected report type : ", this.selectedReportType);
  }

  public generateReport() {
    console.log("This is the selected report type : ", this.selectedReportType);
    if (this.selectedReportType === "pdf") {
      this.generatePDFReport();
    } else if (this.selectedReportType === "csv") {
      this.generateCSVReport();
    } else if (this.selectedReportType === "xlsx") {
      this.generateXLSXReport();
    }
  }
}
