import { Component, EventEmitter, Output, Query } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from "../../../button/button.component";
import { AdminService } from '../../../../services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-projects-filtered-modal',
  standalone: true,
  imports: [FormsModule, ButtonComponent],
  templateUrl: './projects-filtered-modal.component.html',
  styleUrl: './projects-filtered-modal.component.css'
})
export class ProjectsFilteredModalComponent {

  @Output() widgetAdded = new EventEmitter<void>();

  public description : string = '';
  public startDate : string = '';
  public endDate : string = '';
  public budget : number = 0;

  public budgetFilter : string = '';
  public endDateFilter : string = '';
  public startDateFilter : string = '';

  public params : string[] = [];
  public paramString : string = '';

  constructor(private activeModalService : NgbActiveModal,
    private modalService : NgbModal,
    private adminService : AdminService
  ){}

  ngOnInit(): void {}

  addParams() {
    this.params = [];

    if (this.startDate) {
      const date = new Date(this.startDate);
      const startDateMillis = date.getTime();
      this.params.push(`startDate=${this.startDateFilter}${startDateMillis}`);
    }

    if (this.endDate) {
      const date = new Date(this.endDate);
      const endDateMillis = date.getTime();
      this.params.push(`endDate=${this.endDateFilter}${endDateMillis}`);
    }

    console.log(this.params);

    const tmpParamString = this.convertParamsToString(this.params);
    this.paramString = "createProjectWidget?" + tmpParamString;
    console.log(this.paramString);
  }

  convertParamsToString(params: string[]): string {
    if (params.length === 0) {
      return '';
    }
    return params.join('&');
  }

  public saveWidget() : void {
    this.addParams();
    const body = {
      Name : "ProjectsFilteredByParameters",
      Description : this.description,
      Querry : this.paramString
    }
    console.log(body);
    this.adminService.saveWidget(body).subscribe(
      (response : any) => {
        console.log("Widget saved successfully", response);
        this.widgetAdded.emit();
        this.closeModal();
      }
    );
  }

  public closeModal() : void {
    this.activeModalService.close();
  }
}
