import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropdownMenuComponent } from '../../dropdown-menu/dropdown-menu.component';
import { ButtonComponent } from '../../button/button.component';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeService } from '../../../services/employee.service';
import { CommonModule } from '@angular/common';
import { EmployeeSkill } from '../../../models/skill';

@Component({
  selector: 'app-add-skill-modal',
  standalone: true,
  imports: [FormsModule, DropdownMenuComponent, ButtonComponent, CommonModule],
  templateUrl: './add-skill-modal.component.html',
  styleUrl: './add-skill-modal.component.css'
})
export class AddSkillModalComponent implements OnInit{

  @Input() addedSkills : any[] = [];
  @Input() existingEmployee : boolean = false;
  @Input() employeeId : string = '';

  @Output() skillAdded = new EventEmitter<void>();

  skills : any[] = [];
  availableSkills : any[] = [];
  levels : any[] = [];

  skillNames : string[] = [];
  availableSkillNames : string[] = [];
  levelNames : string[] = [];

  selectedSkillName : string = '';
  selectedLevelName : string = '';

  selectedSkill : any;
  selectedLevel : any;

  constructor(private modalService : NgbActiveModal,
    private employeeService : EmployeeService,
    private companyService : CompanyService){}

  ngOnInit(): void {
    this.fetchCompany();
    console.log("POSTOJECI SKILOVI: ", this.addedSkills);
  }

  public fetchCompany() : void {
    const companyId = sessionStorage.getItem('company')

    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.skills = response.TechStack;

        this.existingEmployee ?
          this.availableSkills = this.skills.filter(
            skill => !this.addedSkills.some(addedSkill => addedSkill.Skill === skill.ID)
          ) :
          this.availableSkills = this.skills.filter(
            skill => !this.addedSkills.some(addedSkill => addedSkill.skill === skill.ID)
          );

        this.skillNames = this.availableSkills.map((skill : any) => skill.Name);

        this.levels = response.ExperienceLevels;
        this.levelNames = this.levels.map((level : any) => level.Name);

        console.log(response);
      }
    )
  }

  onSkillSelected(skill: string) {
    this.selectedSkillName = skill;
    this.selectedSkill = this.skills.find((skill : any) => skill.Name == this.selectedSkillName);
  }

  onLevelSelected(level : string) {
    this.selectedLevelName = level;
    this.selectedLevel = this.levels.find((level : any) => level.Name == this.selectedLevelName);
  }

  public closeModal(): void {
		this.modalService.close();
	}

  public addSkill(): void {
    const skill = {
      skill: this.selectedSkill.ID,
      level: this.selectedLevel.ID
    };
    this.employeeService.addSkill(skill);
    this.modalService.close();
  }

  public addSkillToExistingEmployee() : void {
    if(this.existingEmployee){
      const body = {
        Skill : this.selectedSkill.ID,
        Level : this.selectedLevel.ID
      }

      console.log("NEW SKILL: ", body);

      this.employeeService.addSkillToExistingEmployee(this.employeeId, body).subscribe(
        (response : any) => {
          this.skillAdded.emit();
          this.modalService.close();
        }
      )
    }
  }


  public isActiveTechStack(techName: string): boolean {
    return this.selectedSkillName === techName;
  }

  public isActiveLevel(levelName: string): boolean {
    return this.selectedLevelName === levelName;
  }
}
