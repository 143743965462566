import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ButtonComponent } from '../../../components/button/button.component';
import { ProjectCardComponent } from '../../../components/project-card/project-card.component';
import { Status } from '../../../models/project-status';
import { Project } from '../../../models/project';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddWidgetModalComponent } from '../../../components/modals/add-widget-modal/add-widget-modal.component';
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectsWidgetComponent } from '../../../components/widgets/projects-widget/projects-widget.component';
import { NgToastService } from 'ng-angular-popup';
import { TotalBudgetWidgetComponent } from "../../../components/widgets/total-budget-widget/total-budget-widget.component";
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import { AssetAvailabilityWidgetComponent } from '../../../components/widgets/asset-availability-widget/asset-availability-widget.component';
import { CashflowWidgetComponent } from "../../../components/widgets/cashflow-widget/cashflow-widget.component";
import { CommonModule } from '@angular/common';
import { ActivityService } from 'src/app/services/activity.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [ButtonComponent, ProjectsWidgetComponent, TotalBudgetWidgetComponent, TotalBudgetWidgetComponent, 
            AssetAvailabilityWidgetComponent, AssetAvailabilityWidgetComponent, CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit{

  public hasTotalBudgetWidgetFlag : boolean = false;
  public hasAssetAvailabilityFlag : boolean = false;

  public showLogs : boolean = false;
  public showWidgets : boolean = true;

  public companyId = sessionStorage.getItem('company');

  public widgets : any[] = [];
  public filteredWidgets : any[] = [];

  public displayedLogs : any[] = [];
  public pageNum : number = 1;

  
  @Output() widgetDeleted = new EventEmitter<void>();

  constructor(private modalService : NgbModal,
    private authService : AuthService,
    private toast : NgToastService,
    private activityService : ActivityService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.fetchAdmin();
    this.activityService.getLogs(1,20).subscribe(
      (Response : any) => {
        console.log("this is the log response: ", Response);

        this.displayedLogs = Response;
      }
    );
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.widgets, event.previousIndex, event.currentIndex);
    this.widgets = [...this.filteredWidgets];

    
  }

  public fetchAdmin() : void {
    const domain = sessionStorage.getItem('domain')
    // this.authService.getUserInfo(domain).subscribe(
    this.authService.getAdmin().subscribe(
      (response : any) => {
        this.widgets = response.Widgets;
        console.log("WIDGETI: ", this.widgets);
        this.widgets.map((widget : any) => {
          if(widget.Name === 'GetOverallBudget') {
            this.hasTotalBudgetWidgetFlag = true;
          } 
          if(widget.Name == 'CreateAssetWidget') {
            this.hasAssetAvailabilityFlag = true;
          }
        })
        this.filteredWidgets = this.widgets.filter(widget => 
          widget.Company.includes(this.companyId)
        )
        this.filterWidgets();
      }
    )
  }

  public handleWidgetDeleted() : void {
    this.fetchAdmin();
    this.filterWidgets();

    this.widgetDeleted.emit();
  }

  public filterWidgets() {
    this.filteredWidgets = this.widgets.filter(widget => 
      widget.Company.includes(this.companyId)
    )

    console.log("FILTRIRANI: ", this.filteredWidgets);
  }

  openAddWidgetModal() {
    this.goToAddWidget();
  }

  public goToAddWidget(): void {
    const modalRef = this.modalService.open(
      AddWidgetModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    console.log("Does admin have total budget widget?" , this.hasTotalBudgetWidgetFlag)
    modalRef.componentInstance.hasTotalBudgetWidget = this.hasTotalBudgetWidgetFlag;
    console.log("Does admin have asset availability widget?" , this.hasAssetAvailabilityFlag)
    modalRef.componentInstance.hasAssetAvailabilityWidget = this.hasAssetAvailabilityFlag;
    modalRef.componentInstance.widgetAdded.subscribe(
      (response : any) => {
        this.fetchAdmin();
        this.toast.success("Widget created successfully!", "Success!", 3000)
      }
    );
  }

  public toggleLogs() : void {
    this.showLogs = true;
    this.showWidgets = false;

    //console.log("Show logs:", this.showLogs, "    Show widgets:", this.showWidgets);
  }

  public toggleWidgets() : void {
    this.showLogs = false;
    this.showWidgets = true;
    //console.log("Show logs:", this.showLogs, "    Show widgets:", this.showWidgets);
  }

  public getParsedDate(dateInMillis : number) : string {
    const date = new Date(dateInMillis);
    const dateParsed = date.toISOString().substring(0,10);
    return dateParsed;
  }

  public fetchLogs(pageNum : number, numOfRows : number) : void {
    this.activityService.getLogs(pageNum,numOfRows).subscribe(
      (Response : any) => {
        this.displayedLogs = [...this.displayedLogs, ...Response];
      }
    );
  }

  @HostListener('scroll', ['$event'])
  public onScroll(event : any) : void {
    const element = event.target;
    console.log("Scrolling");
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
      this.pageNum = this.pageNum + 1;
      this.fetchLogs(this.pageNum,10);
    }
  }

}
