<form [formGroup]="form">
    <div class="flex items-center w-[800px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Add new task</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <div class="flex flex-col w-full">
                        @if (form.get('description')?.touched && form.get('description')?.invalid) {
                            <label for="description" class="text-lightRed font-regular text-[12px]">Description (Required)</label>
                        } @else {
                            <label for="description" class="text-primaryBlack/70 font-regular text-[12px]">Description</label>
                        }
                        <textarea formControlName="description" placeholder="Description" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[100px] max-h-[200px] text-primaryBlack focus:outline-lightGreen"></textarea>
                    </div>
                    <div class="flex flex-row items-center gap-2 w-full">
                        <div class="flex flex-col w-full gap-2">
                            <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">Priority</p>
                            <div class="flex flex-row items-center gap-4">
                                <label class="flex items-center gap-2">
                                    <input type="radio" formControlName="priority" [value]="'Low'" class="h-4 w-4">
                                    <span class="text-[14px] text-primaryBlack">Low</span>
                                </label>
                                <label class="flex items-center gap-2">
                                    <input type="radio" formControlName="priority" [value]="'Mid'" class="h-4 w-4">
                                    <span class="text-[14px] text-primaryBlack">Mid</span>
                                </label>
                                <label class="flex items-center gap-2">
                                    <input type="radio" formControlName="priority" [value]="'Top'" class="h-4 w-4">
                                    <span class="text-[14px] text-primaryBlack">Top</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr class="w-full text-primaryBlack">
            <div [ngClass]="{'border-[1px] p-4 max-h-[200px]' : project === null}" 
                class="flex flex-col w-full gap-2 border-primaryBlack/20 rounded-md">
                <p class="text-primaryBlack/50 text-[14px]">Project</p>
                @if (project === null) {
                    <div class="flex flex-row flex-wrap w-full items-center justify-evenly overflow-y-auto">
                        @for (project of projects; track $index) {
                            <div class="flex flex-row max-w-auto items-center justify-center px-4 py-2 border-[1px] rounded-md gap-4
                                        hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                                [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(project.ID),
                                        'border-[1px]':!isActive(project.ID)}"
                                (click)="setActive(project)">
                                <p class="text-[14px] text-primaryBlack">{{project.Name}}</p>
                            </div>
                        }
                    </div>
                } @else {
                    <p>{{project.Name}}</p>
                }
            </div>

            <div class="flex flex-col w-full gap-2 border-primaryBlack/20 rounded-md border-[1px] p-4 max-h-[200px]">
                <p class="text-primaryBlack/50 text-[14px]">Assign to the employee</p>
                <div class="flex flex-row flex-wrap w-full items-center justify-evenly overflow-y-auto">
                    @for (employee of employees; track $index) {
                        <div class="flex flex-row max-w-auto items-center justify-center px-4 py-2 border-[1px] rounded-md gap-4
                                    hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                            [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveAssignee(employee.EmployeeID),
                                    'border-[1px]':!isActiveAssignee(employee.EmployeeID)}"
                            (click)="setActiveAssignee(employee)">
                            <p class="text-[14px] text-primaryBlack">{{employee.FirstName}} {{employee.LastName}}</p>
                        </div>
                    }
                </div>
            </div>

            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="addTask()"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    Add task
                </button>
            </div>
        </div>
    </div>
</form>
