<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-scroll font-poppins gap-4 overflow-y-auto py-2 px-4">
    <div class="flex flex-row w-full gap-2 items-center">
        <a [routerLink]="['/departments']">
            <img src="../../../../assets/icons/BackIcon.png"  class="size-[30px] cursor-pointer"/>
        </a>
        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Department details']}}</p> 
    </div>
    <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="me-2" (click)="toggleGeneral()">
                <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                    [ngClass]="{'text-brandSurfaceDefault border-b-2 border-brandSurfaceDefault' : showGeneral,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showGeneral}">
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-brandSurfaceDefault':showGeneral,
                            'group-hover:text-bg-primaryBlack/60' : showGeneral}"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                    </svg>
                    {{translationService.translations?.variables['General']}}
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleOffices()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-brandSurfaceDefault border-b-2 border-brandSurfaceDefault' : showOffices,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showOffices}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-brandSurfaceDefault':showOffices,
                                    'group-hover:text-bg-primaryBlack/60' : showOffices}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.612 15.443c-.387.198-.824-.149-.746-.592l.78-4.295-3.148-3.065c-.329-.321-.154-.888.283-.94l4.34-.63 1.938-3.932c.176-.356.653-.356.829 0l1.939 3.932 4.34.63c.438.052.612.619.283.94l-3.148 3.065.78 4.295c.078.443-.359.79-.746.592L8 13.187l-3.862 2.256z"/>
                    </svg>
                    {{translationService.translations?.variables['Offices']}}
                </a>
            </li>

            <li class="me-2 relative" (click)="toggleVenues()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-brandSurfaceDefault border-b-2 border-brandSurfaceDefault' : showVenues,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showVenues}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-brandSurfaceDefault':showVenues,
                                    'group-hover:text-bg-primaryBlack/60' : showVenues}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.612 15.443c-.387.198-.824-.149-.746-.592l.78-4.295-3.148-3.065c-.329-.321-.154-.888.283-.94l4.34-.63 1.938-3.932c.176-.356.653-.356.829 0l1.939 3.932 4.34.63c.438.052.612.619.283.94l-3.148 3.065.78 4.295c.078.443-.359.79-.746.592L8 13.187l-3.862 2.256z"/>
                    </svg>
                    {{translationService.translations?.variables['Venues']}}
                </a>
            </li>
        </ul>
    </div>

    @if (showGeneral) {
        <section class="flex flex-col w-full h-auto gap-4">
            <div class="flex flex-col w-full h-auto rounded-md bg-primaryWhite p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['General']}}</p> 
                    <button class="flex items-center justify-center group">
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                            <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Edit']}}</p>
                        </div>
                    </button>
                </div>
                <div class="flex flex-col w-full gap-4">
                    <div class="flex flex-col md:flex-row w-full items-center gap-4">
                        <!-- <div class="flex items-center justify-start w-15">
                            <div class="flex 4xl:size-[250px] 2xl:size-[140px] 3xl:size-[160px] sm:size-[70px] lg:size-[100px] xl:size-[120px] img-container rounded-full border-[1px] border-primaryBlack/20">
                                <img [src]="companyLogoLink" class="rounded-full size-full"/>
                            </div>
                        </div> -->
                        <div class="flex flex-col w-full items-center md:items-start">
                            <p class="heading-h1 text-greyScaleTextTitle">{{department.Name}}</p>
                        </div>
                    </div>
                    <hr class="text-primaryBlack/40 w-full">
                    <div class="flex flex-col md:flex-row w-full items-center md:items-start justify-start">
                        <div class="flex flex-col items-start justify-start w-full gap-4">
                            <div class="flex flex-col w-full">
                                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Address']}}</p>
                                <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">{{parsedAddress[0]}}</p>
                            </div>
                            <div class="flex flex-col w-full">
                                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Postal code']}}</p>
                                <div class="flex flex-row w-full items-center gap-4">
                                    <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">{{parsedAddress[1]}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start w-full gap-4">
                            <div class="flex flex-col w-full">
                                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['City']}}</p>
                                <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">{{parsedAddress[2]}}</p>
                            </div>
                            <div class="flex flex-col w-full">
                                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Country']}}</p>
                                <div class="flex flex-row w-full items-center gap-4">
                                    <p class="sm:text-[16px] lg:text-[20px] xl:text-[24px] font-medium text-primaryBlack">{{parsedAddress[3]}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
            <div class="flex flex-col w-full gap-2">
                <p class="text-[24px] text-primaryBlack">{{translationService.translations?.variables['Danger zone']}}</p>
                <div class="flex flex-col w-full rounded-md border-[1px] border-lightRed p-4">
                    <div class="flex flex-row w-full items-center justify-between">
                        <div class="flex flex-col w-full gap-2">
                            <p class="text-greyScaleTextTitle big-bold">{{translationService.translations?.variables['Delete department']}}</p>
                            <p class="text-greyScaleTextTitle global">{{translationService.translations?.variables['This action implies the complete deletion of the department from the system']}}.</p>
                        </div>
                        <button class="flex px-4 py-2 items-center justify-center text-lightRed rounded-md border-[1px] border-lightRed w-[220px]
                                        hover:bg-lightRed hover:text-primaryWhite transition-[0.5s]"
                                (click)="deleteDepartment()">
                            <p>{{translationService.translations?.variables['Delete department']}}</p>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    }

    @if (showOffices) {
        <div class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
            <ng-container *ngIf="!isLoading; else skeletons">
                <ng-container *ngIf="department.Offices.length > 0; else noOffices">
                    <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-greyScaleBorderDefault group hover:bg-greyScaleBorderDisabled
                                items-center justify-center transition-[0.5s] cursor-pointer"
                            (click)="addOffice()">
                        <img src="../../../../assets/icons/PlusIcon.png" class="size-12 md:size-12 xl:size-12 2xl:size-14 opacity-40 group-hover:opacity-100">
                        <p class="big opacity-[50%] group-hover:opacity-[100%] text-greyScaleTextTitle group-hover:text-greyScaleTextTitle">{{translationService.translations?.variables['Add new']}}</p>
                    </button>
                    @for (office of department.Offices; track $index) {
                        <app-office-card
                            [office]="office"
                            [freeSeatsCounter]="countFreeSeats(office)"
                        />
                    }
                </ng-container>
            </ng-container>
            <ng-template #skeletons>
                <app-skeleton-employee-card *ngFor="let skeleton of skeletonCount"/>
            </ng-template>
            <ng-template #noOffices>
                <div class="flex w-full flex-col gap-2 col-span-full">
                    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no offices to display']}}</p>
                    <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can add new office to the department by pressing the']}} <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                </div>
                    <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-greyScaleBorderDefault group hover:bg-greyScaleBorderDisabled
                                items-center justify-center transition-[0.5s] cursor-pointer"
                            (click)="addOffice()">
                        <img src="../../../../assets/icons/PlusIcon.png" class="size-12 md:size-12 xl:size-12 2xl:size-14 opacity-40 group-hover:opacity-100">
                        <p class="big opacity-[50%] group-hover:opacity-[100%] text-greyScaleTextTitle group-hover:text-greyScaleTextTitle">{{translationService.translations?.variables['Add new']}}</p>
                    </button>
            </ng-template>
        </div>
    }

    @if (showVenues) {
        <div class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
            <ng-container *ngIf="!isLoading; else skeletons">
                <ng-container *ngIf="department.Venues.length > 0; else noVenues">
                    <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-greyScaleBorderDefault group hover:bg-greyScaleBorderDisabled
                                items-center justify-center transition-[0.5s] cursor-pointer"
                            (click)="addVenue()">
                        <img src="../../../../assets/icons/PlusIcon.png" class="size-12 md:size-12 xl:size-12 2xl:size-14 opacity-40 group-hover:opacity-100">
                        <p class="big opacity-[50%] group-hover:opacity-[100%] text-greyScaleTextTitle group-hover:text-greyScaleTextTitle">{{translationService.translations?.variables['Add new']}}</p>
                    </button>
                    @for (venue of department.Venues; track $index) {
                        <app-venue-card (click)="navigationService.navigateToVenue(department.ID, venue.ID)"
                            [venue]="venue"
                        />
                    }
                </ng-container>
            </ng-container>
            <ng-template #skeletons>
                <app-skeleton-employee-card *ngFor="let skeleton of skeletonCount"/>
            </ng-template>
            <ng-template #noVenues>
                <div class="flex w-full flex-col gap-2 col-span-full">
                    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no venues to display']}}</p>
                    <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can add new venue to the department by pressing the']}} <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                </div>
                <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-greyScaleBorderDefault group hover:bg-greyScaleBorderDisabled
                            items-center justify-center transition-[0.5s] cursor-pointer"
                        (click)="addVenue()">
                    <img src="../../../../assets/icons/PlusIcon.png" class="size-12 md:size-12 xl:size-12 2xl:size-14 opacity-40 group-hover:opacity-100">
                    <p class="big opacity-[50%] group-hover:opacity-[100%] text-greyScaleTextTitle group-hover:text-greyScaleTextTitle">{{translationService.translations?.variables['Add new']}}</p>
                </button>
            </ng-template>
        </div>
    }
</div>