import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit{

  public user : any;
  public companies : any[] = [];

  constructor(private adminService : AdminService,
    private companyService : CompanyService
  ){}

  ngOnInit(): void {
    this.getAdmin();
  }

  public getAdmin() : void {
    this.adminService.getAdmin().subscribe(
      (response : any) => {
        this.user = response;
        console.log(this.user);
      }
    )
  }
}
