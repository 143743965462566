import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { SkeletonEmployeeCardComponent } from 'src/app/components/loaders/skeleton-employee-card/skeleton-employee-card.component';
import { AddOfficeModalComponent } from 'src/app/components/modals/add-office-modal/add-office-modal.component';
import { AddVenueModalComponent } from 'src/app/components/modals/add-venue-modal/add-venue-modal.component';
import { OfficeCardComponent } from 'src/app/components/office-card/office-card.component';
import { VenueCardComponent } from 'src/app/components/venue-card/venue-card.component';
import { Department } from 'src/app/models/department';
import { Office } from 'src/app/models/office';
import { Venue } from 'src/app/models/venue';
import { NavigationService } from 'src/app/services/navigation.service';
import { OfficeManagementService } from 'src/app/services/office-management.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-department-details',
  standalone: true,
  imports: [RouterModule, CommonModule, SkeletonEmployeeCardComponent, OfficeCardComponent, VenueCardComponent],
  templateUrl: './department-details.component.html',
  styleUrl: './department-details.component.css'
})
export class DepartmentDetailsComponent implements OnInit{

  public department! : Department;
  public parsedAddress : any;

  public isLoading : boolean = false;
  public showGeneral : boolean = true;
  public showOffices : boolean = false;
  public showVenues : boolean = false;

  public skeletonCount = new Array(5)

  constructor(public translationService : TranslationService,
    private route : ActivatedRoute,
    private modalService : NgbModal,
    private officeManagementService : OfficeManagementService,
    private toast : NgToastService,
    public navigationService : NavigationService
  ){}

  ngOnInit(): void {
    const idFromPath = this.route.snapshot.paramMap.get('id');
    if(idFromPath) {
      this.getDepartment(idFromPath);
    }
  }

  public getDepartment(departmentId : string) : void {
    this.officeManagementService.getDepartment(departmentId).subscribe(
      (response : Department) => {
        console.log("Fetched department: ", response);
        this.department = response;
        this.parsedAddress = this.department.Address.split(',');
      }
    )
  }

  public deleteDepartment() : void {

  }

  public addOffice() : void {
    const modalRef = this.modalService.open(
      AddOfficeModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.departmentId = this.department.ID;

    modalRef.componentInstance.officeCreated.subscribe(
      () => {
        this.toast.success("Office successfully created!", "Success!", 3000);
        this.getDepartment(this.department.ID);
        this.showOffices = true;
      }
    )
  }

  public addVenue() : void {
    const modalRef = this.modalService.open(
      AddVenueModalComponent,
      { backdrop : 'static', keyboard : true}
    );

    modalRef.componentInstance.departmentId = this.department.ID;

    modalRef.componentInstance.venueCreated.subscribe(
      () => {
        this.toast.success("Venue successfully created!", "Success!", 3000);
        this.getDepartment(this.department.ID);
        this.showVenues = true;
      }
    )
  }

  public countFreeSeats(office : Office) : number {
    const freeSeats = office.Seats.filter(seat => seat.Reserved === false)
    return freeSeats.length;
  }

  public toggleGeneral(){
    this.showGeneral = true;
    this.showOffices = false;
    this.showVenues = false;
  }

  public toggleOffices(){
    this.showGeneral = false;
    this.showOffices = true;
    this.showVenues = false;
  }

  public toggleVenues(){
    this.showGeneral = false;
    this.showOffices = false;
    this.showVenues = true;
  }
}
