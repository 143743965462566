import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CashflowService } from '../../services/cashflow.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Income } from '../../models/income';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { BindingType } from '@angular/compiler';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateIncomeModalComponent } from '../modals/create-income-modal/create-income-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { ViewIncomeOrExpenseModalComponent } from '../modals/view-income-or-expense-modal/view-income-or-expense-modal.component';
import { EditIncomeOrExpenseComponent } from '../modals/edit-income-or-expense/edit-income-or-expense.component';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-incomes-section',
  standalone: true,
  imports: [FormsModule, ButtonComponent, ReactiveFormsModule],
  templateUrl: './incomes-section.component.html',
  styleUrl: './incomes-section.component.css'
})
export class IncomesSectionComponent implements OnInit{
  
  @Input() incomes : Income[] = [];

  public isLoading : boolean = false;
  public searchText : string = '';
  public totalIncome : number = 0;

  public processedIncomes : any[] = [];
  public incomesCount = new Array(10);
  public filteredIncomes: any[] = [];

  constructor(private cashFlowService : CashflowService,
    private modalService : NgbModal,
    private toast : NgToastService,
    public translationService : TranslationService
  ) {}
  
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['incomes'] && changes['incomes'].currentValue) {
      console.log("RECEIVED INCOMES: ", this.incomes);
      this.processIncomes();
    }
  }

  public processIncomes() : any {
    this.isLoading = true;
    this.totalIncome = 0;
    this.processedIncomes = this.incomes.map(income => {
      this.totalIncome += income.Ammount
      const date = new Date(income?.PlanedPaymentDate);
      const paymentDateParsed = date.toISOString().substring(0,10);
      return {
        ...income,
        parsedPaymentDate : paymentDateParsed
      };
    });
    console.log("PROCESIRANI: ", this.processedIncomes)
    this.filteredIncomes = this.processedIncomes;
    if(this.processedIncomes !== null && this.processedIncomes.length > 0){
      this.searchIncomes();
    }

    this.isLoading = false;
  }

  public getAllIncomes() : void {
    this.isLoading = true;
    this.totalIncome = 0;
    this.cashFlowService.getAllIncomes().subscribe(
      (response : Income[]) => {
        this.processedIncomes = response.map(income => {
          this.totalIncome += income.Ammount
          const date = new Date(income.PlanedPaymentDate);
          const paymentDateParsed = date.toISOString().substring(0,10);
          return {
            ...income,
            parsedPaymentDate : paymentDateParsed
          };
        });
        this.filteredIncomes = this.processedIncomes;
        if(this.processedIncomes !== null && this.processedIncomes.length > 0){
          this.searchIncomes();
        }

        this.isLoading = false;
      }
    )
  }

  public searchIncomes(): void {
    if (!this.searchText) {
      this.processedIncomes = this.filteredIncomes;
    } else {
      this.processedIncomes = this.filteredIncomes.filter(income =>
        income.Name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  public addNewIncome() : void {
    const modalRef = this.modalService.open(
      CreateIncomeModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.incomeAdded.subscribe(
      () => {
        this.toast.success("Income successfully added!", "Success!", 3000);
        this.getAllIncomes();
      }
    )
  }

  public deleteIncome(incomeId : string) : void {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.cashFlowService.deleteIncome(incomeId).subscribe(
          (response : any) => {
            this.toast.success("Income successfully deleted!", "Success!", 3000);
            console.log("Income successfully deleted, ", response);
            this.getAllIncomes();
          }
        )
      }
    )
  }

  public viewIncome(income : Income) : void {
    const modalRef = this.modalService.open(
      ViewIncomeOrExpenseModalComponent,
      { backdrop : 'static', keyboard : true },
    )

    modalRef.componentInstance.isIncome = true;
    modalRef.componentInstance.income = income;
  }

  public editIncome(income : Income) : void {
    const modalRef = this.modalService.open(
      EditIncomeOrExpenseComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.isIncome = true;
    modalRef.componentInstance.income = income;
  }
}
