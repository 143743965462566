import { Component } from '@angular/core';

@Component({
  selector: 'app-denied-page',
  standalone: true,
  imports: [],
  templateUrl: './denied-page.component.html',
  styleUrl: './denied-page.component.css'
})
export class DeniedPageComponent {

}
