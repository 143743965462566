<div class="flex items-center flex-col justify-center bg-primaryWhite font-poppins p-4 gap-8">
    @if (isIncome) {
        <!--Income-->
        <div class="flex flex-col w-full h-full bg-white rounded-md gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Edit income</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-col w-full gap-2">
                <!-- <div class="flex flex-row w-full items-center justify-between">
                    <p class="font-semibold text-primaryBlack text-[24px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">{{income?.Name}}</p>
                    <p class="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-lightGreen">{{income?.Ammount}}</p>
                </div> -->
                <div class="flex flex-col w-full gap-2">
                    <p class="text-primaryBlack text-xs sm:text-sm lg:text-md xl:text-lg">Income name: </p>
                    <input formControlName="name" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] p-4 text-primaryBlack focus:outline-lightGreen" [placeholder]="income?.Name"/>
                </div>
                <div class="flex flex-col w-full gap-2">
                    <p class="text-primaryBlack text-xs sm:text-sm lg:text-md xl:text-lg">Payer: </p>
                    <input formControlName="payer" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] p-4 text-primaryBlack focus:outline-lightGreen" [placeholder]="income?.Payer"/>
                </div>
                <div class="flex flex-col w-full gap-2">
                    <p class="text-primaryBlack text-xs sm:text-sm lg:text-md xl:text-lg">Project: </p>
                    <input formControlName="project" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] p-4 text-primaryBlack focus:outline-lightGreen" [placeholder]="project?.Name"/>
                </div>
                <div class="flex flex-col w-full gap-2">
                    <p class="text-primaryBlack text-xs sm:text-sm lg:text-md xl:text-lg">Payment date: </p>
                    <input formControlName="paymentDate" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] p-4 text-primaryBlack focus:outline-lightGreen" [placeholder]="parsedDate"/>
                </div>
                <!-- <div class="flex flex-col w-full">
                    
                    <p class="text-primaryBlack text-[16px] font-light">Payer: <span class="font-medium">{{income?.Payer}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Project: <span class="font-medium">{{project?.Name}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Payment date: <span class="font-medium">{{parsedDate}}</span></p>
                </div>     -->
            </div>
            <div class="flex flex-col w-full overfloy-y-auto">
                <p class="text-primaryBlack text-xs sm:text-sm lg:text-md xl:text-lg">Description: </p>
                <textarea class="w-full p-2 rounded-md focus:outline-lightGreen min-h-[200px] max-h-[350px]"
                            [placeholder]="income?.Description"></textarea>
            </div>
        </div>
    } @else {
        <!--Expense-->
        <div class="flex flex-col w-full h-full bg-white rounded-md gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Edit expense</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-col w-full gap-2">
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="font-semibold text-primaryBlack text-[24px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">{{expense?.name}}</p>
                    <p class="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-lightGreen">{{expense?.amount}}</p>
                </div>
                <hr class="w-full text-primaryBlack/50">
                <div class="flex flex-col w-full">
                    <p class="text-primaryBlack text-[16px] font-light">Frequency: <span class="font-medium">{{expense?.frequency}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Creation date: <span class="font-medium">{{creationDate}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Payment date: <span class="font-medium">{{parsedDate}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Cycles: <span class="font-medium">{{expense?.cycles}}</span></p>
                </div>    
            </div>
        </div>
    }
    <div class="flex flex-row w-full justify-end items-center">
        <app-button
            text="Save changes"
        />
    </div>
</div>