<div class="flex flex-col md:flex-row w-full gap-4">
    <div class="flex flex-col w-full rounded-md bg-white p-4 gap-8">
        <div class="flex flex-row w-full items-center justify-between justify-center">
            <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Net salary']}}</p>
            @if (!editingNet) {
                <button class="flex items-center justify-center group" (click)="toggleEditNet()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Edit']}}</p>
                    </div>
                </button>
            } @else {
                <button class="flex items-center justify-center group" (click)="cancelEditNet()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Cancel']}}</p>
                    </div>
                </button>
            }
        </div>
        <div class="flex flex-col w-full items-start justify-center gap-4">
            @if (!editingNet) {
                <p class="heading-h2 text-lightGreen">${{netSalary}}</p>
            } @else {
                <div class="flex flex-col w-full gap-[4px]">
                    <input [(ngModel)]="tmpNet" class="w-full text-wrap text-[28px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-full p-2 text-primaryBlack focus:outline-lightGreen"
                           type="number" [placeholder]="netSalary">
                    <div class="flex flex-row w-full justify-end">
                        <button class="flex py-2 px-4 text-[12px] rounded-md bg-primaryBlack hover:bg-lightGreen text-white transition-[0.5s]" (click)="save()">
                            {{translationService.translations?.variables['Save']}}
                        </button>
                    </div>
                </div>
            }
            <div class="flex flex-row items-center w-full justify-between justify-center mt-2">
                <p class="global text-greyScaleTextBody">
                    <span class="text-lightGreen global-bold">${{netPerHour}}</span> /{{translationService.translations?.variables['Hour']}}
                </p>
                <p class="global text-greyScaleTextBody">
                    <span class="text-lightGreen global-bold">${{netPerDay}}</span> /{{translationService.translations?.variables['Day']}}
                </p>
                <p class="global text-greyScaleTextBody">
                    <span class="text-lightGreen global-bold">${{netPerYear}}</span> /{{translationService.translations?.variables['Year']}}
                </p>
            </div>
        </div>
    </div>
    <div class="flex flex-col w-full rounded-md bg-white p-4 gap-8">
        <div class="flex flex-row w-full items-center justify-between justify-center">
            <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Gross salary']}}</p>
            @if (!editingGross) {
                <button class="flex items-center justify-center group" (click)="toggleEditGross()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables["Edit"]}}</p>
                    </div>
                </button>
            } @else {
                <button class="flex items-center justify-center group" (click)="cancelEditGross()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Cancel']}}</p>
                    </div>
                </button>
            }
        </div>    
        <div class="flex flex-col w-full items-start justify-center gap-4">
            @if (!editingGross) {
                <p class="heading-h2 text-lightGreen">${{grossSalary}}</p>
            } @else {
                <div class="flex flex-col w-full gap-[4px]">
                    <input [(ngModel)]="tmpGross" class="w-full text-wrap text-[28px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-full p-2 text-primaryBlack focus:outline-lightGreen"
                           type="number" [placeholder]="grossSalary">
                    <div class="flex flex-row w-full justify-end">
                        <button class="flex py-2 px-4 text-[12px] rounded-md bg-primaryBlack hover:bg-lightGreen text-white transition-[0.5s]" (click)="save()">
                            {{translationService.translations?.variables['Save']}}
                        </button>
                    </div>
                </div>
            }
            <div class="flex flex-row items-center w-full justify-between justify-center mt-2">
                <p class="global text-greyScaleTextBody">
                    <span class="text-lightGreen global-bold">${{grossPerHour}}</span> /{{translationService.translations?.variables['Hour']}}
                </p>
                <p class="global text-greyScaleTextBody">
                    <span class="text-lightGreen global-bold">${{grossPerDay}}</span> /{{translationService.translations?.variables['Day']}}
                </p>
                <p class="global text-greyScaleTextBody">
                    <span class="text-lightGreen global-bold">${{grossPerYear}}</span> /{{translationService.translations?.variables['Year']}}
                </p>
            </div>
        </div>
    </div>
    <div class="flex flex-col w-full rounded-md bg-white p-4 gap-8 z-1 relative">
        <div class="flex flex-row w-full items-center justify-between justify-center z-1">
            <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Vacation status']}}</p>
            <button class="flex items-center justify-center group">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">{{translationService.translations?.variables['Edit']}}</p>
                </div>
            </button>
        </div>    
        <!--CHART SECTION-->
        <div class="flex flex-row items-start justify-between w-full max-h-[80px] z-10">
            <div class="flex flex-col w-[40%] w-auto items-start justify-start gap-4">
                <p class="text-[16px] font-light text-primaryBlack">
                    <span class="heading-h2 text-lightGreen">{{this.remainingDaysOff}}</span> {{translationService.translations?.variables['Of']}} <span class="heading-h2 text-greyScaleTextBody"> {{this.remainingDaysOff}}</span>
                </p>
                <p class="global text-greyScaleTextBody mt-2">{{translationService.translations?.variables['Days left']}}</p>   
            </div>
            <div id="chart" class="w-[60%] overflow-y-hidden"></div>
        </div>
    </div>
</div>