import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamsService } from '../../../services/teams.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../button/button.component';
import { ColorTranslator } from 'colortranslator';

@Component({
  selector: 'app-add-team-to-project',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './add-team-to-project.component.html',
  styleUrl: './add-team-to-project.component.css'
})
export class AddTeamToProjectComponent implements OnInit{

  @Input() project : any;

  @Output() teamAdded = new EventEmitter<void>();

  public teams : any[] = [];
  public selectedTeamId : string = '';

  constructor(public modalService : NgbActiveModal,
    public teamService : TeamsService
  ){}

  ngOnInit(): void {
    this.fetchTeams();
  }

  public fetchTeams() : void {
    this.teamService.getAll().subscribe(
      (response : any) => {
        this.teams = response;
      }
    )
  }

  public getBadgeBackgroundColor(team : any) : string {
    const color_rgb = new ColorTranslator(team.ColorHash);
    const badgeRGBA = "rgba(" + color_rgb.R + ", " + color_rgb.G + ", " + color_rgb.B + ", 0.2)"

    return badgeRGBA;
  }

  public setActive(team : any) : void {
    this.selectedTeamId = team.ID;
  }

  public isActive(teamId: string): boolean {
    return this.selectedTeamId === teamId;
  }

  public addTeamToProject() : void {
    console.log("Selected team id: ", this.selectedTeamId);
    console.log("Selected project id: ", this.project.ID);

    this.teamService.addTeamToProject(this.selectedTeamId, this.project.ID).subscribe(
      (response : any) => {
        console.log("Team successfully added to the project, ", response);
        this.teamAdded.emit();
        this.modalService.close();
      }
    )
  }

}
