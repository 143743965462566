import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Employee } from '../../../models/employee';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeCardComponent } from '../../employee-card/employee-card.component';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonComponent } from "../../button/button.component";
import { ProjectService } from '../../../services/project.service';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-assign-employee-modal',
  standalone: true,
  imports: [EmployeeCardComponent, CommonModule, ButtonComponent],
  templateUrl: './assign-employee-modal.component.html',
  styleUrl: './assign-employee-modal.component.css'
})
export class AssignEmployeeModalComponent implements OnInit{

  @Input() positionID : any;
  @Input() primarySkillID : any;
  @Input() requiredSkillLevelID : any;
  @Input() cost: any;
  @Input() positionName: any;

  @Output() employeeAssignedModal = new EventEmitter<void>();

  public professions : any[] = [];
  public techStacks : any[] = [];
  public experienceLevels : any[] = [];

  // public positionName : string = '';
  public primarySkillName : string = '';
  public requiredSkillLevelName : string = '';

  public matchingEmployees : any[] = [];

  public imagePath : any;
  public imageString : string = '';

  public activeEmployeeId : string = '';
  public selectedEmployee : any;


  constructor(private modalService : NgbActiveModal,
    private projectService : ProjectService,
    private employeeService : EmployeeService,
    private sanitizer : DomSanitizer,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.fetchSessionStorageCompanyData();
    this.getPositionDataForDisplay();
    this.findMatchingEmployees();
  }

  public setActive(employee: any): void {
    this.activeEmployeeId = employee.EmployeeID;
    this.selectedEmployee = employee;
  }

  public isActive(employeeId: string): boolean {
    return this.activeEmployeeId === employeeId;
  }

  public getEmployeePicture(employeePicture: string) {
    if (employeePicture !== '') {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + employeePicture);
    }
    return this.imagePath;
  }

  public fetchSessionStorageCompanyData() : void {
    const techStackData = sessionStorage.getItem('techStack');
    const experienceLevelsData = sessionStorage.getItem('experienceLevels');
    const professionsData = sessionStorage.getItem('professions')
   
    if(techStackData && experienceLevelsData && professionsData){
      this.techStacks = JSON.parse(techStackData);
      this.experienceLevels = JSON.parse(experienceLevelsData);
      this.professions = JSON.parse(professionsData);
    } else {
      console.log("Error while parsing data from session storage!");
    }
  }

  public getPositionDataForDisplay() : void {
    this.techStacks.map((stack : any) => {
      if(stack.ID == this.primarySkillID) {
        this.primarySkillName = stack.Name;
      }
    });
    this.experienceLevels.map((level : any) => {
      if(level.ID == this.requiredSkillLevelID) {
        this.requiredSkillLevelName = level.Name
      }
    })
  }

  public findMatchingEmployees() : void {
    const body = {
      primarySkill : this.primarySkillID,
      skillLevel: this.requiredSkillLevelID,
      cost : this.cost
    }

    this.employeeService.getMatchingEmployeesForPosition(body).subscribe(
      (response : any[]) => {
        this.matchingEmployees = response;
        console.log(this.matchingEmployees);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching matching employees for position, ", error.message);
      }
    )
  }

  public assignEmployee() : void {
    console.log("Position: ", this.positionID);
    console.log("Employee: ", this.selectedEmployee.EmployeeID)
    this.projectService.assignEmployeeToPosition(this.positionID, this.selectedEmployee.EmployeeID).subscribe(
      (response : any) => {
        console.log("Employee successfully assigned to position, ", response);
        this.employeeAssignedModal.emit();
        this.modalService.close();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while assigning employe to possition, ", error.message);
        this.toast.danger("Error while assigning employee to position!", "Error!", 3000);
      }
    )
  }

  public closeModal() : void {
    this.modalService.close();
  }
}
