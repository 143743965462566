import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ButtonComponent } from '../../../components/button/button.component';
import { HttpErrorResponse } from '@angular/common/http';
import { VerifyEmailModalComponent } from '../../../components/modals/verify-email-modal/verify-email-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from '../../../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import  { newPasswordRequestBody } from '../../../dtos/newPasswordRequestDTO';
import { NavigationService } from 'src/app/services/navigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ButtonComponent, FormsModule, ReactiveFormsModule, ButtonComponent, CommonModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent implements OnInit {

  form!: FormGroup;
  public isLoading: boolean = false;
  public verified: boolean = false;

  public isEmailValid: boolean = false;
  public email: string = '';
  public emailIsFound: boolean = false;

  public isRequestSent: boolean = false;

  public passwordHidden: boolean = true;
  public repeatedPasswordHidden: boolean = true;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private toast: NgToastService,
    private modalService: NgbModal,
    private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, this.passwordStrengthValidator]],
      repeatPassword: ['', [Validators.required]],
    }, { validators: this.passwordMatchValidator });
  }

  public verifyMail(email: string) {
    
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    this.isEmailValid = emailPattern.test(email);
  
    //console.log(this.isEmailValid);

    if (this.isEmailValid) {
      this.authService.verifyEmailExistence(email).subscribe(
        (response) => {
          //console.log(response);
          this.emailIsFound = true;
          console.log(this.emailIsFound);
        },
        (error) => {
          //console.error('Error verifying email adress: ', error);
          this.emailIsFound = false;
          console.log(this.emailIsFound);
        }
      );
    }
  }

  public passwordMatchValidator(group: FormGroup): ValidationErrors | null {
    const password = group.get('password')?.value;
    const repeatPassword = group.get('repeatPassword')?.value;
    return password === repeatPassword ? null : { passwordMismatch: true };
  }

  public passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    let errors = {};

    if (!value.match(/[A-Z]/)) {
      errors = { ...errors, uppercase: 'Password must include at least one uppercase letter' };
    }
    if (!value.match(/[0-9]/)) {
      errors = { ...errors, digit: 'Password must include at least one number' };
    }
    if (!value.match(/[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\{\}\[\]\:\"\|\;\'\\\,\.\<\>\?]/)) {
      errors = { ...errors, symbol: 'Password must include at least one symbol' };
    }
    if (value.length < 6) {
      errors = { ...errors, length: 'Password must be at least 6 characters long' };
    }

    return Object.keys(errors).length > 0 ? errors : null;
  }


  public showHidePassword() {
    this.passwordHidden = !this.passwordHidden;
  }

  public showHideRepeatedPassword() {
    this.repeatedPasswordHidden = !this.repeatedPasswordHidden;
  }

  public getInputType() {
    if (!this.passwordHidden) {
      return "text";
    } else {
      return "password";
    }
  }

  public getRepeatedPasswordType() {
    return !this.repeatedPasswordHidden ? "text" : "password";
  }

  public sendNewPasswordRequest() {
    console.log("asdas");
    const newPasswordDTO : newPasswordRequestBody = {
        password: this.form.value.password,
        repeatedPassword: this.form.value.repeatPassword
    };
    this.isLoading = true;
    this.authService.requestPasswordChange(this.email,newPasswordDTO).subscribe(
      response => {
          this.isLoading = false;
          console.log(response);
          //this.navigationService.navigateToPasswordChangeConfirmationScreen();
          this.isRequestSent = true;
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
        this.toast.danger("An error has occured while trying to change password.");
        console.log("Error while sending password change request: ", error.message);
      }
    );
    //this.navigationService.navigateToLoadingScreen();

  }

}
