import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient) { }

  public createTeam(body : any) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/project/create/team`, body);
  }

  public deleteTeam(teamId : string) : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/project/delete/team/${teamId}`);
  }

  public getAll() : Observable<any[]> {
    return this.http.get<any[]>(`${this.apiServerUrl}/project/get/all/teams`);
  }

  public getById(teamId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/find/team/by/id/${teamId}`);
  }

  public getBench() : Observable<any[]> {
    return this.http.get<any>(`${this.apiServerUrl}/project/get/all/employees/on/bench`);
  }

  public addEmployeeToTeam(employeeId : string, teamId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/add/employee/to/team?employeeID=${employeeId}&teamID=${teamId}`);
  }

  public removeEmployeeFromTeam(employeeId : string, teamId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/delete/employee/from/team?employeeID=${employeeId}&teamID=${teamId}`);
  }

  public addTeamToProject(teamId : string, projectId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/hire/team?teamID=${teamId}&projectID=${projectId}`);
  }

  public removeTeamFromProject(teamId : string, projectId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/fire/team?teamID=${teamId}&projectID=${projectId}`);
  }
}
