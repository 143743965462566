import { Component, Input, OnInit } from '@angular/core';
import { AssetsService } from '../../../services/assets.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-asset-history-modal',
  standalone: true,
  imports: [],
  templateUrl: './asset-history-modal.component.html',
  styleUrl: './asset-history-modal.component.css'
})
export class AssetHistoryModalComponent implements OnInit{
  @Input() asset : any;
  @Input() defaultPicture : boolean = true;

  public counter : number = 0;

  public assets : any[] = [];
  public activeAsset : any;
  public history : any[] = [];
  public filteredHistory : any[] = [];
  public image : any;

  constructor(private assetService : AssetsService,
    public modalService : NgbActiveModal,
    private sanitizer: DomSanitizer,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.counter = this.asset.InventoryNumbers.length
    this.getAssetHistory();
    this.getAssetImage();
  }

  public getAssetHistory() {
    this.assetService.getAssetHistory(this.asset.ID).subscribe(
      (response : any) => {
        this.filteredHistory = response.map((historyItem: { EndDate: number; StartDate: string | number | Date; }) => {
          const startDate = new Date(historyItem.StartDate);
          const startDateParsed = startDate.toISOString().substring(0, 10);
          let endDateParsed;
          if(historyItem.EndDate === -1){
            endDateParsed = "Currently in use"
          } else {
            const endDate = new Date(historyItem.EndDate);
            endDateParsed = endDate.toISOString().substring(0, 10);
          }
          return {
            ...historyItem, 
            StartDateParser: startDateParsed,
            EndDateParsed : endDateParsed
          };
        });

        console.log("History: ", this.filteredHistory)
      }
    )
  }
  
  public getAssetImage(): void {
    const company = sessionStorage.getItem("company");
    let param;
    let companyId;

    if (this.defaultPicture) {
      param = "defaultasset";
      companyId = "firmegeneralepictures";
    } else {
      param = this.asset.ID;
      companyId = company;
    }

    this.assetService.getAssetImage(param, companyId).subscribe(
      (response: string) => {
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      }
    );
  }
}
