<div class="flex flex-col w-full rounded-md bg-primaryWhite p-4 gap-4 border-[1px] border-primaryBlack">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[12px] font-medium text-primaryBlack/60 tracking-wide uppercase">{{description}}</p>
        <button class="flex flex-row gap-2 items-center group" (click)="deleteWidget()">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
            <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s]">Delete</p>
        </button>
    </div>
    <div class="flex flex-row gap-4 items-center">
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-active rounded-full me-1.5 flex-shrink-0"></span>Active</span>
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-scheduled rounded-full me-1.5 flex-shrink-0"></span>Scheduled</span>
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-paused rounded-full me-1.5 flex-shrink-0"></span>Paused</span>
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-finished rounded-full me-1.5 flex-shrink-0"></span>Finished</span>
    </div>
    <div class="flex flex-row w-full items-start overflow-x-auto gap-2">
        @for (project of projects; track $index) {
            <app-project-card (click)="navigationService.navigateToProject(project.ID)" 
                [name]="project.Name" 
                [status]="project.ProjectStatus" 
                [date]="project.StartDate"
            />
        }
    </div>
</div>