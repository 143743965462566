import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from "../../../components/button/button.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewTaskModalComponent } from 'src/app/components/modals/add-new-task-modal/add-new-task-modal.component';
import { TasksService } from 'src/app/services/tasks.service';
import { TaskCardComponent } from "../../../components/task-card/task-card.component";
import { NgToastService } from 'ng-angular-popup';
import { Task } from 'src/app/models/task';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { EmployeeService } from 'src/app/services/employee.service';
import { CommonModule } from '@angular/common';
import { initFlowbite } from 'flowbite';


@Component({
  selector: 'app-tasks',
  standalone: true,
  imports: [ButtonComponent, TaskCardComponent, DragDropModule, CommonModule],
  templateUrl: './tasks.component.html',
  styleUrl: './tasks.component.css'
})
export class TasksComponent implements OnInit{

  public tasks: Task[] = [];
  public todoTasks: Task[] = [];
  public inProgressTasks: Task[] = [];
  public doneTasks: Task[] = [];
  public activeSection: string | null = null;

  constructor(
    private modalService: NgbModal,
    private taskService: TasksService,
    private toastService: NgToastService
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  public getAll(): void {
    this.taskService.getAll().subscribe(
      (response: Task[]) => {
        console.log("ALL TASKS: ", response);
        this.tasks = response;
        console.log('tasks: ', this.tasks);
        this.filterTasksByStatus();
      }
    );
  }

  private filterTasksByStatus(): void {
    this.todoTasks = this.tasks.filter(task => task.Status === 'TO DO');
    this.inProgressTasks = this.tasks.filter(task => task.Status === 'IN PROGRESS');
    this.doneTasks = this.tasks.filter(task => task.Status === 'DONE');
  }

  public addNewaTask(): void {
    const modalRef = this.modalService.open(AddNewTaskModalComponent, {
      backdrop: 'static',
      keyboard: true,
    });

    modalRef.componentInstance.project = null;

    modalRef.componentInstance.taskCreated.subscribe(() => {
      this.toastService.success('Task created successfully!', 'Success!', 3000);
      this.getAll();
    });
  }

  public drop(event: CdkDragDrop<Task[]>, newStatus: string): void {
    const task: Task | undefined = event.item.data;

    if (task) {
      task.Status = newStatus;
      this.taskService.updateStatus(task.ID, newStatus).subscribe(() => {
        this.toastService.success('Task status updated successfully!', 'Success!', 3000);
        this.getAll();
        setTimeout(() => {
          initFlowbite(); // Re-initialize Flowbite after DOM changes
          window.location.reload();
      }, 0);
      });
    } else {
      console.error('Dragged item data is undefined');
    }

    this.activeSection = null;
  }

  public onDragEntered(section: string): void {
      this.activeSection = section;
  }

  public onDragExited(section: string): void {
    if (this.activeSection === section) {
      this.activeSection = null;
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public handleTaskDeleted() : void {
    this.toastService.success("Task successfully deleted!", "Success!", 3000);
    this.getAll();
    window.location.reload();
  }

  public handleTaskEdited() : void {
    this.toastService.success("Task successfully edited!", "Success!", 3000);
    this.getAll();
  }

}