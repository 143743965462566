import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../models/message';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private socketUrl = environment.chatSocketConnection;
  private apiServerUrl = environment.apiServerUrl;

  private socket! : WebSocket;
  private heartbeatInterval!: number;
  private reconnectInterval!: number;
  private isManuallyClosed: boolean = false; 
  private readonly reconnectDelay: number = 5000; 
  private userId : string = '';
  private chatId : string = '';

  private messageSubject: Subject<Message> = new Subject<Message>();
  public messages: string[] = [];

  constructor(private http : HttpClient) { }

  public sendMessage(body : Message) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/chat/sendMessage`, body);
  }

  public connect(userId : string, chatId : string) : void{
    this.userId = userId;
    this.chatId = chatId;
    this.initWebSocket(userId, chatId);
  }

  private initWebSocket(userId : string, chatId : string): void {
    this.socket = new WebSocket(`wss://${this.socketUrl}/ws/connect?id=${userId}&chatID=${chatId}`);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      // this.startHeartbeat();
    };

    this.socket.onmessage = (event) => {
      console.log('Message received from server: ', event.data);
      this.messages.push(event.data);

      const adminId = sessionStorage.getItem('adminId')

      if(adminId) {
        const message : Message = {
          senderID : adminId,
          chatID : this.chatId,
          payload : event.data,
          timestamp : Date.now()
        }
        this.messageSubject.next(message);
      }
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
      clearInterval(this.heartbeatInterval);

      if (!this.isManuallyClosed) {
        this.scheduleReconnection();
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error: ', error);

      this.socket.close();
    };
  }

  private scheduleReconnection(): void {
    console.log('Attempting to reconnect...');
    this.reconnectInterval = window.setTimeout(() => {
      this.initWebSocket(this.userId, this.chatId);
    }, this.reconnectDelay);
  }

  // private startHeartbeat(): void {
  //   const time = new Date();
  //   const body : Message = {
  //     chatId : this.chatId,
  //     payload : "1234PING!@#$",
  //     timestamp : time.getTime()
  //   }
  //   this.heartbeatInterval = window.setInterval(() => {
  //     if (this.socket && this.socket.readyState === WebSocket.OPEN) {
  //       this.socket.send(body);
  //     }
  //   }, 2500);
  // }


  close(): void {
    this.isManuallyClosed = true;
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.reconnectInterval);

    if (this.socket) {
      this.socket.close();
    }
  }

  getMessages(): Observable<Message> {
    return this.messageSubject.asObservable();
  }
}
