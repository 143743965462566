import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsService } from '../../../services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-asset',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './create-asset.component.html',
  styleUrl: './create-asset.component.css'
})
export class CreateAssetComponent implements OnInit{

  form!: FormGroup;
  
  imageSrc: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;

  @Output() assetCreated = new EventEmitter<void>();

  constructor(private fb: FormBuilder,
    public modalService : NgbActiveModal,
    public assetService : AssetsService
  ){}

  ngOnInit(): void {
    this.form = this.fb.group({
      Name: ['', Validators.required],
      SerialNumber: [''],
      RequiredApproval: [false, Validators.required],
      Counter : [0, [Validators.min(0), Validators.max(9999)]]
    });
  }

  public onPictureSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      this.selectedFile = element.files[0];
      const reader = new FileReader();
      
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
  
      reader.readAsDataURL(this.selectedFile);
    } else {
      this.imageSrc = null;
      this.selectedFile = null;
    }
  }

  public saveAsset() {
    var formData;
    if(this.form.valid) {
      const data = this.form.value
      console.log("DATURINA: ", data)
      if(data.RequiredApproval) {
        data.Counter = 1;
      }

      formData = new FormData();
      formData.append('name', data.Name);
      formData.append('serialNumber', data.SerialNumber);
      formData.append('requireApproval', data.RequiredApproval);
      formData.append('counter', data.Counter);

      if(this.selectedFile){
        formData.append('image', this.selectedFile);
      }

      console.log("NAME:", formData.get('name'));
      console.log("SERIAL:", formData.get('serialNumber'));
      console.log("APPROVAL:", formData.get('requireApproval'));
      console.log("COUNTER:", formData.get('counter'));
      console.log("IMAGE:", formData.get('image'));

      console.log(formData);

      this.assetService.save(formData!).subscribe(
        (response : any) => {
          this.assetCreated.emit();
          this.modalService.close();
        }
      )
    }
  }
}
