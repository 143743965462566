<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto py-2 px-4 gap-4">
    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Profile']}}</p> 
    <div class="flex flex-row gap-8 w-full rounded-md items-center p-4 bg-primaryWhite">
        <div class="flex rounded-full size-[150px] bg-lightGreen">
            
        </div>
        <div class="flex flex-col">
            <p class="text-[28px] font-semibold text-primaryBlack">{{user.FirstName}} {{user.LastName}}</p>
            <p class="text-[18px] font-light">{{translationService.translations?.variables['Owner']}}</p>
            <p class="text-[18px] font-light">{{user.City}}, {{user.Country}}</p>
        </div>
    </div>
    <div class="flex flex-col rounded-md w-full bg-primaryWhite p-4 gap-4">
        <p class="small text-greyScaleTextCaption tracking-wide uppercase">
            {{translationService.translations?.variables['Personal info']}}
        </p>
        <div class="flex flex-row w-full items-center justifty-between">
            <div class="flex flex-col w-full">
                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['First name']}}</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.FirstName}}</p>
            </div>
            <div class="flex flex-col w-full">
                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Last name']}}</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.LastName}}</p>
            </div>
        </div>
        <div class="flex flex-row w-full items-center justifty-between">
            <div class="flex flex-col w-full">
                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Email address']}}</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.Email}}</p>
            </div>
            <div class="flex flex-col w-full">
                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Phone number']}}</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.Phone}}</p>
            </div>
        </div>
    </div>
    <div class="flex flex-col rounded-md w-full bg-primaryWhite p-4 gap-4">
        <p class="small text-greyScaleTextCaption tracking-wide uppercase">
            {{translationService.translations?.variables['Address']}}
        </p>
        <div class="flex flex-row w-full items-center justifty-between">
            <div class="flex flex-col w-full">
                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Country']}}</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.Country}}</p>
            </div>
            <div class="flex flex-col w-full">
                <p class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['City']}}</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.City}}</p>
            </div>
        </div>
    </div>
    <div class="flex flex-row w-full items-center justify-end">
        <app-button
            [text]="translationService.translations?.variables['Sign out from all devices']"
            [function]="globalSignOut.bind(this)"
        />
    </div>
</div>