<form [formGroup]="form">
    <div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Invite administrator']}}</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full gap-2 items-center bg-greyScaleSurfaceDisabled border-[1px] border-greyScaleBorderDefault rounded-md px-4 py-2">
                <img src="../../../../assets/icons/SupportIcon.png" class="size-[20px]">
                <p class="small text-greyScaleBorderDarker">{{translationService.translations?.variables['Enter the email with which you want to invite the administrator to the organization']}}.</p>
            </div>
            <div class="flex flex-col w-full">
                @if (form.get('email')?.touched && form.get('email')?.invalid) {
                    <label for="email" class="text-lightRed font-regular text-[12px]">{{translationService.translations?.variables['Email address (Required)']}}</label>
                }
                <input formControlName="email" class="placeholder:italic block w-full rounded-md  px-4 rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Email address']"/>
            </div>
            <div class="flex flex-row w-full justify-end">
                <app-button 
                    [text]="translationService.translations?.variables['Invite']"
                    [function]="inviteAdministrator.bind(this)"
                />
            </div>
        </div>
    </div>
</form>