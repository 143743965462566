<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto py-2 px-4 gap-4">
    <div class="flex flex-col md:flex-row w-full justify-between md:items-center">
        <div class="flex flex-col md:flex-row gap-4 md:items-center">
            <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Departments']}}</p> 
            <div class="flex h-[45px] relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" [(ngModel)]="searchText" (input)="searchDepartments()" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 2xl:w-[450px] h-[45px] bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" [placeholder]="translationService.translations?.variables['Search departments']">
            </div>
        </div>
    </div>

    <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 justify-between gap-6">
        <ng-container *ngIf="!isLoading; else skeletons">
            <ng-container *ngIf="departments; else noDepartments">
                <button class="flex flex-col p-6 items-center rounded-md font-poppins gap-2 border-[1px] border-greyScaleBorderDefault group hover:bg-greyScaleBorderDisabled
                                tems-center justify-center transition-[0.5s] cursor-pointer"            
                        (click)="createDepartment()">
                    <img src="../../../../assets/icons/PlusIcon.png" class="size-12 md:size-12 xl:size-12 2xl:size-14 opacity-40 group-hover:opacity-100">
                    <p class="big opacity-[50%] group-hover:opacity-[100%] text-greyScaleTextTitle group-hover:text-greyScaleTextTitle">{{translationService.translations?.variables['Add new']}}</p>
                </button>

                @for (department of departments; track $index) {
                    <app-department-card
                        [department]="department"
                    />
                }
            </ng-container>
        </ng-container>
        <ng-template #skeletons>
            <app-skeleton-project-card *ngFor="let skeleton of departmentsCount"/>
        </ng-template>
        <ng-template #noDepartments>
            <div class="flex w-full gap-2 flex-col col-span-full">
                <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no departments to display']}}</p>
                <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your new department by pressing the']}} <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
            </div>
            <button class="flex flex-col p-6 items-center rounded-md font-poppins gap-2 border-[1px] border-greyScaleBorderDefault group hover:bg-greyScaleBorderDisabled
                            tems-center justify-center transition-[0.5s] cursor-pointer"            
                    (click)="createDepartment()">
                <img src="../../../../assets/icons/PlusIcon.png" class="size-12 md:size-12 xl:size-12 2xl:size-14 opacity-40 group-hover:opacity-100">
                <p class="big opacity-[50%] group-hover:opacity-[100%] text-greyScaleTextTitle group-hover:text-greyScaleTextTitle">{{translationService.translations?.variables['Add new']}}</p>
            </button>
        </ng-template>
    </div>
</div>