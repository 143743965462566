import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

// export const errorInterceptor: HttpInterceptorFn = (req, next) => {
//   const token = sessionStorage.getItem('token');
//   const companyId = sessionStorage.getItem('company');

//   const authService = inject(AuthService)
  
//   let authRequest = req;
//   if(companyId === null && token !== null){
//     authRequest = req.clone({
//       setHeaders : {
//         'Authorization' : `Bearer ${token}`
//       }
//     })
//     return next(authRequest).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 401) {
//           console.log("401 Unauthorized - Attempting to refresh token...");
//           return authService.checkAdminRefreshToken()(
//             switchMap((refreshResponse: HttpResponse<any>) => {
//               const newToken = refreshResponse.headers.get('Accesstoken');
//               if (newToken) {
//                 sessionStorage.setItem('token', newToken);
//                 console.log('Access token refreshed:', newToken);
  
//                 const newAuthReq = req.clone({
//                   withCredentials : true,
//                   setHeaders: {
//                     'Authorization': `Bearer ${newToken}`,
//                   }
//                 });
//                 return next(newAuthReq);
//               } else {
//                 console.log("No new token received, logging out...");
//                 authService.logOut();
//                 return throwError(() => new Error('Unauthorized - Unable to refresh token.'));
//               }
//             }),
//             catchError((refreshError: HttpErrorResponse) => {
//               if (refreshError.status === 401) {
//                 console.log("Refresh token failed, logging out...");
//                 authService.logOut();
//               }
//               return throwError(() => refreshError); 
//             })
//           );
//         }
  
//         return throwError(() => error);
//       })
//     );
//   }
//   else if(token === null && companyId === null){
//     return next(authRequest).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 401) {
//           console.log("401 Unauthorized - Attempting to refresh token...");
//           return authService.checkAdminRefreshToken().pipe(
//             switchMap((refreshResponse: HttpResponse<any>) => {
//               const newToken = refreshResponse.headers.get('Accesstoken');
//               if (newToken) {
//                 sessionStorage.setItem('token', newToken);
//                 console.log('Access token refreshed:', newToken);
  
//                 const newAuthReq = req.clone({
//                   withCredentials : true,
//                 });
//                 return next(newAuthReq);
//               } else {
//                 console.log("No new token received, logging out...");
//                 authService.logOut();
//                 return throwError(() => new Error('Unauthorized - Unable to refresh token.'));
//               }
//             }),
//             catchError((refreshError: HttpErrorResponse) => {
//               if (refreshError.status === 401) {
//                 console.log("Refresh token failed, logging out...");
//                 authService.logOut();
//               }
//               return throwError(() => refreshError); 
//             })
//           );
//         }
  
//         return throwError(() => error);
//       })
//     );
//   }
//   else{
//     return next(authRequest).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 401) {
//           console.log("401 Unauthorized - Attempting to refresh token...");
//           return authService.checkAdminRefreshToken().pipe(
//             switchMap((refreshResponse: HttpResponse<any>) => {
//               const newToken = refreshResponse.headers.get('Accesstoken');
//               if (newToken) {
//                 sessionStorage.setItem('token', newToken);
//                 console.log('Access token refreshed:', newToken);
  
//                 const newAuthReq = req.clone({
//                   withCredentials : true,
//                   setHeaders: {
//                     'Authorization': `Bearer ${newToken}`,
//                     'companyID' : `${companyId}`
//                   }
//                 });
//                 return next(newAuthReq);
//               } else {
//                 console.log("No new token received, logging out...");
//                 authService.logOut();
//                 return throwError(() => new Error('Unauthorized - Unable to refresh token.'));
//               }
//             }),
//             catchError((refreshError: HttpErrorResponse) => {
//               if (refreshError.status === 401) {
//                 console.log("Refresh token failed, logging out...");
//                 authService.logOut();
//               }
//               return throwError(() => refreshError);
//             })
//           );
//         }
  
//         return throwError(() => error);
//       })
//     );
//   }
// };
import { NgToastService } from 'ng-angular-popup';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(NgToastService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage: string = "";

      if (error.error === "mongo: no documents in result") {
        //console.log("Mongo error");
        return throwError(() => error);
      } else if (typeof error.error === 'string') {
        errorMessage = error.error;
      } else if (error.error?.message) {
        errorMessage = error.error.message;
      } else {
        errorMessage = 'An unexpected error occurred';
      }

      toastService.danger(errorMessage, 'Error!', 5000);
      return throwError(() => error);
    })
  );
};
