import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-employees-landing',
  standalone: true,
  imports: [],
  templateUrl: './employees-landing.component.html',
  styleUrl: './employees-landing.component.css'
})
export class EmployeesLandingComponent implements OnInit{
  
  public token : string = ''

  constructor(private router: Router, private route: ActivatedRoute,
    private authService : AuthService,
  ){}

  ngOnInit(): void {
    const domainFromRoute = this.route.snapshot.paramMap.get('domain');
    console.log("Domain from route: ", domainFromRoute);
    if(domainFromRoute != null){
      sessionStorage.setItem('domain', domainFromRoute)
    }
    const tokenFromRoute = this.route.snapshot.paramMap.get('token');
    if(tokenFromRoute != null){
      this.token = tokenFromRoute;
      sessionStorage.setItem("token", this.token);
      this.authService.authStatusSubject.next(true);
    }
  }
}
