<!-- <div [ngStyle]="{'background': !loggedIn ? 'white' :'linear-gradient(to top left, #3b5671 35%, #244c70 65%)'}"
    [ngClass]="{'p-4' : loggedIn}"
    class="flex fex-row w-[100vw] h-[100vh] gap-4 overflow-y-auto items-start justify-between relative">
  @if(loggedIn){
    <div class="flex md:w-[18%] items-start">
      <app-navigation class="w-full h-full"/>
    </div>
  }
  <div class="flex-1 grow w-[85%] h-full overflow-y-auto flex flex-col gap-3">
    @if (loggedIn) {
      <app-global-search class="w-full"/>
    }
    <div [ngClass]="{'bg-lightGrey overflow-hidden overflow-y-auto bg-lightGrey p-4 h-full rounded-md' : loggedIn}">
      <router-outlet/>
    </div>
  </div>
  <p class="text-primaryBlack/30 font-light text-[10px] absolute bottom-1 right-1">0.1.1-238</p>
</div>
<ng-toast [position]="ToasterPosition.TOP_RIGHT"/> -->


<!-- <div [ngStyle]="{'background': !loggedIn ? 'white' :'linear-gradient(to top left, #3b5671 35%, #244c70 65%)'}"
    [ngClass]="{'p-4' : loggedIn}"
    class="flex fex-row w-[100vw] h-[100vh] gap-4 overflow-y-auto items-start justify-between relative">
  @if(loggedIn){
    <div class="flex w-72 items-start">
      <app-navigation class="w-full h-full"/>
    </div>
  }
  <div class="flex w-full h-full overflow-y-auto flex flex-col gap-3">
    <div [ngClass]="{'bg-lightGrey overflow-hidden overflow-y-auto bg-lightGrey p-4 h-full rounded-md' : loggedIn}">
      <router-outlet/>
    </div>
  </div>
  <p class="text-primaryBlack/30 font-light text-[10px] absolute bottom-1 right-1">0.1.1-238</p>
</div>
<ng-toast [position]="ToasterPosition.TOP_RIGHT"/> -->


<div class="flex md:flex-row flex-col h-screen bg-blueGradient">
  @if (loggedIn) {
    <app-navigation/>
  }

  <div class="flex w-full h-full overflow-y-auto flex flex-col"
      [ngClass]="{'p-4' : loggedIn}">
    <div [ngClass]="{'bg-lightGrey overflow-hidden overflow-y-auto bg-lightGrey py-2 px-4 h-full rounded-md' : loggedIn}">
      <router-outlet/>
    </div>
  </div>
  <!-- <div class="flex-1 bg-primaryWhite">
    <router-outlet/>
  </div> -->
</div>
<ng-toast [position]="ToasterPosition.TOP_RIGHT"/>
