import { Component, Input, OnInit } from '@angular/core';
import { ProjectCardComponent } from '../../../components/project-card/project-card.component';
import { Status } from '../../../models/project-status';
import { CommonModule } from '@angular/common';
import { DetailedProjectCardComponent } from '../../../components/detailed-project-card/detailed-project-card.component';
import { ProjectService } from '../../../services/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from '../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateProjectModalComponent } from '../../../components/modals/create-project-modal/create-project-modal.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { FormsModule } from '@angular/forms';
import { SkeletonProjectCardComponent } from "../../../components/loaders/skeleton-project-card/skeleton-project-card.component";
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-all-projects',
  standalone: true,
  imports: [CommonModule, DetailedProjectCardComponent, ButtonComponent, FormsModule, SkeletonProjectCardComponent],
  templateUrl: './all-projects.component.html',
  styleUrl: './all-projects.component.css'
})
export class AllProjectsComponent implements OnInit{

  companyId : any = sessionStorage.getItem('company');

  public isLoading : boolean = false;
  public projectsCount = new Array(10);

  public filterActive : boolean = false;
  public filterScheduled : boolean = false;
  public filterPaused : boolean = false;
  public filterFinished : boolean = false;

  public projects : any[] = [];
  public filteredProjects: any[] = [];
  public searchText: string = '';

  constructor(private projectService : ProjectService,
    private modalService : NgbModal,
    private authService : AuthService,
    private navigationService : NavigationService
  ) {}

  ngOnInit(): void {
    this.fetchProjects();
  }

  public fetchProjects() : void {
    this.isLoading = true;
    this.projectService.getAll().subscribe(
      (response: any[]) => {
        if(response) {
          this.projects = response.map(project => ({
            ...project, 
            PositionsCount: project.Positions ? project.Positions.length : 0
          }));
          this.searchProjects();
          this.isLoading = false;
          console.log(this.projects);
        } else {
          this.isLoading = false;
        }
      }
    );
  }

  public searchProjects(): void {
    if (!this.searchText) {
      this.filteredProjects = this.projects;
    } else {
      this.filteredProjects = this.projects.filter(project =>
        project.Name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  public filterProjects() : void {
    if(this.filterActive || this.filterFinished || this.filterPaused || this.filterScheduled) {
      this.filteredProjects = this.projects.filter(project => 
        (this.filterActive && project.ProjectStatus === 'Active') || (this.filterFinished && project.ProjectStatus === 'Finished') ||
        (this.filterPaused && project.ProjectStatus === 'Paused') || (this.filterScheduled && project.ProjectStatus === 'Scheduled')
      );
    } else {
      this.filteredProjects = this.projects
    }
  }

  public viewProject(projectId : string) : void {
    this.navigationService.navigateToProject(projectId);
  }

  openCreateProjectModal() {
    this.goToCreateProject();
  }

  public goToCreateProject(): void {
    const modalRef = this.modalService.open(
      CreateProjectModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.projectCreated.subscribe(() => {
      this.fetchProjects();
    });
  }

  trackByProjectId(index: number, project: any): any {
    return project.ID;
  }

}
