import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OfficeManagementService } from 'src/app/services/office-management.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-add-venue-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './add-venue-modal.component.html',
  styleUrl: './add-venue-modal.component.css'
})
export class AddVenueModalComponent {
  @Input() departmentId : string = '';

  @Output() venueCreated = new EventEmitter<void>();

  public form! : FormGroup
  public isLoading : boolean = false;

  constructor(public translationService : TranslationService,
    private fb : FormBuilder,
    public modalService : NgbActiveModal,
    private officeManagement : OfficeManagementService
  ){}

  ngOnInit(): void {
    this.initializeForm();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      Name : ['', Validators.required],
      Capacity : [null, [Validators.required, Validators.min(0)]]
    });
  }

  public createVenue() : void {
    if(this.form.valid){
      const formData = this.form.value;

      const body = {
        Name : formData.Name,
        Capacity : formData.Capacity,
        DepartmentID : this.departmentId
      }

      this.isLoading = true;

      this.officeManagement.createVenue(body).subscribe(
        (response : any) => {
          console.log("Venue created: ", response);
          this.venueCreated.emit();
          this.modalService.close();
          this.isLoading = false;
        },
        (error : HttpErrorResponse) => {
          this.isLoading = false;
        }
      )
    }
  }
}
