import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { SkeletonProjectCardComponent } from 'src/app/components/loaders/skeleton-project-card/skeleton-project-card.component';
import { CreateDepartmentModalComponent } from 'src/app/components/modals/create-department-modal/create-department-modal.component';
import { Department } from 'src/app/models/department';
import { OfficeManagementService } from 'src/app/services/office-management.service';
import { TranslationService } from 'src/app/services/translation.service';
import { DepartmentCardComponent } from "../../../components/department-card/department-card.component";

@Component({
  selector: 'app-departments',
  standalone: true,
  imports: [CommonModule, FormsModule, SkeletonProjectCardComponent, DepartmentCardComponent],
  templateUrl: './departments.component.html',
  styleUrl: './departments.component.css'
})
export class DepartmentsComponent implements OnInit{

  public departments : Department[] = [];
  public departmentsCount = new Array(10);
  public isLoading : boolean = false;
  public searchText : string = '';

  constructor(public translationService : TranslationService,
    public modalService : NgbModal,
    public officeManagement : OfficeManagementService,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.getAllDepartments();
  }

  public searchDepartments() : void {}

  public createDepartment() : void {
    const modalRef = this.modalService.open(
      CreateDepartmentModalComponent,
      { backdrop : 'static', keyboard : true }
    )
    
    modalRef.componentInstance.departmentCreated.subscribe(
      () => {
        this.toast.success("Department successfully created!", "Success!", 3000);
        this.getAllDepartments();
      }
    )
  }

  public getAllDepartments() : void {
    this.isLoading = true;
    this.officeManagement.getAllDepartments().subscribe(
      (response : Department[]) => {
        this.departments = response;
        console.log("Departments: ", this.departments);
        this.isLoading = false;
      }
    )
  }
}
