import { Component, Input, OnInit } from '@angular/core';
import { Income } from '../../../models/income';
import { Expense } from '../../../models/expense';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../models/project';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-view-income-or-expense-modal',
  standalone: true,
  imports: [],
  templateUrl: './view-income-or-expense-modal.component.html',
  styleUrl: './view-income-or-expense-modal.component.css'
})
export class ViewIncomeOrExpenseModalComponent implements OnInit{

  @Input() isIncome : boolean = true;
  @Input() income? : Income;
  @Input() expense? : Expense;

  public parsedDate : string = '';
  public creationDate : string = ''
  public project? : Project;

  public hasProject : boolean = true;

  constructor(public modalService : NgbActiveModal,
    private projectsService : ProjectService
  ){}

  ngOnInit(): void {
    if(this.income){
      const date = new Date(this.income?.PaymentDate);
      const paymentDate = date.toISOString().substring(0, 10);
      this.parsedDate = paymentDate;
      this.getProjcet();
    }

    if(this.expense){
      const creationDate = new Date(this.expense?.date);
      const creationDateParsed = creationDate.toISOString().substring(0, 10);
      this.creationDate = creationDateParsed;

      const date = new Date(this.expense?.paymentDate);
      const paymentDate = date.toISOString().substring(0, 10);
      this.parsedDate = paymentDate;
    }
  }

  public getProjcet() : void {
    this.projectsService.get(this.income?.BindingID).subscribe(
      (response : any) => {
        console.log("Project: ", response);
        this.project = response;
      },
      (error : HttpErrorResponse) => {
        if ( error.error ===  "mongo: no documents in result") {
          this.hasProject = false;
        }
      }
    )
  }


}
