<div class="flex items-center w-[850px] items-center justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-2">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            @if(editingExistingPosition && existingProject){
                <p class="text-[24px] font-semibold text-primaryBlack">Edit position</p> 
            } @else {
                <p class="text-[24px] font-semibold text-primaryBlack">Add new position</p> 
            }
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="closeModal()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <div class="flex flex-col w-full">
            <label for="firstname" class="text-primaryBlack/70 font-regular text-[12px]">Cost (per hour)</label>
            <div class="flex flex-row w-full rounded-md">
                <div class="flex items-center justify-center px-4 bg-primaryBlack/20 rounded-l-md border-y-primaryBlack/20 border-l-primaryBlack/20">
                    <p class="text-primaryBlack font-semibold text-[16px]">$</p>
                </div>
                <div class="flex w-[50%] rounded-r-md">
                    <input [(ngModel)]="cost" type="number" class="w-full rounded-r-md bg-white border-y-[1px] border-r-[1px] h-[45px] p-4 text-primaryBlack focus:outline-none" placeholder="Cost"/>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-full mt-2 gap-2 border-[1px] rounded-md p-2">
            <p class="font-light text-[14px] text-primaryBlack/50">Choose one of the following professions</p>
            <div class="flex w-full max-h-[200px] flex-row flex-wrap justify-evenly gap-2 px-2 pb-2 pt-[15px] overflow-y-auto">
                @for (profession of professions; track $index) {
                    <div class="flex flex-row px-2 py-2 gap-2 rounded-md relative hover:border-lightGreen transition-[0.5s] 
                                cursor-pointer hover:translate-y-[-5px] hover:shadow-md w-auto items-center"
                         [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(profession.ID),
                                    'border-[1px]':!isActive(profession.ID)}"
                         (click)="setActive(profession)">
                        <img src="../../../assets/icons/ProfessionIcon.png" class="size-[20px]"/>
                        <p class="text-[14px] text-primaryBlack">{{profession.Name}}</p>
                    </div>
                }
            </div>
        </div>  

        <div class="flex flex-col w-full mt-2 gap-2 border-[1px] rounded-md p-2">
            <p class="font-light text-[14px] text-primaryBlack/50">Choose one of the following options as a primary skill</p>
            <div class="flex w-full max-h-[200px] flex-row flex-wrap justify-evenly gap-2 px-2 pb-2 pt-[15px] overflow-y-auto">
                @for (tech of techStack; track $index) {
                    <div class="flex flex-row px-2 py-2 gap-2 rounded-md relative hover:border-lightGreen transition-[0.5s] 
                                cursor-pointer hover:translate-y-[-5px] hover:shadow-md w-auto items-center"
                         [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveTechStack(tech.ID),
                                    'border-[1px]':!isActiveTechStack(tech.ID)}"
                         (click)="setActiveTechStack(tech)">
                         <img src="../../../assets/icons/{{tech.Name}}Icon.png" class="size-[20px]" onerror="this.onerror=null; this.src='../../../assets/icons/GeneralTechIcon.png'"/>
                         <p class="text-[14px] text-primaryBlack">{{tech.Name}}</p>
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-col w-full mt-2 gap-2 border-[1px] rounded-md p-2">
            <p class="font-light text-[14px] text-primaryBlack/50">Choose one of the following options as a required primary skill level</p>
            <div class="flex w-full max-h-[200px] flex-row flex-wrap justify-evenly gap-2 px-2 pb-2 pt-[15px] overflow-y-auto">
                @for (level of experienceLevels; track $index) {
                    <div class="flex flex-row px-4 py-2 gap-2 rounded-md relative hover:border-lightGreen transition-[0.5s] 
                                cursor-pointer hover:translate-y-[-5px] hover:shadow-md w-auto items-center"
                         [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveLevel(level.ID),
                                    'border-[1px]':!isActiveLevel(level.ID)}"
                         (click)="setActiveLevel(level)">
                         <p class="text-[14px] text-primaryBlack">{{level.Name}}</p>
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-col w-full items-end">
            @if (editingExistingPosition && existingProject) {
                <app-button
                    text="Edit position"
                    [function]="editPosition.bind(this)"
                />
            } @else {
                <app-button
                    text="Add position"
                    [function]="savePosition.bind(this)"
                />
            }
        </div>  
    </div>
</div>
