<div class="flex items-center bg-primaryBlack/30 font-poppins" style="z-index: 100;">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-end justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Generate activity report</p>
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]" (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>
        </div>
        <div class="flex flex-col w-full">
            <div class="flex flex-col w-[100%] p-4">
                <label for="dateInput" class="text-primaryBlack font-regular text-[12px]">Start date</label>
                <input type="date" id="dateInput" (change)="onStartDateChange($event)"
                    class="w-auto rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack outline-transparent no-outline-input">
            </div>
            <div class="flex flex-col w-[100%] p-4">
                <label for="dateInput" class="text-primaryBlack font-regular text-[12px]">End date</label>
                <input type="date" id="dateInput" (change)="onEndDateChange($event)" 
                    class="w-auto rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack">
            </div>
        </div>

        <div class="flex flex-row w-full justify-between">
            <app-generate-activity-dropdown [employeeId]="employeeId"
                (reportType)="handleReportType($event)"></app-generate-activity-dropdown>

            <button style="outline: none;" (click)="generateReport()" [disabled]="buttonDisabled"
                class="flex w-[230px] h-[45px] rounded-md bg-lightGreen hover:bg-lightHoverGreen items-center transition-[0.5s] justify-center text-primaryBlack gap-2 ml-2">
                <p class="text-[14px] text-white font-medium">
                    Generate report
                </p>
            </button>
        </div>
    </div>
</div>