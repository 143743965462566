import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Task } from 'src/app/models/task';
import { ProjectService } from 'src/app/services/project.service';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-edit-task-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './edit-task-modal.component.html',
  styleUrl: './edit-task-modal.component.css'
})
export class EditTaskModalComponent implements OnInit, AfterViewInit{

  @Input() task! : Task;

  @Output() taskEdited = new EventEmitter<void>();

  public form! : FormGroup;
  public projects : any[] = [];
  public employees : any[] = [];
  public initialSelectedProject : any;

  public activeAssigneeID : string = '';
  public selectedAsignee : any;

  constructor(private fb : FormBuilder,
    public modalService : NgbActiveModal,
    private projectService : ProjectService,
    private taskService : TasksService
  ){}

  ngOnInit(): void {
    this.initializeForm();
    this.activeAssigneeID = this.task.AssigneeID;
    this.getEmployeesOnProject();
  }

  ngAfterViewInit(): void {
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      description : [this.task.Description, Validators.required],
      priority: [this.task.Priority, Validators.required],
    })
  }

  public getEmployeesOnProject() : void {
    this.projectService.getAllEmployeesOnProject(this.task.ProjectID).subscribe(
      (response : any) => {
        this.employees = response.employees;
      }
    )
  }

  public edit() : void {
    const formValue = this.form.value
    
    const body = {
      TaskID : this.task.ID,
      AssigneeID : this.activeAssigneeID,
      TaskStatus : this.task.Status,
      TaskDescription : formValue.description,
      TaskPriority : formValue.priority
    }

    console.log(body);

    this.taskService.edit(body).subscribe(
      (response : any) => {
        console.log("Task edited: ", response);
        this.taskEdited.emit();
        this.modalService.close();
      }
    )
  }

  public initiallySelectedProject() : void {
    this.projectService.get(this.task.ProjectID).subscribe(
      (response : any) => {
        this.initialSelectedProject = response;
        this.setActive(this.initialSelectedProject.ID);
      }
    )
  }

  public getAllProjects() : void {
    this.projectService.getAll().subscribe(
      (response : any) => {
        this.projects = response;
        console.log("Projects: ", this.projects);
        this.initiallySelectedProject();
      }
    )
  }

  public setActive(assignee: any): void {
    if(assignee.EmployeeID) {
      this.activeAssigneeID = assignee.EmployeeID;
    }
    this.selectedAsignee = assignee;
  }

  public isActive(assigneeId: string): boolean {
    return this.activeAssigneeID === assigneeId;
  }
}
