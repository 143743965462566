import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TeamsService } from '../../services/teams.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgToastService } from 'ng-angular-popup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-team-card',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './team-card.component.html',
  styleUrl: './team-card.component.css'
})
export class TeamCardComponent implements OnInit{
  @Input() team : any;
  @Input() teamId : string = '';
  @Input() displayedOnProject : boolean = false;
  @Input() projectId : string = '';

  @Output() teamRemovedFromProject = new EventEmitter<void>();

  constructor(private teamService : TeamsService,
    private toast : NgToastService,
    private modalService : NgbModal,
    public navigationService : NavigationService
  ){}

  ngOnInit(): void {
    if(this.displayedOnProject){
      this.getTeamById();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['team'] && changes['team'].currentValue) {
      console.log("RECEIVED TEAM: ", this.team);
    }
  }

  public getTeamById() : void {
    this.teamService.getById(this.teamId).subscribe(
      (response : any) => {
        console.log("Fetched team: ", response);
        this.team = response;
      }
    )
  }

  public deleteTeamFromProject(event : Event) : void {
    event.stopPropagation()

    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.teamService.removeTeamFromProject(this.teamId, this.projectId).subscribe(
          (response : any) => {
            console.log("Team successfully removed from project!", response);
            this.teamRemovedFromProject.emit();
          }
        )
      }
    )
  }
}
