import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-project-card',
  standalone: true,
  imports: [],
  templateUrl: './skeleton-project-card.component.html',
  styleUrl: './skeleton-project-card.component.css'
})
export class SkeletonProjectCardComponent {

}
