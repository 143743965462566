import { Component, OnInit } from '@angular/core';
import { ChatItemComponent } from 'src/app/components/chat-item/chat-item.component';
import { ButtonComponent } from "../../../components/button/button.component";
import { ChatMessageComponent } from "../../../components/chat-message/chat-message.component";
import { ChatService } from 'src/app/services/chat.service';
import { FormsModule } from '@angular/forms';
import { Message } from 'src/app/models/message';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [ChatItemComponent, ButtonComponent, ChatMessageComponent, FormsModule, CommonModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.css'
})
export class ChatComponent implements OnInit{

  public groupChat : boolean = false;
  public activeChatId : string = '';
  // public messages  = new Array(40);
  public chats = new Array(1);
  public messageString : string = '';
  public messages: { sender: 'me' | 'participant'; content: string, timestamp: number}[] = [];


  constructor(private chatService : ChatService) {}

  ngOnInit(): void {
  }

  public initializeWebSocketConnection() : void {
    const adminId = sessionStorage.getItem('adminId')
    if(adminId){
      this.chatService.connect(adminId, this.activeChatId);
      this.subscribeToChatMessages();
    }
  }

  public subscribeToChatMessages() : void {
    this.chatService.getMessages().subscribe(
      (response : any) => {
        this.messages.push({
          sender: 'participant',
          content: response.payload,
          timestamp: Date.now()
        });      
      }
    )
  }

  public openChat(chatId : string) : void {
    this.activeChatId = chatId;
    const adminId = sessionStorage.getItem('adminId')
    console.log("adminId: ", adminId);
    console.log("chatId: ", this.activeChatId);
    this.initializeWebSocketConnection();
  }

  public sendMessage() : void {
    const adminId = sessionStorage.getItem('adminId');
    if(this.messageString !== '' && adminId){
      const date = new Date();
      const messageContent = this.messageString;
      const message: Message = {
        senderID: adminId,
        chatID: this.activeChatId,
        payload: messageContent,
        timestamp: date.getTime(),
      };

      this.chatService.sendMessage(message).subscribe(
        (response: any) => {
          this.messages.push({
            sender: 'me',
            content: messageContent,
            timestamp: date.getTime(),
          });
        },
        (error) => {
          console.error("Failed to send message:", error);
        }
      );

      this.messageString = '';
    }
  }
}
