<div class="flex items-center w-[800px] justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Create new team</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>

        <div class="flex flex-col w-full">
            <label for="teamName" class="text-primaryBlack font-regular text-[12px]">Team name</label>
            <input [(ngModel)]="teamName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Team name"/>
        </div>

        <div class="flex flex-col w-full border-[1px] rounded-md p-4 gap-2">
            <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">Employees</p>
            <p class="text-primaryBlack font-light text-[12px] mb-2">Select employees that you want to add to the new team</p>
            <div class="flex flex-row w-full flex-wrap justify-evenly items-center max-h-[300px] overflow-y-auto">
                @for (employee of employees; track $index) {
                    <div class="flex flex-row min-w-[300px] max-w-auto items-center justify-center p-4 border-[1px] rounded-md
                                hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                        [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(employee.EmployeeID),
                                'border-[1px]':!isActive(employee.EmployeeID)}"
                        (click)="setActive(employee)">
                        <app-small-employee-card-content
                            [EmployeeID]="employee.EmployeeID"
                        />
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-col w-full gap-2">
            <label for="teamName" class="text-primaryBlack font-regular text-[12px]">Team color</label>
            <div class="flex flex-row flex-wrap gap-x-8 gap-y-4 overflow-hidden w-full items-center">
                @for (color of colors; track $index) {
                    <div class="flex flex-row p-1 rounded-full relative hover:border-lightGreen transition-[0.5s] 
                                cursor-pointer hover:shadow-md items-center justify-center"
                         [ngClass]="{'border-[3px] border-lightGreen': isActiveColor(color)}"
                         (click)="onColorSelected(color)">
                        <div [ngStyle]="{'background' : color}" 
                            class="size-[30px] rounded-full">
                        </div>
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-row w-full justify-end">
            <app-button 
                text="Create"
                [function]="saveTeam.bind(this)"
            />
        </div>
    </div>
</div>