import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Department } from 'src/app/models/department';

@Component({
  selector: 'app-department-card',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './department-card.component.html',
  styleUrl: './department-card.component.css'
})
export class DepartmentCardComponent implements OnInit{

  @Input() department! : Department;

  constructor(){}

  ngOnInit(): void {
    
  }
}
