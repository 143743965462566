<div class="flex items-center justify-center w-[600px] bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-6">
        <div class="flex flex-col w-full items-start justify-start gap-2">
            <p class="text-greyScaleTextTitle heading-h2">Verification code sent!</p>
            <p class="text-greyScaleTextTitle global">An email with a verification code has been sent to your email address 
                <span class="text-brandSurfaceDefault global-bold">{{email}}</span>
            </p>
            <p class="text-greyScaleTextTitle global">Enter the code you received in the email to verify your email.</p>
        </div>
    
        <div class="flex flex-row w-full justify-between gap-2 items-center">
            <input type="number" class="h-16 w-16 rounded-md" [(ngModel)]="digitOne" (input)="focusNext(input2, $event)" #input1 maxlength="1">
            <input type="number" class="h-16 w-16 rounded-md" [(ngModel)]="digitTwo" (input)="focusNext(input3, $event)" #input2 maxlength="1">
            <input type="number" class="h-16 w-16 rounded-md" [(ngModel)]="digitThree" (input)="focusNext(input4, $event)" #input3 maxlength="1">
            <input type="number" class="h-16 w-16 rounded-md" [(ngModel)]="digitFour" (input)="focusNext(input5, $event)" #input4 maxlength="1">
            <input type="number" class="h-16 w-16 rounded-md" [(ngModel)]="digitFive" (input)="focusNext(input6, $event)" #input5 maxlength="1">
            <input type="number" class="h-16 w-16 rounded-md" [(ngModel)]="digitSix" #input6 maxlength="1">
        </div>
        
        <div class="flex flex-row w-full justify-end">
            <app-button
                text="Verify code"
                [function]="proceedWithVerification.bind(this)"
            />
        </div>
    </div>
</div>
