<div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-6">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Add new approver</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        
        <div class="flex flex-row w-full gap-2">
            <input type="checkbox" [ngModel]="existingEmployee" (change)="addFromEmployees()"/>
            <p class="text-[14px] text-primaryBlack">Add from employees</p>
        </div>

        @if (existingEmployee) {
            <div class="flex relative">
                <button class="flex flex-row h-[45px] w-full rounded-md border-[1px] border-primaryBlack/20 bg-white font-poppins relative
                                hover:bg-primaryBlack/10 transition-[0.5s] py-4 px-2 text-primaryBlack relative items-center justify-start"
                        (click)="toggleDropdown()">
                    <input [(ngModel)]="searchQuery" [value]="selectedItem" [(ngModel)]="searchQuery" (input)="onSearchChange()" class="placeholder:italic border-none p-2 w-full focus:outline-none placeholder:italic cursor-pointer" placeholder="Search for employees">
                    <img src="../../../assets/icons/{{isOpen ? 'ArrowUpIcon' : 'ArrowDownIcon'}}.png" class="size-[20px] opacity-50 absolute right-5">
                </button>
            
                @if (isOpen) {
                    <div class="z-[50] flex flex-col bg-white absolute rounded-md top-10 mt-2 font-poppins max-h-[300px] w-full overflow-y-auto border-[1px] shadow-2xl p-2">
                        <!-- Search Input -->
                        <div class="flex h-[45px] relative my-2">
                            <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="text" [(ngModel)]="searchQuery" (input)="onSearchChange()" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-full bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search">
                        </div>
                        <!-- List of Items -->
                        <ul class="space-y-1 text-sm text-primaryBlack w-full">
                            @for (item of filteredData; track $index) {
                                <div class="flex flex-row gap-2 w-full items-center border-b-[1px] p-2">
                                    <input type="checkbox" [(ngModel)]="item.checked" (change)="onSelection(item, $event)" class="w-5 h-5 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen focus:ring-2 dark:bg-gray-600"/>
                                    <div class="flex flex-col">
                                        <p class="text-[14px] font-medium text-primaryBlack">{{item.name}}</p>
                                        <p class="text-[12px] font-light text-primaryBlack">{{item.email}}</p>
                                    </div>
                                </div>
                            }
                        </ul>
                    </div>
                }
            </div>
        } @else {
            <input [(ngModel)]="email" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Email address"/>
        }

        <div class="flex flex-row w-full justify-end">
            <app-button
                text="Save"
                [function]="saveApprover.bind(this)"
            />
        </div>
    </div>
</div>