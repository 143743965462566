import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Task } from '../models/task';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public getAll() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/task/get`);
  } 

  public create(body : any) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/task/create`, body);
  }

  public updateStatus(taskId : string, status : string) : Observable<any>{
    return this.http.patch(`${this.apiServerUrl}/task/update?id=${taskId}&Status=${status}`, {});
  }

  public deleteTask(taskId : string | undefined) : Observable<any>{
    return this.http.delete(`${this.apiServerUrl}/task/delete?id=${taskId}`);
  }

  public edit(body : any) : Observable<any> {
    return this.http.patch(`${this.apiServerUrl}/task/update?id=${body.TaskID}&AssigneeID=${body.AssigneeID}&Status=${body.TaskStatus}&Description=${body.TaskDescription}&Priority=${body.TaskPriority}`, {})
  }

  public getProjectTasks(projectId : string) : Observable<Task[]> {
    return this.http.get<Task[]>(`${this.apiServerUrl}/task/getOnProject?ProjectID=${projectId}`);
  }
}
