<div class="flex items-center items-center justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-6">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Add skill</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="closeModal()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>

        <div class="flex flex-col w-full mt-2 gap-2 border-[1px] rounded-md p-2">
            <p class="font-light text-[14px] text-primaryBlack/50">Choose one of the following options as a primary skill</p>
            <div class="flex w-full max-h-[200px] flex-row flex-wrap justify-evenly gap-2 px-2 pb-2 pt-[15px] overflow-y-auto">
                @if(skillNames.length <= 0){
                    <div class="flex w-full col-span-full">
                        <p class="text-primaryBlack font-semibold text-[24px]">
                            There are no more available skills. <br>
                            <span class="font-light text-[16px]">You have already added all the available skills for this employee.</span>
                        </p>
                    </div>
                } @else {
                    @for (tech of skillNames; track $index) {
                        <div class="flex flex-row p-2 gap-2 rounded-md relative hover:border-lightGreen transition-[0.5s] 
                                    cursor-pointer hover:translate-y-[-5px] hover:shadow-md w-auto items-center"
                             [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveTechStack(tech),
                                        'border-[1px]':!isActiveTechStack(tech)}"
                             (click)="onSkillSelected(tech)">
                             <img src="../../../assets/icons/{{tech}}Icon.png" class="size-[20px]" onerror="this.onerror=null; this.src='../../../assets/icons/GeneralTechIcon.png'"/>
                             <p class="text-[14px] text-primaryBlack">{{tech}}</p>
                        </div>
                    }
                }
            </div>
        </div>

        <div class="flex flex-col w-full mt-2 gap-2 border-[1px] rounded-md p-2">
            <p class="font-light text-[14px] text-primaryBlack/50">Choose one of the following options as a required primary skill level</p>
            <div class="flex w-full max-h-[200px] flex-row flex-wrap justify-evenly gap-2 px-2 pb-2 pt-[15px] overflow-y-auto">
                @for (level of levelNames; track $index) {
                    <div class="flex flex-row px-4 py-2 gap-2 rounded-md relative hover:border-lightGreen transition-[0.5s] 
                                cursor-pointer hover:translate-y-[-5px] hover:shadow-md w-auto items-center"
                         [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveLevel(level),
                                    'border-[1px]':!isActiveLevel(level)}"
                         (click)="onLevelSelected(level)">
                         <p class="text-[14px] text-primaryBlack">{{level}}</p>
                    </div>
                }
            </div>
        </div>


        @if (!existingEmployee) {
            <div class="flex flex-row w-full justify-end">
                <app-button
                    text="Add skill"
                    [function]="addSkill.bind(this)"
                />
            </div>
        } @else {
            <div class="flex flex-row w-full justify-end">
                <app-button
                    text="Add skill"
                    [function]="addSkillToExistingEmployee.bind(this)"
                />
            </div>
        }
    </div>
</div>