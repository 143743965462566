<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <p class="text-[24px] font-semibold text-primaryBlack">Profile</p> 
    <div class="flex flex-row gap-8 w-full rounded-md items-center p-4 bg-primaryWhite">
        <div class="flex rounded-full size-[150px] bg-lightGreen">
            
        </div>
        <div class="flex flex-col">
            <p class="text-[28px] font-semibold text-primaryBlack">{{user.FirstName}} {{user.LastName}}</p>
            <p class="text-[18px] font-light">Owner</p>
            <p class="text-[18px] font-light">{{user.City}}, {{user.Country}}</p>
        </div>
    </div>
    <div class="flex flex-col rounded-md w-full bg-primaryWhite p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-between">
            <p class="text-primaryBlack/50 uppercase tracking-wide text-[16px]">Personal Informations</p>
        </div>
        <hr class="w-full text-primaryBlack/50">
        <div class="flex flex-row w-full items-center justifty-between">
            <div class="flex flex-col w-full">
                <p class="text-darkGreen text-[14px]">First Name</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.FirstName}}</p>
            </div>
            <div class="flex flex-col w-full">
                <p class="text-darkGreen text-[14px]">Last Name</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.LastName}}</p>
            </div>
        </div>
        <div class="flex flex-row w-full items-center justifty-between">
            <div class="flex flex-col w-full">
                <p class="text-darkGreen text-[14px]">Email Address</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.Email}}</p>
            </div>
            <div class="flex flex-col w-full">
                <p class="text-darkGreen text-[14px]">Phone Number</p>
                <p class="text-primaryBlack text-[24px] font-medium">+{{user.Phone}}</p>
            </div>
        </div>
    </div>
    <div class="flex flex-col rounded-md w-full bg-primaryWhite p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-between">
            <p class="text-primaryBlack/50 uppercase tracking-wide text-[16px]">Address</p>
        </div>
        <hr class="w-full text-primaryBlack/50">
        <div class="flex flex-row w-full items-center justifty-between">
            <div class="flex flex-col w-full">
                <p class="text-darkGreen text-[14px]">Country</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.Country}}</p>
            </div>
            <div class="flex flex-col w-full">
                <p class="text-darkGreen text-[14px]">City</p>
                <p class="text-primaryBlack text-[24px] font-medium">{{user.City}}</p>
            </div>
        </div>
    </div>
</div>