<div class="relative inline-block text-left dropdown-container w-[230px] h-[45px]">
    <button style="outline: none;"
        class="flex w-[230px] h-[45px] rounded-md bg-brandBorderSubtle hover:bg-brandBorderLighter items-center transition-[0.5s] justify-center text-primaryBlack gap-2"
        (click)="toggleDropdown()" [attr.aria-expanded]="isDropdownOpen">
        <img src={{displayedImg}} class="size-[15px]" />
        <p class="text-[14px] text-primaryBlack font-medium">
            {{displayedText}}
        </p>
        <img src="../../../assets/icons/ArrowDownIcon.png" class="size-[15px]  justify-end" />
    </button>

    <!-- Dropdown Menu -->
    <div *ngIf="isDropdownOpen" class="absolute mt-2 w-[150] bg-white border border-gray-300 rounded-md shadow-lg">
        <ul class="py-1">
            <li #dropDownOption (click)="generatePDFReport()"
                class="flex items-center justify-start hover:bg-lbrandBorderLighter cursor-pointer px-4 py-2">
                <img src="../../../assets/icons/PdfFileIcon.png" class="size-[15px]" />
                <p class="text-[14px] text-primaryBlack font-medium gap-2 space-text">
                    PDF Report
                </p>
            </li>
            <li #dropDownOption (click)="generateCSVReport()"
                class="flex items-center justify-start hover:bg-brandBorderLighter cursor-pointer px-4 py-2">
                <img src="../../../assets/icons/CsvFileIcon.png" class="size-[15px]" />
                <p class="text-[14px] text-primaryBlack font-medium gap-2 space-text">
                    CSV Report
                </p>
            </li>
            <li #dropDownOption (click)="generateXLSXReport()"
                class="flex items-center justify-start hover:bg-brandBorderLighter cursor-pointer px-4 py-2">
                <img src="../../../assets/icons/XlsxFileIcon.png" class="size-[15px]" />
                <p class="text-[14px] text-primaryBlack font-medium gap-2 space-text">
                    XLSX Report
                </p>
            </li>
        </ul>
    </div>
</div>