<div class="flex relative dropdown-container">
    <button [ngStyle]="{'width': width}"
    style="outline: #0A210F;"
        class="flex flex-row h-[45px] rounded-md border-[1px] border-primaryBlack/20 bg-white font-poppins relative
                    hover:bg-primaryBlack/10 transition-[0.5s] py-4 px-2 text-primaryBlack relative items-center justify-start"
        [attr.aria-expaneded]="isOpen"
        (click)="toggleDropdown()">
        @if (inputPlaceHolder === 'Srpski') {
            <div class="flex flex-row w-full gap-2 items-center">
                <img src="../../../assets/icons/RS.png" class="w-5"/>
                <p class="global text-greyScaleTextTitle">Srpski</p>
            </div>
        } @else if (inputPlaceHolder === 'English') {
            <div class="flex flex-row w-full gap-2 items-center">
                <img src="../../../assets/icons/US.png" class="w-5"/>
                <p class="global text-greyScaleTextTitle">English</p>
            </div>
        }
        @else {
            <input [(ngModel)]="searchQuery" [value]="selectedItem" (ngModelChange)="onSearchChange($event)"
                class="border-none p-2 w-full focus:outline-none placeholder:italic cursor-pointer"
                [placeholder]="inputPlaceHolder"
                style="outline: #0A210F;">
        }
        <img src="../../../assets/icons/{{isOpen ? 'ArrowUpIcon' : 'ArrowDownIcon'}}.png"
            class="size-[20px] opacity-50 absolute right-5">
    </button>

    @if (isOpen) {
    <div [ngStyle]="{'width': '250px'}"
        class="flex flex-col bg-white absolute rounded-md top-10 mt-2 font-poppins max-h-[300px] overflow-y-auto border-[1px] shadow-2xl p-2">
        <!-- <input [(ngModel)]="searchQuery" (ngModelChange)="onSearchChange($event)" class="placeholder:italic w-full p-2 my-2 text-primaryBlack rounded-md border-[1px] boreder-primaryBlack/50" placeholder="Search for country"> -->
        <!-- <div class="flex h-[45px] relative my-2">
            <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input type="text" [(ngModel)]="searchQuery" (ngModelChange)="onSearchChange($event)" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search">
        </div> -->
        <ul class="space-y-1 text-sm text-primaryBlack w-full" aria-labelledby="dropdownCheckboxButton">
            @for (item of filteredData; track $index) {
            <li #dropDownOption class="flex hover:bg-primaryBlack/10 w-full cursor-pointer" (click)="onSelection(item)">
                <div class="flex items-center p-2">
                    @if (item === 'Srpski') {
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/RS.png" class="w-5"/>
                            <label for="checkbox-item-1"
                                class="w-full flex text-[12px] font-regular text-primaryBlack cursor-pointer">
                                {{item}}
                            </label>
                        </div>
                    }
                    @else if (item === 'Español') {
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/ES.png" class="w-5"/>
                            <label for="checkbox-item-1"
                                class="w-full flex text-[12px] font-regular text-primaryBlack cursor-pointer">
                                {{item}}
                            </label>
                        </div>
                    }
                    @else if (item === 'Deutsch') {
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/DE.png" class="w-5"/>
                            <label for="checkbox-item-1"
                                class="w-full flex text-[12px] font-regular text-primaryBlack cursor-pointer">
                                {{item}}
                            </label>
                        </div>
                    }
                    @else if (item === 'English') {
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/US.png" class="w-5"/>
                            <label for="checkbox-item-1"
                                class="w-full flex text-[12px] font-regular text-primaryBlack cursor-pointer">
                                {{item}}
                            </label>
                        </div>
                    }
                    @else if (item === 'Italiano') {
                        <div class="flex flex-row gap-2 items-center">
                            <img src="../../../assets/icons/IT.png" class="w-5"/>
                            <label for="checkbox-item-1"
                                class="w-full flex text-[12px] font-regular text-primaryBlack cursor-pointer">
                                {{item}}
                            </label>
                        </div>
                    }
                    @else {
                        <label for="checkbox-item-1"
                                class="w-full flex text-[12px] font-regular text-primaryBlack cursor-pointer">
                            {{item}}
                        </label>
                    }
                </div>
            </li>
            }
        </ul>
    </div>
    }
</div>