import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { NavigationService } from '../navigation.service';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from '../auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  
  const navigationService = inject(NavigationService);
  const toast = inject(NgToastService)

  const token = sessionStorage.getItem('token');

  if(token != null) {
    return true;
  } else {
    toast.danger("You must sign in in to access the desired page!", "Error!", 3000);
    navigationService.navigateToSignIn();
    return false;
  }
};
