import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { ButtonComponent } from '../../../components/button/button.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginRequestBody } from '../../../dtos/LoginRequestBodyDTO';
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NgToastService } from 'ng-angular-popup';
import { TokenAppLoginResponse } from '../../../dtos/TokenAppLoginResponse';
import { ErrorResponse } from '../../../dtos/ErrorResponse';
import { CredentialResponse } from 'google-one-tap';
import { environment } from '../../../../environments/environment';
import { GoogleLoginResponse } from '../../../models/googleLoginResponse';
import { first } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})
export class SignInComponent implements OnInit{

  @Input() displayedAsPage : boolean = true;
  @Output() signUp = new EventEmitter<void>();

  form!: FormGroup;
  public passwordHidden : boolean = true;
  public isLoading : boolean = false;

  constructor(
    public navigationService : NavigationService,
    public authService : AuthService,
    private toast : NgToastService,
    private _ngZone : NgZone,
    private fb: FormBuilder,
    private toastService : NgToastService,
  ){}

  ngOnInit(): void {
    this.loadGoogleScript().then(() => {
      this.initGoogleSignIn();
    });

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  public loadGoogleScript() {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
    });
  }

  public toggleSignUp() {
    this.signUp.emit();
  }

  public initGoogleSignIn() {
    const buttonDiv = document.getElementById("buttonDiv");
  
    if (buttonDiv) {
      //@ts-ignore
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      //@ts-ignore
      google.accounts.id.renderButton(
        buttonDiv,
        { theme: "outline", size: "large" }
      );
      //@ts-ignore
      google.accounts.id.prompt((notification) => {
        console.log(notification);
      });
    } else {
      console.error("Element 'buttonDiv' nije pronađen u DOM-u.");
    }
  }

  public signIn() : void {
    if(this.form.valid){
      const uuid = this.authService.generateDeviceUUID();
      localStorage.setItem('deviceId', uuid);
      console.log('UUID: ', uuid);      
      const formValue = this.form.value;

      const loginDto : LoginRequestBody = {
        email : formValue.email,
        password : formValue.password
      }
      this.isLoading = true;

      this.authService.logIn(loginDto, uuid).subscribe(
        (response : any) => {
          console.log("Successfully logged in, ", response);
          this.authService.authStatusSubject.next(true);
          this.toast.success("Successfully logged in!", "Welcome back!", 3000);
          this.isLoading = false;
          // this.navigationService.navigateToChooseCompany();
        }, 
        (error : HttpErrorResponse) => {
          this.isLoading = false;
        }
      )
    }
  }

  async handleCredentialResponse(response : CredentialResponse) {
    const uuid = this.authService.generateDeviceUUID();
    localStorage.setItem('deviceId', uuid);
    this.authService.loginWithGoogle(response.credential, uuid).subscribe(
      (response : any) => {
        const token = response.headers.get('Accesstoken')
        sessionStorage.setItem("token", token);

        this._ngZone.run(() => {
          this.authService.authStatusSubject.next(true);
        })
        // this.navigationService.navigateToChooseCompany();
        console.log(response.Message)
        // window.location.reload()
      }
    )
  }

  public showHidePassword() {
    this.passwordHidden = !this.passwordHidden;
  }

  public getInputType() {
    if(!this.passwordHidden) {
      return "text";
    } else {
      return "password";
    }
  }

}
