<div class="flex items-center justify-center font-poppins">
    <div class="flex flex-col w-full h-full rounded-md p-4 gap-6 z-1">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Add new widget</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="closeModal()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <p class="text-[14px] text-primaryBlack/50 font-light">Select one of the following widgets</p>
        <div class="flex flex-col w-full gap-2">
            @for (widget of widgets; track $index) {
                @if (widget === 'Overall project budget') {
                    @if (hasTotalBudgetWidget) {
                        <div class="flex w-full items-center bg-lightRed/20 cursor-not-allowed transition-[0.5s] rounded-md p-4 relative"
                            data-tooltip-target="tooltip-for-widget"
                            data-tooltip-style="light">
                            <p class="text-primaryBlack font-medium text-[16px]">{{widget}} (Already added)</p>
                        </div>
                        
                        <!-- <div id="tooltip-for-widget" role="tooltip" class="absolute z-[20] invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip">
                            This widget is already added.
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div> -->
                    } @else {
                        <div class="flex w-full items-center hover:bg-primaryBlack/20 transition-[0.5s] rounded-md p-4 cursor-pointer"
                            (click)="getFunction(widget)">
                            <p class="text-primaryBlack font-medium text-[16px]">{{widget}}</p>
                        </div>
                    }
                } 
                @else if (widget === 'Equipment widget') {
                    @if (hasAssetAvailabilityWidget) {
                        <div class="flex w-full items-center bg-lightRed/20 cursor-not-allowed transition-[0.5s] rounded-md p-4 relative"
                            data-tooltip-target="tooltip-for-widget"
                            data-tooltip-style="light">
                            <p class="text-primaryBlack font-medium text-[16px]">{{widget}} (Already added)</p>
                        </div>
                        
                        <!-- <div id="tooltip-for-widget" role="tooltip" class="absolute z-[20] invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip">
                            This widget is already added.
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div> -->
                    } @else {
                        <div class="flex w-full items-center hover:bg-primaryBlack/20 transition-[0.5s] rounded-md p-4 cursor-pointer"
                            (click)="getFunction(widget)">
                            <p class="text-primaryBlack font-medium text-[16px]">{{widget}}</p>
                        </div>
                    }
                } 
                @else {
                    <div class="flex w-full items-center hover:bg-primaryBlack/20 transition-[0.5s] rounded-md p-4 cursor-pointer"
                        (click)="getFunction(widget)">
                        <p class="text-primaryBlack font-medium text-[16px]">{{widget}}</p>
                    </div>
                }
                
            }
        </div>
    </div>
</div>