import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-administrator-modal',
  standalone: true,
  imports: [ButtonComponent, FormsModule, CommonModule, ReactiveFormsModule],
  templateUrl: './add-administrator-modal.component.html',
  styleUrl: './add-administrator-modal.component.css'
})
export class AddAdministratorModalComponent implements OnInit{

  @Output() adminAdded = new EventEmitter<void>();

  public form! : FormGroup
  public email : string = '';

  constructor(public modalService : NgbActiveModal,
    private companyService : CompanyService,
    private fb : FormBuilder,
    private toast : NgToastService,
  ){}

  ngOnInit(): void {
    this.initializeForm();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      email : ['', [Validators.required, Validators.email]]
    })
  }

  public inviteAdministrator() : void {
    if(this.form.valid) {
      const email = this.form.value.email;

      console.log(email);

      const companyId = sessionStorage.getItem('company');
      const companyName = sessionStorage.getItem('CompanyName')

      const body = {
        companyIDs : [companyId],
        companyNames : [companyName]
      }

      this.companyService.inviteAdministrator(email, body).subscribe(
        (response : any) => {
          console.log(response);
          this.modalService.close();
          this.adminAdded.emit();
        }
      )
    }
  }

}
