import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { SmallEmployeeCardContentComponent } from "../../small-employee-card-content/small-employee-card-content.component";

@Component({
  selector: 'app-select-project-manager-modal',
  standalone: true,
  imports: [CommonModule, SmallEmployeeCardContentComponent],
  templateUrl: './select-project-manager-modal.component.html',
  styleUrl: './select-project-manager-modal.component.css'
})
export class SelectProjectManagerModalComponent implements OnInit{

  @Output() projectManagerSelected = new EventEmitter<any>();

  public selected : boolean = false;
  public isLoading : boolean = true;
  public hasPicture : boolean = true;

  public employees : any[] = [];
  public filteredEmployees : any[] = [];
  public activeEmployeeId : string = '';
  public selectedEmployee : any;

  public employeePictureLink : any;

  constructor(public modalService : NgbActiveModal,
    public employeeService : EmployeeService,
    private sanitizer: DomSanitizer
  ){}

  ngOnInit(): void {
    this.fetchEmployees();
  }

  public select() : void{
    if(this.selected){
      this.projectManagerSelected.emit(this.selectedEmployee);
      this.modalService.close();
    }
  }

  public fetchEmployees() : void {
    this.isLoading = true;
    this.filteredEmployees = [];
    this.employeeService.getAll().subscribe(
      (response : any[]) => {
        if(response) {
          this.employees = response.map(employee => {
            return {
              ...employee, 
              FullName: employee.FirstName + " " + employee.LastName,
            };
          });
          this.filteredEmployees = this.employees
          this.isLoading = false;
          console.log(this.employees);
        } else {
          this.isLoading = false;
        }
      }
    );  
  }

  public setActive(employee: any): void {
    this.activeEmployeeId = employee.EmployeeID;
    this.selectedEmployee = employee;
    this.selected = true;
  }

  public isActive(employeeId: string): boolean {
    return this.activeEmployeeId === employeeId;
  }
}
