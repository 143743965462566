import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BasicCompanyDTO } from '../dtos/basicCompanyDTO';
import { CountriesData } from '../models/countries';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companyNameSubject = new BehaviorSubject<string | null>(null);
  public companyStatusSubject = new BehaviorSubject<boolean>(false);

  public companyCreatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public companyCreated$: Observable<boolean> = this.companyCreatedSubject.asObservable();

  public companyChangesRequiredSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public companyChangesRequired$: Observable<boolean> = this.companyChangesRequiredSubject.asObservable();

  public setCompanyName(name: string): void {
    this.companyNameSubject.next(name);
  }

  public setCompanyStatus(status: boolean): void {
    this.companyStatusSubject.next(status);
  }

  private apiServerUrl = environment.apiServerUrl;
  private apiCountriesAndCitiesUrl = "https://countriesnow.space/api/v0.1/countries";
 
  constructor(private http: HttpClient) { 
    const companyName = sessionStorage.getItem("CompanyName")
    if(companyName) {
      this.companyNameSubject.next(companyName);
    } else {
      this.companyNameSubject.next("No company")
    }
  }

  public getCompanyToChoose(companyID : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/company/findByID`);
  }

  public getCompany() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/company/findByID`);
  }

  public createStack(body : any) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/company/createStack`, body);
  }

  public deleteStack(stackType : string, stackId : string) : Observable<void> {
    return this.http.delete<any>(`${this.apiServerUrl}/company/deleteStack/${stackType}/${stackId}`);
  }

  public registerCompany(body : any) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/auth/registerCompany`, body);
  }

  public getMyCompaines() : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/company/getMyCompanies`);
  }

  public addApprovers(body : any) : Observable<any>{
    return this.http.patch<any>(`${this.apiServerUrl}/company/addApprover`, body)
  }

  public globalSearch(searchQuery : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/company/search?Keyword=${searchQuery}`);
  }

  public getBaseCompanyDetails(domain : string) : Observable<BasicCompanyDTO> {
    return this.http.get<BasicCompanyDTO>(`${this.apiServerUrl}/company/GetCompanyFrom/${domain}`)
  }

  public deleteCompany() : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/company/delete`)
  }

  public deleteApprover(email : string) : Observable<any>{
    return this.http.delete<any>(`${this.apiServerUrl}/company/deleteApprover/${email}`)
  }

  public uploadLogo(formData : FormData, newCompanyId : string) : Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization' : `Bearer ${token}`,
      'companyID' : `${newCompanyId}`
    })
    return this.http.patch<any>(`${this.apiServerUrl}/S3/uploadLogo`, formData, { headers : headers });
  }

  public getCompanyLogo() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/S3/viewLogo`);
  }

  public inviteAdministrator(email : string, body : any) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/company/inviteAdmin?email=${email}`, body)
  }

  public registerAdmin(body : any, requestId : string) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/company/registerAdmin/${requestId}`, body);
  }

  public getCountriesAndCities():Observable<CountriesData>{
    return this.http.get<CountriesData>(`${this.apiCountriesAndCitiesUrl}`);
  }
}
