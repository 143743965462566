import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsService } from '../../../services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-asset-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './edit-asset-modal.component.html',
  styleUrl: './edit-asset-modal.component.css'
})
export class EditAssetModalComponent implements OnInit{

  @Input() assetId : string = ''
  @Input() assetName : string = '';
  @Input() serialNumber : string = '';
  @Input() imageUrl : string = '';
  @Input() requireApproval : boolean = false;
  @Input() defaultPicture : boolean = false;
  @Input() asset : any;

  @Output() assetUpdated = new EventEmitter<void>();

  public quantity : number = 0;

  public form!: FormGroup;
  public imageSrc: string | ArrayBuffer | null = null;
  public selectedFile: File | null = null;
  public initialImage : boolean = true;

  constructor(public fb : FormBuilder,
    public modalService : NgbActiveModal,
    private assetsService : AssetsService,
    private sanitizer: DomSanitizer
  ){}

  ngOnInit(): void {
    if(this.asset.InventoryNumbers !== null || this.asset.InventoryNumbers.length !== 0){
      this.quantity = this.asset.InventoryNumbers.length;
    }
    this.form = this.fb.group({
      Name: [this.assetName, Validators.required],
      SerialNumber: [this.serialNumber],
      RequiredApproval: [this.requireApproval, Validators.required],
      Quantity : [this.quantity]
    });
  
    this.getAssetImage();
  }

  public getAssetImage() {
    if(this.imageUrl !== ''){
      this.initialImage = true;
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.imageUrl);
    } else {
      this.initialImage = false;
      return null;
    }
  }

  public onPictureSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      this.selectedFile = element.files[0];
      const reader = new FileReader();
      
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
      
      this.initialImage = false;
      reader.readAsDataURL(this.selectedFile);
    } else {
      this.imageSrc = null;
      this.selectedFile = null;
    }
  }

  public editAsset() {
    const data = this.form.value;
    let difference: number;

    if(this.asset.InventoryNumbers !== null || this.asset.InventoryNumbers.length !== 0){
      const originalQuantity = this.asset.InventoryNumbers.length;
      console.log("Original quantity: ", originalQuantity);
      console.log("Edited Quantity: ", data.Quantity)

      difference = originalQuantity - data.Quantity;
      console.log("Difference:", difference);
    }

    const formData = new FormData();
    formData.append('name', data.Name);
    formData.append('serialNumber', data.SerialNumber);
    formData.append('requireApproval', data.RequiredApproval);
    if(this.selectedFile){
      formData.append('imagePath', this.selectedFile);
    } 

    console.log(formData.get('name'));
    console.log(formData.get('serialNumber'));
    console.log(formData.get('requireApproval'));
    console.log(formData.get('imagePath'));

    
    this.assetsService.edit(formData, this.assetId).subscribe(
      (response : any) => {
        if(difference > 0) {
          this.assetsService.deleteAssetInstances(difference, this.assetId).subscribe(
            (response : any) => {
              console.log("Instances successfully deleted!", response);
            }
          );

          console.log("Remove instances counter: ", difference);
        } else {
          const counter = difference - (difference * 2);
          console.log("Increase instances counter: ", counter);

          this.assetsService.addAssetInstances(counter, this.assetId).subscribe(
            (response : any) => {
              console.log("Instances successfully added!", response);
            }
          )
        }
        console.log("Asset successfully edited!", response);
        this.assetUpdated.emit();
        this.modalService.close();
      }
    )
  }
}
