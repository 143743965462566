<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-scroll font-poppins gap-4 overflow-y-auto p-2">
    <div class="flex flex-row w-full gap-2 items-center">
        <a [routerLink]="['/teams']">
            <img src="../../../../assets/icons/BackIcon.png"  class="size-[30px] cursor-pointer"/>
        </a>
        <p class="text-[24px] font-semibold text-primaryBlack">Team</p> 
    </div>
    <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="me-2" (click)="toggleGeneral()">
                <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showGeneral,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showGeneral}">
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen':showGeneral,
                            'group-hover:text-bg-primaryBlack/60' : showGeneral}"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                    </svg>
                    General
                </a>
            </li>
            <li class="me-2 relative" (click)="toggleMembers()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showMembers,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showMembers}">                    
                    <svg class="w-4 h-4 me-2" 
                        [ngClass]="{'text-lightGreen':showMembers,
                                    'group-hover:text-bg-primaryBlack/60' : showMembers}" 
                        fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.612 15.443c-.387.198-.824-.149-.746-.592l.78-4.295-3.148-3.065c-.329-.321-.154-.888.283-.94l4.34-.63 1.938-3.932c.176-.356.653-.356.829 0l1.939 3.932 4.34.63c.438.052.612.619.283.94l-3.148 3.065.78 4.295c.078.443-.359.79-.746.592L8 13.187l-3.862 2.256z"/>
                    </svg>
                    Members
                </a>
            </li>
        </ul>
    </div>

    @if (showGeneral) {
        <div class="flex flex-col w-full h-auto rounded-md bg-primaryWhite p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-between">
                <p class="small text-greyScaleTextCaption tracking-wide">GENERAL</p> 
                <!-- <button class="flex items-center justify-center group" (click)="toggleEdit()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Edit</p>
                    </div>
                </button> -->
            </div>
            <div class="flex flex-col w-full gap-4">
                <div class="flex flex-row w-full items-center gap-4">
                    <div class="flex flex-col w-auto">
                        <p class="font-semibold lg:text-[48px] xl:text-[64px] sm:text-[32px] text-primaryBlack">{{team?.Name}}</p>
                        <span [ngStyle]="{'background' : badgeRGBA, 'color' : team?.ColorHash}" 
                            class="text-sm max-w-[500px] font-medium px-2.5 py-0.5 rounded">{{team?.Name}}</span>
                    </div>
                </div>
                <hr class="text-primaryBlack/40 w-full">
            </div>   
        </div>

        <div class="flex flex-col w-full gap-2">
            <p class="text-[24px] text-primaryBlack">Danger zone</p>
            <div class="flex flex-col w-full rounded-md border-[1px] border-lightRed p-4">
                <div class="flex flex-row w-full items-center justify-between">
                    <div class="flex flex-col w-full">
                        <p class="text-primaryBlack font-medium text-[18px]">Delete team</p>
                        <p class="text-primaryBlack text-[14px]">This action implies the complete deletion of the team from the system.</p>
                    </div>
                    <button class="flex px-4 py-2 items-center justify-center text-lightRed rounded-md border-[1px] border-lightRed w-[220px]
                                    hover:bg-lightRed hover:text-primaryWhite transition-[0.5s]"
                            (click)="deleteTeam()">
                        <p>Delete team</p>
                    </button>
                </div>
            </div>
        </div>
    }

    @if (showMembers) {
        <div class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
            <ng-container *ngIf="!isLoading; else skeletons">
                <ng-container *ngIf="membersCount > 0; else noBench">
                    <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryBlack/30 
                                items-center justify-center transition-[0.5s] cursor-pointer"
                            (click)="openAddEmployeeToTeamModal()">
                        <img src="../../../../assets/icons/AddEmployeeIcon.png" class="size-[70px] 2xl:size-[100px] opacity-[50%] group-hover:opacity-[100%]">
                        <p class="text-[36px] font-semibold text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
                    </button>
                    @for (employee of members; track $index) {
                        <app-employee-card
                            [userId]="employee"
                            [displayOnTeam]="true"
                            [teamID]="team.ID"
                            (employeeRemovedFromTeam)="handleEmployeeRemovedFromTeam()"
                        />
                    }
                </ng-container>
            </ng-container>
            <ng-template #skeletons>
                <app-skeleton-employee-card *ngFor="let skeleton of skeletonCount"/>
            </ng-template>
            <ng-template #noBench>
                <div class="flex w-full col-span-full">
                    <p class="text-primaryBlack font-semibold text-[24px]">
                        There are no employees in team to display. <br>
                        <span class="font-light text-[16px]">You can add new employee to the team by pressing the <span class="font-medium text-lightGreen">"Add new"</span> button.</span>
                    </p>
                </div>
                <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryBlack/30 
                            items-center justify-center transition-[0.5s] cursor-pointer"
                        (click)="openAddEmployeeToTeamModal()">
                    <img src="../../../../assets/icons/AddEmployeeIcon.png" class="size-[70px] 2xl:size-[100px] opacity-[50%] group-hover:opacity-[100%]">
                    <p class="text-[36px] font-semibold text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
                </button>
            </ng-template>
        </div>
    }
</div>