<div class="flex flex-col size-[128px] rounded-md gap-2 items-center justify-center border-[1px] hover:translate-y-[-6px] transition-[0.5s]
            font-poppins relative cursor-pointer transition-[0.5s] border-primaryBlack/20 rounded-md hover:shadow-md"
    [ngClass]="{'bg-active border-active' : active,
                'bg-scheduled border-scheduled' : scheduled,
                'bg-finished border-finished' : finished,
                'bg-paused border-paused' : paused}">
    <!-- <div class="flex flex-row w-auto absolute top-2 right-2 items-center justify-center">
        <div class="rounded-full size-[15px]"
            [ngClass]="{'bg-lightBlue' : active,
            'bg-scheduled' : scheduled,
            'bg-lightGreen' : finished,
            'bg-lightYellow' : paused}"></div>

    </div> -->
    <div class="flex flex-col items-center justify-center w-full">
        <div class="text-center word-break-all w-full px-2">
            <h1 class="text-[14px] font-medium text-balanced text-ellipsis">{{name}}</h1>
        </div>
    </div>
</div>