<form [formGroup]="form">
    <div class="flex items-center h-auto justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Create new project']}}</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="closeModal()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-col w-full items-start justify-start gap-[16px]">
                <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Project info']}}</p>

                <div class="flex flex-col w-full">
                    @if (form.get('ProjectName')?.touched && form.get('ProjectName')?.invalid) {
                        <label for="projectName" class="text-lightRed small">{{translationService.translations?.variables['Project name (Required)']}}</label>
                    }
                    <input formControlName="ProjectName" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Project name']"/>
                </div>

                <div class="flex flex-col w-full">
                    @if (form.get('Budget')?.touched && form.get('Budget')?.invalid) {
                        <label for="budget" class="text-lightRed small">{{translationService.translations?.variables['Budget (Required, must be greater than 0)']}}</label>
                    }
                    <input formControlName="Budget" type="number" min="0" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Budget']"/>
                </div>

                <div class="flex flex-col w-full">
                    @if (form.get('StartDate')?.touched && form.get('StartDate')?.invalid) {
                        <label for="startDate" class="text-lightRed small">{{translationService.translations?.variables['Start date (Required)']}}</label>
                    } @else {
                        <label for="startDate" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Start date']}}</label>
                    }
                    <input formControlName="StartDate" type="date" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Start date']"/>
                </div>

                <div class="flex flex-col w-full">
                    @if (form.get('Duration')?.touched && form.get('Duration')?.invalid) {
                        <label for="duration" class="text-lightRed small">{{translationService.translations?.variables['Duration (in days) (Required, must be greater than 0)']}}</label>
                    }
                    <input formControlName="Duration" type="number" min="0" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Duration']"/>
                </div>

                <div class="flex flex-col w-full h-full">
                    <textarea [placeholder]="translationService.translations?.variables['Description']" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[100px] max-h-[200px] text-greyScaleTextTitle focus:outline-brandBorderDefault" formControlName="Description"></textarea>
                </div>

                <div class="flex flex-col w-full gap-2">
                    <label for="duration" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Project manager']}}</label>
                    @if (!projectManager) {
                        <div class="flex items-center justify-center w-full p-2 rounded-md text-[12px] font-medium border-[1px] border-greyScaleBorderDefault
                                    hover:bg-brandSurfaceDefault hover:text-greyScaleSurfaceDefault transition-[0.5s] uppercase cursor-pointer"
                            (click)="selectProjectManager()">
                            {{translationService.translations?.variables['Select manager']}}
                        </div>
                    } @else {
                        <div class="flex flex-row w-full items-center justify-between">
                            <div class="flex flex-row items-center gap-2">
                                <div class="flex size-[50px] h-auto img-container rounded-full border-[1px] border-primaryBlack/20">
                                    <img [src]="employeePictureLink" class="rounded-full size-full"/>                   
                                </div>
                                <div class="flex flex-col">
                                    <p class="text-[16px] font-medium text-primaryBlack">{{projectManager.FirstName}} {{projectManager.LastName}}</p>
                                    <p class="text-[14px] font-light text-primaryBlack">{{projectManager.Email}}</p>
                                </div>
                            </div>
                            <div class="flex flex-row items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/70 p-1 transition-[1s]"
                                (click)="selectProjectManager()">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center">
                                    <img src="../../../assets/icons/ChangeIcon.png"
                                        class="size-[20px] 2xl:size-[30px] transition-[0.7s] opacity-50 group-hover:opacity-100">
                                </div>
                            </div> 
                        </div>
                    }
                </div>
            </div>
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="createProject()"
                        [ngClass]="{'bg-greyScaleBorderDisabled text-greyScaleTextBody': !form.valid, 'bg-brandSurfaceDefault hover:bg-brandBorderDarker text-greyScaleSurfaceDefault': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s]">
                    {{translationService.translations?.variables['Save']}}
                </button>
            </div>
        </div>
    </div>
</form>