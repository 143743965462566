<div class="flex items-center  justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-6">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Edit skill</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <div class="flex flex-col w-full gap-2">
            <p class="text-[14px] text-primaryBlack/50 font-light">Select a skill</p>
            <app-dropdown-menu class="z-[10]"
                width="100%"
                [data]="skillNames"
                [inputPlaceHolder]="selectedTech"
                (selectionChanged)="onSkillSelected($event)"
            />
        </div>

        <div class="flex flex-col w-full gap-2">
            <p class="text-[14px] text-primaryBlack/50 font-light">Select a experience level</p>
            <app-dropdown-menu class="z-[9]"
                width="100%"
                [data]="levelNames"
                (selectionChanged)="onLevelSelected($event)"
            />
        </div>

        <div class="flex flex-row w-full justify-end">
            <app-button
                text="Edit skill"
                [function]="editSkill.bind(this)"
            />
        </div>
    </div>
</div>