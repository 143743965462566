<div class="flex flex-row w-full border-b border-greyScaleBorderDefault items-center gap-2 p-2 truncate cursor-pointer transition-[0.5s] hover:bg-greyScaleSurfaceSubtle">
    <div class="flex w-auto relative">
        <div class="flex size-10 rounded-full bg-brandSurfaceDefault"></div>
        <div class="absolute bottom-0 right-0 size-3 rounded-full bg-lightGreen"></div>
    </div>
    <div class="flex flex-col w-full truncate">
        <div class="flex flex-row w-full items-center justify-between">
            <p class="global text-greyScaleTextTitle">Chat name</p>
            <p class="global text-greyScaleTextTitle">14:20</p>
        </div>
        <p class="small text-greyScaleTextSubtitle">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus labore eveniet incidunt veritatis provident tempore impedit itaque ad dolor odit, esse quod reiciendis voluptatem adipisci repellat aliquam corporis natus laborum.</p>
    </div>
</div>