<div class="flex flex-col p-4 rounded-md border-[1px] max-w-[350px] min-w-[250px] h-auto w-auto relative transition-[0.5s] gap-4"
    [ngClass]="{'bg-lightYellow/20 hover:border-lightYellow' : requiresEditing,
                'hover:border-lightGreen' : !requiresEditing}">
    <button class="flex flex-row gap-2 items-center group absolute top-4 right-4"
            (click)="deletePosition(positionId, $event)">
        <img src="../../../assets/icons/TrashIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Delete</p>
    </button>
    <img src="../../../assets/icons/PositionIcon.png" class="size-[40px]"/>

    <div class="flex flex-col w-full justify-start gap-2">
        <p class="text-[16px] font-medium text-primaryBlack">{{positionName}}</p>
        <div class="flex flex-col w-full">
            <p class="text-[12px] font-light text-primaryBlack">Primary skill: <span class="font-medium">{{primarySkillName}}</span></p>
            <p class="text-[12px] font-light text-primaryBlack">Required experience level: <span class="font-medium">{{requiredSkillLevelName}}</span></p>
            <p class="text-[12px] font-light text-primaryBlack">Cost per hour: <span class="font-medium">${{cost}}</span></p>
        </div>
    </div>
    <div class="flex flex-col w-full items-center gap-2">
        @if (assigned) {
            <div class="flex flex-col w-full gap-2">
                <p class="text-[14px] font-medium text-lightGreen tracking-wide uppercase">Assigned</p>
                <p class="text-[12px] font-light text-primaryBlack">Employee: <span class="font-medium">{{assignedEmployeeFirstName}} {{assignedEmployeeLastName}}</span></p>
            </div>
            @if (requiresEditing) {
                <div class="flex flex-col w-full">
                    <p class="text-[16px] font-semibold text-lightRed">UPDATE REQUIRED</p>
                    <button class="flex items-center justify-center w-auto p-2 rounded-md text-[12px] font-medium border-[1px] 
                                    border-primaryBlack hover:border-lightGreen
                                    hover:bg-lightGreen hover:text-primaryWhite transition-[0.5s] uppercase"
                            (click)="editPosition()">
                        Edit position
                    </button>
                </div>
            }            
        } @else {
            @if (requiresEditing) {
                <div class="flex flex-col w-full">
                    <p class="text-[16px] font-semibold text-lightRed">UPDATE REQUIRED</p>
                    <button class="flex items-center justify-center w-auto p-2 rounded-md text-[12px] font-medium border-[1px] 
                                    border-primaryBlack hover:border-lightGreen
                                    hover:bg-lightGreen hover:text-primaryWhite transition-[0.5s] uppercase"
                            (click)="editPosition()">
                        Edit position
                    </button>
                </div>
            } @else {
                <div class="flex flex-col w-full gap-2">
                    <p class="text-[14px] font-medium text-lightRed tracking-wide uppercase">Not assigned</p>
                    <button class="flex items-center justify-center w-auto p-2 rounded-md text-[12px] font-medium border-[1px] 
                                    hover:bg-lightGreen hover:text-primaryWhite transition-[0.5s] uppercase"
                        (click)="openAssignEmployeeForm()">
                        Assign employee
                    </button>
                </div>
            }
        }
    </div>
</div>