<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <p class="text-[24px] font-semibold text-primaryBlack">Plans</p> 

    <div class="flex flex-col gap-4 md:flex-row w-full h-full items-center justify-center justify-evenly">   
        <app-plan-card
            planName="Free"
            [availableOptions]="['Employee Management', 'Project Management']"
            [unavailableOptions]="['Teams Management', 'Inventory and Equipment Management', 'Employee Activity Tracking', 'Finance']"
            [cost]=0
        />
        <app-plan-card
            planName="Business"
            [availableOptions]="['Employee Management', 'Project Management', 'Teams Management', 'Inventory and Equipment Management',  'Employee Activity Tracking', 'Finance']"
            [unavailableOptions]="[]"
            [cost]=50
        />
        <app-plan-card
            planName="Basic"
            [availableOptions]="['Employee Management', 'Project Management', 'Teams Management', 'Inventory and Equipment Management']"
            [unavailableOptions]="['Employee Activity Tracking', 'Finance']"
            [cost]=30
        />
    </div>
</div>