import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AvailableAsset } from '../models/availableAsset';
import { AssetStatus } from '../models/assetStatus';
import { Asset } from '../models/asset';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  // private apiServerUrl = "https://api.firmegenerale.cloud";
  private apiServerUrl = environment.apiServerUrl;
  
  constructor(private http : HttpClient) { }

  // private getCustomHeaders() : HttpHeaders{
  //   const companyId = sessionStorage.getItem('company');
  //   const domain = sessionStorage.getItem('domain');
  //   const token = sessionStorage.getItem('token');

  //   if(companyId != null && domain == null){
  //     const customHeaders = new HttpHeaders({
  //       'Content-Type' : 'application/json',
  //       'Authorization' : `Bearer ${token}`,
  //       'companyID' : `${companyId}`
  //     })
  //     return customHeaders
  //   } else {
  //     const customHeaders = new HttpHeaders({
  //       'Content-Type' : 'application/json',
  //       'Authorization' : `Bearer ${token}`,
  //       'companyID' : `${domain}`
  //     })
  //     return customHeaders;
  //   }
  // }


  //Check if employees are sending domain inside of companyID header. If so, change the header name to be companyDomain
  public getAll() : Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.apiServerUrl}/asset/getAll`, /*{ headers : this.getCustomHeaders() }*/);
  }

  public save(formData : FormData) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/asset/save`, formData);
  }

  public deleteAsset(assetId : string) : Observable<any>{
    return this.http.delete<any>(`${this.apiServerUrl}/asset/delete?assetID=${assetId}`, {});
  }

  public deleteMoreAssets(assetId : string, counter : number) {
    return this.http.delete<any>(`${this.apiServerUrl}/asset/delete/instanc?assetID=${assetId}&counter=${counter}`);
  }

  public edit(formData : FormData, assetId : string) : Observable<any> {
    return this.http.patch<any>(`${this.apiServerUrl}/asset/update?assetID=${assetId}`, formData);
  }

  public getAssetHistory(assetId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/asset/admin/find/assetHistory?assetID=${assetId}`)
  }

  public getAvailable() : Observable<AvailableAsset[]>{
    return this.http.get<AvailableAsset[]>(`${this.apiServerUrl}/asset/find/all/available`)
  }

  public getAssetHistoryForEmployee() : Observable<any>{
    const employeeId = sessionStorage.getItem('employeeId');
    return this.http.get<any>(`${this.apiServerUrl}/asset/admin/find/for?employeeID=${employeeId}`);
  }

  public getAssetImage(assetId : string, companyId : any) : Observable<string>{
    return this.http.get<string>(`${this.apiServerUrl}/S3/generateS3Link/${assetId}/${companyId}`)
  }

  public rentAsset(assetId : string, body : any) : Observable<any>{
    const employeeId = sessionStorage.getItem('employeeId')
    return this.http.post<any>(`${this.apiServerUrl}/asset/assignTo/employee?assetID=${assetId}&employeeID=${employeeId}`, body);
  }

  public checkAssetStatus(assetId : string) : Observable<AssetStatus>{
    return this.http.get<AssetStatus>(`${this.apiServerUrl}/asset/check/asset/status?assetID=${assetId}`);
  }

  public employeesAssetHistory(employeeId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/asset/admin/find/for?employeeID=${employeeId}`)
  }
  
  public getById(assetId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/asset/get/by/id?assetID=${assetId}`);
  }

  public getQRDetails(assetId : string, domain : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/asset/reservation/info/qr/${assetId}/${domain}`);
  }

  public addAssetInstances(difference : number, assetId : string) : Observable<any>{
    return this.http.patch(`${this.apiServerUrl}/asset/add/instances?assetID=${assetId}&counter=${difference}`, {});
  }

  public deleteAssetInstances(difference : number, assetId : string) : Observable<any>{
    return this.http.delete(`${this.apiServerUrl}/asset/delete/instanc?assetID=${assetId}&counter=${difference}`, {});
  }
}
