@if (isIncome) {
    <!--Income-->
    <div class="flex items-center w-[700px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Income details</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-col w-full gap-2">
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="font-semibold text-primaryBlack text-[24px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">{{income?.Name}}</p>
                    <p class="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-lightGreen">{{income?.Ammount}}</p>
                </div>
                <div class="flex flex-col w-full">
                    <p class="text-primaryBlack text-[16px] font-light">Payer: <span class="font-medium">{{income?.Payer}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Payment date: <span class="font-medium">{{parsedDate}}</span></p>
                    @if (hasProject) {
                        <p class="text-primaryBlack text-[16px] font-light">Project: <span class="font-medium">{{project?.Name}}</span></p>
                    } @else {
                        <p class="text-white text-[16px] font-light">Project: <span class="font-medium">{{project?.Name}}</span></p>
                    }
                </div>    
            </div>
            <hr class="w-full text-primaryBlack/50">
            <div class="flex flex-col w-full max-h-[200px] overfloy-y-auto">
                <p class="text-primaryBlack/50 text-xs md:text-sm xl:text-md uppercase tracking-wider">Description</p>
                <p class="text-primaryBlack text-sm md:text-md xl:text-xl">{{income?.Description}}</p>
            </div>
        </div>
    </div>
    
} @else {
    <!--Expense-->
    <div class="flex items-center w-[800px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Expense details</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-col w-full gap-2">
                <div class="flex flex-row w-full items-center justify-between">
                    <p class="font-semibold text-primaryBlack text-[24px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">{{expense?.name}}</p>
                    <p class="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-lightGreen">{{expense?.amount}}</p>
                </div>
                <hr class="w-full text-primaryBlack/50">
                <div class="flex flex-col w-full">
                    <p class="text-primaryBlack text-[16px] font-light">Frequency: <span class="font-medium">{{expense?.frequency}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Creation date: <span class="font-medium">{{creationDate}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Payment date: <span class="font-medium">{{parsedDate}}</span></p>
                    <p class="text-primaryBlack text-[16px] font-light">Cycles: <span class="font-medium">{{expense?.cycles}}</span></p>
                </div>    
            </div>
        </div>
    </div>
}