import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { end } from '@popperjs/core';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public getFilteredActivities(employeeId : string, startDate : number, endDate : number) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/employee/generateActivityReport?employeeID=${employeeId}&startDate=${startDate}&endDate=${endDate}&durationMin=1&projectID=`);
  }

  public getAllForEmployee(employeeId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/employee/getAllActivities?employeeID=${employeeId}`);
  }

  public reportPdf(employeeId : string, activities : any[], startDate : number, endDate : number, domain : string) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/report/pdf?employeeID=${employeeId}&startDate=${startDate}&endDate=${endDate}&domain=${domain}&projectID=`, activities, { responseType: 'arraybuffer' as 'json' })
  }

  public reportCsv(employeeId : string, activities : any[], startDate : number, endDate : number, domain : string) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/report/csv?employeeID=${employeeId}&startDate=${startDate}&endDate=${endDate}&domain=${domain}&projectID=`, activities, { responseType: 'arraybuffer' as 'json' })
  }

  public reportXls(employeeId : string, activities : any[], startDate : number, endDate : number, domain : string) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/report/excel?employeeID=${employeeId}&startDate=${startDate}&endDate=${endDate}&domain=${domain}&projectID=`, activities, { responseType: 'arraybuffer' as 'json' });
  }

  public getAllQRCodes() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/report/qr/pdf`, { responseType: 'arraybuffer' as 'json' });
  }

  public getQRForAsset(assetId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/report/qr?assetID=${assetId}`,{responseType: 'arraybuffer' as 'json'});
  }

  public getLogs(page : number, limit : number) : Observable <any> {
    return this.http.get<any>(`${this.apiServerUrl}/company/get/logs?page=${page}&limit=${limit}`);
  }
}
