import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../../button/button.component';
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verify-email-modal',
  standalone: true,
  imports: [FormsModule, ButtonComponent],
  templateUrl: './verify-email-modal.component.html',
  styleUrl: './verify-email-modal.component.css'
})
export class VerifyEmailModalComponent {
  @Input() email : string = '';

  @Output() verified = new EventEmitter<void>();

  constructor(private authService : AuthService,
    private modalService : NgbActiveModal
  ){}

  public digitOne : string = '';
  public digitTwo : string = '';
  public digitThree : string = '';
  public digitFour : string = '';
  public digitFive : string = '';
  public digitSix : string = '';

  
  public proceedWithVerification() {
    const concatenatedString = `${this.digitOne}${this.digitTwo}${this.digitThree}${this.digitFour}${this.digitFive}${this.digitSix}`;
    const enteredCode = parseInt(concatenatedString, 10);
    console.log(enteredCode);

    this.authService.proceedWithVerification(this.email, enteredCode).subscribe(
      (response : any) => {
        console.log(response);
        this.verified.emit();
        this.modalService.close();
      }
    )
  }

  public focusNext(nextInput: HTMLInputElement, event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= 1) {
      nextInput.focus();
    }
  }
  

}
