import { Component, Input, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-small-employee-card-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './small-employee-card-content.component.html',
  styleUrl: './small-employee-card-content.component.css'
})
export class SmallEmployeeCardContentComponent implements OnInit{
  @Input() FirstName : string = '';
  @Input() LastName : string = '';
  @Input() Role : string = ''
  @Input() DefaultPicture : boolean = true;
  @Input() EmployeeID : string = '';
  @Input() memberID : string = '';

  public employee : any;  
  public employeePictureLink : any;

  constructor(private employeeService : EmployeeService,
    private sanitizer: DomSanitizer,
  ){}

  ngOnInit(): void {
    if(this.EmployeeID !== '') { 
      this.employeeService.getEmployee(this.EmployeeID).subscribe(
        (response : any) => {
          console.log("Fetched employee: ", response);
          this.employee = response;
          this.DefaultPicture = response.DefaultPicture;
          this.FirstName = response.FirstName;
          this.LastName = response.LastName;
          this.getEmployeePicture(response.DefaultPicture);
        }
      )
    }
    if(this.memberID !== '') { 
      this.employeeService.getEmployee(this.memberID).subscribe(
        (response : any) => {
          console.log("Fetched employee: ", response);
          this.DefaultPicture = response.DefaultPicture;
          this.FirstName = response.FirstName;
          this.LastName = response.LastName;
          this.getEmployeePicture(response.DefaultPicture);
        }
      )
    } else {
      this.getEmployeePicture(this.DefaultPicture);
    }
  }

  public getEmployeePicture(defaultPicture : any | undefined) {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(this.DefaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      if(this.memberID !== ''){
        param = this.memberID;
      } else {
        param = this.EmployeeID;
      }
      companyId = company;
    }
    this.employeeService.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      }
    )  
  }
  
}
