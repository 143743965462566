<form [formGroup]="form" class="justify-center items-center">
    @if (isLoading) {
        <div class="flex flex-col w-full h-[700px] w-[800px] xl:w-[800px] 2xl:w-[1000px] bg-white rounded-md p-16 gap-4 items-center justify-center">
            <svg aria-hidden="true" class="inline w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
            <p class="text-lg font-semibold text-lightGreen">Creating employee...</p>
        </div>
    }
    @else {
        <div class="flex items-center w-[800px] xl:w-[800px] 2xl:w-[1000px] items-center justify-center bg-primaryBlack/30 font-poppins">
            <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-center justify-between">
                    <p class="text-[24px] font-semibold text-primaryBlack">Add new employee</p> 
                    <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                    hover:bg-lightGreen transition-[0.5s]"
                            (click)="activeModalService.close()">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                    </button>        
                </div>
                <div class="border-b border-gray-200 dark:border-gray-700">
                    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        <li class="me-2" (click)="toggleGeneral()">
                            <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                                [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showGeneral,
                                            'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showGeneral}">
                                <svg class="w-4 h-4 me-2"
                                    [ngClass]="{'text-lightGreen':showGeneral,
                                        'group-hover:text-bg-primaryBlack/60' : showGeneral}"
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                                </svg>
                                General
                            </a>
                        </li>
                        <li class="me-2" (click)="toggleFinance()">
                            <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                                [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showFinance,
                                            'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showFinance}">                    
                                <svg class="w-4 h-4 me-2"
                                    [ngClass]="{'text-lightGreen':showFinance,
                                                'group-hover:text-bg-primaryBlack/60' : showFinance}" 
                                    fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V3zM0 5h16v3H0V5zm0 4h16v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-3zm0 0v3v-3z"/>
                                </svg>
                                Finance
                            </a>
                        </li>
                        <!-- <li class="me-2" (click)="toggleSkills()">
                            <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                                [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showSkills,
                                            'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showSkills}">                    
                                <svg class="w-4 h-4 me-2"
                                    [ngClass]="{'text-lightGreen':showSkills,
                                                'group-hover:text-bg-primaryBlack/60' : showSkills}" 
                                    fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V3zM0 5h16v3H0V5zm0 4h16v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-3zm0 0v3v-3z"/>
                                </svg>
                                Skills
                            </a>
                        </li> -->
                    </ul>
                </div>
                <div class="flex flex-row w-full items-start justify-center">
                    @if (showGeneral) {
                        <div class="flex flex-col gap-2 w-full">
                            <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">PERSONAL INFO</p>
                            <div class="flex flex-row w-full items-start justify-center gap-[16px]">
                                <div class="flex flex-col w-full gap-2">
                                    <div class="flex flex-row w-full gap-4 mt-2">
                                        <div class="flex flex-col w-full">
                                            @if (form.get('FirstName')?.touched && form.get('FirstName')?.invalid) {
                                                <label for="firstname" class="text-lightRed font-regular text-[12px]">First name (Required)</label>
                                            } @else {
                                                <label for="firstname" class="text-primaryBlack/70 font-regular text-[12px]">First name</label>
                                            }
                                            <input formControlName="FirstName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="First name"/>
                                        </div>
                                        <div class="flex flex-col w-full">
                                            @if (form.get('LastName')?.touched && form.get('LastName')?.invalid) {
                                                <label for="lastname" class="text-lightRed font-regular text-[12px]">Last name (Required)</label>
                                            } @else {
                                                <label for="lastname" class="text-primaryBlack/70 font-regular text-[12px]">Last name</label>
                                            }
                                            <input formControlName="LastName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Last name"/>
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full">
                                        @if (form.get('DateOfBirth')?.touched && form.get('DateOfBirth')?.invalid) {
                                            <label for="lastname" class="text-lightRed font-regular text-[12px]">Date of birth (Required)</label>
                                        } @else {
                                            <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Date of birth</label>
                                        }
                                        <input formControlName="DateOfBirth" type="date" id="dateInput" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                                    </div>
                                    <div class="flex flex-col w-full">
                                        @if (form.get('Email')?.touched && form.get('Email')?.invalid) {
                                            <label for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                                        } @else {
                                            <label for="email" class="text-primaryBlack/70 font-regular text-[12px]">Email address</label>
                                        }
                                        <input formControlName="Email" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="email@domain.com"/>
                                    </div>
                                    <div class="flex flex-col w-full">
                                        @if (form.get('Phone')?.touched && form.get('Phone')?.invalid) {
                                            <label for="Phone" class="text-lightRed font-regular text-[12px]">Phone number (Required)</label>
                                        } @else {
                                            <label for="Phone" class="text-primaryBlack/70 font-regular text-[12px]">Phone number</label>
                                        }
                                        <input formControlName="Phone" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Phone number"/>
                                    </div>
                                    <div class="flex flex-row justify-between w-full gap-4">
                                        <div class="flex flex-col w-full">
                                            <label for="country" class="text-primaryBlack/70 font-regular text-[12px]">Country</label>
                                            <app-dropdown-menu class="z-[1]"
                                                inputPlaceHolder="Country"
                                                width="100%"
                                                [data]="country_list"
                                                (selectionChanged)="onCountrySelected($event)"
                                            />
                                        </div>
                                        <div class="flex flex-col w-full">
                                            <label for="city" class="text-primaryBlack/70 font-regular text-[12px]">City</label>
                                            <app-dropdown-menu #cityDropdown 
                                                class="z-[1]"         
                                                inputPlaceHolder="City"
                                                [data]="cities"
                                                width="100%"
                                                (selectionChanged)="onCitySelected($event)"
                                            /> 
                                        </div>          
                                    </div>
                                    <div class="flex flex-col w-full">
                                        @if (form.get('Address')?.touched && form.get('Address')?.invalid) {
                                            <label for="Address" class="text-lightRed font-regular text-[12px]">Address (Required)</label>
                                        } @else {
                                            <label for="Address" class="text-primaryBlack/70 font-regular text-[12px]">Address</label>
                                        }
                                        <input formControlName="Address" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Address"/>
                                    </div>
                                    <div class="flex flex-col w-full">
                                        @if (form.get('EmergencyContact')?.touched && form.get('EmergencyContact')?.invalid) {
                                            <label for="Emergency" class="text-lightRed font-regular text-[12px]">Emergency phone number (Required)</label>
                                        } @else {
                                            <label for="Emergency" class="text-primaryBlack/70 font-regular text-[12px]">Emergency phone number</label>
                                        }
                                        <input formControlName="EmergencyContact" type="number" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Emergency phone number"/>
                                    </div>
                                    <div class="flex flex-col w-full">
                                        @if (form.get('NumberOfDaysOff')?.touched && form.get('NumberOfDaysOff')?.invalid) {
                                            <label for="NumberOfDaysOff" class="text-lightRed font-regular text-[12px]">Number of days off per year (Required, Not negative)</label>
                                        } @else {
                                            <label for="NumberOfDaysOff" class="text-primaryBlack/70 font-regular text-[12px]">Number of days off per year</label>
                                        }
                                        <input type="number" min="0" formControlName="NumberOfDaysOff" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Number of days off per year"/>
                                    </div>
                                    <div class="flex flex-col w-full gap-2">
                                        <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">Select Role</p>
                                        <div class="flex flex-row items-center gap-4">
                                            <label class="flex items-center gap-2">
                                                <input type="radio" formControlName="Role" [value]="'Employee'" class="h-4 w-4">
                                                <span class="text-[14px] text-primaryBlack">Employee</span>
                                            </label>
                                            <label class="flex items-center gap-2">
                                                <input type="radio" formControlName="Role" [value]="'ProjectManager'" class="h-4 w-4">
                                                <span class="text-[14px] text-primaryBlack">Manager</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
    
                                <!--IMAGE UPLOAD-->
                                <div class="flex flex-col w-full rounded-md gap-2">
                                    <p class="text-[12px] font-light text-primaryBlack/50">Select an image from your device</p>
                                    <input type="file" (change)="onPictureSelected($event)" class="flex w-full shadow-md cursor-pointer rounded-[6px] bg-primaryBlack/20"/>
                                    <div class="flex w-full items-center justify-center">
                                        @if(imageSrc){
                                            <div class="flex size-[220px] img-container rounded-full border-[1px] border-primaryBlack/20">
                                                <img [src]="imageSrc" class="rounded-full w-full h-full" alt="Image Preview">
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    @if(showFinance){
                        <div class="flex flex-col w-full items-start justify-start gap-[16px]">
                            <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">FINANCES</p>
                            <div class="flex flex-row w-full gap-4 mt-2">
                                <div class="flex flex-col w-full">
                                    @if (form.get('NetSalary')?.touched && form.get('NetSalary')?.invalid) {
                                        <label for="NetSalary" class="text-lightRed font-regular text-[12px]">Net salary (Required, Not negative)</label>
                                    } @else {
                                        <label for="NetSalary" class="text-primaryBlack/70 font-regular text-[12px]">Net salary (monthly)</label>
                                    }
                                    <input formControlName="NetSalary" min="0" type="number" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Net salary"/>
                                </div>
                                <div class="flex flex-col w-full">
                                    @if (form.get('GrossSalary')?.touched && form.get('GrossSalary')?.invalid) {
                                        <label for="GrossSalary" class="text-lightRed font-regular text-[12px]">Gross salary (Required, Not negative)</label>
                                    } @else {
                                        <label for="GrossSalary" class="text-primaryBlack/70 font-regular text-[12px]">Gross salary (monthly)</label>
                                    }
                                    <input formControlName="GrossSalary" min="0" type="number" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Gross salary"/>
                                </div>
                            </div>
                        </div>
                    }
                    @if (showSkills) {
                        <div class="flex flex-col w-full">
                            <div class="flex flex-col w-full gap-2">
                                <div class="flex flex-row w-full items-center gap-2 justify-start mt-2">
                                    <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">SKILLS</p>
                                    <button class="flex p-2 rounded-md gap-2 bg-darkGreen items-center justify-center hover:bg-lightGreen transition-[0.5s]"
                                            (click)="goToAddSkill()">
                                        <img src="../../../assets/icons/PlusIcon.png" class="size-[16px] nav_menu_icon">
                                        <p class="text-primaryWhite text-[12px]">Add new</p>
                                    </button>
                                </div>
                                <p class="text-[12px] text-primaryBlack/50 font-light">Add one or more skills for the new employee</p>
                                <hr class="text-primaryBlack/50 w-full">
                            </div>
                            <div class="flex flex-col w-full overflow-y-scroll gap-2">
                                @for (skills of selectedSkills; track $index) {
                                    <div class="flex flex-row w-full justify-between items-center justify-center">
                                        <div class="flex flex-row items-center justify-center gap-2">
                                            <img src="../../../assets/icons/{{selectedSkills[$index]}}Icon.png" class="size-[20px]"
                                                onerror="this.onerror=null; this.src='../../../assets/icons/GeneralTechIcon.png'">
                                            <p class="font-regular text-[16px] text-primaryBlack">{{selectedSkills[$index]}}</p>
                                        </div>
                                        <p class="font-regular text-[16px] text-primaryBlack">{{selectedLevels[$index]}}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div class="flex w-full justify-end">
                    <button (click)="SaveEmployee()"
                            [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                            class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                        Save employee
                    </button>
                </div>
            </div>
        </div>
    }
</form>
