import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TeamsService } from '../../../services/teams.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ColorTranslator } from 'colortranslator';
import { SkeletonEmployeeCardComponent } from "../../../components/loaders/skeleton-employee-card/skeleton-employee-card.component";
import { EmployeeCardComponent } from "../../../components/employee-card/employee-card.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../../components/modals/warning-modal/warning-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { NavigationService } from '../../../services/navigation.service';
import { AddEmployeeToTeamComponent } from '../../../components/modals/add-employee-to-team/add-employee-to-team.component';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-team-display',
  standalone: true,
  imports: [RouterModule, CommonModule, SkeletonEmployeeCardComponent, EmployeeCardComponent],
  templateUrl: './team-display.component.html',
  styleUrl: './team-display.component.css'
})
export class TeamDisplayComponent implements OnInit{

  public teamId : string = ''
  public team! : any;
  public badgeRGBA : string = '';
  public membersCount : number = 0;
  public members : any[] = [];
  public skeletonCount : any[] = []

  public isLoading : boolean = false;
  public showGeneral : boolean = true;
  public showMembers : boolean = false;

  constructor(private route : ActivatedRoute,
    private teamService : TeamsService,
    private modalService : NgbModal,
    private toast : NgToastService,
    private navigationService : NavigationService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    const teamIdFromRoute = this.route.snapshot.paramMap.get('teamId');

    if(teamIdFromRoute !== null){
      this.teamId = teamIdFromRoute;
      this.fetchTeam();
    }
  }

  public fetchTeam() : void {
    this.teamService.getById(this.teamId).subscribe(
      (response : any) => {
        console.log("Fetched team: ", response);
        this.team = response;
        this.members = this.team.Employees;
        this.membersCount = this.members.length;
        this.getBadgeBackgroundColor();
      }
    )
  }

  public getBadgeBackgroundColor() : void {
    const color_rgb = new ColorTranslator(this.team.ColorHash);
    console.log("HSL Color L: ", color_rgb.R)

    this.badgeRGBA = "rgba(" + color_rgb.R + ", " + color_rgb.G + ", " + color_rgb.B + ", 0.2)"
    console.log("RGBA Filter: ", this.badgeRGBA);
  }

  public deleteTeam() : void {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.teamService.deleteTeam(this.team.ID).subscribe(
          (response : any) => {
            console.log(response);
            this.toast.success("Team successfully deleted!", "Success!", 3000);
            this.navigationService.navigateToTeams();
          }
        )
      }
    )
  }

  public openAddEmployeeToTeamModal() : void {
    const modalRef = this.modalService.open(
      AddEmployeeToTeamComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.team = this.team;

    modalRef.componentInstance.employeeAdded.subscribe(
      () => {
        this.fetchTeam();
        this.toggleMembers();
        this.toast.success("Employee successfully added to the team!", "Success!", 3000);
      }
    )
  }

  public handleEmployeeRemovedFromTeam() : void {
    this.fetchTeam();
    this.toggleMembers();
    this.toast.success("Employee successfully removed from team!", "Success!", 3000);
  }

  public toggleGeneral(){
    this.showGeneral = true;
    this.showMembers = false;
  }

  public toggleMembers(){
    this.showGeneral = false;
    this.showMembers = true;
  }
}
