<div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Create filtered projects widget</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="closeModal()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <p class="text-[14px] text-primaryBlack/50 font-light">Filter by one or more of the following properties</p>
        <div class="flex flex-col gap-4 items-center">
            <div class="flex flex-col w-full">
                <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Widget description</label>
                <div class="flex w-full flex-row gap-4 items-end">
                    <input [(ngModel)]="description" type="text" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                </div>
            </div>  

            <div class="flex flex-col gap- w-full">
                <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Project start date</label>
                <div class="flex w-full flex-row gap-4 items-end">
                    <input [(ngModel)]="startDate" type="date" id="dateInput" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    <div class="flex flex-row gap-2">
                        <label>
                            <input type="radio" name="startDateFilter" [(ngModel)]="startDateFilter" value="<"> &lt;
                        </label>
                        <label>
                            <input type="radio" name="startDateFilter" [(ngModel)]="startDateFilter" value="="> =
                        </label>
                        <label>
                            <input type="radio" name="startDateFilter" [(ngModel)]="startDateFilter" value=">"> &gt;
                        </label>
                    </div>
                </div>
            </div>  

            <div class="flex flex-col gap-1 w-full">
                <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Project end date</label>
                <div class="flex w-full flex-row gap-4 items-end">
                    <input [(ngModel)]="endDate" type="date" id="dateInput" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    <div class="flex flex-row gap-2">
                        <label>
                            <input type="radio" name="endDateFilter" [(ngModel)]="endDateFilter" value="<"> &lt;
                        </label>
                        <label>
                            <input type="radio" name="endDateFilter" [(ngModel)]="endDateFilter" value="="> =
                        </label>
                        <label>
                            <input type="radio" name="endDateFilter" [(ngModel)]="endDateFilter" value=">"> &gt;
                        </label>
                    </div>
                </div>
            </div>  

            <div class="flex flex-col gap-1 w-full">
                <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Project budget</label>
                <div class="flex w-full flex-row gap-4 items-end">
                    <input [(ngModel)]="budget" type="number" id="dateInput" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    <div class="flex flex-row gap-2">
                        <label>
                            <input type="radio" name="budgetFilter" [(ngModel)]="budgetFilter" value="<"> &lt;
                        </label>
                        <label>
                            <input type="radio" name="budgetFilter" [(ngModel)]="budgetFilter" value="="> =
                        </label>
                        <label>
                            <input type="radio" name="budgetFilter" [(ngModel)]="budgetFilter" value=">"> &gt;
                        </label>
                    </div>
                </div>
            </div>  
        </div>
        <div class="flex flex-row w-full justify-end">
            <app-button
                text="Add widget"
                [function]="saveWidget.bind(this)"
            />
        </div>
    </div>
</div>