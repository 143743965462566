<div class="flex flex-col w-full overflow-x-hidden rounded-md overflow-y-auto bg-primaryWhite p-4 gap-4 max-h-[500px] border-[1px] border-primaryBlack">
    <div class="flex flex-row w-full justify-between items-center overflow-x-hidden">
        <p class="text-[12px] font-medium text-primaryBlack/60 tracking-wide uppercase">{{description}}</p>
        <button class="flex flex-row gap-2 items-center group" (click)="deleteWidget()">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
            <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s]">Delete</p>
        </button>
    </div>
    <div class="flex flex-row w-full items-center px-4 overflow-y-auto overflow-x-hidden">
        <div class="w-full" id="pie-chart-asset-availability"></div>
    </div>
</div>