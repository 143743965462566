<div class="flex flex-col py-16 px-12 justify-between w-[550px] h-[600px] bg-greyScaleSurfaceSubtle
            border-[1.32px] border-greyScaleBorderDefault rounded-3xl">
    <p class="big text-greyScaleTextTitle">
        {{Quote}}
    </p>
    <div class="flex flex-row itemse-center w-full gap-2">
        <div class="rounded-md size-16 bg-brandSurfaceDefault">
        </div>
        <div class="flex flex-col w-full">
            <p class="text-[21px] font-bold text-greyScaleTextTitle">{{Testimonial}}</p>
            <p class="text-[21px] font-regular text-greyScaleTextTitle">{{Position}}, {{Company}}</p>
        </div>
    </div>
</div>