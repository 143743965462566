import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { CompanyService } from 'src/app/services/company.service';
import { ButtonComponent } from "../../../components/button/button.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from 'src/app/components/modals/warning-modal/warning-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { NgToastService } from 'ng-angular-popup';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit{

  public user : any;
  public companies : any[] = [];

  constructor(private adminService : AdminService,
    private authService : AuthService,
    private navigationService : NavigationService,
    private modalService : NgbModal,
    private toast : NgToastService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.getAdmin();
  }

  public getAdmin() : void {
    this.adminService.getAdmin().subscribe(
      (response : any) => {
        this.user = response;
        console.log(this.user);
      }
    )
  }

  public globalSignOut() : void {
    const userId = sessionStorage.getItem('adminId')

    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true}
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        if(userId){
          this.authService.signOutFromAllDevices(userId).subscribe(
            (response : any) => {
              console.log(response);
              this.toast.success("Successfully logged out from all devices!", "Success!", 3000);
              sessionStorage.clear();
              this.authService.authStatusSubject.next(false);
              this.navigationService.navigateToLanding();
            }
          )
        }
      }
    )
  }
}
