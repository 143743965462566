<div class="flex flex-col w-full h-full justify-center items-center p-8 font-poppins">

    <div class="block max-w-xl p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        @if(details?.EndDate !== "31.12.1969. 23:59:59"){
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-lightGreen dark:text-white">This equipment is free to use!</h5>
            <p class="font-normal text-gray-700 dark:text-gray-400">This is information about previous reservation.</p>
        }
        <hr class="w-full text-primaryBlack/30 my-2">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Equipment information</h5>
        <div class="flex flex-col w-full justify-start text-start">
            <p class="font-normal text-gray-700 dark:text-gray-400">Name: <span class="font-semibold">{{details?.AssetName}}</span></p>
            <p class="font-normal text-gray-700 dark:text-gray-400">Serial number: <span class="font-semibold">{{details?.AssetSerialNumber}}</span></p>
            @if (details?.InventoryNumbers !== null) {
                <p class="font-normal text-gray-700 dark:text-gray-400">Inventory number: <span class="font-semibold">{{details?.InventoryNumbers[0]}}</span></p>
            }
            <p class="font-normal text-gray-700 dark:text-gray-400">Start date: <span class="font-semibold">{{details?.StartDate}}</span></p>
            @if(details?.EndDate !== "31.12.1969. 23:59:59"){
                <p class="font-normal text-gray-700 dark:text-gray-400">End date: <span class="font-semibold">{{details?.EndDate}}</span></p>
            }
        </div>

        <hr class="w-full text-primaryBlack/30 my-2">

        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Employee information</h5>
        <div class="flex flex-col w-full justify-start text-start">
            <p class="font-normal text-gray-700 dark:text-gray-400">First Name: <span class="font-semibold">{{details?.UserFirstName}}</span></p>
            <p class="font-normal text-gray-700 dark:text-gray-400">Last Name: <span class="font-semibold">{{details?.UserLastName}}</span></p>
            <p class="font-normal text-gray-700 dark:text-gray-400">Email: <span class="font-semibold">{{details?.UserEmail}}</span></p>
        </div>        
        
        <hr class="w-full text-primaryBlack/30 my-2">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Project information</h5>
        @if (details?.ProjectName === '') {
            <p class="font-normal text-gray-700 dark:text-gray-400">This asset is not binded to any project.</p>

        } @else {
            <div class="flex flex-col w-full justify-start text-start">
                <p class="font-normal text-gray-700 dark:text-gray-400">Name: <span class="font-semibold">{{details?.ProjectName}}</span></p>
                <p class="font-normal text-gray-700 dark:text-gray-400">Status: <span class="font-semibold">{{details?.ProjectStatus}}</span></p>
            </div>        
        }
    </div>        
</div>