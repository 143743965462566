import { Component, OnInit } from '@angular/core';
import { UserInfoCardComponent } from '../../../components/user-info-card/user-info-card.component';
import { TechStackAndExperienceComponent } from '../../../components/tech-stack-and-experience/tech-stack-and-experience.component';
import { UserProjectsComponent } from '../../../components/user-projects/user-projects.component';
import { FinanceAndVacationSectionComponent } from '../../../components/finance-and-vacation-section/finance-and-vacation-section.component';
import { EmployeeService } from '../../../services/employee.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Employee } from '../../../models/employee';
import { DomSanitizer } from '@angular/platform-browser';
import { NgToastService } from 'ng-angular-popup';
import { EmployeeAssetHistorySectionComponent } from "../../../components/employee-asset-history-section/employee-asset-history-section.component";
import { NavigationService } from '../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../../components/modals/warning-modal/warning-modal.component';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-view-user-profile',
  standalone: true,
  imports: [UserInfoCardComponent, TechStackAndExperienceComponent, EmployeeAssetHistorySectionComponent, RouterModule,
            UserProjectsComponent, FinanceAndVacationSectionComponent, EmployeeAssetHistorySectionComponent],
  templateUrl: './view-user-profile.component.html',
  styleUrl: './view-user-profile.component.css'
})
export class ViewUserProfileComponent implements OnInit{

  userId : string = '';
  employee! : Employee;
  imagePath : any;

  constructor(private employeeService : EmployeeService,
    private routes : ActivatedRoute,
    public navigationService : NavigationService,
    private toast : NgToastService,
    private modalService : NgbModal,
    private authService : AuthService
  ){}

  ngOnInit(): void {
    const idFromRoute = this.routes.snapshot.paramMap.get('id');
    if(idFromRoute != null){
      this.userId = idFromRoute;
    }
    this.fetchUser();
  }

  public fetchUser() : void {
    this.employeeService.getEmployee(this.userId).subscribe(
      (response : Employee) => {
        this.employee = response;
        console.log(this.employee);
      }
    )
  }

  public handleProfileChanges(message : string) : void {
    this.fetchUser();
    this.toast.success(message + " successfully edited!", "Success!", 3000)
  }

  public deleteEmployee() : void {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.employeeService.deleteEmployee(this.userId).subscribe(
          (response : any) => {
            console.log(response);
            this.toast.success( "Employee deleted successfully!", "Success!", 3000);
            this.navigationService.navigateToEmployees();
          }
        );
      }
    )
  }

}