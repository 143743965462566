<form [formGroup]="form" class="flex w-full py-8 items-center justify-center bg-primaryWhite rounded-md">
    <div class="flex flex-col w-[50%] font-poppins h-full items-center justify-center justify-evenly gap-4">
        <div class="flex flex-row">
            <p class="font-primaryBlack font-bold text-lightGreen text-[48px]">Firme</p>
            <p class="ml-[5px] font-light text-primaryBlack text-[48px]">Générale</p>
        </div>

        <div class="flex flex-col items-center justify-center w-full word-break-all text-center gap-2">
            <p class="text-[24px] xl:text-[22px] text-primaryBlack font-medium text-balance">Sign up as an administrator</p>
            <p class="text-[16px] xl:text-[14px] text-primaryBlack font-light text-balance">Enter all the necessary data in order to be able to use the application</p>
        </div>
        <div class="flex flex-col w-full items-start justify-start gap-4">
            <div class="flex flex-col w-full items-start h-full gap-2">
                <div class="flex flex-row w-full gap-2 mt-2">
                    <div class="flex flex-col w-full">
                        @if (form.get('firstname')?.touched && form.get('firstname')?.invalid) {
                            <label for="firstname" class="text-lightRed font-regular text-[12px]">First name (Required)</label>
                        } @else {
                            <label for="firstname" class="text-primaryBlack/70 font-regular text-[12px]">First name</label>
                        }
                        <input formControlName="firstname" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="First name"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('lastname')?.touched && form.get('lastname')?.invalid) {
                            <label for="lastname" class="text-lightRed font-regular text-[12px]">Last name (Required)</label>
                        } @else {
                            <label for="lastname" class="text-primaryBlack/70 font-regular text-[12px]">Last name</label>
                        }
                        <input formControlName="lastname" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Last name"/>
                    </div>
                </div>
                <div class="flex flex-row w-full gap-2">
                    <div class="flex flex-col w-full">
                        <label for="country" class="text-primaryBlack/70 font-regular text-[12px]">Country</label>
                        <app-dropdown-menu class="z-[50]"
                            width="100%"
                            inputPlaceHolder="Select a country"
                            [data]="country_list"
                            (selectionChanged)="getCountryDialCode($event)"
                        /> 
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="country" class="text-primaryBlack/70 font-regular text-[12px]">City</label>
                        <app-dropdown-menu class="z-[50]"
                            width="100%"
                            inputPlaceHolder="Select a city"
                            [data]="cities"
                            (selectionChanged)="onCitySelected($event)"
                        /> 
                    </div>
                </div>
                <div class="flex flex-row w-full items-center gap-2">
                    <div class="flex flex-col">
                        <label for="phone" class="text-primaryBlack/70 font-regular text-[12px]">Dial code</label>
                        <input [value]="selectedDialCode" disabled class="w-[100px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="+"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('phone')?.touched && form.get('phone')?.invalid) {
                            <label for="phone" class="text-lightRed font-regular text-[12px]">Phone number (Required)</label>
                        } @else {
                            <label for="phone" class="text-primaryBlack/70 font-regular text-[12px]">Phone number</label>
                        }
                        <input formControlName="phone" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Phone number"/>
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('email')?.touched && form.get('email')?.invalid) {
                        <label for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                    } @else {
                        <label for="email" class="text-primaryBlack/70 font-regular text-[12px]">Email address</label>
                    }
                    <input formControlName="email" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="email@domain.com"/>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('password')?.touched && form.get('password')?.invalid) {
                        <label for="password" class="text-lightRed font-regular text-[12px]">Password (Required)</label>
                    }
                    @if (form.get('password')?.touched || form.get('password')?.dirty) {} 
                        @if (form.get('password')?.errors?.['uppercase']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain an uppercase letter.</label>
                        }
                        @else if (form.get('password')?.errors?.['digit']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain a digit.</label>
                        }
                        @else if (form.get('password')?.errors?.['symbol']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain a symbol.</label>
                        }
                        @else if (form.get('password')?.errors?.['length']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must be at least 6 characters long.</label>
                        }
                    @else {
                        <label for="password" class="text-primaryBlack/70 font-regular text-[12px]">Password</label>
                    }
                    <label class="relative block">
                        <input class="placeholder:italic block w-full rounded-md py-4 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] text-primaryBlack focus:outline-lightGreen" 
                                placeholder="Password" [type]="getInputType()" formControlName="password"/>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                            @if(passwordHidden){
                                <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            } @else {
                                <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            }
                        </span>
                    </label>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('repeatPassword')?.touched && form.get('repeatPassword')?.invalid) {
                        <label for="repeatPassword" class="text-lightRed font-regular text-[12px]">Repeated password (Required)</label>
                    } @else if (form.errors?.['passwordMismatch'] && (form.get('repeatPassword')?.touched || form.get('repeatPassword')?.dirty)) {
                        <label for="password" class="text-lightRed font-regular text-[12px]">The password does not match.</label>
                    } 
                    @else {
                        <label for="repeatPassword" class="text-primaryBlack/70 font-regular text-[12px]">Repeated password</label>
                    }
                    <label class="relative block">
                        <input class="placeholder:italic block w-full rounded-md py-4 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] text-primaryBlack focus:outline-lightGreen" 
                                placeholder="Repeated password" [type]="getRepeatedPasswordType()" formControlName="repeatPassword"/>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                            @if(repeatedPasswordHidden){
                                <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[50]" (click)="showHideRepeatedPassword()"/>
                            } @else {
                                <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[50]" (click)="showHideRepeatedPassword()"/>
                            }
                        </span>
                    </label>
                </div>        
            </div>
        </div>
        <div class="flex flex-row w-full items-center justify-startn">
            <input type="checkbox" class="mr-4 size-[16px] checked:bg-lightGreen"/>
            <p class="ml-2 text-[12px] font-light text-primaryBlack">By ticking, you are confirming that you have read, understood and agree to Firme Generale
            <span class="text-[12px] font-medium text-lightGreen hover:text-darkGreen transition-[0.5s] cursor-pointer underline-[1px] underline-lightGreen">Terms and Conditions *</span></p>
            <img src="../../../assets/icons/ShieldIcon.png" class="size-[20px] opacity-50 transition-[0.7s] ml-2">
        </div>
        <div class="flex flex-col items-center w-full justify-end gap-4">
            <button [disabled]="!form.valid" (click)="signUp()"
                    [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                    class="flex w-full items-center justify-center px-16 py-2 rounded-md transition-[0.5s] font-medium text-[20px]">
                Sign Up
            </button>
        </div>
    </div>
</form>