import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanSelectionPreviewModalComponent } from '../modals/plan-selection-preview-modal/plan-selection-preview-modal.component';

@Component({
  selector: 'app-plan-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plan-card.component.html',
  styleUrl: './plan-card.component.css'
})
export class PlanCardComponent {
  @Input() planName : string = ''
  @Input() availableOptions : string[] = [];
  @Input() cost : number = 0;
  @Input() subtitle : string = '';
  @Input() footer : string = '';
  @Input() billedMonthly : boolean = false;

  constructor(public modalService : NgbModal){}

  public openPlanPreview() : void {
    const modalRef = this.modalService.open(
      PlanSelectionPreviewModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.planName = this.planName;
    modalRef.componentInstance.planPrice = this.cost;
    modalRef.componentInstance.availableOptions = this.availableOptions;
    modalRef.componentInstance.aditionalInfo = this.footer;
    modalRef.componentInstance.description = this.subtitle;
  }
}
