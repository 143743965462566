import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EmployeeService } from '../../../services/employee.service';
import { TeamsService } from '../../../services/teams.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { Employee } from '../../../models/employee';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgToastService } from 'ng-angular-popup';
import { SmallEmployeeCardContentComponent } from "../../small-employee-card-content/small-employee-card-content.component";

@Component({
  selector: 'app-create-team-modal',
  standalone: true,
  imports: [ButtonComponent, CommonModule, FormsModule, SmallEmployeeCardContentComponent],
  templateUrl: './create-team-modal.component.html',
  styleUrl: './create-team-modal.component.css'
})
export class CreateTeamModalComponent implements OnInit{

  @Output() teamCreated = new EventEmitter<void>();

  public imagePath : any;
  public imageString : string = '';

  public teamName : string = '';
  public selectedColor : string = '';

  public employees : Employee[] = [];
  public selectedEmployeesIds : string[] = [];

  public colors : string[] = ["#c3dbbe", "#b3d1ef", "#d3e5ee", "#85c1c2", "#e2b4d0", "#d2e8da", "#e9a2b5", "#faf6c1", "#faeba6", "#ace8dc", "#dcc6e7", "#b5eecc", "#f78aa2"];

  constructor(private employeeService : EmployeeService,
    private teamService : TeamsService,
    public modalService : NgbActiveModal,
    public sanitizer : DomSanitizer,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.fetchEmployees();
  }

  public fetchEmployees() : void {
    this.employeeService.getAll().subscribe(
      (response : Employee[]) => {
        this.employees = response;
        console.log("Fetched employees: ", this.employees);
      }
    )
  }

  public setActive(employee : any) : void {
    const index = this.selectedEmployeesIds.indexOf(employee.EmployeeID);

    if (index === -1) {
      this.selectedEmployeesIds.push(employee.EmployeeID);
    } else {
      this.selectedEmployeesIds.splice(index, 1);
    }
  }

  public isActive(employeeId: string): boolean {
    const index = this.selectedEmployeesIds.indexOf(employeeId);
    return index > -1;
  }

  public saveTeam() : void {
    let colorHash;

    this.selectedColor === '' ? colorHash = "#ffffff" : colorHash = this.selectedColor;

    const body = {
      name : this.teamName,
      employees : this.selectedEmployeesIds,
      colorHash : colorHash 
    }

    console.log('SELECTED: ', body);
  
    this.teamService.createTeam(body).subscribe(
      (response : any) => {
        console.log("Team created: ", response);
        this.teamCreated.emit();
        this.modalService.close();
      }
    )
  }

  public isActiveColor(color: string): boolean {
    return this.selectedColor === color;
  }

  public onColorSelected(color: string) {
    this.selectedColor = color;
  }
}
