<div class="flex flex-col font-poppins h-full items-center justify-center">
    <!-- <div class="flex flex-row">
        <p class="font-primaryBlack font-bold text-lightGreen text-[48px]">Firme</p>
        <p class="ml-[5px] font-light text-primaryBlack text-[48px]">Générale</p>
    </div> -->
    <img src="../../../../assets/WelcomePicture2.png" class="w-full absolute top-0 left-0"/>
    <div class="flex flex-col items-center justify-center w-full text-center word-break-all z-[2] mt-[200px]">
        <p class="text-[36px] text-lightGreen font-medium text-balance">Request for password change is being processed.</p>
    </div>
    <div class="loader flex justify-center"></div>
</div>