import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import ApexCharts, { ApexOptions } from 'apexcharts'
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-finance-and-vacation-section',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './finance-and-vacation-section.component.html',
  styleUrl: './finance-and-vacation-section.component.css'
})
export class FinanceAndVacationSectionComponent implements OnInit, OnDestroy, AfterViewInit{
  
  @Input() netSalary : number = 0;
  @Input() grossSalary : number = 0;
  @Input() employeeId : string = '';
  @Input() remainingDaysOff : number = 0;

  @Output() changes = new EventEmitter<string>(); 

  private chart!: ApexCharts;

  netPerHour : number = 0;
  netPerDay : number = 0;
  netPerYear : number = 0;

  grossPerHour : number = 0;
  grossPerDay : number = 0;
  grossPerYear : number = 0;

  public editingNet : boolean = false;
  public editingGross : boolean = false;
  public tmpNet : number | undefined = 0;
  public tmpGross : number | undefined = 0;

  constructor(private employeeService : EmployeeService,
    private toast : NgToastService,
    private _ngZone : NgZone,
    private cdr : ChangeDetectorRef
  ){}

  ngOnInit(): void {
    this.renderChart();
    this.cdr.detectChanges();
  }

  ngAfterViewInit() : void {
    this.calculateNetAndGross();
    this.cdr.detectChanges();
  }

  public calculateNetAndGross() : void {
    this.netPerHour = parseFloat((this.netSalary / 22 / 8).toFixed(1));
    this.netPerDay = parseFloat((this.netSalary/22).toFixed(1));
    this.netPerYear = this.netSalary*12

    this.grossPerHour = parseFloat((this.grossSalary/22/8).toFixed(1));
    this.grossPerDay = parseFloat((this.grossSalary/22).toFixed(1));
    this.grossPerYear = this.grossSalary*12
  }

  public renderChart() : void {
    const options = {
      series: [0, this.remainingDaysOff],
      chart: {
        type: 'donut',
        height: 200
      },
      labels: ['Used', 'Left'],
      colors: ['#032123', '#45DB82'],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 0,
          donut: {
            size: '55%', 
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Total',
                formatter: function (w : any) {
                  return w.globals.seriesTotals.reduce((a : any, b : any) => a + b, 0);
                }
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
        }
      }]
    };

    this.chart = new ApexCharts(document.querySelector('#chart'), options);
    this.chart.render();
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  public toggleEditNet() : void {
    this.editingNet = !this.editingNet;
    this.tmpNet = this.netSalary;
  }

  public cancelEditNet() : void {
    this.editingNet = false;
  }

  public toggleEditGross() : void {
    this.editingGross = !this.editingGross;
    this.tmpGross = this.grossSalary;
  }

  public cancelEditGross() : void {
    this.editingGross = false;
  }

  public save(): void {
    var body;
    if(this.editingNet){
      const newNet = this.tmpNet;
      console.log(newNet);
      this.editingNet = false;
      body = {
        field : "Net",
        value : newNet
      }
    }
    if(this.editingGross){
      const newGross = this.tmpGross;
      console.log(newGross);
      this.editingGross = false;
      body = {
        field : "Gross",
        value : newGross
      }
    }

    this.employeeService.editEmployeeProfile(body, this.employeeId).subscribe(
      () => {
        console.log("Salary updated");
        this.changes.emit("Salary");
        this._ngZone.run(() => {
          this.calculateNetAndGross();
        })
      }
    )
  }
}
