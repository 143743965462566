<form [formGroup]="form">
    <div class="flex items-center w-[500px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Invite administrator</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full gap-2 items-center bg-greyScaleSurfaceDisabled border-[1px] border-greyScaleBorderDefault rounded-md px-4 py-2">
                <img src="../../../../assets/icons/SupportIcon.png" class="size-[20px]">
                <p class="small text-greyScaleBorderDarker">Enter the email with which you want to invite the administrator to the organization.</p>
            </div>
            <div class="flex flex-col w-full">
                @if (form.get('email')?.touched && form.get('email')?.invalid) {
                    <label for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                }
                <input formControlName="email" class="placeholder:italic block w-full rounded-md  px-4 rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] text-primaryBlack focus:outline-brandBorderDefault" placeholder="Email address"/>
            </div>

    <!-- 
            <div class="flex flex-col w-full border-[1px] rounded-md p-4 gap-2">
                <p class="text-primaryBlack/50 tracking-wide text-[12px] font-medium mb-2 uppercase">Employees who fit the position</p>
                <div class="flex flex-row w-full flex-wrap justify-evenly items-center max-h-[300px] overflow-y-auto">
                    @for (employee of matchingEmployees; track $index) {
                        <div class="flex flex-row min-w-[300px] max-w-auto items-center justify-center p-4 border-[1px] rounded-md gap-4
                                    hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                            [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(employee.EmployeeID),
                                    'border-[1px]':!isActive(employee.EmployeeID)}"
                            (click)="setActive(employee)">
                            <div class="flex w-auto size-[65px] img-container rounded-full border-[1px] border-primaryBlack/20">
                                <img *ngIf="employee.EmployeePicture" [src]="getEmployeePicture(employee.EmployeePicture)" class="rounded-full"/>
                            </div>
                            <div class="flex flex-col w-full">
                                <p class="font-medium text-[14px] text-primaryBlack">{{employee.FirstName}} {{employee.LastName}}</p>
                                <p class="font-light text-[12px] text-primaryBlack">{{primarySkillName}}</p>
                                <p class="font-light text-[12px] text-primaryBlack">{{requiredSkillLevelName}}</p>
                                <p class="font-medium text-[12px] text-lightGreen">${{employee.Financial.Gross/22/8 | number:'1.0-1'}}</p>
                            </div>
                        </div>
                    }
                </div>
            </div> -->
            <div class="flex flex-row w-full justify-end">
                <app-button 
                    text="Send invite"
                    [function]="inviteAdministrator.bind(this)"
                />
            </div>
        </div>
    </div>
</form>