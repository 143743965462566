<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto py-2 px-4 gap-4">
    <div class="flex flex-col md:flex-row w-full md:items-center justify-between">
        <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Tasks']}}</p>
        <app-button
            [text]="translationService.translations?.variables['Add new task']"
            [primary]="true"
            [secondary]="false"
            icon="../../../assets/icons/PlusIcon.png"
            [function]="addNewaTask.bind(this)"
        ></app-button>
    </div>
    <div class="grid md:grid-cols-3 gap-4 w-full h-full">
        <div
            class="flex flex-col w-full h-full p-4 bg-primaryWhite rounded-md overflow-y-auto gap-2 overflow-x-hidden"
            cdkDropList
            id="todoList"
            [cdkDropListData]="todoTasks"
            [cdkDropListConnectedTo]="['inProgressList', 'doneList']"
            (cdkDropListDropped)="drop($event, 'TO DO')"
            (cdkDragEntered)="onDragEntered('TO DO')"
            (cdkDragExited)="onDragExited('TO DO')"
            [ngClass]="{'border-green-500': activeSection === 'TO DO', 'border-transparent': activeSection !== 'TO DO'}"
        >
            <p class="small text-greyScaleTextCaption tracking-wide">TO DO</p>
            <div *ngFor="let task of todoTasks; trackBy: trackByIndex">
                <app-task-card 
                    [task]="task" 
                    cdkDrag 
                    [cdkDragData]="task"
                    (taskDeleted)="handleTaskDeleted()"
                    (taskEdited)="handleTaskEdited()">
                </app-task-card>
            </div>
        </div>

        <div
            class="flex flex-col w-full h-full p-4 bg-primaryWhite rounded-md overflow-y-auto gap-2 overflow-x-hidden"
            cdkDropList
            id="inProgressList"
            [cdkDropListData]="inProgressTasks"
            [cdkDropListConnectedTo]="['todoList', 'doneList']"
            (cdkDropListDropped)="drop($event, 'IN PROGRESS')"
            (cdkDragEntered)="onDragEntered('IN PROGRESS')"
            (cdkDragExited)="onDragExited('IN PROGRESS')"
            [ngClass]="{'border-green-500': activeSection === 'IN PROGRESS', 'border-transparent': activeSection !== 'IN PROGRESS'}"
        >
            <p class="small text-greyScaleTextCaption tracking-wide">IN PROGRESS</p>
            <div *ngFor="let task of inProgressTasks; trackBy: trackByIndex">
                <app-task-card 
                    [task]="task" 
                    cdkDrag 
                    [cdkDragData]="task"
                    (taskDeleted)="handleTaskDeleted()"
                    (taskEdited)="handleTaskEdited()">
                </app-task-card>
            </div>
        </div>

        <div
            class="flex flex-col w-full h-full p-4 bg-primaryWhite rounded-md overflow-y-auto gap-2 overflow-x-hidden"
            cdkDropList
            id="doneList"
            [cdkDropListData]="doneTasks"
            [cdkDropListConnectedTo]="['todoList', 'inProgressList']"
            (cdkDropListDropped)="drop($event, 'DONE')"
            (cdkDragEntered)="onDragEntered('DONE')"
            (cdkDragExited)="onDragExited('DONE')"
            [ngClass]="{'border-green-500': activeSection === 'DONE', 'border-transparent': activeSection !== 'DONE'}"
        >
            <p class="small text-greyScaleTextCaption tracking-wide">DONE</p>
            <div *ngFor="let task of doneTasks; trackBy: trackByIndex">
                <app-task-card 
                    [task]="task" 
                    cdkDrag 
                    [cdkDragData]="task"
                    (taskDeleted)="handleTaskDeleted()"
                    (taskEdited)="handleTaskEdited()">
                </app-task-card>
            </div>
        </div>
    </div>
</div>
