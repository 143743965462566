<div class="flex items-center w-[800px] justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Asset history</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <div class="flex flex-col w-full border-[1px] rounded-md p-4 gap-2">
            <p class="text-primaryBlack/50 tracking-wide text-[12px] font-medium mb-2">ASSET DETAILS</p>
            <div class="flex flex-row items-center w-full gap-4">
                <div class="flex size-[100px] img-container rounded-md border-[1px] p-2 border-primaryBlack/20">
                    <img [src]="image" class="size-full"/>
                </div>
                <div class="flex flex-col w-full gap-2">
                    <p class="font-semibold text-primaryBlack text-[24px]">{{asset.Name}}</p>
                    <div class="flex flex-col w-full">
                        @if(asset.SerialNumber !== ''){
                            <p class="font-light text-primaryBlack text-[16px]">Serial number: <span class="font-medium">{{asset.SerialNumber}}</span></p>
                        }
                        <p class="font-light text-primaryBlack text-[16px]">Quantity: <span class="font-medium">{{counter}}</span></p>
                        @if (asset.Permission) {
                            <p class="font-light text-primaryBlack text-[16px]">Require approval: <span class="font-medium text-lightRed">YES</span></p>
                        } @else {
                            <p class="font-light text-primaryBlack text-[16px]">Require approval: <span class="font-medium text-lightGreen">NO</span></p>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col max-h-[600px] overflow-y-auto gap-2">
            <p class="text-[14px] font-medium text-primaryBlack/60  tracking-wide">History</p>
            @if (history !== null) {
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-primaryBlack uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Employee
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Start date
                            </th>
                            <th scope="col" class="px-6 py-3">
                                End date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (historyItem of filteredHistory; track $index){
                            <tr class="bg-primaryWhite h-[45px] border-b hover:bg-primaryBlack/10 transition-[0.5s]">
                                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white gap-4">
                                    <div class="text-base font-semibold">{{historyItem.employee}}</div>
                                </th>
                                <td class="px-6">
                                    <p class="font-medium text-[16px] text-lightGreen">{{historyItem.StartDateParser}}</p>
                                </td>
                                <td class="px-6">
                                    @if (historyItem.EndDateParsed === "Currently in use") {
                                        <p class="font-medium text-[16px] text-lightRed">{{historyItem.EndDateParsed}}</p>
                                    } @else {
                                        <p class="font-medium text-[16px] text-lightGreen">{{historyItem.EndDateParsed}}</p>
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            }
        </div>
    </div>
</div>
