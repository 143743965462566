<div class="flex flex-col w-full items-start rounded-md py-4 px-8 font-poppins gap-2">
    <p class="text-[32px] font-medium text-primaryBlack">{{company?.name}}</p>
    <div class="flex flex-col w-full items-center">
        <div class="flex flex-row gap-2 items-center w-full">
            <img src="../../../assets/icons/DomainIcon.png" class="size-[20px]"/>
            <p class="text-[16px] text-primaryBlack">|</p>
            <p class="text-[16px] text-primaryBlack">firmegenerale.cloud/{{company?.domain}}</p>
        </div>
        <div class="flex flex-row w-full items-center gap-2">
            <img src="../../../assets/icons/LocationIcon.png" class="size-[20px]"/>
            <p class="text-[16px] text-primaryBlack">|</p>
            <p class="text-[16px] text-primaryBlack">{{company?.address}}</p>
        </div>
    </div>
</div>