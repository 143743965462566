import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private apiServerUrl = environment.apiServerUrl;

  public translations: any;
  private selectedLanguage: string = 'en';

  constructor(private http : HttpClient) {
    this.loadInitialLanguage();
  }

  private loadInitialLanguage(): void {
    const storedLanguage = localStorage.getItem('language');
    this.selectedLanguage = storedLanguage ? storedLanguage : 'en';
    localStorage.setItem('language', this.selectedLanguage);
    this.loadDict(this.selectedLanguage);
  }

  public loadDict(selectedLanguage : string) : void {
    this.http.get<{ [key: string]: string }>(`${this.apiServerUrl}/auth/get/dict?language=${selectedLanguage}`).subscribe({
      next: (data) => {
        this.translations = data;
        console.log(this.translations)
        console.log(this.translations.variables.Action)
        this.selectedLanguage = selectedLanguage;
        localStorage.setItem('language', selectedLanguage);
        localStorage.setItem('dictionary', JSON.stringify(this.translations.variables))
      },
      error: (err) => {
        console.error(`Error loading ${selectedLanguage} translations`, err);
      }
    });
  }
}
