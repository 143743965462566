import { Component, OnInit, inject } from '@angular/core';
import { ButtonComponent } from '../../../components/button/button.component';
import { CompanyCardComponent } from "../../../components/company-card/company-card.component";
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../../../services/company.service';
import { NavigationService } from '../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCompanyModalComponent } from '../../../components/modals/create-company-modal/create-company-modal.component';
import { ErrorResponse } from '../../../dtos/ErrorResponse';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-choose-company',
  standalone: true,
  imports: [CompanyCardComponent, CommonModule],
  templateUrl: './choose-company.component.html',
  styleUrl: './choose-company.component.css'
})
export class ChooseCompanyComponent implements OnInit{

  public user : any;
  public companies : any;
  public selectedCompanyID : string = ''
  public companySelected : boolean = false;
  public hasCompnay : boolean = false;

  constructor(private authService : AuthService,
    private companyService : CompanyService,
    public navigationService : NavigationService,
    private modalService : NgbModal,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.getMyCompanies(); 
  }

  public getMyCompanies() : void {
    this.companyService.getMyCompaines().subscribe(
      (response : any[]) => {
        this.companies = response;
        console.log("MY COMPANIES", this.companies);
        if((this.companies.owned === null || this.companies.owned.length === 0)){
          this.hasCompnay = false;
        } else {
          this.hasCompnay = true;
        }
      }
    )
  }

  public setActive(companyId: any): void {
    console.log("Selected company: ", companyId);
    this.selectedCompanyID = companyId;
    this.companySelected = true;
    this.proceedWithSelectedCompany();
  }

  public isActive(companyId: string): boolean {
    return this.selectedCompanyID === companyId;
  }

  public proceedWithSelectedCompany() {
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('CompanyName');
    sessionStorage.setItem("company", this.selectedCompanyID);
    sessionStorage.removeItem('settings');

    let index;

    const owned : any[] = this.companies.owned;
    console.log(owned);

    if(owned !== null) {
      owned.map(company => {
        if(company.id === this.selectedCompanyID){
          index = 1;
        }
      });
      if(index === 1) {
        sessionStorage.setItem("Role", "Owner")
      } else {
        sessionStorage.setItem("Role", "Admin")
      }
    } else {
      sessionStorage.setItem("Role", "Admin")
    }
  
    this.companyService.getCompany().subscribe(
      (response : any) => {
        sessionStorage.setItem("techStack", JSON.stringify(response.TechStack));
        sessionStorage.setItem("experienceLevels", JSON.stringify(response.ExperienceLevels));
        sessionStorage.setItem("professions", JSON.stringify(response.Professions));
  
        console.log("TechStack : ", response.TechStack);
        console.log("Experience levels : ", response.ExperienceLevels);
        console.log("Professions : ", response.Professions);
  
        sessionStorage.setItem('CompanyName', response.Name);
        this.companyService.setCompanyName(response.Name); 
        this.authService.companyStatusSubject.next(true);
  
        if (response.ExperienceLevels.length === 0 || response.TechStack.length === 0 || response.Professions.length === 0) {
          this.companyService.companyChangesRequiredSubject.next(true);
          sessionStorage.setItem('settings', "Not Completed");
          this.navigationService.navigateToSettings();
        } else {
          this.navigationService.navigateToDashboard();
        }
      }
    );
  }
  
  public openCreateNewCompanyModal() {
    const modalRef = this.modalService.open(
      CreateCompanyModalComponent,
      { backdrop: 'static', keyboard: true}
    );

    modalRef.componentInstance.companyCreated.subscribe(
      (response : any) => {
        this.getMyCompanies();
      }
    )
    
  }
}
