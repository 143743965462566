import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { PositionCardComponent } from "../../../components/position-card/position-card.component";
import { EmployeeCardComponent } from '../../../components/employee-card/employee-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgToastService } from 'ng-angular-popup';
import { CompanyService } from '../../../services/company.service';
import { DropdownMenuComponent } from '../../../components/dropdown-menu/dropdown-menu.component';
import { FormsModule } from '@angular/forms';
import { WarningModalComponent } from '../../../components/modals/warning-modal/warning-modal.component';
import { NavigationService } from '../../../services/navigation.service';
import { TeamCardComponent } from '../../../components/team-card/team-card.component';
import { AddTeamToProjectComponent } from '../../../components/modals/add-team-to-project/add-team-to-project.component';
import { AddProjectMemberComponent } from 'src/app/components/modals/add-project-member/add-project-member.component';
import { AddProjectManagerModalComponent } from 'src/app/components/modals/add-project-manager-modal/add-project-manager-modal.component';
import { TaskCardComponent } from 'src/app/components/task-card/task-card.component';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { Task } from 'src/app/models/task';
import { initFlowbite } from 'flowbite';
import { TasksService } from 'src/app/services/tasks.service';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { AddNewTaskModalComponent } from 'src/app/components/modals/add-new-task-modal/add-new-task-modal.component';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-project-display',
  standalone: true,
  imports: [CommonModule, RouterModule, ButtonComponent, EmployeeCardComponent, DropdownMenuComponent, FormsModule, TeamCardComponent, TaskCardComponent, DragDropModule],
  templateUrl: './project-display.component.html',
  styleUrl: './project-display.component.css'
})
export class ProjectDisplayComponent implements OnInit{

  public project : any;
  public projectId : string = '';
  public fetchedCompany : any;
  public projectManagerId : string = '';
  
  public startDateParsed : string = '';
  public endDateParsed : string = '';

  public positions : any[] = [];
  public filteredPositions : any[] = [];
  public employees : any[] = [];
  public filteredEmployees : any[] = [];
  public members : any[] = [];

  public editing : boolean = false;
  public editingBudget : boolean = false;
  public editingDescription : boolean = false;
  public selectedStatus : string = '';

  public originalProject: any = {};
  public changedValues: any = {};

  public showGeneral : boolean = true;
  public showMembers : boolean = false;
  public showTasks : boolean = false;

  public searchPositionsText : string = '';
  public searchMembersText : string = '';

  public tmpDescription : string = '';
  public startDatePresentationWhenEditing : string = '';

  public taskColumns : string[] = [];

  public tasks: Task[] = [];
  public todoTasks: Task[] = [];
  public inProgressTasks: Task[] = [];
  public doneTasks: Task[] = [];
  public activeSection: string | null = null;

  constructor(private routes: ActivatedRoute,
    private projectService : ProjectService,
    private modalService : NgbModal,
    private toast : NgToastService,
    private companyService : CompanyService,
    private navigationService : NavigationService,
    private taskService : TasksService,
    public translationService : TranslationService
  ){}


  ngOnInit(): void {
    const idFromRoute = this.routes.snapshot.paramMap.get('id');
    if(idFromRoute != null){
      this.projectId = idFromRoute;
    }
    this.fetchCompany();
    this.fetchProject();
  }

  public fetchProject() : void {
    this.employees = [];
    this.projectService.get(this.projectId).subscribe(
      (response : any) => {
        this.project = response;
        console.log("PROJECT: ", this.project);
        this.projectManagerId = this.project.ProjectManager;
        this.originalProject = {
          Name: response.Name,
          StartDate: response.StartDate,
          EndDate: response.EndDate,
          Duration: response.ProjectDuration,
          Status: response.ProjectStatus
        };
        const startDate = new Date(this.project.StartDate);
        const endDate = new Date(this.project.EndDate);
        this.members = response.Employees;
        console.log("MEMBERCINE: ", this.members)
        this.startDateParsed = startDate.toISOString().substring(0, 10);
        this.endDateParsed = endDate.toISOString().substring(0, 10);

        console.log("Fetched Project: ",this.project);
        this.taskColumns = this.project.LabelProgress;
        console.log("TASK COLUMNS: ", this.taskColumns);
        console.log("Project Members: ", this.employees);

        this.getProjectTasks();
      }
    );
  }

  public fetchCompany() : void {
    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.fetchedCompany = response;
        console.log("Fetched Company: ",this.fetchedCompany);
      }
    )
  }

  public toggleEditing(section : string) : void {
    if(section === "General") {
      this.editing = !this.editing; 
      const date = new Date(this.project.StartDate);
      this.startDatePresentationWhenEditing = date.toISOString().substring(0, 10);
    }
    if(section === "Budget") this.editingBudget = !this.editingBudget;
    if(section === "Description") this.editingDescription = !this.editingDescription;

  }

  public onFieldChange(field: string, newValue: any) {
    const date = new Date(this.project.StartDate);
    const newDateMillis = date.getTime();
    this.changedValues['startDate'] = newDateMillis;
    if (newValue !== this.originalProject[field]) {
      this.changedValues[field] = newValue;
    }
  }


  public save() {
    let queryParams = new URLSearchParams();
    console.log("NEMA", this.changedValues);
    for (const key in this.changedValues) {
      if(key === 'startDate'){
        const date = new Date(this.changedValues[key]);
        const newDate = date.getTime();
        this.changedValues[key] = newDate;
        queryParams.append(key, this.changedValues[key]);
      } else {
        queryParams.append(key, this.changedValues[key]);
      }
    }
  
    let queryString = queryParams.toString();
    
    console.log(queryString);
    this.projectService.edit(queryString, this.projectId).subscribe(
      (response : any) => {
        console.log("Project updated successfully", response);
        this.toast.success("Project updated successfully!", "Success!", 3000);
        this.editing = false;
        this.editingBudget = false;
        this.fetchProject();
      }
    );
  }

  public openAddTeamModal() : void {
    const modalRef = this.modalService.open(
      AddTeamToProjectComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.project = this.project;

    modalRef.componentInstance.teamAdded.subscribe(
      () => {
        this.fetchProject();
        this.toggleMembers();
        this.toast.success("Team successfully added to the project!", "Success!", 3000);
      }
    )
  }

  public toggleGeneral(){
    this.showGeneral = true;
    this.showMembers  = false;
    this.showTasks = false;
  }

  public toggleMembers(){
    this.showGeneral = false;
    this.showMembers  = true;
    this.showTasks = false;
  }

  public toggleTasks(){
    this.showGeneral = false;
    this.showMembers  = false;
    this.showTasks = true;
  }

  public deleteProject() {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true },
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.projectService.deleteProject(this.projectId).subscribe(
          (response : any) => {
            console.log(response);
            this.toast.success("Project deleted successfully!", "Success!", 3000);
            this.navigationService.navigateToProjects();
          }
        );
      }
    )
  }

  public handleTeamRemovedFromProject() : void {
    this.fetchProject();
    this.toggleMembers();
    this.toast.success("Team successfully removed from project!", "Success!", 3000);
  }

  public addMembers() : void {
    const modalRef = this.modalService.open(
      AddProjectMemberComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.project = this.project;

    modalRef.componentInstance.memberAdded.subscribe(
      () => {
        console.log("Member added successfully!");
        this.toast.success("Member successfully added!", "Success!", 3000);
        this.fetchProject();
        this.toggleMembers();
      }
    )
  }

  public openAddProjectManagerModal() : void {
    const modalRef = this.modalService.open(
      AddProjectManagerModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.project = this.project;

    modalRef.componentInstance.managerAdded.subscribe(
      (res : any) => {
        this.fetchProject();
        this.toggleMembers();
      }
    )
  }

  public addNewaTask(): void {
    const modalRef = this.modalService.open(
      AddNewTaskModalComponent, 
      { backdrop: 'static', keyboard: true,}
    );

    modalRef.componentInstance.project = this.project;

    modalRef.componentInstance.taskCreated.subscribe(() => {
      this.toast.success('Task created successfully!', 'Success!', 3000);
      this.getProjectTasks();
    });
  }

  public handleMemberRemovedFromProject() : void {
    this.toast.success("Member successfully removed from project!", "Success!", 3000);
    this.fetchProject();
    this.toggleMembers();
  }

  public getProjectTasks() : void {
    this.taskService.getProjectTasks(this.project.ID).subscribe(
      (response : Task[]) => {
        console.log("PROJECT TASKS: ", response);
        this.tasks = response;
        this.filterTasksByStatus();
      }
    )
  }

  private filterTasksByStatus(): void {
    this.todoTasks = this.tasks.filter(task => task.Status === 'TO DO');
    this.inProgressTasks = this.tasks.filter(task => task.Status === 'IN PROGRESS');
    this.doneTasks = this.tasks.filter(task => task.Status === 'DONE');
  }

  public drop(event: CdkDragDrop<Task[]>, newStatus: string): void {
    const task: Task | undefined = event.item.data;

    if (task) {
      task.Status = newStatus;
      this.taskService.updateStatus(task.ID, newStatus).subscribe(() => {
        this.toast.success('Task status updated successfully!', 'Success!', 3000);
        this.getProjectTasks();
        setTimeout(() => {
          initFlowbite();
      }, 0);
      });
    } else {
      console.error('Dragged item data is undefined');
    }

    this.activeSection = null;
  }

  public onDragEntered(section: string): void {
      this.activeSection = section;
  }

  public onDragExited(section: string): void {
    if (this.activeSection === section) {
      this.activeSection = null;
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public handleTaskDeleted() : void {
    this.toast.success("Task successfully deleted!", "Success!", 3000);
    this.getProjectTasks();
  }

  public handleTaskEdited() : void {
    this.toast.success("Task successfully edited!", "Success!", 3000);
    this.getProjectTasks();
  }

}
