import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Department } from '../models/department';
import { Office } from '../models/office';
import { Venue } from '../models/venue';

@Injectable({
  providedIn: 'root'
})
export class OfficeManagementService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public createDepartment(body : any) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/office/createDepartment`, body);
  }

  public getAllDepartments() : Observable<Department[]> {
    return this.http.get<Department[]>(`${this.apiServerUrl}/office/department/getAll`);
  }

  public getDepartment(departmentId : string) : Observable<Department> { 
    return this.http.get<Department>(`${this.apiServerUrl}/office/department/getById?id=${departmentId}`)
  }

  public createVenue(body : any) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/office/createVenue`, body);
  }

  public getAllVenues() : Observable<Venue[]> {
    return this.http.get<Venue[]>(`${this.apiServerUrl}/office/venue/getAll`);
  }

  public createOffice(body : any) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/office/createOffice`, body);
  }

  public getAllOffices() : Observable<Office[]> {
    return this.http.get<Office[]>(`${this.apiServerUrl}/office/getAll`);
  }
}
