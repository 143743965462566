import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-password-change-confirmation',
  standalone: true,
  imports: [],
  templateUrl: './password-change-confirmation.component.html',
  styleUrl: './password-change-confirmation.component.css'
})
export class PasswordChangeConfirmationComponent implements OnInit {

  constructor(
    private navigationService : NavigationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.navigationService.navigateToSignIn();
    }, 3000); 
  }
}
