<form [formGroup]="form">
    <div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Edit asset</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">ASSET INFO</p>
                    <div class="flex flex-col w-full">
                        <label for="Name" class="text-primaryBlack/70 font-regular text-[12px]">Name</label>
                        <input formControlName="Name" type="text" id="Name" [value]="assetName" [placeholder]="assetName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    </div>
                    
                    @if(requireApproval){
                        <div class="flex flex-col w-full">
                            <label for="SerialNumber" class="text-primaryBlack/70 font-regular text-[12px]">Serial Number</label>
                            <input formControlName="SerialNumber" [value]="serialNumber" [placeholder]="serialNumber" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Serial Number"/>
                        </div>
                    } @else {
                        <div class="flex flex-col w-full">
                            <label for="Quantity" class="text-primaryBlack/70 font-regular text-[12px]">Quantity</label>
                            <input type="number" formControlName="Quantity" [value]="quantity" [placeholder]="quantity" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Serial Number"/>
                        </div>
                    }
                    <div class="flex flex-row items-center gap-2 w-full">
                        <label for="RequiredApproval" class="text-primaryBlack/70 font-regular text-[12px]">Required Approval</label>
                        <input formControlName="RequiredApproval" [value]="requireApproval" type="checkbox"/>
                    </div>

                    <div class="flex flex-col w-full">
                        <label for="Image" class="text-primaryBlack/70 font-regular text-[12px]">Upload image</label>
                        <input type="file" (change)="onPictureSelected($event)" class="flex w-full shadow-md cursor-pointer rounded-[6px] bg-primaryBlack/20"/>
                    </div>

                    <div class="flex w-full items-center justify-center">
                        @if(imageSrc){
                            <div class="flex w-[220px] max-w-full max-h-[300px] img-container rounded-md border-[1px] border-primaryBlack/20">
                                <img [src]="imageSrc" class="w-full h-full" alt="Image Preview">
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="flex w-full justify-end">
                <button (click)="editAsset()"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    Save asset
                </button>
            </div>
        </div>
    </div>
</form>
