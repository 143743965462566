<a [routerLink]="['/department', department.ID]">
    <div class="flex flex-col min-w-lg h-full max-w-2xl border-[1px] border-greyScaleBorderDefault p-4 hover:shadow-md
                rounded-lg overflow-y-auto relative transition-[0.5s] cursor-pointer hover:translate-y-[-5px] bg-greyScaleSurfaceDefault">
        <div class="flex flex-col items-start justify-start gap-4">
            <!-- <div class="img-container">
                <img class="w-24 h-24 mb-3 rounded-full" [src]="employeePictureLink"/>
            </div> -->
            <p class="global-bold text-greyScaleTextTitle">{{department.Name}}</p>

            <div class="flex flex-col w-full gap-2 items-start">
                <p class="small text-greyScaleTextSubtitle"><span class="small-bold text-greyScaleTextBody">{{department.Offices.length}}</span> offices</p>
                <p class="small text-greyScaleTextSubtitle"><span class="small-bold text-greyScaleTextBody">{{department.Venues.length}}</span> venues</p>
            </div> 
        </div>
    </div>
</a>
