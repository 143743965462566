<div class="flex items-center w-[900px] xl:w-[1000px] 2xl:w-[1100px] 3xl:w-[1200px] h-auto justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Choose project manager</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <div class="flex flex-col w-full h-full gap-4">
            <div class="flex flex-row w-full items-center p-4 gap-4 rounded-md bg-lightYellow/30">
                <img src="../../../../assets/icons/SupportIcon.png" class="size-[25px]"/>
                <p class="text-primaryBlack text-[14px] font-light">
                    Choose one of the following employees to place him in the position of project manager.<br>
                    If you do not choose anyone, you will automatically be placed in the position of project manager for the project you are creating.             
                </p>
            </div>
            <div class="flex flex-col w-full rounded-md border-[1px] border-primaryBlack/20 h-full p-4 gap-4">
                <p class="text-primaryBlack/40 tracking-wide text-[14px] font-medium">AVAILABLE EMPLOYEES</p>
                <div class="flex flex-row flex-wrap justify-evenly items-center max-h-[600px] overflow-y-auto gap-4">
                    @for (employee of employees; track $index) {
                        @if (employee.Role === "ProjectManager") {
                        <div class="flex flex-row min-w-[300px] max-w-auto items-center justify-center p-4 border-[1px] rounded-md gap-4
                                    hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                            [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(employee.EmployeeID),
                                    'border-[1px]':!isActive(employee.EmployeeID)}"
                            (click)="setActive(employee)">
                            <!-- <div class="flex w-auto size-[65px] img-container rounded-full border-[1px] border-primaryBlack/20">
                                <img [src]="employeeService.getEmployeePicture(employee.DefaultPicture, employee.EmployeeID)" class="rounded-full size-full"/>                   
                            </div>
                            <div class="flex flex-col w-full">
                                <p class="font-medium text-[14px] text-primaryBlack">{{employee.FirstName}} {{employee.LastName}}</p>
                                <p class="font-light text-[12px] text-primaryBlack">{{employee.Role}}</p>
                            </div> -->
                            <app-small-employee-card-content
                                [FirstName]="employee.FirstName"
                                [LastName]="employee.LastName"
                                [Role]="employee.Role"
                                [DefaultPicture]="employee.DefaultPicture"
                                [EmployeeID]="employee.EmployeeID"
                            />
                        </div>
                    }
                    }
                </div>
            </div>
        </div>
        <div class="flex w-full justify-end">
            <button [disabled]="!selected" (click)="select()"
                    [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !selected, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': selected}"
                    class="flex items-center justify-center px-8 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                Save manager
            </button>
        </div>
    </div>
</div>