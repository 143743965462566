import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { LoginRequestBody } from '../dtos/LoginRequestBodyDTO';
import { RegisterRequestBody } from '../dtos/RegisterRequestBodyDTO';
import { TokenAppLoginResponse } from '../dtos/TokenAppLoginResponse';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from './company.service';
import { GoogleLoginResponse } from '../models/googleLoginResponse';
import { newPasswordRequestBody } from '../dtos/newPasswordRequestDTO';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn());
  public authStatus$: Observable<boolean> = this.authStatusSubject.asObservable();

  // public $refreshTokenSubject = new Subject<boolean>; 
  // public $refreshTokenReceived = new Subject<boolean>;
  public isRefreshingToken = false; 
  public tokenRefreshedSubject = new Subject<void>();
  public tokenRefreshed$ = this.tokenRefreshedSubject.asObservable();

  public companyStatusSubject = new BehaviorSubject<boolean>(false);

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient, 
    private router : Router, 
    private route: ActivatedRoute
  ) { }
  
  public isLoggedIn(): boolean {
    const token = sessionStorage.getItem('token');
    return !!token;
  }

  public isCompanySelected(): boolean {
    const company = sessionStorage.getItem('company');
    return !!company;
  }
  
  public logIn(logInDTO: LoginRequestBody, uuid : string) : Observable<HttpResponse<any>> {
    return this.http.post(`${this.apiServerUrl}/auth/login?uuid=${uuid}`, logInDTO, { observe : 'response', responseType: 'text', withCredentials:true}).pipe(
      tap((response: HttpResponse<any>) => {
        const token = response.headers.get('Accesstoken');
        if(token) {
          sessionStorage.setItem('token', token);
          this.authStatusSubject.next(true);
        }
      })
    );
  }

  public login(token : string) : void {
    sessionStorage.setItem('token',token);
  }

  public refreshToken() {
    const adminId = sessionStorage.getItem('adminId');
    const uuid = localStorage.getItem('deviceId')
    return this.http.get<any>(`${this.apiServerUrl}/auth/check/admin/refresh/token?uuid=${uuid}&adminID=${adminId}`, { observe: 'response', withCredentials: true }).pipe(
      tap(response => {
        const refreshedAccessToken = response.headers.get('Accesstoken');
        console.log("Refreshed access token: ", refreshedAccessToken);
        if (refreshedAccessToken) {
          sessionStorage.setItem('token', refreshedAccessToken);
          this.tokenRefreshedSubject.next();
        } else {
          this.logOut();
        }
      })
    );
  }

  public logOut() : void {
    sessionStorage.clear();
    this.authStatusSubject.next(false);
    this.companyStatusSubject.next(false);
    this.router.navigate(['']);
  }

  public signUp(dto : RegisterRequestBody, uuid : string) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/auth/register?uuid=${uuid}`, dto, {observe : 'response'});
  }

  public getAdmin() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/auth/getAdmin`);
  }

  public getUserInfo(domain : any) : Observable<any> {
    var path;
    if(domain != null) {
      path = `/auth/getUser?domain=${domain}`
    } else {
      path = `/auth/getUser`
    }
    return this.http.get<any>(`${this.apiServerUrl}${path}`);
  }

  public loginWithGoogle(credentials : string, uuid : string) : Observable<any> {
    const body = {
      googleToken : credentials
    }

    return this.http.post<any>(`${this.apiServerUrl}/auth/signInWithGoogle?uuid=${uuid}`, body, {observe : 'response'}).pipe(
      tap(response => {
        const token = response.headers.get('AccessToken')
        if(token) {
          sessionStorage.setItem('token', token);
          this.authStatusSubject.next(true);
        }
      })
    );
  }

  public signUpWithGoogle(credentials : string, uuid : string) : Observable<any> {
    const body = {
      googleToken : credentials
    }

    return this.http.post<any>(`${this.apiServerUrl}/auth/signUpWithGoogle?uuid=${uuid}`, body, {observe : 'response'})
  }
 
  public magicLinkLogin(domain : string, body : any) : Observable<string> {
    return this.http.post<string>(`${this.apiServerUrl}/auth/${domain}/magicLink`, body);
  }

  public sendEmailForVerification(email : string) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/auth/createVerificationCode/verification?email=${email}`, {});
    // return this.http.post<any>(`${this.apiServerUrl}/auth/createVerificationCode/verification?email=${email}`, {});
  }

  public proceedWithVerification(email : string, code : number) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/auth/verifyEmail?email=${email}&code=${code}`)
  }

  public generateDeviceUUID() : string {
    const s : any[] = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
  }

  public getDeviceId() : string{
    let deviceId = localStorage.getItem('deviceId')
    if(!deviceId) {
       deviceId = this.generateDeviceUUID();
       localStorage.setItem('deviceId', deviceId);
    }
    return deviceId
  }

  public logoutAdmin() : Observable<any> {
    const admingId = sessionStorage.getItem('adminId');
    const uuid = localStorage.getItem('deviceId');
    return this.http.get<any>(`${this.apiServerUrl}/auth/logout?userID=${admingId}&userType=admin&uuid=${uuid}`);
  }

  public verifyEmailExistence(email:string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/auth/findEmail?email=${email}`);
  }

  public requestPasswordChange(email : string, newPasswordDTO : newPasswordRequestBody) : Observable<HttpResponse<any>> {
    return this.http.post(`${this.apiServerUrl}/auth/forgotPassword?email=${email}`,newPasswordDTO, {observe:'response'});
  }

  public forgotPasswordConfirmation(sometoken : string) {
    return this.http.post(`${this.apiServerUrl}/auth/forgotPasswordCallback/${sometoken}`,{},{observe:'response'});
  }
  
  public handleErrorResponse(error : HttpErrorResponse) : string {
    let errorMessage: string;

    if (typeof error.error === 'string') {
      errorMessage = error.error;
    } else if (error.error?.message) {
      errorMessage = error.error.message;
    } else {
      errorMessage = 'An unexpected error occurred';
    }

    return errorMessage;
  } 
}
