<div [ngStyle]="{'background' : team.ColorHash}"
    class="w-full font-poppins justify-center items-center hover:translate-y-[-5px]
        flex h-full max-w-xl border border-gray-200 p-4 hover:shadow-lg rounded-lg overflow-y-auto relative transition-[0.5s] cursor-pointer"
    (click)="navigationService.navigateToTeam(team.ID)">
    @if (displayedOnProject) {
        <div class="flex flex-row absolute top-2 right-2 group hover:opacity-100 opacity-40 transition-[0.5s] items-center gap-2"
            (click)="deleteTeamFromProject($event)">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[14px]"/>
            <p class="text-[12px]">{{translationService.translations?.variables['Remove from project']}}</p>
        </div>
    }
    <div class="flex flex-col items-center">
        <div class="flex flex-col w-full items-center justify-center text-center">
            <p class="big text-greyScaleTextTitle">{{team.Name}}</p>
        </div>
        <div class="flex flex-row items-center absolute bottom-2 right-2 gap-2">
            <p class="global text-greyScaleTextTitle">{{team.Employees.length}}</p>
            @if (team.Employees.length === 1) {
                <img src="../../../assets/icons/UserIcon.png" class="size-[15px] sm:size-[17px] md:size-[19px] lg:size-[21px] xl:size-[24px]"/>
            }
            @else if (team.Employees.length === 2 || team.Employees.length === 3) {
                <img src="../../../assets/icons/EmployeesIcon.png" class="size-[15px] sm:size-[17px] md:size-[19px] lg:size-[21px] xl:size-[24px]"/>
            }
            @else {
                <div class="flex relative">
                    <img src="../../../assets/icons/EmployeesIcon.png"/>
                    <p class="absolute top-[-8px] right-0 text-primaryBlack font-medium text-[12px]">+</p>
                </div>
            }
        </div>
    </div>
</div>