<form [formGroup]="form">
    <div class="flex items-center w-[800px] xl:w-[900px] 2xl:w-[1000px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Add new income</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">INCOME DETAILS</p>
                    <div class="flex flex-col w-full">
                        @if (form.get('name')?.touched && form.get('name')?.invalid) {
                            <label for="name" class="text-lightRed font-regular text-[12px]">Name (Required)</label>
                        } @else {
                            <label for="name" class="text-primaryBlack/70 font-regular text-[12px]">Name</label>
                        }
                        <input formControlName="name" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Income name"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('description')?.touched && form.get('description')?.invalid) {
                            <label for="description" class="text-lightRed font-regular text-[12px]">Description (Required)</label>
                        } @else {
                            <label for="description" class="text-primaryBlack/70 font-regular text-[12px]">Description</label>
                        }
                        <textarea formControlName="description" id="description" class="placeholder:italic w-full max-h-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 min-h-[45px] text-primaryBlack focus:outline-lightGreen" placeholder="Income description"></textarea>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('amount')?.touched && form.get('amount')?.invalid) {
                            <label for="amount" class="text-lightRed font-regular text-[12px]">Amount (Required, greater than 0)</label>
                        } @else {
                            <label for="amount" class="text-primaryBlack/70 font-regular text-[12px]">Amount</label>
                        }
                        <input formControlName="amount" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Amount"/>
                    </div>
        
                    <div class="flex flex-col w-full">
                        @if (form.get('payer')?.touched && form.get('payer')?.invalid) {
                            <label for="payer" class="text-lightRed font-regular text-[12px]">Payer (Required)</label>
                        } @else {
                            <label for="payer" class="text-primaryBlack/70 font-regular text-[12px]">Payer</label>
                        }
                        <input formControlName="payer" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Payer"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('paymentDate')?.touched && form.get('paymentDate')?.invalid) {
                            <label for="paymentDate" class="text-lightRed font-regular text-[12px]">Payment date (Required)</label>
                        } @else {
                            <label for="paymentDate" class="text-primaryBlack/70 font-regular text-[12px]">Payment date</label>
                        }
                        <input formControlName="paymentDate" type="date" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Payment date"/>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">Select payment type</p>
                        <div class="flex flex-row items-center gap-4">
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Single'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Single</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Monthly'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Monthly</span>
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('cycles')?.touched && form.get('cycles')?.invalid) {
                            <label for="cycles" class="text-lightRed font-regular text-[12px]">Cycles (Required)</label>
                        } @else {
                            <label for="cycles" class="text-primaryBlack/70 font-regular text-[12px]">Cycles</label>
                        }
                        <input formControlName="cycles" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Cycles"/>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">Select payment type</p>
                        <div class="flex flex-row items-center gap-4">
                            <label class="flex items-center gap-2">
                                <input type="radio" (change)="toggleSection('Assets')" formControlName="bindingType" [value]="'Asset'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Asset</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" (change)="toggleSection('Projects')" formControlName="bindingType" [value]="'Project'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Project</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="bindingType" [value]="'None'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">None</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div> 
            @if (showAssets || showProjects) {
                <div class="flex flex-col w-full max-h-[300px] p-4 rounded-md border-[1px] border-primaryBlack/20">
                    @if (showAssets) {
                        <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">Available assets</p>
                        <p class="text-primaryBlack/50 tracking-wide text-[12px] font-light">Select the asset for which the income is intended</p>
                        <div class="flex flex-row flex-wrap w-full items-center gap-4 justify-evenly mt-4 max-h-[300px] overflow-y-auto pt-2">
                            @for (asset of assets; track $index) {
                                <div class="flex flex-row w-auto gap-2 items-center justify-center p-4 rounded-md border-[1px] hover:border-lightGreen transition-[0.5s]
                                            hover:translate-y-[-5px] hover:shadow-md cursor-pointer"
                                    [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveAsset(asset),
                                                'border-[1px]':!isActiveAsset(asset)}"
                                    (click)="onAssetSelected(asset)">
                                    <div class="flex size-[60px] img-container rounded-sm border-[1px] border-primaryBlack/20">
                                        <img [src]="asset.imageUrl" class="rounded-sm size-full"/>
                                    </div>
                                    <div class="flex flex-col w-full">
                                        <p class="text-[16px] font-medium text-primaryBlack">{{asset.Name}}</p>
                                        <p class="text-[12px] font-light text-primaryBlack">Serial number: <span class="font-medium">{{asset.SerialNumber}}</span></p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    @if (showProjects) {
                        <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">Available projects</p>
                        <p class="text-primaryBlack/50 tracking-wide text-[12px] font-light mb-2">Select the project for which the income is intended</p>
                        <div class="flex flex-row flex-wrap w-full items-center gap-4 justify-evenly mt-4 max-h-[300px] overflow-y-auto pt-2">
                            @for (project of projects; track $index) {
                                <div class="flex flex-row w-auto gap-2 items-center justify-center p-4 rounded-md border-[1px] hover:border-lightGreen transition-[0.5s]
                                            hover:translate-y-[-5px] hover:shadow-md cursor-pointer"
                                    [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveProject(project),
                                                'border-[1px]':!isActiveProject(project)}"
                                    (click)="onSelectedProject(project)">
                                    <div class="flex flex-col w-full">
                                        <p class="text-[16px] font-medium text-primaryBlack">{{project.Name}}</p>
                                        <p class="text-[12px] font-light text-primaryBlack">
                                            Status: 
                                            <span class="font-medium"
                                                [ngClass]="{'text-active' : project.ProjectStatus === 'Active',
                                                'text-scheduled' : project.ProjectStatus === 'Scheduled',
                                                'text-paused' : project.ProjectStatus === 'Paused',
                                                'text-finished' : project.ProjectStatus === 'Finished'}">
                                                {{project.ProjectStatus}}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="saveIncome()"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid || selectedAsset === null || selectedProject === null, 
                                    'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid && selectedAsset !== null && selectedProject !== null}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    Save income
                </button>
            </div>
        </div>
    </div>
</form>
