import { Component, Input, OnInit } from '@angular/core';
import { Income } from '../../../models/income';
import { Expense } from '../../../models/expense';
import { Project } from '../../../models/project';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '../../../services/project.service';
import { ButtonComponent } from "../../button/button.component";

@Component({
  selector: 'app-edit-income-or-expense',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './edit-income-or-expense.component.html',
  styleUrl: './edit-income-or-expense.component.css'
})
export class EditIncomeOrExpenseComponent implements OnInit{
  @Input() isIncome : boolean = true;
  @Input() income? : Income;
  @Input() expense? : Expense;

  public parsedDate : string = '';
  public creationDate : string = ''
  public project? : Project;


  constructor(public modalService : NgbActiveModal,
    private projectsService : ProjectService
  ){}

  ngOnInit(): void {
    if(this.income){
      const date = new Date(this.income?.PlanedPaymentDate);
      const paymentDate = date.toISOString().substring(0, 10);
      this.parsedDate = paymentDate;
      this.getProjcet();
    }

    if(this.expense){
      const creationDate = new Date(this.expense?.date);
      const creationDateParsed = creationDate.toISOString().substring(0, 10);
      this.creationDate = creationDateParsed;

      const date = new Date(this.expense?.paymentDate);
      const paymentDate = date.toISOString().substring(0, 10);
      this.parsedDate = paymentDate;
    }
  }

  public getProjcet() : void {
    this.projectsService.get(this.income?.BindingID).subscribe(
      (response : any) => {
        console.log("Project: ", response);
        this.project = response;
      }
    )
  }
}
