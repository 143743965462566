import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgToastService } from 'ng-angular-popup';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-generate-activity-dropdown',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './generate-activity-dropdown.component.html',
  styleUrl: './generate-activity-dropdown.component.css'
})
export class GenerateActivityDropdownComponent {

  @Input() employeeId: string = '';
  @Output() reportType : EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private activityService: ActivityService,
    private ngToast : NgToastService
  ){}

  public isDropdownOpen = false;
  public selectedIndex = -1;

  public displayedText = "Activity report type";
  public displayedImg = "../../../assets/icons/ReportIcon.png";


  @ViewChildren('dropDownOption') dropdownOptions!: QueryList<ElementRef>;

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.selectedIndex = -1; 
    }
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardNavigation(event: KeyboardEvent) {
    if (this.isDropdownOpen) {
      const optionsCount = this.dropdownOptions.length;

      if (event.key === 'ArrowDown') {
        this.selectedIndex = (this.selectedIndex + 1) % optionsCount;
        this.highlightOption();
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        this.selectedIndex = (this.selectedIndex - 1 + optionsCount) % optionsCount;
        this.highlightOption();
        event.preventDefault();
      } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
        this.selectOption(this.selectedIndex);
        event.preventDefault();
      } else if (event.key === 'Escape') {
        this.isDropdownOpen = false;
        this.selectedIndex = -1;
      }
    }
  }

  highlightOption() {
    this.dropdownOptions.forEach((option, index) => {
      const element = option.nativeElement;
      if (index === this.selectedIndex) {
        element.classList.add('highlighted'); 
        element.scrollIntoView({ block: 'nearest' });
      } else {
        element.classList.remove('highlighted');
      }
    });
  }


  public selectOption(index: number) {
    const selectedOption = this.dropdownOptions.get(index)?.nativeElement.textContent.trim();
    console.log(`Selected option: ${selectedOption}`);
    if (selectedOption == "CSV Report") { 
      this.generateCSVReport();
    } else if (selectedOption == "PDF Report") {
      this.generatePDFReport()
    } else if (selectedOption == "XLSX Report") {
      this.generateXLSXReport();
    }

    this.isDropdownOpen = false;
    this.selectedIndex = -1;
  }

  public generatePDFReport() {
    this.displayedText = "PDF Report";
    this.displayedImg = "../../../assets/icons/PdfFileIcon.png";
    this.selectedType("pdf");
    this.isDropdownOpen = false;
  }

  public generateCSVReport() {
    this.displayedText = "CSV Report";
    this.displayedImg = "../../../assets/icons/CsvFileIcon.png";
    this.selectedType("csv");
    this.isDropdownOpen = false;
  }
  
  public generateXLSXReport() {
    this.displayedText = "XLSX Report";
    this.displayedImg = "../../../assets/icons/XlsxFileIcon.png";
    this.selectedType("xlsx");
    this.isDropdownOpen = false;
  }
  

  public selectedType(type : string)  {
    this.reportType.emit(type);
  }
  
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    const isClickedInside = targetElement.closest('.dropdown-container');

    if (!isClickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }
}
