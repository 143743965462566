import { Component, EventEmitter, Output } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';

@Component({
  selector: 'app-colorpicker',
  standalone: true,
  imports: [ColorPickerModule],
  templateUrl: './colorpicker.component.html',
  styleUrl: './colorpicker.component.css'
})
export class ColorpickerComponent {
  public color : any;
  public presetValues : string[] = [];

  public selectedColorHash : any;

  @Output() selectedColor = new EventEmitter<string>();

  public colorList = [
    {key: "firmegenerale", value: "#39b36a", friendlyName: "firmegenerale" },
    {key: "orange", value: "#fa761e", friendlyName: "Orange" },
    {key: "infrared",     value: "#ef486e", friendlyName: "Infrared" },
    {key: "male",       value: "#4488ff", friendlyName: "Male Color" },
    {key: "female",     value: "#ff44aa", friendlyName: "Female Color" },
    {key: "paleyellow",    value: "#ffd165", friendlyName: "Pale Yellow" },
    {key: "gargoylegas",  value: "#fde84e", friendlyName: "Gargoyle Gas" },
    {key: "androidgreen",   value: "#9ac53e", friendlyName: "Android Green" },
    {key: "carribeangreen",    value: "#05d59e", friendlyName: "Carribean Green" },
    {key: "bluejeans",    value: "#5bbfea", friendlyName: "Blue Jeans" },
		{key: "cyancornflower",    value: "#1089b1", friendlyName: "Cyan Cornflower" },
		{key: "warmblack",    value: "#06394a", friendlyName: "Warm Black" },
  ];

  public colorSelected(color : {slider: string, color: string}) {
    this.selectedColor.emit(color.color);
  }

}
