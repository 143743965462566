import { Component, ElementRef, HostListener, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { GlobalSearchResult } from '../../models/globalSearchResult';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetHistoryModalComponent } from '../modals/asset-history-modal/asset-history-modal.component';
import { AssetsService } from '../../services/assets.service';
import { ViewIncomeOrExpenseModalComponent } from '../modals/view-income-or-expense-modal/view-income-or-expense-modal.component';
import { CashflowService } from 'src/app/services/cashflow.service';
import { Income } from 'src/app/models/income';
import { Expense } from 'src/app/models/expense';
import { combineAll } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-global-search',
  standalone: true,
  imports: [FormsModule, RouterModule],
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.css'
})
export class GlobalSearchComponent implements OnInit{

  public globalSearchVisible : boolean = true;

  public globalDropdownVisible: boolean = false;
  public searchText: string = '';

  public employeesVisible: boolean = true;
  public projectsVisible: boolean = true;
  public assetsVisible: boolean = true;
  public incomesVisible: boolean = true;
  public expensesVisible: boolean = true;

  public income: Income = {} as Income;
  public expense: Expense = {} as Expense;

  public assetDefaultPicture: boolean = false;

  public searchResult?: GlobalSearchResult;
  public assets: any[] = [];
  public employees: any[] = [];
  public projects: any[] = [];
  public incomes: any[] = [];
  public expenses: any[] = [];

  @ViewChildren('dropDownOption') dropDownOptions! : QueryList<ElementRef>;
  public selectedIndex = -1;

  constructor(private companyService: CompanyService,
    private router: Router,
    private modalService: NgbModal,
    private assetService: AssetsService,
    private cashflowService: CashflowService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
      
    this.route.paramMap.subscribe(
      params => {
        const category = params.get('category');
        const id = params.get('id');

        if (category && id) {
          this.navigate(category,id);
        }
      }
    );
  }


  @HostListener('document:click', ['$event'])
  public documentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const isClickedOutside = targetElement.closest('.dropdown-container');

    if (!isClickedOutside && this.globalDropdownVisible) {
      this.globalDropdownVisible = false;
    }

    //this.globalDropdownVisible = false;
  }

  public navigate(category: string, id: string) {
    //console.log("This is the id:", id);
    if (category === "Employee") {
      this.router.navigate([`/viewUser/${id}`]).then(
        () => {
          window.location.reload();
        }
      );
    }
    if (category === "Project") {
      this.router.navigate([`/project/${id}`]).then(
        () => {
          window.location.reload();
        }
      );
    }
    if (category === "Asset") {
      this.router.navigate([`/inventory`]);
      const modalRef = this.modalService.open(
        AssetHistoryModalComponent,
        { backdrop: 'static', keyboard: true }
      );

      this.assetService.getById(id).subscribe(
        (response: any) => {
          this.assetDefaultPicture = response.DefaultPicture;
          modalRef.componentInstance.assetId = id;
          modalRef.componentInstance.defaultPicture = this.assetDefaultPicture;
        }
      )

    }
    if (category === "Income" || category === "Expense") {
      if (category === "Income") {

        this.cashflowService.getIncomeById(id).subscribe(
          (response: Income) => {
            this.income = response;
            this.router.navigate(['/analytics']);
            const modalRef = this.modalService.open(
              ViewIncomeOrExpenseModalComponent,
              { backdrop: 'static', keyboard: true }
      
            );
            modalRef.componentInstance.isIncome = true;
            modalRef.componentInstance.income = this.income;
      
          }
        );

        //console.log("this is the response income:", this.income);
      } else {

        this.cashflowService.getExpenseById(id).subscribe(
          (response : any) => {
            this.expense = response;  
            this.router.navigate(['/analytics']);
            const modalRef = this.modalService.open(
              ViewIncomeOrExpenseModalComponent,
              { backdrop: 'static', keyboard: true }
      
            ); 
            modalRef.componentInstance.isIncome = false;
            modalRef.componentInstance.expense = this.expense;
          }
        );
      }
    }
  }

  public showMenu(category: string): void {
    if (category === 'Employees') this.employeesVisible = !this.employeesVisible;
    if (category === 'Projects') this.projectsVisible = !this.projectsVisible;
    if (category === 'Assets') this.assetsVisible = !this.assetsVisible;
    if (category === 'Incomes') this.incomesVisible = !this.incomesVisible;
    if (category === 'Expenses') this.expensesVisible = !this.expensesVisible;
  }

  public globalSearch() {
    if (this.searchText.length > 2) {
      this.globalDropdownVisible = true;
      this.companyService.globalSearch(this.searchText).subscribe(
        (response: GlobalSearchResult) => {
          //console.log("This is the global search response", response);
          this.searchResult = response;

          this.assets = this.searchResult.Assets;
          // if (this.assets.length < 1) {
          //   this.assetsVisible = false;
          // }

          this.employees = this.searchResult.Employees;
          // if (this.employees.length < 1) {
          //   this.employeesVisible = false;
          // }

          this.projects = this.searchResult.Projects;
          // if (this.projects.length < 1) {
          //   this.projectsVisible = false;
          // }

          this.incomes = this.searchResult.Incomes;
          // if (this.incomes.length < 1){
          //   this.incomesVisible = false;
          // }

          this.expenses = this.searchResult.Expenses;
          // if (this.expenses.length < 1) {
          //   this.expensesVisible = false;
          // }

          //console.log("These are incomes :", this.incomes);
          //console.log("These are expenses :", this.expenses);
          //income, expense
        }
      )
    } else {
      this.globalDropdownVisible = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardNavigation(event: KeyboardEvent) {
    if (this.globalDropdownVisible) {
      const optionsCount = this.dropDownOptions.length;

      if (event.key === 'ArrowDown') {
        this.selectedIndex = (this.selectedIndex + 1) % optionsCount;
        this.highlightOption();
        event.preventDefault();
        console.log("this is the index:", this.selectedIndex);
      } else if (event.key === 'ArrowUp') {
        this.selectedIndex = (this.selectedIndex - 1 + optionsCount) % optionsCount;
        this.highlightOption();
        event.preventDefault();
      } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
        this.selectOption(this.selectedIndex);
        event.preventDefault();
      } else if (event.key === 'Escape') {
        //this.isOpen = false;
        this.selectedIndex = -1;
      }
    }
  }

  public highlightOption() {
    this.dropDownOptions.forEach((option, index) => {
      const element = option.nativeElement;
      if (index === this.selectedIndex) {
        element.classList.add('highlighted'); 
        element.scrollIntoView({ block: 'nearest' });
      } else {
        element.classList.remove('highlighted');
      }
    });
  }

  public selectOption(index: number) {
    const selectedOption = this.dropDownOptions.get(index)?.nativeElement;
    const category = selectedOption.getAttribute('data-category');
    const id = selectedOption.getAttribute('data-id');

    if (category && id){
      this.navigate(category,id);
      this.globalDropdownVisible = false;
    } else { 
      console.warn("Category or ID must be provided");
    }
  }
}
