import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/adminPages/dashboard/dashboard.component';
import { SignInComponent } from './pages/adminPages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/adminPages/sign-up/sign-up.component';
import { ViewUserProfileComponent } from './pages/adminPages/view-user-profile/view-user-profile.component';
import { EmployeesComponent } from './pages/adminPages/employees/employees.component';
import { CreateCompanyModalComponent } from './components/modals/create-company-modal/create-company-modal.component';
import { AllProjectsComponent } from './pages/adminPages/all-projects/all-projects.component';
import { CompanySettingsComponent } from './pages/adminPages/company-settings/company-settings.component';
import { ProjectDisplayComponent } from './pages/adminPages/project-display/project-display.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AllAssetsComponent } from './pages/adminPages/all-assets/all-assets.component';
import { ChooseCompanyComponent } from './pages/adminPages/choose-company/choose-company.component';
import { EmployeesLandingComponent } from './pages/employees-landing/employees-landing.component';
import { MagicLinkLoginComponent } from './pages/employeePages/magic-link-login/magic-link-login.component';
import { ProfileComponent } from './pages/adminPages/profile/profile.component';
import { NotificationsComponent } from './pages/employeePages/notifications/notifications.component';
import { VacanciesComponent } from './pages/employeePages/vacancies/vacancies.component';
import { EmployeeAssetsComponent } from './pages/employeePages/employee-assets/employee-assets.component';
import { FinanceComponent } from './pages/employeePages/finance/finance.component';
import { EngagementsComponent } from './pages/employeePages/engagements/engagements.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { authGuard } from './services/guards/auth.guard';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { roleGuard } from './services/guards/role.guard';
import { ApprovedPageComponent } from './pages/requestsPages/approved-page/approved-page.component';
import { AnalyticsComponent } from './pages/adminPages/analytics/analytics.component';
import { ForgotPasswordComponent } from './pages/adminPages/forgot-password/forgot-password.component';
import { TeamsComponent } from './pages/adminPages/teams/teams.component';
import { CreateAdminProfileComponent } from './pages/requestsPages/create-admin-profile/create-admin-profile.component';
import { TeamDisplayComponent } from './pages/adminPages/team-display/team-display.component';
import { PasswordChangeConfirmationComponent } from './pages/requestsPages/password-change-confirmation/password-change-confirmation.component';
import { PasswordRequestchangeConfirmationComponent } from './pages/requestsPages/password-requestchange-confirmation/password-requestchange-confirmation.component';
import { DeniedPageComponent } from './pages/requestsPages/denied-page/denied-page.component';
import { TasksComponent } from './pages/adminPages/tasks/tasks.component';
import { AssetNotUsedPageComponent } from './pages/landing/asset-not-used-page/asset-not-used-page.component';
import { AssetQrDetailsComponent } from './pages/requestsPages/asset-qr-details/asset-qr-details.component';
import { PlansComponent } from './pages/adminPages/plans/plans.component';
import { DepartmentsComponent } from './pages/adminPages/departments/departments.component';
import { DepartmentDetailsComponent } from './pages/adminPages/department-details/department-details.component';
import { VenueDisplayComponent } from './pages/adminPages/venue-display/venue-display.component';
import { ChatComponent } from './pages/adminPages/chat/chat.component';

export const routes: Routes = [
    //Administrator routes
    {path: "globalLanding", component: LandingComponent},
    {path: "dashboard", component: DashboardComponent, canActivate:[authGuard]},
    {path: ":domain/landing/:token", component: EmployeesLandingComponent},
    {path: "signIn", component: SignInComponent},
    {path: "signUp", component: SignUpComponent},
    {path: "viewUser/:id", component: ViewUserProfileComponent, canActivate:[authGuard]},
    {path: "employees", component: EmployeesComponent, canActivate:[authGuard]},
    {path: "addCompany", component: CreateCompanyModalComponent, canActivate:[authGuard]},
    {path: "projects", component: AllProjectsComponent, canActivate:[authGuard]},
    {path: "settings", component: CompanySettingsComponent, canActivate:[authGuard]},
    {path: "viewUser/:id", component: ViewUserProfileComponent, canActivate:[authGuard]},
    {path: "employees", component: EmployeesComponent, canActivate:[authGuard]},
    {path: "addCompany", component: CreateCompanyModalComponent, canActivate:[authGuard]},
    {path: "projects", component: AllProjectsComponent, canActivate:[authGuard]},
    {path: "settings", component: CompanySettingsComponent, canActivate:[authGuard]},
    {path: "project/:id", component: ProjectDisplayComponent},
    {path: "inventory", component: AllAssetsComponent},
    {path: "choose", component: ChooseCompanyComponent, canActivate:[authGuard]},
    {path: "analytics", component: AnalyticsComponent, canActivate:[authGuard]},
    {path: "forgotPassword", component: ForgotPasswordComponent},
    {path: "myProfile", component: ProfileComponent, canActivate:[authGuard]},
    {path: "teams", component: TeamsComponent, canActivate: [authGuard]},
    {path: "team/:teamId", component: TeamDisplayComponent},
    {path: "tasks", component: TasksComponent},
    {path: "forgotPassword", component: ForgotPasswordComponent},
    {path: "plans", component: PlansComponent},
    {path: "departments", component: DepartmentsComponent},
    {path: "department/:id", component: DepartmentDetailsComponent},
    {path: "department/:departmentId/:venueId", component: VenueDisplayComponent},
    {path: "chat", component: ChatComponent},
    {path: "", component: LandingComponent},
    //annualLeavRequestApproved i annualLeaveRequestDenied

    {path: "passwordChangeConfirmation", component: PasswordChangeConfirmationComponent},
    {path: "auth/forgotPasswordCallback/:token", component:ForgotPasswordComponent},
    {path: "forgotPasswordConfirmation", component: PasswordRequestchangeConfirmationComponent},

    //Requests pages
    {path: "annualLeaveRequestApproved", component:ApprovedPageComponent},
    {path: "asset/qr/details/:assetId/:domain", component: AssetQrDetailsComponent},
    {path: "annualLeaveRequestDenied", component:DeniedPageComponent},
    {path: "createAdminProfile/:requestId", component:CreateAdminProfileComponent},
    {path: "createAdminProfile/:requestId", component:CreateAdminProfileComponent},

    // {path: "freeAsset", component:AssetNotUsedPageComponent},

    {path: "pageNotFound", component: PageNotFoundComponent},
    {path: "unauthorized", component: UnauthorizedComponent},
    
    
    {path: ":domain/:token", redirectTo: ":domain/notifications/:token", pathMatch: 'prefix'},
    {path: "company/acceptAdminInvite/:requestId", redirectTo:"createAdminProfile/:requestId", pathMatch:'full'},
    {path: "company/acceptAdminInvite/:requestId", redirectTo:"createAdminProfile/:requestId", pathMatch:'full'},
    // {path: "", redirectTo: "signIn", pathMatch: 'prefix'},
    {path: "**", redirectTo: "pageNotFound", pathMatch:"full"}
];

// @NgModule({
//     imports:[RouterModule.forRoot(routes, { useHash: true })],
//     exports:[RouterModule]
// })
// export class AppRoutingModule{}