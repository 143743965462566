import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'src/app/models/task';
import { initFlowbite } from 'flowbite';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/models/project';
import { EmployeeService } from 'src/app/services/employee.service';
import { Employee } from 'src/app/models/employee';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditTaskModalComponent } from '../modals/edit-task-modal/edit-task-modal.component';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-task-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './task-card.component.html',
  styleUrl: './task-card.component.css'
})
export class TaskCardComponent implements OnInit, AfterViewInit {
  
  @Input() task? : Task;

  @Output() taskDeleted = new EventEmitter<void>();
  @Output() taskEdited = new EventEmitter<void>();

  public project? : Project;
  public assignee? : Employee;
  public employeePictureLink : any;

  constructor(private projectService : ProjectService,
    public employeeService : EmployeeService,
    private sanitizer : DomSanitizer,
    public navigationService : NavigationService,
    private modalService : NgbModal,
    private taskService : TasksService
  ){}

  ngOnInit(): void {
    console.log("Received task: ", this.task);
  }

  ngAfterViewInit(): void {
    initFlowbite();
    this.getProject();
    this.getAssignee();
  }

  public getProject() : void {
    this.projectService.get(this.task?.ProjectID).subscribe(
      (response : Project) => {
        this.project = response;
      }
    )
  }
  
  public getAssignee() : void {
    if(this.task?.AssigneeID){
      this.employeeService.getEmployee(this.task?.AssigneeID).subscribe(
        (response : Employee) => {
          this.assignee = response;
          this.getEmployeePicture()
        }
      )
    }
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(this.assignee?.DefaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = this.assignee?.EmployeeID;
      companyId = company;
    }
    this.employeeService.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      }
    )
  }

  public editTask() : void {
    const modalRef = this.modalService.open(
      EditTaskModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.task = this.task;

    modalRef.componentInstance.taskEdited.subscribe(
      () => {
        this.taskEdited.emit();
      }
    )
  }

  public deleteTask() : void {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.taskService.deleteTask(this.task?.ID).subscribe(
          (response : any) => {
            console.log("Task deleted successfully!", response);
            this.taskDeleted.emit();
          }
        )
      }
    )
  }
}
