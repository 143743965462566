<form [formGroup]="form">
    <div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Add new expense']}}</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Expense details']}}</p>
                    <div class="flex flex-col w-full">
                        @if (form.get('name')?.touched && form.get('name')?.invalid) {
                            <label for="name" class="text-lightRed small">{{translationService.translations?.variables['Name (Required)']}}</label>
                        }
                        <input formControlName="name" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Expense name']"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('date')?.touched && form.get('date')?.invalid) {
                            <label for="date" class="text-lightRed small">{{translationService.translations?.variables['Creation date (Required)']}}</label>
                        }@else {
                            <label for="date" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Creation date']}}</label>
                        }
                        <input formControlName="date" id="date" type="date" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Creation date']">
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('amount')?.touched && form.get('amount')?.invalid) {
                            <label for="amount" class="text-lightRed small">{{translationService.translations?.variables['Amount (Required, greater than 0)']}}</label>
                        }
                        <input formControlName="amount" type="number" min="0" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Amount']"/>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <p class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Select payment type']}}</p>
                        <div class="flex flex-row items-center gap-4">
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Single'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Single']}}</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Monthly'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Monthly']}}</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Yearly'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Yearly']}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('cycles')?.touched && form.get('cycles')?.invalid) {
                            <label for="cycles" class="text-lightRed small">{{translationService.translations?.variables['Cycles (Required)']}}</label>
                        }
                        <input formControlName="cycles" type="number" min="0" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault"  [placeholder]="translationService.translations?.variables['Cycles']"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('paymentDate')?.touched && form.get('paymentDate')?.invalid) {
                            <label for="paymentDate" class="text-lightRed small">{{translationService.translations?.variables['Payment date (Required)']}}</label>
                        } @else {
                            <label for="paymentDate" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Payment date']}}</label>
                        }
                        <input formControlName="paymentDate" type="date" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Payment date']"/>
                    </div>
                </div>
            </div> 
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="saveExpense()" type="submit"
                        [ngClass]="{'bg-greyScaleBorderDisabled text-greyScaleTextBody': !form.valid, 
                                    'bg-brandSurfaceDefault hover:bg-brandBorderDarker text-greyScaleSurfaceDefault': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    {{translationService.translations?.variables['Save']}}
                </button>
            </div>
        </div>
    </div>
</form>
