<form [formGroup]="form">
    <div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
        @if (isLoading) {
            <div class="flex flex-col w-full h-[700px] bg-white rounded-md p-16 gap-4 items-center justify-center">
                <svg aria-hidden="true" class="inline w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
                <p class="text-lg font-semibold text-lightGreen">Creating company...</p>
            </div>
        } @else {
            <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
                <div class="flex flex-row w-full items-center justify-center justify-between">
                    <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Create new company']}}</p> 
                    <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                    hover:bg-lightGreen transition-[0.5s]"
                            (click)="modalService.close()">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                    </button>        
                </div>
                <div class="flex flex-col w-full items-start h-full gap-4">
                    <div class="flex flex-col w-full">
                        @if (form.get('Name')?.touched && form.get('Name')?.invalid) {
                            <label for="Name" class="text-lightRed small">{{translationService.translations?.variables['Company name (Required)']}}</label>
                        }
                        <input formControlName="Name" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Company name']"/>
                    </div>
    
                    <!-- <div class="flex flex-col w-full relative">
                        <label for="companyName" class="py-2 text-primaryBlack/70 font-regular text-[12px]">Company Name</label>
                        <input [(ngModel)]="companyName" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Name"/>
                    </div>  -->
                    <div class="flex flex-col w-full">
                        @if (form.get('EmailAddress')?.touched && form.get('EmailAddress')?.invalid) {
                            <label for="EmailAddress" class="text-lightRed small">Contact email address (Required)</label>
                        }
                        <input formControlName="EmailAddress" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Email address']"/>
                    </div>
    
                    <!-- <div class="flex flex-col w-full relative">
                        <label for="companyEmail" class="py-2 text-primaryBlack/70 font-regular text-[12px]">Email Address</label>
                        <input [(ngModel)]="companyEmailAddress" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Email address"/>
                    </div> -->
    
                    <div class="flex flex-col w-full">
                        @if (form.get('PostalCode')?.touched && form.get('PostalCode')?.invalid) {
                            <label for="PostalCode" class="text-lightRed small">Postal code (Required)</label>
                        }
                        <input formControlName="PostalCode" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Postal code']"/>
                    </div>
                    <!-- <div class="flex flex-col w-full relative">
                        <label for="postalCode" class="py-2 text-primaryBlack/70 font-regular text-[12px]">Postal Code</label>
                        <input [(ngModel)]="postalCode" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Postal code"/>
                    </div>  -->
        
                    <div class="flex flex-row w-full justify-between gap-4">
                        <div class="flex flex-col w-full relative">
                            <app-dropdown-menu class="z-[1]"
                                [inputPlaceHolder]="selectedCountry"
                                [data]="country_list"
                                width="100%"
                                (selectionChanged)="onCountrySelected($event)"
                            /> 
                        </div>
                        <div class="flex flex-col w-full relative">
                            <app-dropdown-menu class="z-[1]"         
                                [inputPlaceHolder]="selectedCity"
                                [data]="cities"
                                width="100%"
                                (selectionChanged)="onCitySelected($event)"
                            /> 
                        </div>          
                    </div>
        
                    <!-- <div class="flex flex-col w-full relative">
                        <label for="contact" class="py-2 text-primaryBlack/70 font-regular text-[12px]">Contact</label>
                        <input [(ngModel)]="contact" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Contact"/>
                    </div> -->
                    <div class="flex flex-col w-full">
                        @if (form.get('TaxId')?.touched && form.get('TaxId')?.invalid) {
                            <label for="TaxId" class="text-lightRed small">Tax ID (Required)</label>
                        } 
                        <input formControlName="TaxId" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Tax ID']"/>
                    </div>
                    <!-- <div class="flex flex-col w-full relative">
                        <label for="taxId" class="py-2 text-primaryBlack/70 font-regular text-[12px]">Tax Id</label>
                        <input [(ngModel)]="taxId" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Tax ID"/>
                    </div> -->
    
                    <div class="flex flex-col w-full">
                        @if (form.get('Domain')?.touched && form.get('Domain')?.invalid) {
                            <label for="Domain" class="text-lightRed small">Domain (Required)</label>
                        }
                        <input formControlName="Domain" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Domain (firmegenerale.cloud/YOUR_DOMAIN)']"/>
                    </div>
                    <!-- <div class="flex flex-col w-full relative">
                        <label for="domain" class="py-2 text-primaryBlack/70 font-regular text-[12px]">Domain</label>
                        <input [(ngModel)]="domain" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="firmegenerale.cloud/YOUR_DOMAIN"/>
                    </div> -->
    
                    <div class="flex flex-col w-full gap-2">
                        <label for="Image" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Choose your company`s primary color']}}</label>
                        <div class="flex flex-row w-full items-center gap-4">
                            <app-colorpicker
                                (selectedColor)="handleColorSelection($event)"
                            />
                            <p class="text-[14px] font-light text-primaryBlack">{{translationService.translations?.variables['Selected color']}}: <span [ngStyle]="{'color': selectedColor, '-webkit-text-stroke' : '0.3px #282B28'}">{{selectedColor}}</span></p>
                        </div>
                    </div>
    
                    <div class="flex flex-col w-full gap-2">
                        <label for="Image" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Upload company logo']}}</label>
                        <input type="file"  (change)="onPictureSelected($event)" class="flex w-full shadow-md cursor-pointer rounded-[6px] bg-primaryBlack/20" placeholder="Choose file"/>
                    </div>
        
                    <div class="flex w-full items-center justify-center">
                        @if(imageSrc){
                            <div class="flex size-[200px] img-container rounded-full border-[1px] border-primaryBlack/20">
                                <img [src]="imageSrc" class="size-full rounded-full" alt="Image Preview">
                            </div>
                        }
                    </div>
                    <div class="flex flex-row w-full items-center justify-end">
                        <button [disabled]="!form.valid"
                                [ngClass]="{'bg-greyScaleBorderDisabled text-greyScaleTextBody': !form.valid, 'bg-brandSurfaceDefault hover:bg-brandBorderDarker text-greyScaleSurfaceDefault': form.valid}"
                                class="flex px-4 py-2 items-center justify-center rounded-md transition-[0.5s]"
                                (click)="createCompany()">
                            {{translationService.translations?.variables['Save']}}
                        </button>
                    </div>
                </div>
            </div>
        }
    </div>
</form>