import { Component, Input, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets.service';
import { AssetHistoryItem } from '../../models/assetHistoryItem';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-employee-asset-history-section',
  standalone: true,
  imports: [],
  templateUrl: './employee-asset-history-section.component.html',
  styleUrl: './employee-asset-history-section.component.css'
})
export class EmployeeAssetHistorySectionComponent implements OnInit{
  
  @Input() employeeId : string = '';

  public assetHistory : AssetHistoryItem[] = [];
  public currentylInUse : AssetHistoryItem[] = [];
  public previouslyUsed : AssetHistoryItem[] = [];

  public processedInUse : { name : string, serialNumber : number, parsedEndDate : string}[] = [];
  public processedPreviouslyUsed : { name : string, serialNumber : number, parsedEndDate : string}[] = [];

  constructor(private assetService : AssetsService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.fetchAssetHistoryForEmployee();
  }

  public fetchAssetHistoryForEmployee() : void {
    this.assetService.employeesAssetHistory(this.employeeId).subscribe(
      (response : AssetHistoryItem[]) => {
        console.log("HISTORY: ", response);
        this.assetHistory = response;

        this.processedInUse = this.assetHistory
        .filter(hist => hist.EndDate === -1)
        .map(hist => ({
          name: hist.name,
          serialNumber: hist.serialNumber,
          parsedEndDate: ''
        }));

      this.processedPreviouslyUsed = this.assetHistory
        .filter(hist => hist.EndDate !== -1)
        .map(hist => ({
          name: hist.name,
          serialNumber: hist.serialNumber,
          parsedEndDate: this.getParsedDate(hist.EndDate)
        }));

      console.log("Currently in use assets: ", this.processedInUse);
      console.log("Previously used assets: ", this.processedPreviouslyUsed);
      }
    )
  }

  public getParsedDate(dateInMillis : number) : string {
    //console.log("This is the name of the asset: ", name);
    //console.log("This is the date in millis that is being parsed: ", dateInMillis);
    const date = new Date(dateInMillis);
    const dateParsed = date.toISOString().substring(0,10);
    //console.log("This is the parsed date: ", dateParsed);
    return dateParsed;
  }

  public logging(name : string) {
    console.log("This is the name of the asset: ", name);
    console.log(this.previouslyUsed);
  }

}
