import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plan-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plan-card.component.html',
  styleUrl: './plan-card.component.css'
})
export class PlanCardComponent {
  @Input() planName : string = ''
  @Input() availableOptions : string[] = [];
  @Input() unavailableOptions : string[] = [];
  @Input() cost : number = 0;
}
