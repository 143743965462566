import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from 'src/app/services/employee.service';
import { SmallEmployeeCardContentComponent } from '../../small-employee-card-content/small-employee-card-content.component';
import { TeamsService } from 'src/app/services/teams.service';
import { ProjectService } from 'src/app/services/project.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-add-project-member',
  standalone: true,
  imports: [CommonModule, SmallEmployeeCardContentComponent],
  templateUrl: './add-project-member.component.html',
  styleUrl: './add-project-member.component.css'
})
export class AddProjectMemberComponent implements OnInit{
  @Input() project : any;

  @Output() memberAdded = new EventEmitter<void>();

  public employees : any[] = [];
  public benchedEmployees : any[] = [];

  public selected : boolean = false;
  public activeEmployeeId : string = '';
  public selectedEmployee : any;
  
  constructor(private employeeService : EmployeeService,
    public modalService : NgbActiveModal,
    private teamService : TeamsService,
    private projectService : ProjectService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.fetchBench();
    this.fetchAvailableEmployees();
  }

  public fetchBench() : void {
    this.teamService.getBench().subscribe(
      (response : any) => {
        console.log("BENCH: ", response);
        this.benchedEmployees = response;
      }
    )
  }
  
  public fetchAvailableEmployees() : void {
    this.projectService.getAvailableEmployees(this.project.ID).subscribe(
      (response : any) => {
        console.log("SVI KOJI MOGU BIT STAVLJENI: ", response);
        this.employees = response;
      }
    )
  }

  public setActive(employee: any): void {
    if(employee.EmployeeID) {
      this.activeEmployeeId = employee.EmployeeID;
    } else {
      this.activeEmployeeId = employee;
    }
    this.selectedEmployee = employee;
    this.selected = true;
  }

  public isActive(employeeId: string): boolean {
    return this.activeEmployeeId === employeeId;
  }

  public select() : void {
    this.employeeService.addToProject(this.activeEmployeeId, this.project.ID).subscribe(
      (response : any) => {
        this.memberAdded.emit();
        this.modalService.close();
      }
    )
  }
}
