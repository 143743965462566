import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmployeeService } from '../../services/employee.service';
import { NavigationService } from '../../services/navigation.service';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-employee-list-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './employee-list-card.component.html',
  styleUrl: './employee-list-card.component.css'
})
export class EmployeeListCardComponent implements OnInit {
  @Input() firstname : string = ''
  @Input() lastname : string = ''
  @Input() grossSalary : number = 0;
  @Input() Skills : any[] = [];
  @Input() userId : string = '';
  @Input() imageString : string = '';
  @Input() displayedOnProject : boolean = false;
  @Input() fullName : string = ''
  @Input() professionId : string = '';
  @Input() role : string = '';
  @Input() requiresEditing : boolean = true;
  @Input() defaultPicture : boolean = true;

  public imagePath : any;
  public grossAHour : number = 0;
  public grossADay : number = 0;
  public grossAYear : number = 0;
  public profession : string = '';
  public employeePictureLink : any;
  public hasPicture : boolean = true;
  public displayName : string  = '';

  public employee : boolean = true;
  public admin : boolean = false;
  public owner : boolean = false;
  public manager : boolean = false


  constructor(public navigationService : NavigationService,
    private employeeService : EmployeeService,
    private sanitizer: DomSanitizer,
    private authService : AuthService,
    private toast : NgToastService
  ){}

  ngOnInit(): void {  
    this.getEmployeeRole();
    if(this.displayedOnProject){
      this.getEmployee()
      const professions = sessionStorage.getItem('professions');
      
      if(professions){
        const parsedProfessions = JSON.parse(professions);
        parsedProfessions.map((tmp: { ID: string; Name: string; }) => {
          if(tmp.ID === this.professionId){
            this.profession = tmp.Name;
          }
        })
      }
    } else {
      this.getEmployeePicture();
    }
  }
  
  public getEmployee() {
    this.employeeService.getEmployee(this.userId).subscribe(
      (response : any) => {
        console.log(response);
        this.displayName = response.FirstName + ' ' + response.LastName;
        this.defaultPicture = response.DefaultPicture
        this.getEmployeePicture();
      }
    );
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(this.defaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = this.userId;
      companyId = company;
    }
    this.employeeService.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        // this.employeePictureLink = response;
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
        this.hasPicture = true;
      }
    )
  }

  public getEmployeeRole() {
    if(this.role === 'Employee'){
      this.employee = true;
      this.admin = false;
      this.owner = false;
      this.manager = false
    } else if(this.role === 'Project Manager'){
      this.employee = false;
      this.admin = false;
      this.owner = false;
      this.manager = true
    } else if(this.role === 'Admin'){
      this.employee = false;
      this.admin = true;
      this.owner = false;
      this.manager = false
    } else if(this.role === 'Owner'){
      this.employee = false;
      this.admin = false;
      this.owner = true;
      this.manager = false
    }
  }
}
