import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-employee-card',
  standalone: true,
  imports: [],
  templateUrl: './skeleton-employee-card.component.html',
  styleUrl: './skeleton-employee-card.component.css'
})
export class SkeletonEmployeeCardComponent {

}
