import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { EmployeeService } from '../../services/employee.service';
import { Employee } from '../../models/employee';
import { HttpErrorResponse } from '@angular/common/http';
import { TeamsService } from 'src/app/services/teams.service';
import { NgToastService } from 'ng-angular-popup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { ProjectService } from 'src/app/services/project.service';
import { AuthService } from 'src/app/services/auth.service';
import { initFlowbite } from 'flowbite';


@Component({
  selector: 'app-employee-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './employee-card.component.html',
  styleUrl: './employee-card.component.css'
})
export class EmployeeCardComponent implements OnInit, AfterViewInit{

  @Input() firstname : string = ''
  @Input() lastname : string = ''
  @Input() grossSalary : number = 0;
  @Input() Skills : any[] = [];
  @Input() userId : string = '';
  @Input() imageString : string = '';
  @Input() fullName : string = ''
  @Input() professionId : string = '';
  @Input() role : string = '';
  @Input() teamID : string = '';
  @Input() requiresEditing : boolean = false;
  @Input() defaultPicture : boolean = true;
  @Input() displayOnTeam : boolean = false;
  @Input() displayOnTeams : boolean = false;
  @Input() displayedOnProject : boolean = false;
  @Input() projectId : string = '';

  @Output() employeeRemovedFromTeam = new EventEmitter<void>();
  @Output() memberRemovedFromProjet = new EventEmitter<void>();

  public imagePath : any;
  public grossAHour : number = 0;
  public grossADay : number = 0;
  public grossAYear : number = 0;
  public profession : string = '';
  public employeePictureLink : any;
  public hasPicture : boolean = true;
  public displayName : string  = '';

  public employee : boolean = true;
  public admin : boolean = false;
  public owner : boolean = false;
  public manager : boolean = false


  constructor(public navigationService : NavigationService,
    private employeeService : EmployeeService,
    private sanitizer: DomSanitizer,
    private teamService : TeamsService,
    private modalService : NgbModal,
    private projectService : ProjectService,
  ){}

  ngOnInit(): void {  
    this.getEmployeeRole();
    if(this.displayedOnProject || this.displayOnTeam || this.displayOnTeams){
      console.log("USERID: ", this.userId);
      this.getEmployee()
    } else {
      this.getEmployeePicture();
    }
  }

  ngAfterViewInit(): void {
    initFlowbite();
  }
  
  public getEmployee() {
    this.employeeService.getEmployee(this.userId).subscribe(
      (response : any) => {
        console.log(response);
        this.displayName = response.FirstName + ' ' + response.LastName;
        this.defaultPicture = response.DefaultPicture
        this.requiresEditing = response.requiresEditing
        this.getEmployeePicture();
      }
    );
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(this.defaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = this.userId;
      companyId = company;
    }
    this.employeeService.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
        this.hasPicture = true;
      }
    )
  }

  public deleteEmployeeFromTeam(event : Event) : void {
    event.stopPropagation();

    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.teamService.removeEmployeeFromTeam(this.userId, this.teamID).subscribe(
          (response : any) => { 
            console.log("Employee successfully removed from team");
            this.employeeRemovedFromTeam.emit();
          }
        )
      }
    )
  }

  public deleteEmployeeFromProject(event : Event) : void {
    event.stopPropagation()
    
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.projectService.removeMemberFromProject(this.userId, this.projectId).subscribe(
          (response : any) => {
            console.log("Member successfully removed from project!", response);
            this.memberRemovedFromProjet.emit();
          }
        )
      }
    )
  }

  public getEmployeeRole() {
    if(this.role === 'Employee'){
      this.employee = true;
      this.admin = false;
      this.owner = false;
      this.manager = false
    } else if(this.role === 'Project Manager'){
      this.employee = false;
      this.admin = false;
      this.owner = false;
      this.manager = true
    } else if(this.role === 'Admin'){
      this.employee = false;
      this.admin = true;
      this.owner = false;
      this.manager = false
    } else if(this.role === 'Owner'){
      this.employee = false;
      this.admin = false;
      this.owner = true;
      this.manager = false
    }
  }
}
