<div class="flex items-center  justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-6">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">{{text}}</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="closeModal()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>
        <input [(ngModel)]="inputValue" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="{{placeholder}}"/>
        <div class="flex flex-row w-full justify-end">
            <app-button
                [text]="translationService.translations?.variables['Save']"
                [function]="saveStack.bind(this)"
            />
        </div>
    </div>
</div>