import { Component, Input } from '@angular/core';
import { Venue } from 'src/app/models/venue';

@Component({
  selector: 'app-venue-card',
  standalone: true,
  imports: [],
  templateUrl: './venue-card.component.html',
  styleUrl: './venue-card.component.css'
})
export class VenueCardComponent {
  @Input() venue! : Venue;
  @Input() freeSeatsCounter : number = 0
  
  constructor(){}

  ngOnInit(): void {
    console.log("OFFICE: ", this.venue);
  }
}
