<!-- <div class="flex flex-col font-poppins w-full items-center justify-center justify-between h-full">
    <div class="flex flex-col w-full h-full gap-4">
        <div class="flex flex-row w-full items-center justify-between">
            <div class="flex flex-row items-center">
                <p class="font-bold text-lightGreen text-[24px]">Firme</p>
                <p class="ml-[5px] font-light text-white text-[24px]">Générale</p>
            </div>
        </div>
        @if(userLogged) {
            <div class="flex flex-col w-full h-full gap-4">
                @if (haveCompany && companyChoosen){
                    <div class="flex flex-col h-auto w-full relative">
                        <app-global-search/>
                    </div>
                    <div class="flex flex-col w-full">
                        <p class="font-light text-[12px] 2xl:text-[16px] text-lightGrey/50 tracking-widest">COMPANY</p>

                        <a [routerLink]="['/choose']">
                            <div class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="openChooseCompanyPage()">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/ChooseIcon.png"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <div class="flex flex-col max-w-[250px] overflow-hidden">
                                    <p class="text-white/70 group-hover:text-white font-medium ml-4 text-[16px] 2xl:text-[20px] truncate">{{companyName}}</p>
                                    @if(numberOfCompanies > 1){
                                        <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to change</p>
                                    } @else {
                                        <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to add</p>
                                    }
                                </div>
                            </div> 
                        </a>
                    </div>

                    <div class="flex flex-col gap-2">
                        <p class="font-light text-[12px] 2xl:text-[16px] text-lightGrey/50 tracking-widest">NAVIGATION</p>
                        <a [routerLink]="['/dashboard']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Dashboard',
                                            '': activeWindow !== 'Dashboard'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Dashboard')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/DashboardIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Dashboard',
                                                    'opacity-50' : activeWindow !== 'Dashboard'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Dashboard',
                                                'text-white/70' : activeWindow !== 'Dashboard'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Dashboard</p>
                            </div> 
                        </a>
    
                        <a [routerLink]="['/employees']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Employees',
                                            '': activeWindow !== 'Employees'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Employees')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/EmployeesIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Employees',
                                                    'opacity-50' : activeWindow !== 'Employees'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Employees',
                                                'text-white/70' : activeWindow !== 'Employees'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Employees</p>
                            </div> 
                        </a>

                        <a [routerLink]="['/teams']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Teams',
                                            '': activeWindow !== 'Teams'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Teams')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/TeamsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Teams',
                                                    'opacity-50' : activeWindow !== 'Teams'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Teams',
                                                'text-white/70' : activeWindow !== 'Teams'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Teams</p>
                            </div> 
                        </a>
    
                        <a [routerLink]="['/projects']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Projects',
                                            '': activeWindow !== 'Projects'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Projects')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/ProjectsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Projects',
                                                    'opacity-50' : activeWindow !== 'Projects'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Projects',
                                                'text-white/70' : activeWindow !== 'Projects'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Projects</p>
                            </div>
                        </a> 

                        <a [routerLink]="['/tasks']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Tasks',
                                            '': activeWindow !== 'Tasks'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Tasks')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/TasksIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Tasks',
                                                    'opacity-50' : activeWindow !== 'Tasks'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Tasks',
                                                'text-white/70' : activeWindow !== 'Tasks'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Tasks</p>
                            </div>
                        </a> 

                        <a [routerLink]="['/analytics']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Finance',
                                            '': activeWindow !== 'Finance'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Finance')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/AnalitycsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Finance',
                                                    'opacity-50' : activeWindow !== 'Finance'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Finance',
                                                'text-white/70' : activeWindow !== 'Finance'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Finance</p>
                            </div>
                        </a> 
    
                        <a [routerLink]="['/inventory']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Assets',
                                            '': activeWindow !== 'Assets'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Assets')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/AssetsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Assets',
                                                    'opacity-50' : activeWindow !== 'Assets'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Assets',
                                                'text-white/70' : activeWindow !== 'Assets'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Equipment</p>
                            </div>
                        </a>   
                        
                        <a [routerLink]="['/settings']">
                            <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Settings',
                                            '': activeWindow !== 'Settings'}" 
                                class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="setActiveWindow('Settings')">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/SettingsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Settings',
                                                    'opacity-50' : activeWindow !== 'Settings'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                </div>
                                <p [ngClass]="{'text-white' : activeWindow === 'Settings',
                                                'text-white/70' : activeWindow !== 'Settings'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Settings</p>
                                @if (notifyForChanges) {
                                    <div class="size-[15px] ml-4 rounded-full bg-lightYellow"></div>
                                }
                            </div>
                        </a>
                    </div>
                } 
            </div>
        } 
    </div>
    @if (userLogged) {
        <div class="flex flex-col w-full gap-2">
            <p class="font-light text-[12px] 2xl:text-[16px] tracking-widest opacity-50 text-primaryWhite">USER PROFILE</p>
            <div class="flex flex-row w-full items-center justify-start justify-between">
                <div [routerLink]="'/myProfile'" (click)="setActiveWindow('')" class="flex flex-row items-center cursor-pointer">
                    <div class="flex flex-col items-start gap-1">
                        <p class="font-medium text-[12px] 2xl:text-[18px] text-primaryWhite">{{fullName}}</p>
                        <p class="font-light sm:text-[8px] xl:text-[9px] 2xl:text-[12px] text-primaryWhite">{{email}}</p>
                    </div>
                </div>
                <div (click)="logout()"
                    class="flex items-center justify-center rounded-full size-[40px] p-2 transition-[0.5s] hover:bg-primaryBlack/50 group cursor-pointer">
                    <img src="../../../assets/icons/SignOutIcon.png"
                        class="opacity-50 group-hover:opacity-100 transition-[0.7s] nav_menu_icon">
                </div>
            </div>
        </div>
    }
</div> -->







<button data-drawer-target="cta-button-sidebar" 
        data-drawer-toggle="cta-button-sidebar" 
        aria-controls="cta-button-sidebar" 
        type="button" 
        class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden 
                hover:bg-gray-100">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
    </svg>
</button>

<!-- class="md:block hidden fixed md:relative top-0 left-0 h-screen w-72 md:z-auto" -->

<aside id="cta-button-sidebar" class="fixed font-poppins top-0 w-70 xl:bg-transparent
                                    2xl:bg-transparent bg-[#244c70] items-center justify-center left-0 z-40 
                                    h-screen transition-transform md:block md:relative h-screen
                                    -translate-x-full sm:translate-x-0 lg:bg-transparent md:bg-transparent" aria-label="Sidebar">
 
    <button type="button" class="absolute top-4 right-4 text-white bg-transparent p-2 rounded focus:outline-none hover:bg-lightGrey/20 
                                block md:hidden" 
            aria-label="Close menu"
            data-drawer-toggle="cta-button-sidebar">
        <span class="sr-only">Close menu</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.225 4.811a1.025 1.025 0 011.449 0L12 9.138l4.326-4.327a1.025 1.025 0 111.449 1.449L13.449 10.588l4.326 4.327a1.025 1.025 0 11-1.449 1.449L12 12.037l-4.326 4.327a1.025 1.025 0 11-1.449-1.449l4.327-4.327L6.225 6.26a1.025 1.025 0 010-1.449z"></path>
        </svg>
    </button>
    <div class="h-full px-3 py-4 justify-between flex flex-col overflow-y-auto dark:bg-gray-800">
        <div class="flex flex-col h-full w-full gap-3">
            <div class="flex flex-row w-full items-center justify-between">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" class="h-[40px]" viewBox="0 0 1363.78 234.65">
                <defs>
                    <style>
                    .cls-1 {
                        fill: #fff;
                    }
                
                    .cls-2 {
                        fill: #228ae6;
                    }
                    </style>
                </defs>
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                    <g>
                        <polygon class="cls-1" points="334.82 173.15 359.6 173.15 359.6 135.83 394.47 135.83 394.47 111.82 359.6 111.82 359.6 93 401.81 93 401.81 68.99 334.82 68.99 334.82 173.15"/>
                        <path class="cls-1" d="M425.21,60.11c-4.28,0-7.75,1.3-10.4,3.9-2.65,2.6-3.98,6.09-3.98,10.48s1.32,7.57,3.98,10.17c2.65,2.6,6.12,3.9,10.4,3.9s7.85-1.3,10.4-3.9c2.55-2.6,3.82-5.99,3.82-10.17s-1.28-7.88-3.82-10.48c-2.55-2.6-6.02-3.9-10.4-3.9Z"/>
                        <rect class="cls-1" x="412.67" y="98.2" width="24.93" height="74.95"/>
                        <path class="cls-1" d="M492.36,96.21c-3.37,0-6.6.84-9.71,2.52-3.11,1.68-5.87,3.65-8.26,5.89-.44.42-.85.82-1.25,1.22l-2.34-7.64h-18.36v74.95h24.78v-48.19c.69-.53,1.42-1.02,2.22-1.44,3.92-2.09,9.05-3.14,15.37-3.14h5.05v-22.64c-.71-.51-1.74-.89-3.06-1.15-1.33-.25-2.81-.38-4.44-.38Z"/>
                        <path class="cls-1" d="M620.09,105.77c-1.63-2.91-3.98-5.22-7.04-6.96-3.06-1.73-7.04-2.6-11.93-2.6-4.28,0-8.41.79-12.39,2.37-3.98,1.58-7.62,3.62-10.94,6.12-.79.6-1.55,1.2-2.3,1.82-.13-.25-.24-.51-.38-.75-1.63-2.91-3.95-5.22-6.96-6.96-3.01-1.73-6.96-2.6-11.85-2.6-4.39,0-8.57.79-12.54,2.37-3.98,1.58-7.62,3.62-10.94,6.12-.72.54-1.4,1.09-2.08,1.65l-2.58-8.15h-18.36v74.95h24.78v-46.35c1.87-1.64,3.74-3,5.58-4.06,2.6-1.48,5.28-2.22,8.03-2.22,1.94,0,3.52.61,4.74,1.84,1.22,1.22,1.84,3.77,1.84,7.65v43.14h24.78v-46.04c0-.14,0-.28,0-.42,1.84-1.59,3.68-2.91,5.52-3.94,2.65-1.48,5.3-2.22,7.95-2.22,1.94,0,3.49.61,4.67,1.84,1.17,1.22,1.76,3.77,1.76,7.65v43.14h24.93v-46.04c0-3.98-.31-7.8-.92-11.47-.61-3.67-1.73-6.96-3.37-9.87Z"/>
                        <path class="cls-1" d="M690.6,101.57c-5.61-3.57-12.34-5.35-20.19-5.35s-14.15,1.71-19.81,5.12c-5.66,3.42-10.07,8.08-13.23,14-3.16,5.92-4.74,12.7-4.74,20.34s1.58,14.84,4.74,20.65c3.16,5.81,7.57,10.3,13.23,13.46,5.66,3.16,12.26,4.74,19.81,4.74,8.16,0,15.3-1.71,21.42-5.12,6.12-3.42,10.76-8.03,13.92-13.84l-16.52-8.87c-1.74,2.65-3.9,4.82-6.5,6.5s-6.09,2.52-10.48,2.52c-5.51,0-9.79-1.4-12.85-4.21-2.33-2.13-3.77-4.97-4.32-8.49h52.5c.2-.82.36-1.89.46-3.21.1-1.32.15-2.8.15-4.44,0-7.24-1.53-13.79-4.59-19.66-3.06-5.86-7.39-10.58-13-14.15ZM670.72,115.33c5.3,0,9.23,1.53,11.78,4.59,1.49,1.79,2.53,3.89,3.15,6.27h-30.26c.66-2.58,1.89-4.77,3.7-6.58,2.85-2.85,6.73-4.28,11.63-4.28Z"/>
                    </g>
                    <g>
                        <path class="cls-1" d="M858.85,117.78h-50.78v21.87h26.35c-.63,1.6-1.4,3.08-2.33,4.44-2.24,3.26-5.23,5.79-8.95,7.57-3.72,1.79-8.08,2.68-13.08,2.68-5.41,0-10.17-1.22-14.3-3.67-4.13-2.45-7.4-5.94-9.79-10.48-2.4-4.54-3.59-9.76-3.59-15.68,0-6.52,1.2-12.08,3.59-16.67,2.39-4.59,5.68-8.13,9.87-10.63,4.18-2.5,8.97-3.75,14.38-3.75,5.71,0,10.65,1.28,14.84,3.82,4.18,2.55,7.7,6.42,10.55,11.63l22.33-9.48c-4.08-9.69-10.07-17.11-17.97-22.26-7.9-5.15-17.82-7.72-29.75-7.72-7.85,0-15.04,1.33-21.57,3.98-6.53,2.65-12.14,6.4-16.83,11.24-4.69,4.84-8.31,10.63-10.86,17.36-2.55,6.73-3.82,14.23-3.82,22.49,0,10.2,2.22,19.3,6.65,27.3,4.44,8.01,10.63,14.28,18.59,18.81,7.95,4.54,17.23,6.81,27.84,6.81,6.52,0,12.77-1.1,18.74-3.29,5.97-2.19,11.29-5.38,15.98-9.56,4.69-4.18,8.39-9.23,11.09-15.14,2.7-5.91,4.05-12.54,4.05-19.89,0-2.04-.1-4.08-.31-6.12-.21-2.04-.51-3.92-.92-5.66Z"/>
                        <polygon class="cls-1" points="929.21 68.99 917.44 57.21 892.66 80 904.28 91.93 929.21 68.99"/>
                        <path class="cls-1" d="M925.08,103.86c-5.61-3.57-12.34-5.35-20.19-5.35s-14.15,1.71-19.81,5.12c-5.66,3.42-10.07,8.08-13.23,14-3.16,5.92-4.74,12.7-4.74,20.34s1.58,14.84,4.74,20.65c3.16,5.81,7.57,10.3,13.23,13.46,5.66,3.16,12.26,4.74,19.81,4.74,8.16,0,15.3-1.71,21.41-5.12,6.12-3.42,10.76-8.03,13.92-13.84l-16.52-8.87c-1.74,2.65-3.9,4.82-6.5,6.5s-6.09,2.52-10.48,2.52c-5.51,0-9.79-1.4-12.85-4.21-2.33-2.13-3.77-4.97-4.32-8.49h52.5c.2-.82.36-1.89.46-3.21.1-1.32.15-2.8.15-4.44,0-7.24-1.53-13.79-4.59-19.66-3.06-5.86-7.4-10.58-13-14.15ZM905.2,117.63c5.3,0,9.23,1.53,11.78,4.59,1.49,1.79,2.53,3.89,3.15,6.27h-30.26c.66-2.58,1.89-4.77,3.7-6.58,2.85-2.85,6.73-4.28,11.63-4.28Z"/>
                        <polygon class="cls-1" points="1095.56 68.99 1083.78 57.21 1059 80 1070.63 91.93 1095.56 68.99"/>
                        <path class="cls-1" d="M1091.43,103.86c-5.61-3.57-12.34-5.35-20.19-5.35s-14.15,1.71-19.81,5.12c-5.66,3.42-10.07,8.08-13.23,14-3.16,5.92-4.74,12.7-4.74,20.34s1.58,14.84,4.74,20.65c3.16,5.81,7.57,10.3,13.23,13.46,5.66,3.16,12.26,4.74,19.81,4.74,8.16,0,15.3-1.71,21.41-5.12,6.12-3.42,10.76-8.03,13.92-13.84l-16.52-8.87c-1.74,2.65-3.9,4.82-6.5,6.5s-6.09,2.52-10.48,2.52c-5.51,0-9.79-1.4-12.85-4.21-2.33-2.13-3.77-4.97-4.32-8.49h52.5c.2-.82.36-1.89.46-3.21.1-1.32.15-2.8.15-4.44,0-7.24-1.53-13.79-4.59-19.66-3.06-5.86-7.4-10.58-13-14.15ZM1071.55,117.63c5.3,0,9.23,1.53,11.78,4.59,1.49,1.79,2.53,3.89,3.15,6.27h-30.26c.66-2.58,1.89-4.77,3.7-6.58,2.85-2.85,6.73-4.28,11.63-4.28Z"/>
                        <path class="cls-1" d="M1019.61,108.07c-1.73-2.91-4.18-5.22-7.34-6.96-3.16-1.73-7.24-2.6-12.24-2.6-4.49,0-8.74.79-12.77,2.37-4.03,1.58-7.75,3.62-11.17,6.12-.76.56-1.5,1.13-2.22,1.71l-2.6-8.21h-18.36v74.95h24.78v-46.23c1.09-.9,2.18-1.74,3.29-2.49,1.89-1.27,3.8-2.24,5.74-2.91,1.94-.66,3.77-.99,5.51-.99,2.34,0,4.13.61,5.35,1.84,1.22,1.22,1.84,3.77,1.84,7.65v43.14h24.78v-46.04c0-3.98-.33-7.8-.99-11.47-.66-3.67-1.86-6.96-3.59-9.87Z"/>
                        <path class="cls-1" d="M1158.2,98.51c-3.37,0-6.6.84-9.71,2.52-3.11,1.68-5.87,3.65-8.26,5.89-.44.42-.85.82-1.25,1.22l-2.34-7.64h-18.36v74.95h24.78v-48.19c.69-.53,1.42-1.02,2.22-1.44,3.92-2.09,9.05-3.14,15.37-3.14h5.05v-22.64c-.71-.51-1.74-.89-3.06-1.15-1.33-.25-2.81-.38-4.44-.38Z"/>
                        <path class="cls-1" d="M1222.83,102.33c-4.84-2.55-10.78-3.82-17.82-3.82-5.81,0-11.42.74-16.83,2.22-5.41,1.48-10.35,3.34-14.84,5.58l7.04,17.59c3.98-1.94,7.75-3.26,11.32-3.98,3.57-.71,6.73-1.07,9.48-1.07,3.87,0,6.73.92,8.57,2.75,1.84,1.84,2.75,4.03,2.75,6.58v3.6c-4.56-3-10.31-4.52-17.28-4.52-5,0-9.56.99-13.69,2.98s-7.45,4.84-9.94,8.57c-2.5,3.72-3.75,8.24-3.75,13.54,0,4.89,1.15,9.25,3.44,13.08,2.29,3.82,5.48,6.78,9.56,8.87,4.08,2.09,8.77,3.14,14.07,3.14,7.85,0,14.05-1.81,18.59-5.43.55-.44,1.08-.9,1.59-1.39l1.54,4.83h20.8v-45.89c0-6.32-1.22-11.8-3.67-16.44-2.45-4.64-6.09-8.23-10.94-10.78ZM1210.21,158.32c-1.73,1.53-4.03,2.29-6.88,2.29-3.06,0-5.43-.76-7.11-2.29-1.68-1.53-2.52-3.52-2.52-5.97s.84-4.28,2.52-5.81c1.68-1.53,4.05-2.29,7.11-2.29,2.85,0,5.15.76,6.88,2.29,1.73,1.53,2.6,3.47,2.6,5.81s-.87,4.44-2.6,5.97Z"/>
                        <path class="cls-1" d="M1274.76,143.17v-71.89h-24.63v80.61c0,5.92,1.09,10.66,3.29,14.23,2.19,3.57,5.07,6.14,8.64,7.72,3.57,1.58,7.39,2.37,11.47,2.37,1.32,0,2.83-.05,4.51-.15,1.68-.1,2.98-.26,3.9-.46v-23.4c-2.24,0-4-.54-5.28-1.61-1.28-1.07-1.91-3.54-1.91-7.42Z"/>
                        <path class="cls-1" d="M1359.19,118.01c-3.06-5.86-7.4-10.58-13-14.15-5.61-3.57-12.34-5.35-20.19-5.35s-14.15,1.71-19.81,5.12c-5.66,3.42-10.07,8.08-13.23,14-3.16,5.92-4.74,12.7-4.74,20.34s1.58,14.84,4.74,20.65c3.16,5.81,7.57,10.3,13.23,13.46,5.66,3.16,12.26,4.74,19.81,4.74,8.16,0,15.3-1.71,21.42-5.12,6.12-3.42,10.76-8.03,13.92-13.84l-16.52-8.87c-1.74,2.65-3.9,4.82-6.5,6.5s-6.09,2.52-10.48,2.52c-5.51,0-9.79-1.4-12.85-4.21-2.33-2.13-3.77-4.97-4.32-8.49h52.5c.2-.82.36-1.89.46-3.21.1-1.32.15-2.8.15-4.44,0-7.24-1.53-13.79-4.59-19.66ZM1326.31,117.63c5.3,0,9.23,1.53,11.78,4.59,1.49,1.79,2.53,3.89,3.15,6.27h-30.26c.66-2.58,1.89-4.77,3.7-6.58,2.85-2.85,6.73-4.28,11.63-4.28Z"/>
                    </g>
                    </g>
                    <rect class="cls-2" width="234.65" height="234.65" rx="44.94" ry="44.94"/>
                    <g>
                    <path class="cls-1" d="M66.14,118.05l-23.81,13.75c3.45,18.98,13.93,35.51,28.65,46.79,15.05,11.54,36.85,7.08,46.33-9.35h0c-28.27-.02-51.18-22.92-51.18-51.19ZM168.51,118.04l23.81-13.74c-3.45-18.98-13.94-35.51-28.66-46.79-15.05-11.54-36.85-7.08-46.33,9.35h0s0,.01,0,.01c28.26,0,51.18,22.91,51.18,51.17ZM117.33,169.23h0l13.75,23.81c18.98-3.45,35.51-13.94,46.79-28.66,11.54-15.05,7.08-36.85-9.35-46.33h0c0,28.27-22.92,51.18-51.18,51.18ZM103.57,43.06c-18.98,3.46-35.51,13.94-46.79,28.65-11.54,15.05-7.08,36.85,9.35,46.33h0c0-28.26,22.91-51.16,51.18-51.17l-13.75-23.81Z"/>
                    <circle class="cls-1" cx="117.32" cy="118.05" r="29.77"/>
                    </g>
                </g>
                </svg>
            </div>
            <div class="flex flex-col h-auto w-full relative">
                <app-global-search/>
            </div>
            @if(userLogged){
                <div class="flex flex-col w-full" *ngIf="haveCompany && companyChoosen">
                    <a [routerLink]="['/choose']">
                        <div class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                            (click)="openChooseCompanyPage()">
                            <img src="../../../assets/icons/ChooseIcon.png"
                                class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                            <div class="flex flex-col max-w-[250px] overflow-hidden">
                                <p class="text-white/70 group-hover:text-white font-medium ml-4 text-[16px] 2xl:text-[20px] truncate">{{companyName}}</p>
                                @if(numberOfCompanies > 0){
                                    <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to change</p>
                                } @else {
                                    <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to add</p>
                                }
                            </div>
                        </div> 
                    </a>    
                </div>
                <hr class="w-full text-lightGrey" *ngIf="haveCompany && companyChoosen">
                <div class="flex flex-col justify-between h-full w-full">
                    <ul class="space-y-2 font-medium" *ngIf="haveCompany && companyChoosen">
                        <li>
                            <a [routerLink]="['/dashboard']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Dashboard',
                                                '': activeWindow !== 'Dashboard'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Dashboard')">
                                    <img src="../../../assets/icons/DashboardIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Dashboard',
                                                    'opacity-50' : activeWindow !== 'Dashboard'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Dashboard',
                                                    'text-white/70' : activeWindow !== 'Dashboard'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Dashboard</p>
                                </div> 
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/employees']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Employees',
                                                '': activeWindow !== 'Employees'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Employees')">
                                    <img src="../../../assets/icons/EmployeesIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Employees',
                                                    'opacity-50' : activeWindow !== 'Employees'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Employees',
                                                    'text-white/70' : activeWindow !== 'Employees'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Employees</p>
                                </div> 
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/teams']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Teams',
                                                '': activeWindow !== 'Teams'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Teams')">
                                    <img src="../../../assets/icons/TeamsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Teams',
                                                    'opacity-50' : activeWindow !== 'Teams'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Teams',
                                                    'text-white/70' : activeWindow !== 'Teams'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Teams</p>
                                </div> 
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/projects']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Projects',
                                                '': activeWindow !== 'Projects'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Projects')">
                                    <img src="../../../assets/icons/ProjectsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Projects',
                                                    'opacity-50' : activeWindow !== 'Projects'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Projects',
                                                    'text-white/70' : activeWindow !== 'Projects'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Projects</p>
                                </div>
                            </a> 
                        </li>
                        <li>
                            <a [routerLink]="['/tasks']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Tasks',
                                                '': activeWindow !== 'Tasks'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Tasks')">
                                    <img src="../../../assets/icons/TasksIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Tasks',
                                                    'opacity-50' : activeWindow !== 'Tasks'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Tasks',
                                                    'text-white/70' : activeWindow !== 'Tasks'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Tasks</p>
                                </div>
                            </a> 
                        </li>
                        <li>
                            <a [routerLink]="['/analytics']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Finance',
                                                '': activeWindow !== 'Finance'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Finance')">
                                    <img src="../../../assets/icons/CashFlowIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Finance',
                                                    'opacity-50' : activeWindow !== 'Finance'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Finance',
                                                    'text-white/70' : activeWindow !== 'Finance'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Finance</p>
                                </div>
                            </a> 
                        </li>
                        <li>
                            <a [routerLink]="['/inventory']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Assets',
                                                '': activeWindow !== 'Assets'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Assets')">
                                    <img src="../../../assets/icons/AssetsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Assets',
                                                    'opacity-50' : activeWindow !== 'Assets'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Assets',
                                                    'text-white/70' : activeWindow !== 'Assets'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Equipment</p>
                                </div>
                            </a>  
                        </li>
                        <!-- <li>
                            <div class="flex flex-col w-full gap-4">
                                <div id="dropdown-cta" class="p-4 rounded-lg bg-blue-50 dark:bg-blue-900 flex flex-col gap-2" role="alert">
                                    <div class="flex items-center">
                                        <span class="bg-orange-100 text-orange-800 text-sm font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">Basic</span>
                                        <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-blue-50 inline-flex justify-center items-center w-6 h-6 text-blue-900 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 hover:bg-blue-200 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800" data-dismiss-target="#dropdown-cta" aria-label="Close">
                                        <span class="sr-only">Close</span>
                                        <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                        </svg>
                                        </button>
                                    </div>
                                    <p class="text-sm text-blue-800 dark:text-blue-400">
                                        You are currently using the free functionalities within the basic plan.             
                                    </p>
                                    <a [routerLink]="['/plans']" class="text-sm text-blue-800 underline font-medium hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300">Upgrade plan</a>
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <ul class="space-y-2 font-medium">
                        <li>
                            <a [routerLink]="['/myProfile']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Profile',
                                                '': activeWindow !== 'Profile'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Profile')">
                                    <img src="../../../assets/icons/ProfileIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Profile',
                                                    'opacity-50' : activeWindow !== 'Profile'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Profile',
                                                    'text-white/70' : activeWindow !== 'Profile'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Profile</p>
                                </div>
                            </a>  
                        </li>
                        <li *ngIf="haveCompany && companyChoosen">
                            <a [routerLink]="['/settings']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Settings',
                                                '': activeWindow !== 'Settings'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Settings')">
                                        <img src="../../../assets/icons/SettingsIcon.png"
                                        [ngClass]="{'opacity-100' : activeWindow === 'Settings',
                                                    'opacity-50' : activeWindow !== 'Settings'}"
                                        class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    <p [ngClass]="{'text-white' : activeWindow === 'Settings',
                                                    'text-white/70' : activeWindow !== 'Settings'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Settings</p>
                                    @if (notifyForChanges) {
                                        <div class="size-[15px] ml-4 rounded-full bg-lightYellow"></div>
                                    }
                                </div>
                            </a>
                        </li>
                        <li>
                            <div class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-md hover:bg-lightGrey/20 p-2 transition-[1s]"
                                (click)="logout()">
                                    <img src="../../../assets/icons/SignOutIcon.png"
                                    class="size-6 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                <p [ngClass]="{'text-white' : activeWindow === 'Settings',
                                                'text-white/70' : activeWindow !== 'Settings'}"
                                    class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Sign Out</p>
                                @if (notifyForChanges) {
                                    <div class="size-[15px] ml-4 rounded-full bg-lightYellow"></div>
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            }
        </div>
    </div>
</aside>



<!-- <aside class="flex relative">
    <div class="bg-transparent h-screen p-4 w-72">
        <div class="bg-primaryWhite rounded-full absolute -right-6 top-9">
            <img src="../../../assets/icons/ArrowDownIcon.png" class="size-3xl"/>
        </div>
    </div>
</aside> -->


