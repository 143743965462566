import { Component, ElementRef, OnInit } from '@angular/core';
import { IncomesSectionComponent } from "../../../components/incomes-section/incomes-section.component";
import { ExpensesSectionComponent } from "../../../components/expenses-section/expenses-section.component";
import { CashflowService } from '../../../services/cashflow.service';
import { forkJoin } from 'rxjs';
import { Income } from '../../../models/income';
import { Expense } from '../../../models/expense';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ViewChild } from "@angular/core";
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApexOptions } from 'apexcharts';
import { TranslationService } from 'src/app/services/translation.service';



export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [IncomesSectionComponent, ExpensesSectionComponent, CommonModule, NgApexchartsModule],
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css'
})
export class AnalyticsComponent implements OnInit{

  @ViewChild('cashflowChart', { static: false }) cashflowChart!: ElementRef;

  public data : any[] = [];
  public incomes : Income[] = []
  public expenses : Expense[] = []
  public totalIncome : number = 0;
  public totalExpense : number = 0;
  public totalSalariesExpense : number = 0;
  public profit : number = 0;
  public revenue: number[] = [];

  public activeWindow : string = 'General';

  private chart: ApexCharts | null = null;

  constructor(private cashFlowService : CashflowService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.getCashFlowWidgetData();
    this.getCashFlow();
  }

  ngAfterViewInit(): void {
    if (this.activeWindow === 'General') {
      setTimeout(() => {
        this.initChart();
      }, 0);
    }
  }

  public getCashFlow() : void {
    this.cashFlowService.getCashFlow().subscribe(
      (response : any) => {
        console.log("CASHFLOW: ", response);
        this.incomes = response.incomes;
        this.expenses = response.expenses;

        console.log("ALL INCOMES: ", this.incomes);
        console.log("ALL EXPENSES: ", this.expenses);

      }
    )
  }

  public getCashFlowWidgetData() : void {
    this.cashFlowService.getWidgetData().subscribe(
      (response : any) => {
        this.data = response;
        console.log("CASHFLOW WIDGET DATA: ", this.data);
        this.initChart();
        this.calculateTotalIncomeAndExpense();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching cashflow widget data, ", error);
      }
    )
  }

  public calculateTotalIncomeAndExpense(): void {
    this.totalIncome = 0;
    this.totalExpense = 0;
    this.totalSalariesExpense = 0;
    this.revenue = []; 
  
    this.data.forEach((data, index) => {
      const income = data.incomes;
      const expense = data.expenses;
  
      this.totalIncome += income;
      this.totalExpense += expense;
      this.totalSalariesExpense += data.salaries;
  
      // Calculate revenue for each month
      if (income > 0 && expense > 0) {
        this.revenue.push(income - expense);
      } else if (income > 0) {
        this.revenue.push(income);
      } else if (expense > 0) {
        this.revenue.push(-expense);
      } else {
        this.revenue.push(0);
      }
    });
  
    // Calculate profit
    this.profit = this.totalIncome - this.totalExpense;
  }

  public toggleWindow(window : string){
    this.activeWindow = window
    if (this.activeWindow === 'General') {
      setTimeout(() => {
        this.initChart();
      }, 0);
    }
  }

  public handleExpenseAdded() : void {
    this.activeWindow = 'Expenses'
  }

  private initChart(): void {
    if (!this.cashflowChart) {
      console.error("Chart element is not available");
      return;
    }

    if (this.chart) {
      this.chart.destroy();
    }

    const months = this.data.map(data => data.month);
    const incomes = this.data.map(data => data.incomes);
    const expenses = this.data.map(data => data.expenses);
    console.log("Revenue: ", this.revenue);

    const chartOptions : ApexOptions = {
      series: [
        {
          name: 'Income',
          type: 'column',
          color: '#61bc78',
          data: incomes
        },
        {
          name: 'Expense',
          type: 'column',
          color: '#FF5A5F',
          data: expenses
        },
        {
          name: 'Revenue',
          type: 'line',
          color: "#228AE6",
          data: this.revenue,
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 4],
        curve: "smooth"
      },
      xaxis: {
        categories: months,
      },
      yaxis: [
        {
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          tooltip: {
            enabled: true
          }
        },
      ],
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    };
      
    const chartElement = this.cashflowChart.nativeElement;
    this.chart = new ApexCharts(chartElement, chartOptions);
    this.chart.render();
  }
}
