<form [formGroup]="form">
    <div class="flex items-center w-[600px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Delete assets</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">This asset has more than one instance</p>
                    <div class="flex flex-row items-center gap-2 w-full">
                        @if (form.get('DeleteAll')?.touched && form.get('DeleteAll')?.invalid) {
                            <label for="DeleteAll" class="text-lightRed font-regular text-[12px]">Delete All</label>
                        } @else {
                            <label for="DeleteAll" class="text-primaryBlack/70 font-regular text-[12px]">Delete All</label>
                        }
                        <input formControlName="DeleteAll" type="checkbox"/>
                    </div>
                    @if(form.value.DeleteAll === false){
                        <div class="flex flex-col w-full">
                            @if (form.get('Counter')?.touched && form.get('Counter')?.invalid) {
                                <label for="Counter" class="text-lightRed font-regular text-[12px]">Quantity (Required, greater than 0)</label>
                            } @else {
                                <label for="Counter" class="text-primaryBlack/70 font-regular text-[12px]">Quantity</label>
                            }
                            <input formControlName="Counter" type="number" min="0" max="9999" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Counter"/>
                        </div>
                    } 
                </div>
            </div>
            <div class="flex w-full justify-end">
                <button [disabled]="form.value.Counter === 0 && !form.value.DeleteAll" (click)="deleteAssets()"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': form.value.Counter === 0 && !form.value.DeleteAll, 
                                    'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.value.Counter !== 0 || form.value.DeleteAll}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    Delete
                </button>
            </div>
        </div>
    </div>
</form>
