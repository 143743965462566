<div class="flex flex-col w-full h-auto p-6 rounded-md items-start overflow-y-auto max-h-[350px] gap-4"
    [ngClass]="{'border-[2px] border-lightYellow bg-lightYellow/20' : requiresEditing,
                'bg-primaryWhite' : !requiresEditing}">
    <div class="flex flex-row w-full items-center justify-between justify-center">
        @if(requiresEditing) {
            <p class="small text-greyScaleTextCaption tracking-wide">
                TECH STACK AND EXPERIENCE LEVEL <span class="text-lightRed">(UPDATE REQUIRED)</span>
            </p>
        } @else {
            <p class="small text-greyScaleTextCaption tracking-wide">TECH STACK AND EXPERIENCE LEVEL</p>
        }
        <button class="flex items-center justify-center group">
            @if (!editing) {
                <button class="flex items-center justify-center group" (click)="toggleEdit()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Edit</p>
                    </div>
                </button>
            } @else {
                <button class="flex items-center justify-center group" (click)="toggleEdit()">
                    <div class="flex flex-row gap-2 items-center">
                        <img src="../../../assets/icons/CloseIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                        <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Cancel</p>
                    </div>
                </button>
            }
        </button>
    </div>
    <div class="flex flex-col w-full gap-4">
        @if (editing) {
            <button class="flex items-center justify-center group w-full py-2 border-[1px] hover:border-primaryBlack rounded-md"
                    [ngClass]="{'border-primaryBlack/50' : requiresEditing,
                                'border-primaryBlack/20' : !requiresEditing}"
                    (click)="openAddSkillModal()">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../assets/icons/PlusIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Add new</p>
                </div>
            </button>
            <div class="flex flex-col w-full gap-4">
                @for (data of displayData; track $index) {
                    <div class="flex flex-row items-center justify-between gap-2">
                        <app-tech-stack-item class="flex w-full"
                            [tehnologyName]="data.skillName"
                            [levelName]="data.levelName"
                        />
                        <div class="flex flex-row items-center gap-2">
                            <img src="../../../assets/icons/TrashIcon.png" class="size-[20px] 2xl:size-[30px] opacity-40 hover:opacity-100 cursor-pointer"
                                (click)="deleteSkill(data.skillId)"/>
                            <img src="../../../assets/icons/EditIcon.png" class="size-[20px] 2xl:size-[30px] opacity-40 hover:opacity-100 cursor-pointer"
                                (click)="editSkill(data.skillId, data.skillName)"/>   
                        </div>
                    </div>
                }
            </div>
        } @else {
            <div class="flex flex-col w-full gap-4">
                @for (data of displayData; track $index) {
                    <app-tech-stack-item
                        [tehnologyName]="data.skillName"
                        [levelName]="data.levelName"
                    />
                }
            </div>
        }
    </div>
</div>