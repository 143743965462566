<div class="flex flex-col md:flex-row w-full gap-4 items center">
    <div class="flex flex-col w-full p-4 bg-primaryWhite rounded-md gap-4">
        <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Equipment in use']}}</p>
        @if (processedInUse.length <= 0) {
            <div class="flex flex-col w-full gap-2">
                <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no equipments currently in use']}}</p>
                <span class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['The employee is not currently using any company equipments']}}</span>
            </div>
        } @else {
            <div class="flex flex-row justify-start w-full gap-4 items-center overflow-x-auto">
                @for (item of processedInUse; track $index) {
                    <div class="flex flex-col w-auto p-4 rounded-md border-[1px] border-primaryBlack/20 gap-2  hover:border-lightGreen transition-[0.5s] cursor-default">
                        <h1 class="text-primaryBlack font-medium text-[16px]">{{item.name}}</h1>    
                        <div class="flex flex-col">
                            <p class="text-primaryBlack font-light text-[12px]">{{translationService.translations?.variables['Serial number']}}: <span class="font-medium">{{item.serialNumber}}</span></p>
                            <p class="text-primaryBlack font-light text-[12px]">{{translationService.translations?.variables['Start date']}}: <span class="font-medium">{{item.parsedEndDate}}</span></p>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
    <div class="flex flex-col w-full p-4 bg-primaryWhite rounded-md gap-4">
        <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Previously used equipments']}}</p>
        @if (processedPreviouslyUsed.length <= 0) {
            <div class="flex flex-col gap-2 w-full">
                <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no equipments that have been used']}} </p>
                <span class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['The employee has not used any equipment']}}</span>
            </div>
        } @else {
            <div class="flex flex-row justify-start w-full gap-4 items-center overflow-x-auto">
                @for (item of processedPreviouslyUsed; track $index) {
                    <div class="flex flex-col w-auto p-4 rounded-md border-[1px] border-primaryBlack/20 gap-2 hover:border-lightGreen transition-[0.5s] cursor-default">
                        <h1 class="text-primaryBlack font-medium text-[16px]">{{item.name}}</h1>    
                        <div class="flex flex-col">
                            <p class="text-primaryBlack font-light text-[12px]">{{translationService.translations?.variables['Serial number']}}: <span class="font-medium">{{item.serialNumber}}</span></p>
                            <p class="text-primaryBlack font-light text-[12px]">{{translationService.translations?.variables['Return date']}}: <span class="font-medium">{{item.parsedEndDate}}</span></p>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>