import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsService } from 'src/app/services/assets.service';

@Component({
  selector: 'app-asset-qr-details',
  standalone: true,
  imports: [],
  templateUrl: './asset-qr-details.component.html',
  styleUrl: './asset-qr-details.component.css'
})
export class AssetQrDetailsComponent implements OnInit{

  public assetId : string = ''
  public domain : string = '';

  public details : any;

  constructor(private assetService : AssetsService,
    private route : ActivatedRoute,
    private router : Router
  ){}

  ngOnInit(): void {
    const domainFromPath = this.route.snapshot.paramMap.get('domain');
    if(domainFromPath) {
      this.domain = domainFromPath;
    }

    const assetIdFromRoute = this.route.snapshot.paramMap.get('assetId');
    if(assetIdFromRoute) {
      this.assetId = assetIdFromRoute;
    }

    if(assetIdFromRoute && domainFromPath) {
      this.getAssetQRDetails();
    }
  }

  public getAssetQRDetails() : void {
    this.assetService.getQRDetails(this.assetId, this.domain).subscribe(
      (response : any) => {
        console.log("ASSET DETAILS", response);
        this.details = response;
        // if(this.details.InventoryNumbers === null && this.details.UserEmail === '' && this.details.ProjectName === ''){
        //   this.router.navigate(['freeAsset']);
        // }
      }
    )
  }
}
