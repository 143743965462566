@if (!atEmployeeProfilePage) {
<div class="flex flex-col md:flex-row w-full gap-4 font-poppins">
    <div [ngClass]="{
      'w-[50%]': bio !== '' || editingBio,
      'w-full': bio === '' || !editingBio
    }" class="flex flex-col items-center justify-between bg-white p-4 gap-4 rounded-md">
        <div class="flex flex-row w-full justify-between items-center">
            <p class="small text-greyScaleTextCaption tracking-wide uppercase">
                {{translationService.translations?.variables['Personal info']}}
            </p>
            @if (!editingPersonal) {
            <button class="flex items-center justify-center group" (click)="toggleEditPersonal()">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../assets/icons/EditIcon.png"
                        class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]" />
                    <p class="text-greyScaleTextCaption group-hover:text-greyScaleTextTitle small transition-[0.5s]">
                        {{translationService.translations?.variables['Edit']}}
                    </p>
                </div>
            </button>
            } @else {
            <button class="flex items-center justify-center group" (click)="cancelEditPersonal()">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../assets/icons/CloseIcon.png"
                        class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]" />
                    <p class="text-greyScaleTextCaption group-hover:text-greyScaleTextTitle small transition-[0.5s]">
                        {{translationService.translations?.variables['Cancel']}}
                    </p>
                </div>
            </button>
            }
        </div>
        <div class="flex flex-col md:flex-row h-auto w-full rounded-xl items-center relative">
            <div
                class="flex size-[120px] 2xl:size-[180px] img-container rounded-full border-[1px] border-primaryBlack/20 relative">
                @if (!editingPersonal) { @if(hasPicture) {
                <img [src]="employeePictureLink" class="rounded-full size-full" />
                } @else {
                <img src="../../../assets/icons/UserIcon.png" class="rounded-full size-full" />
                } } @else {
                <img [src]="employeePictureLink" class="rounded-full" />
                <div class="flex rounded-full bg-primaryBlack hover:bg-lightGreen transition-[0.5s] p-2 absolute 2xl:top-[7px] 2xl:right-[7px] top-[-2px] right-[-2px] cursor-pointer"
                    (click)="toggleDotedOpened()">
                    <img src="../../../assets/icons/DotedMenuIcon.png" class="size-[15px] nav_menu_icon" />
                </div>
                @if (dotedOpened) {
                <div class="flex bg-primaryWhite w-auto absolute top-[20px] right-[-10px] border-[1px]">
                    <div class="flex bg-primaryWhite p-2 hover:bg-primaryBlack/20 transition-[0.5s] cursor-pointer"
                        (click)="openChangePictureModal()">
                        <p class="text-[12px] text-primaryBlack">Change picture</p>
                    </div>
                </div>

                } }
            </div>
            <div class="flex flex-col items-start ml-4 gap-1">
                <div class="flex flex-col w-full md:items-start items-center">
                    <p class="big text-greyScaleTextTitle">
                        {{ firstName }} {{ lastName }}
                    </p>
                    <p class="global text-greyScaleTextSubtitle">
                        {{ role }}
                    </p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <img src="../../../assets/icons/MailIcon.png" class="size-[18px] 2xl:size-[20px]" />
                    <p class="global text-greyScaleTextBody">
                        {{ email }}
                    </p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <img src="../../../assets/icons/PhoneIcon.png" class="size-[18px] 2xl:size-[20px]" />
                    <p class="global text-greyScaleTextBody">
                        {{ emergencyContact }}
                    </p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <img src="../../../assets/icons/DateIcon.png" class="size-[18px] 2xl:size-[20px]" />
                    <p class="global text-greyScaleTextBody">
                        {{ dateToDisplay }}
                    </p>
                </div>
            </div>
            <div class="flex flex-row w-full gap-4 items-center md:absolute md:bottom-0 md:right-0 md:justify-end justify-center">
                <button
                    class="flex px-[1.5rem] py-[1rem] rounded-md border-[1px] bg-brandSurfaceDefault hover:bg-brandBorderDarker 
                            items-center transition-[0.5s] justify-center text-greyScaleSurfaceDefault global"
                    (click)="openGenerateActivityModal()">
                    {{translationService.translations?.variables['Generate activity report']}}
                </button>
                <!-- <app-generate-activity-dropdown [employeeId]="employeeId"></app-generate-activity-dropdown> -->

                @if (bio === '') {
                <button
                    class="flex px-[1.5rem] py-[1rem] rounded-md border-[1px] border-greyScaleBorderDarker hover:border-transparent hover:bg-brandSurfaceDefault 
                            items-center transition-[0.5s] justify-center text-greyScaleBorderDarker hover:text-greyScaleSurfaceDefault global"
                    (click)="toggleEdit()">
                    {{translationService.translations?.variables['Add bio']}}
                </button>
                }
            </div>
        </div>
    </div>
    @if (bio !== '' || editingBio) {
    <div class="flex flex-col w-full md:w-[50%] h-auto p-4 rounded-xl bg-white items-start">
        <div class="flex flex-row w-full items-center justify-between justify-center">
            <p class="small text-greyScaleTextCaption tracking-wide uppercase">
                {{translationService.translations?.variables['Bio']}}
            </p>
            @if (!editingBio) {
            <button class="flex items-center justify-center group" (click)="toggleEdit()">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../assets/icons/EditIcon.png"
                        class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]" />
                    <p class="small text-greyScaleTextCaption group-hover:text-greyScaleTextTitle transition-[0.5s]">
                        {{translationService.translations?.variables['Edit']}}
                    </p>
                </div>
            </button>
            } @else {
            <button class="flex items-center justify-center group" (click)="cancelEdit()">
                <div class="flex flex-row gap-2 items-center">
                    <img src="../../../assets/icons/CloseIcon.png"
                        class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]" />
                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s]">
                        {{translationService.translations?.variables['Cancel']}}
                    </p>
                </div>
            </button>
            }
        </div>
        @if (!editingBio) {
            <p class="global text-greyScaleTextBody mt-4">{{ bio }}</p>
        } @else {
        <div class="flex flex-col w-full gap-[4px]">
            <textarea [(ngModel)]="tmpBio"
                class="w-full text-wrap text-[12px] min-h-[150px] max-h-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-full p-2 text-primaryBlack focus:outline-lightGreen"
                [placeholder]="bio"></textarea>
            <div class="flex flex-row w-full justify-end">
                <button
                    class="flex py-2 px-4 text-[12px] rounded-md bg-primaryBlack hover:bg-lightGreen text-white transition-[0.5s]"
                    (click)="saveBio()">
                    {{translationService.translations?.variables['Save']}}
                </button>
            </div>
        </div>
        }
    </div>
    }
</div>
} @else {
<div class="flex flex-row w-full gap-4 font-poppins">
    <div class="flex flex-col items-center justify-between w-[50%] bg-white p-4 gap-4 rounded-md">
        <div class="flex flex-row w-full justify-between items-center">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide uppercase">
                {{translationService.translations?.variables['Personal info']}}
            </p>
        </div>
        <div class="flex flex-row h-auto w-full rounded-xl items-center">
            <div class="flex size-[120px] img-container rounded-full border-[1px] border-primaryBlack/20 relative">
                @if(hasPicture) {
                <img [src]="employeePictureLink" class="rounded-full size-full" />
                } @else {
                <img src="../../../assets/icons/UserIcon.png" class="rounded-full size-full" />
                }
            </div>
            <div class="flex flex-col items-start ml-4 gap-1">
                <div class="flex flex-col w-full items-start">
                    <p class="text-[28px] text-primaryBlack font-semibold">
                        {{ firstName }} {{ lastName }}
                    </p>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../../../assets/icons/MailIcon.png" class="size-[18px]" />
                    <p class="text-[14px] text-primaryBlack font-regular ml-2">
                        {{ email }}
                    </p>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../../../assets/icons/PhoneIcon.png" class="size-[18px]" />
                    <p class="text-[14px] text-primaryBlack font-regular ml-2">
                        {{ emergencyContact }}
                    </p>
                </div>
                <div class="flex flex-row items-center">
                    <img src="../../../assets/icons/DateIcon.png" class="size-[18px]" />
                    <p class="text-[14px] text-primaryBlack font-regular ml-2">
                        {{ dateToDisplay }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col w-[50%] h-auto p-4 rounded-xl bg-white items-start">
        <div class="flex flex-row w-full items-center justify-between justify-center">
            <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide uppercase">
                {{translationService.translations?.variables['Bio']}}
            </p>
        </div>
        <p class="global text-greyScaleTextBody mt-4">{{ bio }}</p>
    </div>
</div>

}