import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown-menu',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.css'
})
export class DropdownMenuComponent implements OnInit{
  @Input() width : string = "230px";
  @Input() inputPlaceHolder : string = "";
  @Input() data : string[] = []

  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('dropDownOption') dropdownOptions!: QueryList<ElementRef>;



  isOpen : boolean = false; 
  selectedItem: string = '';
  filteredData: string[] = [];
  searchQuery: string = "";

  public menuWidth : string = "230px"
  public selectedIndex = -1;

  constructor(public cdr: ChangeDetectorRef) {}


  ngOnInit() {
    this.filteredData = this.data;
  }
  
  ngOnChanges() {
    this.filteredData = this.data;
    this.cdr.detectChanges();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    if(this.width == '100%'){
      this.menuWidth = "350px"
    } else {
      this.menuWidth = this.width;
    }
  }

  public onSelection(item: string) {
    this.selectedItem = item;
    this.selectionChanged.emit(item);
    this.toggleDropdown();
  }

  // public onSearchChange(searchValue: string) {
  //   if(this.searchQuery){
  //     this.filteredData = this.data.filter(tmp => 
  //       tmp.toLowerCase().includes(this.searchQuery.toLowerCase())
  //     );
  //   } else {
  //     this.filteredData = this.data
  //   }
  // }

  /////    FUNCTIONALITIES FOR BASIC DROPDOWN UI INTERACTIONS /////////////////

  onSearchChange(searchValue: string) {
    this.searchQuery = searchValue;
    this.filteredData = this.searchQuery ? 
      this.data.filter(item => item.toLowerCase().startsWith(this.searchQuery.toLowerCase())) 
      : this.data;
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    const isClickedInside = targetElement.closest('.dropdown-container');

    if (!isClickedInside && this.isOpen) {
      this.isOpen = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardNavigation(event: KeyboardEvent) {
    if (this.isOpen) {
      const optionsCount = this.dropdownOptions.length;

      if (event.key === 'ArrowDown') {
        this.selectedIndex = (this.selectedIndex + 1) % optionsCount;
        this.highlightOption();
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        this.selectedIndex = (this.selectedIndex - 1 + optionsCount) % optionsCount;
        this.highlightOption();
        event.preventDefault();
      } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
        this.selectOption(this.selectedIndex);
        event.preventDefault();
      } else if (event.key === 'Escape') {
        this.isOpen = false;
        this.selectedIndex = -1;
      }
    }
  }

  highlightOption() {
    this.dropdownOptions.forEach((option, index) => {
      const element = option.nativeElement;
      if (index === this.selectedIndex) {
        element.classList.add('highlighted'); 
        element.scrollIntoView({ block: 'nearest' });
      } else {
        element.classList.remove('highlighted');
      }
    });
  }


  public selectOption(index: number) {
    const selectedOption = this.dropdownOptions.get(index)?.nativeElement.textContent.trim();
    this.onSelection(selectedOption);
    console.log(`Selected option: ${selectedOption}`);
    this.isOpen = false;
    this.selectedIndex = -1;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////

}
