<div class="flex flex-row w-screen h-screen bg-greyScaleSurfaceDefault items-center justify-center font-poppins">
    @if (!isLoading) {
        <div class="flex flex-col bg-greyScaleSurfaceSubtle px-12 py-8 items-center justify-center rounded-[16px] border-[1px] border-greyScaleBorderDefault gap-8">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" class="h-14" viewBox="0 0 1195.22 184.87">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #228ae6;
                    }
              
                    .cls-2 {
                      fill: #041019;
                    }
                  </style>
                </defs>
                <g id="Layer_1-2" data-name="Layer 1">
                  <g>
                    <g>
                      <path class="cls-1" d="M29.35,92.43L0,109.37c4.26,23.4,17.18,43.77,35.31,57.67s45.42,8.73,57.11-11.52h0c-34.84-.02-63.08-28.25-63.08-63.1ZM155.53,92.42l29.34-16.93c-4.26-23.4-17.18-43.77-35.32-57.67-18.55-14.22-45.42-8.72-57.11,11.52v.02s0,0,0,0c34.84,0,63.08,28.23,63.09,63.07ZM92.44,155.52h0l16.94,29.35c23.4-4.26,43.77-17.18,57.68-35.32,14.22-18.55,8.73-45.42-11.52-57.11h0c0,34.84-28.25,63.08-63.09,63.08ZM75.49,0C52.09,4.26,31.72,17.18,17.82,35.31c-14.22,18.55-8.73,45.42,11.52,57.11h.01c0-34.83,28.23-63.06,63.08-63.07L75.49,0Z"/>
                      <circle class="cls-1" cx="92.44" cy="92.43" r="36.7"/>
                    </g>
                    <g>
                      <polygon class="cls-2" points="239.36 146.22 258.22 146.22 258.22 107.34 292.35 107.34 292.35 89.34 258.22 89.34 258.22 66.15 299.98 66.15 299.98 48.15 239.36 48.15 239.36 146.22"/>
                      <path class="cls-2" d="M321.44,42.25c-3.46,0-6.22,1.08-8.28,3.24-2.07,2.16-3.1,4.97-3.1,8.42,0,3.27,1.03,5.95,3.1,8.06,2.06,2.11,4.82,3.17,8.28,3.17s6.21-1.06,8.28-3.17c2.06-2.11,3.1-4.8,3.1-8.06,0-3.46-1.03-6.26-3.1-8.42-2.07-2.16-4.82-3.24-8.28-3.24Z"/>
                      <rect class="cls-2" x="312.08" y="75.8" width="18.86" height="70.42"/>
                      <path class="cls-2" d="M381.34,74.22c-3.07,0-6.03.74-8.86,2.23-2.83,1.49-5.33,3.24-7.49,5.26-.61.57-1.16,1.12-1.68,1.66l-1.85-7.57h-14.11v70.42h18.86v-48.11c.93-.97,1.98-1.81,3.17-2.51,3.36-1.97,8.16-2.95,14.4-2.95h4.61v-17.14c-.67-.48-1.63-.81-2.88-1.01-1.25-.19-2.64-.29-4.18-.29Z"/>
                      <path class="cls-2" d="M501.58,83.29c-1.44-2.78-3.6-4.99-6.48-6.62-2.88-1.63-6.67-2.45-11.38-2.45-3.84,0-7.51.65-11.02,1.94-3.5,1.3-6.77,3.05-9.79,5.26-1.46,1.07-2.86,2.22-4.2,3.44-.22-.53-.45-1.06-.7-1.57-1.39-2.78-3.53-4.99-6.41-6.62-2.88-1.63-6.72-2.45-11.52-2.45-3.84,0-7.51.65-11.02,1.94-3.51,1.3-6.75,3.05-9.72,5.26-1.24.92-2.43,1.91-3.59,2.95l-2.46-8.57h-14.11v70.42h18.86v-45.48c1.73-1.91,3.68-3.59,5.9-4.99,3.26-2.06,6.62-3.1,10.08-3.1,2.4,0,4.46.72,6.19,2.16,1.73,1.44,2.59,4.32,2.59,8.64v42.77h18.86v-42.62c0-.94-.02-1.87-.05-2.8,1.74-1.94,3.71-3.63,5.96-5.05,3.26-2.06,6.62-3.1,10.08-3.1,2.4,0,4.44.72,6.12,2.16,1.68,1.44,2.52,4.32,2.52,8.64v42.77h19.01v-42.62c0-3.74-.27-7.37-.79-10.87-.53-3.5-1.51-6.65-2.95-9.43Z"/>
                      <path class="cls-2" d="M569.19,78.97c-5.23-3.17-11.4-4.75-18.5-4.75s-13.15,1.56-18.43,4.68c-5.28,3.12-9.36,7.44-12.24,12.96-2.88,5.52-4.32,11.93-4.32,19.22s1.44,13.82,4.32,19.3c2.88,5.47,6.96,9.72,12.24,12.74,5.28,3.02,11.42,4.54,18.43,4.54,7.68,0,14.23-1.56,19.66-4.68,5.42-3.12,9.72-7.61,12.89-13.46l-13.1-7.49c-2.02,2.98-4.42,5.47-7.2,7.49-2.79,2.02-6.62,3.02-11.52,3.02-5.57,0-9.96-1.61-13.18-4.82-2.8-2.79-4.37-6.52-4.74-11.16h51.75c.19-.67.31-1.51.36-2.52.05-1.01.07-2.04.07-3.1,0-7.1-1.44-13.41-4.32-18.94-2.88-5.52-6.94-9.86-12.17-13.03ZM550.68,89.05c5.95,0,10.34,1.87,13.18,5.62,1.67,2.21,2.83,4.8,3.52,7.78h-33.57c.65-3.4,2.09-6.27,4.35-8.57,3.17-3.21,7.34-4.82,12.53-4.82Z"/>
                      <path class="cls-2" d="M726.22,93.95h-47.81v17.14h29.56c-.69,2.24-1.66,4.33-2.92,6.26-2.59,3.98-6.07,7.11-10.44,9.36-4.37,2.26-9.34,3.38-14.9,3.38-5.86,0-11.07-1.37-15.62-4.1-4.56-2.74-8.16-6.55-10.8-11.45-2.64-4.9-3.96-10.51-3.96-16.85s1.32-12.38,3.96-17.28c2.64-4.9,6.24-8.71,10.8-11.45,4.56-2.74,9.77-4.1,15.62-4.1s10.87,1.13,15.34,3.38c4.46,2.26,8.47,6.17,12.02,11.74l17.57-6.62c-4.13-8.35-9.84-14.9-17.14-19.66-7.3-4.75-16.56-7.13-27.79-7.13-7.2,0-13.82,1.25-19.87,3.74-6.05,2.5-11.3,6.03-15.77,10.58-4.46,4.56-7.92,9.96-10.37,16.2-2.45,6.24-3.67,13.1-3.67,20.59,0,9.6,2.06,18.19,6.19,25.78,4.13,7.58,9.94,13.56,17.42,17.93,7.49,4.37,16.17,6.55,26.06,6.55,6.62,0,12.79-1.1,18.5-3.31,5.71-2.21,10.75-5.33,15.12-9.36,4.37-4.03,7.8-8.78,10.3-14.26,2.5-5.47,3.74-11.47,3.74-18,0-1.53-.1-3.1-.29-4.68-.19-1.58-.48-3.05-.86-4.39Z"/>
                      <polygon class="cls-2" points="791.45 45.85 782.23 36.63 759.62 57.51 768.7 66.87 791.45 45.85"/>
                      <path class="cls-2" d="M788.06,78.97c-5.23-3.17-11.4-4.75-18.5-4.75s-13.15,1.56-18.43,4.68c-5.28,3.12-9.36,7.44-12.24,12.96-2.88,5.52-4.32,11.93-4.32,19.22s1.44,13.82,4.32,19.3c2.88,5.47,6.96,9.72,12.24,12.74,5.28,3.02,11.42,4.54,18.43,4.54,7.68,0,14.23-1.56,19.66-4.68,5.42-3.12,9.72-7.61,12.89-13.46l-13.1-7.49c-2.02,2.98-4.42,5.47-7.2,7.49-2.79,2.02-6.62,3.02-11.52,3.02-5.57,0-9.96-1.61-13.18-4.82-2.8-2.79-4.37-6.52-4.74-11.16h51.75c.19-.67.31-1.51.36-2.52.05-1.01.07-2.04.07-3.1,0-7.1-1.44-13.41-4.32-18.94-2.88-5.52-6.94-9.86-12.17-13.03ZM769.56,89.05c5.95,0,10.34,1.87,13.18,5.62,1.67,2.21,2.83,4.8,3.52,7.78h-33.57c.65-3.4,2.09-6.27,4.35-8.57,3.17-3.21,7.34-4.82,12.53-4.82Z"/>
                      <path class="cls-2" d="M876.62,83.29c-1.49-2.78-3.7-4.99-6.62-6.62-2.93-1.63-6.79-2.45-11.59-2.45-4.03,0-7.87.65-11.52,1.94-3.65,1.3-7.08,3.05-10.3,5.26-1.38.95-2.68,1.97-3.92,3.04l-2.49-8.65h-14.11v70.42h18.86v-45.37c.99-1.04,2.06-2.03,3.24-2.94,2.16-1.68,4.49-2.97,6.98-3.89,2.5-.91,4.94-1.37,7.34-1.37,2.59,0,4.73.72,6.41,2.16,1.68,1.44,2.52,4.32,2.52,8.64v42.77h18.86v-42.62c0-3.74-.24-7.37-.72-10.87-.48-3.5-1.46-6.65-2.95-9.43Z"/>
                      <polygon class="cls-2" points="947.69 45.85 938.47 36.63 915.86 57.51 924.93 66.87 947.69 45.85"/>
                      <path class="cls-2" d="M944.3,78.97c-5.23-3.17-11.4-4.75-18.5-4.75s-13.15,1.56-18.43,4.68c-5.28,3.12-9.36,7.44-12.24,12.96-2.88,5.52-4.32,11.93-4.32,19.22s1.44,13.82,4.32,19.3c2.88,5.47,6.96,9.72,12.24,12.74,5.28,3.02,11.42,4.54,18.43,4.54,7.68,0,14.23-1.56,19.66-4.68,5.42-3.12,9.72-7.61,12.89-13.46l-13.1-7.49c-2.02,2.98-4.42,5.47-7.2,7.49-2.79,2.02-6.62,3.02-11.52,3.02-5.57,0-9.96-1.61-13.18-4.82-2.8-2.79-4.37-6.52-4.74-11.16h51.75c.19-.67.31-1.51.36-2.52.05-1.01.07-2.04.07-3.1,0-7.1-1.44-13.41-4.32-18.94-2.88-5.52-6.94-9.86-12.17-13.03ZM925.8,89.05c5.95,0,10.34,1.87,13.18,5.62,1.67,2.21,2.83,4.8,3.52,7.78h-33.57c.65-3.4,2.09-6.27,4.35-8.57,3.17-3.21,7.34-4.82,12.53-4.82Z"/>
                      <path class="cls-2" d="M1006.29,74.22c-3.07,0-6.03.74-8.86,2.23-2.83,1.49-5.33,3.24-7.49,5.26-.61.57-1.16,1.12-1.68,1.66l-1.85-7.57h-14.11v70.42h18.86v-48.11c.93-.97,1.98-1.81,3.17-2.51,3.36-1.97,8.16-2.95,14.4-2.95h4.61v-17.14c-.67-.48-1.63-.81-2.88-1.01-1.25-.19-2.64-.29-4.18-.29Z"/>
                      <path class="cls-2" d="M1064.9,77.67c-4.23-2.3-9.55-3.46-15.98-3.46-4.7,0-9.38.58-14.04,1.73-4.66,1.15-9.05,2.74-13.18,4.75l5.62,13.82c3.55-1.73,6.96-2.9,10.22-3.53,3.26-.62,6.24-.94,8.93-.94,4.32,0,7.44,1.06,9.36,3.17,1.92,2.11,2.88,4.51,2.88,7.2v4.78c-4.26-3.08-9.78-4.63-16.56-4.63-4.99,0-9.46.99-13.39,2.95-3.94,1.97-7.03,4.73-9.29,8.28-2.26,3.55-3.38,7.73-3.38,12.53s1.06,9,3.17,12.6c2.11,3.6,5.13,6.39,9.07,8.35,3.94,1.97,8.5,2.95,13.68,2.95,6.62,0,12.12-1.58,16.49-4.75.94-.68,1.8-1.43,2.61-2.22l1.49,4.96h14.98v-45.22c0-5.18-1.06-9.79-3.17-13.82-2.11-4.03-5.28-7.2-9.5-9.5ZM1055.54,131.24c-2.11,1.73-4.9,2.59-8.35,2.59s-6.24-.86-8.35-2.59c-2.11-1.73-3.17-4.03-3.17-6.91s1.06-5.3,3.17-6.98c2.11-1.68,4.9-2.52,8.35-2.52s6.24.84,8.35,2.52c2.11,1.68,3.17,4.01,3.17,6.98s-1.06,5.18-3.17,6.91Z"/>
                      <path class="cls-2" d="M1110.26,121.02V48.15h-18.72v79.49c0,4.51.79,8.19,2.38,11.02,1.58,2.83,3.69,4.92,6.34,6.26,2.64,1.34,5.54,2.02,8.71,2.02,1.44,0,2.9-.07,4.39-.22,1.49-.14,2.76-.41,3.82-.79v-16.85c-1.92,0-3.55-.5-4.9-1.51-1.35-1.01-2.02-3.19-2.02-6.55Z"/>
                      <path class="cls-2" d="M1190.9,92c-2.88-5.52-6.94-9.86-12.17-13.03-5.23-3.17-11.4-4.75-18.5-4.75s-13.15,1.56-18.43,4.68c-5.28,3.12-9.36,7.44-12.24,12.96-2.88,5.52-4.32,11.93-4.32,19.22s1.44,13.82,4.32,19.3c2.88,5.47,6.96,9.72,12.24,12.74,5.28,3.02,11.42,4.54,18.43,4.54,7.68,0,14.23-1.56,19.66-4.68,5.42-3.12,9.72-7.61,12.89-13.46l-13.1-7.49c-2.02,2.98-4.42,5.47-7.2,7.49-2.79,2.02-6.62,3.02-11.52,3.02-5.57,0-9.96-1.61-13.18-4.82-2.8-2.79-4.37-6.52-4.74-11.16h51.75c.19-.67.31-1.51.36-2.52.05-1.01.07-2.04.07-3.1,0-7.1-1.44-13.41-4.32-18.94ZM1160.23,89.05c5.95,0,10.34,1.87,13.18,5.62,1.67,2.21,2.83,4.8,3.52,7.78h-33.57c.65-3.4,2.09-6.27,4.35-8.57,3.17-3.21,7.34-4.82,12.53-4.82Z"/>
                    </g>
                  </g>
                </g>
            </svg>
            <form [formGroup]="form" class="flex w-full h-full items-center justify-center">
                <div class="flex flex-col w-full items-center justify-center gap-4 z-[2]">    
                    @if (step === 0) {
                        <div class="flex flex-col w-full justify-start">
                            <p class="small text-greyScaleTextCaption tracking-wide">
                                PERSONAL INFO
                            </p>
                        </div>
                        <div class="flex flex-col w-full">
                            @if (form.get('firstname')?.touched && form.get('firstname')?.invalid) {
                                <label for="firstname" class="text-lightRed font-regular text-[12px]">First name (Required)</label>
                            }
                            <input  formControlName="firstname" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Enter your first name"/>
                        </div>
    
                        <div class="flex flex-col w-full">
                            @if (form.get('lastname')?.touched && form.get('lastname')?.invalid) {
                                <label for="lastname" class="text-lightRed font-regular text-[12px]">Last name (Required)</label>
                            }
                            <input  formControlName="lastname" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Enter your last name"/>
                        </div>
    
                        <div class="flex flex-col w-full">
                            <app-dropdown-menu class="z-[51]"
                                width="100%"
                                [inputPlaceHolder]="this.selectedCountry"
                                [data]="country_list"
                                (selectionChanged)="getCountryDialCode($event)"
                            /> 
                        </div>
    
                        <div class="flex flex-col w-full">
                            <app-dropdown-menu class="z-[50]"
                                width="100%"
                                [inputPlaceHolder]="this.selectedCity"
                                [data]="cities"
                                (selectionChanged)="onCitySelected($event)"
                            />
                        </div>
    
                        <div class="flex flex-row w-full items-end gap-2">
                            <div class="flex flex-col">
                                <input [value]="selectedDialCode" disabled class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="+"/>
                            </div>
                            <div class="flex flex-col w-full">
    
                                <div class="flex flex-col w-full">
                                    @if (form.get('phone')?.touched && form.get('phone')?.invalid) {
                                        <label for="phone" class="text-lightRed font-regular text-[12px]">Phone number (Required)</label>
                                    }
                                    <input  formControlName="phone" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Enter your phone number"/>
                                </div>
                            </div>
                        </div>
                    }

                    @if(step === 1){
                        <div class="flex flex-col w-full justify-start">
                            <p class="small text-greyScaleTextCaption tracking-wide">
                                ACCOUNT INFO
                            </p>
                        </div>
                        <div class="flex flex-col w-full">
                            @if (form.get('email')?.touched && form.get('email')?.invalid) {
                                <label for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                            }
                            <div class="flex flex-row w-full items-center gap-2">
                                <input  formControlName="email" [(ngModel)]="email" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" placeholder="Enter your email address"/>
    
                                @if (!isLoadingVerification) {
                                    @if (!verified) {
                                        <div class="flex flex-row items-center gap-[4px]"
                                            (click)="verifyMail()">
                                            <img src="../../../../assets/icons/SupportIcon.png" class="size-[15px]"/>
                                            <p class="text-greyScaleTextTitle underline hover:text-brandSurfaceDefault cursor-pointer transition-[0.5s] small">Verify</p>
                                        </div>
                                    } @else {
                                        <div class="flex flex-row items-center gap-[4px]">
                                            <img src="../../../../assets/icons/ApprovedIcon.png" class="size-[15px]"/>
                                            <p class="text-brandSurfaceDefault small">Verified</p>
                                        </div>
                                    }
                                } @else {
                                    <svg aria-hidden="true" class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-brandSurfaceDefault" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                }
                            </div>
                        </div>
                        

                        <div class="flex flex-col w-full">
                            @if (form.get('password')?.touched && form.get('password')?.invalid) {
                                <label for="password" class="text-lightRed font-regular text-[12px]">Password (Required)</label>
                            }
                            @if (form.get('password')?.errors?.['uppercase']) {
                                <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain an uppercase letter.</label>
                            }
                            @else if (form.get('password')?.errors?.['digit']) {
                                <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain a digit.</label>
                            }
                            @else if (form.get('password')?.errors?.['symbol']) {
                                <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain a symbol.</label>
                            }
                            @else if (form.get('password')?.errors?.['length']) {
                                <label for="password" class="text-lightRed font-regular text-[12px]">Password must be at least 6 characters long.</label>
                            }
                        
                            <label class="relative block">
                                <input class="placeholder:italic block w-full rounded-md  px-4 rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] text-primaryBlack focus:outline-brandBorderDefault" 
                                placeholder="Enter your password" [type]="getInputType()" formControlName="password"/>
                                <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                                    @if(passwordHidden){
                                        <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                                    } @else {
                                        <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                                    }
                                </span>
                            </label>
                        </div>

                        <div class="flex flex-col w-full">
                            @if (form.get('repeatPassword')?.touched && form.get('repeatPassword')?.invalid) {
                                <label for="repeatPassword" class="text-lightRed font-regular text-[12px]">Repeated password (Required)</label>
                            } @else if (form.errors?.['passwordMismatch'] && (form.get('repeatPassword')?.touched || form.get('repeatPassword')?.dirty)) {
                                <label for="password" class="text-lightRed font-regular text-[12px]">The password does not match.</label>
                            }
                            <label class="relative block">
                                <input class="placeholder:italic block w-full rounded-md  px-4 rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] text-primaryBlack focus:outline-brandBorderDefault" 
                                        placeholder="Enter repeated password" [type]="getInputType()" formControlName="repeatPassword"/>

                                <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                                    @if(repeatedPasswordHidden){
                                        <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[50]" (click)="showHideRepeatedPassword()"/>
                                    } @else {
                                        <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[50]" (click)="showHideRepeatedPassword()"/>
                                    }
                                </span>
                            </label>
                        </div>   
                    }

                    <div class="flex flex-col md:max-4xl:flex-row w-full items-center"
                        [ngClass]="{'gap-8 md:max-4xl:justify-between' : step === 1,
                            'justify-end items-end' : step === 0}">
                        @if (step === 0) {
                            <button class="w-[50%] items-center justify-center py-[1rem] px-8 rounded-1 disabled:bg-greyScaleBorderDisabled bg-brandSurfaceDefault text-greyScaleSurfaceSubtle hover:bg-brandBorderDarker transition-[0.5s]"
                                    (click)="changeSteps(1)"
                                    [disabled]="form.value.firstname === '' || form.value.lastname === '' || form.value.phone === '' || this.selectedCountry === '' || this.selectedCity === ''">
                                Next
                            </button>
                        }
                        @if (step === 1) {
                            <button class="w-full items-center justify-center py-[1rem] rounded-1 bg-brandSurfaceDefault text-greyScaleSurfaceSubtle hover:bg-brandBorderDarker transition-[0.5s]"
                                    (click)="changeSteps(-1)">
                                Back
                            </button>

                            <button class="w-full items-center justify-center py-[1rem] rounded-1 disabled:bg-greyScaleBorderDisabled bg-brandSurfaceDefault text-greyScaleSurfaceSubtle hover:bg-brandBorderDarker transition-[0.5s]"
                                    (click)="signUp()" [disabled]="!form.valid || !verified">
                                Sign Up
                            </button>
                        }
                    </div>
    
                    <p class="global text-greyScaleBorderDarker hover:text-brandSurfaceDefault transition-[0.5s] cursor-pointer">Or continue with</p>
    
                    <div class="flex w-full items-center justify-center" id="buttonDiv"></div>
    
                    <hr class="w-full text-greyScaleSurfaceDarker">
    
                    <p class="text-greyScaleBorderDarker global">Already have an account?
                        <span class="global-bold text-brandSurfaceDefault hover:text-brandBorderDarker cursor-pointer"
                                (click)="navigationService.navigateToSignIn()">
                            Sign In
                        </span>
                    </p>
                </div>
            </form>
        </div>
    } @else {
        <div>
            <div class="flex flex-col w-full h-full rounded-md p-16 gap-4 items-center justify-center">
                <svg aria-hidden="true" class="inline w-20 h-20 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="#228AE6" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
                <p class="big text-brandBorderDefault">Signing up...</p>
            </div>
        </div>
    }
</div>