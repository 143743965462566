<form [formGroup]="form" class="flex w-full py-16 items-center justify-center bg-primaryWhite rounded-md">
    <div class="flex flex-col w-[50%] font-poppins  h-full items-center justify-center justify-evenly gap-4">
        <div class="flex flex-row">
            <p class="font-primaryBlack font-bold text-lightGreen text-[48px]">Firme</p>
            <p class="ml-[5px] font-light text-primaryBlack text-[48px]">Générale</p>
        </div>

        <div class="flex flex-col items-center justify-center w-full word-break-all text-center gap-2">
            <p class="text-[24px] xl:text-[22px] text-primaryBlack font-medium text-balance">Create your new account</p>
            <p class="text-[16px] xl:text-[14px] text-primaryBlack font-light text-balance">Enter all the necessary data in order to be able to use the application</p>
            <div class="flex flex-row w-full items-center justify-center my-2">
                <p class="3xl:text-[24px] xs:text-[16px] text-primaryBlack font-light">Already have an account?</p>
                <p class="3xl:text-[24px] xs:text-[16px] text-lightGreen font-bold ml-2 hover:text-darkGreen transition-[0.5s] cursor-pointer"
                   (click)="toggleSignIn()">Sign in</p>
            </div>
        </div>
        <div class="flex flex-col w-full items-start justify-start gap-4">
            <div class="flex flex-col w-full items-start h-full gap-2">
                <p class="text-[14px] text-primaryBlack/50 font-light tracking-wider">PERSONAL INFO</p>
                <div class="flex flex-row w-full gap-2 mt-2">
                    <div class="flex flex-col w-full">
                        @if (form.get('firstname')?.touched && form.get('firstname')?.invalid) {
                            <label for="firstname" class="text-lightRed font-regular text-[12px]">First name (Required)</label>
                        } @else {
                            <label for="firstname" class="text-primaryBlack/70 font-regular text-[12px]">First name</label>
                        }
                        <input formControlName="firstname" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="First name"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('lastname')?.touched && form.get('lastname')?.invalid) {
                            <label for="lastname" class="text-lightRed font-regular text-[12px]">Last name (Required)</label>
                        } @else {
                            <label for="lastname" class="text-primaryBlack/70 font-regular text-[12px]">Last name</label>
                        }
                        <input formControlName="lastname" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Last name"/>
                    </div>
                </div>
                <div class="flex flex-row w-full gap-2">
                    <div class="flex flex-col w-full">
                        <label for="country" class="text-primaryBlack/70 font-regular text-[12px]">Country</label>
                        <app-dropdown-menu class="z-[50]"
                            width="100%"
                            inputPlaceHolder="Select a country"
                            [data]="country_list"
                            (selectionChanged)="getCountryDialCode($event)"
                        /> 
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="country" class="text-primaryBlack/70 font-regular text-[12px]">City</label>
                        <app-dropdown-menu class="z-[50]"
                            width="100%"
                            inputPlaceHolder="Select a city"
                            [data]="cities"
                            (selectionChanged)="onCitySelected($event)"
                        /> 
                    </div>
                </div>
                <div class="flex flex-row w-full items-center gap-2">
                    <div class="flex flex-col">
                        <label for="phone" class="text-primaryBlack/70 font-regular text-[12px]">Dial code</label>
                        <input [value]="selectedDialCode" disabled class="w-[100px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="+"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('phone')?.touched && form.get('phone')?.invalid) {
                            <label for="phone" class="text-lightRed font-regular text-[12px]">Phone number (Required)</label>
                        } @else {
                            <label for="phone" class="text-primaryBlack/70 font-regular text-[12px]">Phone number</label>
                        }
                        <input formControlName="phone" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Phone number"/>
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('email')?.touched && form.get('email')?.invalid) {
                        <label for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                    } @else {
                        <label for="email" class="text-primaryBlack/70 font-regular text-[12px]">Email address</label>
                    }
                    <div class="flex flex-row w-full items-center gap-2">
                        <input formControlName="email" [(ngModel)]="email" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="email@domain.com"/>
                        @if (!isLoading) {
                            @if (!verified) {
                                <div class="flex flex-row items-center gap-[4px]"
                                    (click)="verifyMail()">
                                    <img src="../../../../assets/icons/SupportIcon.png" class="size-[15px]"/>
                                    <p class="text-primaryBlack underline hover:text-lightGreen cursor-pointer transition-[0.5s] text-[14px] font-light">Verify</p>
                                </div>
                            } @else {
                                <div class="flex flex-row items-center gap-[4px]">
                                    <img src="../../../../assets/icons/ApprovedIcon.png" class="size-[15px]"/>
                                    <p class="text-lightGreen text-[14px] font-light">Verified</p>
                                </div>
                            }
                        } @else {
                            <svg aria-hidden="true" class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        }
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('password')?.touched && form.get('password')?.invalid) {
                        <label for="password" class="text-lightRed font-regular text-[12px]">Password (Required)</label>
                    }
                    @if (form.get('password')?.touched || form.get('password')?.dirty) {} 
                        @if (form.get('password')?.errors?.['uppercase']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain an uppercase letter.</label>
                        }
                        @else if (form.get('password')?.errors?.['digit']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain a digit.</label>
                        }
                        @else if (form.get('password')?.errors?.['symbol']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must contain a symbol.</label>
                        }
                        @else if (form.get('password')?.errors?.['length']) {
                            <label for="password" class="text-lightRed font-regular text-[12px]">Password must be at least 6 characters long.</label>
                        }
                    @else {
                        <label for="password" class="text-primaryBlack/70 font-regular text-[12px]">Password</label>
                    }
                    <label class="relative block">
                        <input class="placeholder:italic block w-full rounded-md py-4 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] text-primaryBlack focus:outline-lightGreen" 
                                placeholder="Password" [type]="getInputType()" formControlName="password"/>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                            @if(passwordHidden){
                                <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            } @else {
                                <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            }
                        </span>
                    </label>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('repeatPassword')?.touched && form.get('repeatPassword')?.invalid) {
                        <label for="repeatPassword" class="text-lightRed font-regular text-[12px]">Repeated password (Required)</label>
                    } @else if (form.errors?.['passwordMismatch'] && (form.get('repeatPassword')?.touched || form.get('repeatPassword')?.dirty)) {
                        <label for="password" class="text-lightRed font-regular text-[12px]">The password does not match.</label>
                    } 
                    @else {
                        <label for="repeatPassword" class="text-primaryBlack/70 font-regular text-[12px]">Repeated password</label>
                    }
                    <label class="relative block">
                        <input class="placeholder:italic block w-full rounded-md py-4 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] text-primaryBlack focus:outline-lightGreen" 
                                placeholder="Repeated password" [type]="getRepeatedPasswordType()" formControlName="repeatPassword"/>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                            @if(repeatedPasswordHidden){
                                <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[50]" (click)="showHideRepeatedPassword()"/>
                            } @else {
                                <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[50]" (click)="showHideRepeatedPassword()"/>
                            }
                        </span>
                    </label>
                </div>        
            </div>
            <!-- <div class="flex flex-col w-full items-start h-full gap-2">
                <p class="text-[14px] text-primaryBlack/50 font-light tracking-wider">COMPANY INFO</p>
                <div class="flex flex-col w-full mt-2">
                    @if (form.get('companyName')?.touched && form.get('companyName')?.invalid) {
                        <label for="companyName" class="text-lightRed font-regular text-[12px]">Company name (Required)</label>
                    } @else {
                        <label for="companyName" class="text-primaryBlack/70 font-regular text-[12px]">Company name</label>
                    }
                    <input formControlName="companyName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Name"/>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('postalCode')?.touched && form.get('postalCode')?.invalid) {
                        <label for="postalCode" class="text-lightRed font-regular text-[12px]">Postal code (Required)</label>
                    } @else {
                        <label for="postalCode" class="text-primaryBlack/70 font-regular text-[12px]">Postal code</label>
                    }
                    <input formControlName="postalCode" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Postal code"/>
                </div>
                <div class="flex flex-row w-full gap-2">
                    <div class="flex flex-col w-full">
                        <label for="country" class="text-primaryBlack/70 font-regular text-[12px]">Country</label>
                        <app-dropdown-menu class="z-[50]"
                            width="100%"
                            inputPlaceHolder="Country"
                            [data]="country_list"
                            (selectionChanged)="onCountrySelected($event)"
                        /> 
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="city" class="text-primaryBlack/70 font-regular text-[12px]">City</label>
                        <app-dropdown-menu #cityDropdown
                            class="z-[1]" 
                            width="100%"        
                            inputPlaceHolder="City"
                            [data]="cities"
                            (selectionChanged)="onCitySelected($event)"
                        />
                    </div>           
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('contact')?.touched && form.get('contact')?.invalid) {
                        <label for="contact" class="text-lightRed font-regular text-[12px]">Contact (Required)</label>
                    } @else {
                        <label for="contact" class="text-primaryBlack/70 font-regular text-[12px]">Contact (email or phone number)</label>
                    }
                    <input formControlName="contact" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Contact"/>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('taxId')?.touched && form.get('taxId')?.invalid) {
                        <label for="taxId" class="text-lightRed font-regular text-[12px]">Tax Id (Required)</label>
                    } @else {
                        <label for="taxId" class="text-primaryBlack/70 font-regular text-[12px]">Tax Id</label>
                    }
                    <input formControlName="taxId" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Tax ID"/>
                </div>
                <div class="flex flex-col w-full">
                    @if (form.get('foundationDate')?.touched && form.get('foundationDate')?.invalid) {
                        <label for="foundationDate" class="text-lightRed font-regular text-[12px]">Foundation date (Required)</label>
                    } @else {
                        <label for="foundationDate" class="text-primaryBlack/70 font-regular text-[12px]">Foundation date</label>
                    }
                    <input formControlName="foundationDate" type="date" id="dateInput" class="w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                </div>   
                <div class="flex flex-col w-full">
                    @if (form.get('companyDomain')?.touched && form.get('companyDomain')?.invalid) {
                        <label for="companyDomain" class="text-lightRed font-regular text-[12px]">Company domain (Required)</label>
                    } @else {
                        <label for="companyDomain" class="text-primaryBlack/70 font-regular text-[12px]">Company domain</label>
                    }
                    <input formControlName="companyDomain" type="text" id="dateInput" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="firmegenerale.cloud/YOUR_DOMAIN">
                </div>       
            </div> -->
        </div>
        <div class="flex flex-row w-full items-center justify-startn">
            <input type="checkbox" class="mr-4 size-[16px] checked:bg-lightGreen"/>
            <p class="ml-2 text-[12px] font-light text-primaryBlack">By ticking, you are confirming that you have read, understood and agree to Firme Generale
            <span class="text-[12px] font-medium text-lightGreen hover:text-darkGreen transition-[0.5s] cursor-pointer underline-[1px] underline-lightGreen">Terms and Conditions *</span></p>
            <img src="../../../assets/icons/ShieldIcon.png" class="size-[20px] opacity-50 transition-[0.7s] ml-2">
        </div>
        <div class="flex flex-col items-center w-full justify-end gap-4">
            <button [disabled]="!form.valid || !verified" (click)="signUp()"
                    [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid || !verified, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid && verified}"
                    class="flex items-center justify-center px-16 py-2 rounded-md transition-[0.5s] font-medium text-[20px]">
                Sign Up
            </button>
        </div>
        <div class="flex flex-row w-full items-center justify-evenly">
            <hr class="text-primaryBlack/50 w-[30%]">
            <p class="text-primaryBlack/50 text-[16px]">Other sign up options</p>
            <hr class="text-primaryBlack/50 w-[30%]">
        </div>
        <div class="flex w-full items-center justify-center" id="buttonDiv"></div>
    </div>
</form>