import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SkeletonEmployeeCardComponent } from '../../../components/loaders/skeleton-employee-card/skeleton-employee-card.component';
import { TeamsService } from '../../../services/teams.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateTeamModalComponent } from '../../../components/modals/create-team-modal/create-team-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { TeamCardComponent } from "../../../components/team-card/team-card.component";
import { EmployeeCardComponent } from '../../../components/employee-card/employee-card.component';

@Component({
  selector: 'app-teams',
  standalone: true,
  imports: [SkeletonEmployeeCardComponent, FormsModule, CommonModule, TeamCardComponent, EmployeeCardComponent],
  templateUrl: './teams.component.html',
  styleUrl: './teams.component.css'
})
export class TeamsComponent implements OnInit{

  public searchText : string = ''
  public isLoading : boolean = false;
  public teams : any[] = [];
  public teamsCount : any[] = [];
  public counter : number = 0;
  public benchCounter : number = 0;
  public benchEmployees : any[] = [];

  public showTeams : boolean = true;
  public showBench : boolean = false;

  constructor(private teamsService : TeamsService,
    private modalService : NgbModal,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.fetchTeams();
    this.fetchBench();
  }

  public fetchBench() : void {
    this.isLoading = true;
    this.teamsService.getBench().subscribe(
      (response : any) => {
        this.benchCounter = response.length;
        this.benchEmployees = response;
        console.log("Employees on bench: ", response);
        this.isLoading = false;
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
      }
    )
  }
 
  public fetchTeams() : void {
    this.isLoading = true;
    this.teamsService.getAll().subscribe(
      (response : any[]) => {
        if(response != null) {
          this.counter = response.length;
        }
        
        this.teams = response;
        console.log("Fetched teams: ", this.teams);
        this.isLoading = false;
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
      }
    )
  }

  public addTeam() : void {
    const modalRef = this.modalService.open(
      CreateTeamModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.teamCreated.subscribe(
      () => {
        this.fetchTeams();
        this.toast.success("Team created successfully!", "Success!", 3000);
      }
    );
  }

  public trackByTeamID(index: number, team: any): any {
    return team.ID;
  }
  
  public searchTeams() : void{
    //TODO
  }

  public toggleTeams(){
    this.showTeams = true;
    this.showBench = false;
  }

  public toggleBench(){
    this.showTeams = false;
    this.showBench = true;
  }

  public trackByEmployeeId(index: number, employee : any) : void {
    return employee;
  }
}
