<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto py-2 px-4 gap-4">
    <div class="flex flex-col md:flex-row w-full justify-between md:items-center">
        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Equipment']}}</p>
        <div class="flex flex-row justify-end gap-4">
            <app-button 
                [primary]=true 
                [secondary]=false 
                [text]="translationService.translations?.variables['Generate QR codes']" 
                (click)="generateQRCodes()" 
            />
            <app-button 
                [primary]=true 
                [secondary]=false 
                icon="../../../assets/icons/PlusIcon.png"
                [text]="translationService.translations?.variables['Add new equipment']" 
                [function]="openAddAssetModal.bind(this)" 
            />
        </div>
    </div>
    <div class="relative flex w-full flex-col overflow-x-auto shadow-md sm:rounded-lg lg:rounded-md xl:rounded-md font-poppins">
        <div class="flex w-auto flex-col space-y-4 md:space-y-0 p-4 gap-4 bg-white">
            <div class="flex h-[45px] relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </div>
                <input type="text" [(ngModel)]="searchText" (input)="searchAssets()" id="table-search-users"
                    class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen"
                    [placeholder]="translationService.translations?.variables['Search equipment']">
            </div>
            <div class="flex flex-col border-[1px] w-[320px] rounded-md py-2 px-4 gap-2 justify-start items-start">
                <div class="flex flex-row items-center gap-2">
                    <img src="../../../../assets/icons/FilterIcon.png" class="size-[15px]" />
                    <p class="text-primaryBlack text-[14px]">{{translationService.translations?.variables['Filters']}}</p>
                </div>
                <div class="flex flex-row gap-4 items-start">
                    <p class="text-primaryBlack font-light text-[12px]">{{translationService.translations?.variables['Approval required']}}</p>
                    <div class="flex flex-row items-center gap-2">
                        <input type="checkbox"
                            class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded-sm focus:ring-lightGreen"
                            [(ngModel)]="approvalRequiredYes" (change)="filterAssets()" />
                        <p class="text-primaryBlack font-medium text-[12px]">{{translationService.translations?.variables['Yes']}}</p>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                        <input type="checkbox"
                            class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded-sm focus:ring-lightGreen"
                            [(ngModel)]="approvalRequiredNo" (change)="filterAssets()" />
                        <p class="text-primaryBlack font-medium text-[12px]">{{translationService.translations?.variables['No']}}</p>
                    </div>
                </div>
            </div>
        </div>
        @if (isLoading) {
        <div role="status"
            class="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
            @for (skeleton of assetsCount; track $index) {
            <div class="flex h-20 items-center justify-between">
                <div class="flex flex-row items-center gap-4">
                    <div class="flex items-center justify-center size-[40px] bg-gray-300 rounded-md dark:bg-gray-700">
                        <svg class="w-4 h-4 text-gray-200 dark:text-gray-600" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                            <path
                                d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                        </svg>
                    </div>
                    <div class="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-48"></div>
                </div>
                <div class="w-32 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div class="w-16 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div class="flex flex-col gap-4">
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                </div>
            </div>
            }
            <span class="sr-only">Loading...</span>
        </div>
        } @else {
        @if (assets != null) {
            @if(assets.length > 0) {
                <table class="w-full text-sm md:text-md lg:text-lg xl:text-lg text-left rtl:text-right">
                    <thead class="text-xs sm:text-sm md:text-md xl:text-md text-primaryBlack uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{translationService.translations?.variables['Name']}}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{translationService.translations?.variables['Approval required']}}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{translationService.translations?.variables['Action']}}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{translationService.translations?.variables['History']}}
                            </th>
                            <th scope="col" class="px-3 py-3">
                                QR 
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (asset of filteredAssets; track $index){
                        <tr class="bg-primaryWhite h-[45px] border-b items-center transition-[0.5s]">
                            <td scope="row"
                                class="flex items-center px-6 py-4 text-gray-900  whitespace-nowrap dark:text-white gap-4 img-container">
                                <img [src]="asset.imageUrl" class="w-14 h-14 rounded-sm border-[1px] size-full" />
                                <div class="text-base font-semibold">{{asset.Name}}</div>
                            </td>
                            <td class="px-6">
                                @if (asset.Permission) {
                                    <p class="font-medium text-[16px] text-lightRed">{{translationService.translations?.variables['Yes']}}</p>
                                } @else {
                                    <p class="font-medium text-[16px] text-lightGreen">{{translationService.translations?.variables['No']}}</p>
                                }
                            </td>
                            <td class="px-6 gap-2">
                                <div class="flex flex-row w-[250px] justify-center items-center gap-2 group cursor-pointer transition-[0.5s] p-2 bg-lightRed/20 rounded-md hover:bg-lightRed/35"
                                    (click)="deleteAsset(asset)">
                                    <img src="../../../assets/icons/TrashIcon.png" class="size-[20px] icon-filter-red" />
                                    <p class="text-darkRed text-[16px]">{{translationService.translations?.variables['Delete']}}</p>
                                </div>
                                <div class="flex flex-row justify-center w-[250px] items-center gap-2 group cursor-pointer transition-[0.5s] mt-2 p-2 bg-lightYellow/25 hover:bg-lightYellow/50 rounded-md"
                                    (click)="openEditAssetModal(asset.ID, asset.Name, asset.SerialNumber, asset.ImageURL, asset.Permission, asset)">
                                    <img src="../../../assets/icons/EditIcon.png" class="size-[20px]" />
                                    <p class="text-primaryBlack text-[16px]">{{translationService.translations?.variables['Edit']}}</p>
                                </div>
                            </td>
                            <td class="px-6 gap-2" (click)="viewAssetHistory(asset, asset.DefaultPicture)">
                                <p class="cursor-pointer text-primaryBlack underline font-medium text-[16px] hover:text-lightGreen transition-[0.5s]">
                                    {{translationService.translations?.variables['View']}}
                                </p>
                            </td>
                            @if (asset.Permission){
                                <td class="px-3 gap-2" (click)="generateQRCodeForAsset(asset.ID)">
                                    <img src="../../../assets/icons/QRCodeIcon.png" class="size-[24px]"/>
                                </td>
                            } @else {
                                <td class="px-3 gap-2"></td>
                            }
                        </tr>
                        }
                    </tbody>
                </table>
                } @else {
                    <div class="flex w-full p-4 gap-2 flex-col">
                        <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no equipments to display']}}</p>
                        <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first equipment by pressing the']}} <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new equipment']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                    </div>
                }
            } @else {
                <div class="flex w-full p-4 gap-2 flex-col">
                    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['There are no equipments to display']}}</p>
                    <p class="global text-greyScaleTextSubtitle">{{translationService.translations?.variables['You can create your first equipment by pressing the']}} <span class="global-bold text-brandSurfaceDefault">"{{translationService.translations?.variables['Add new equipment']}}"</span> {{translationService.translations?.variables['Button']}}.</p>
                </div>
            }
        }
    </div>
</div>