<button [ngStyle]="{'width': width}"
        [ngClass]="{'bg-brandSurfaceDefault hover:bg-brandBorderDarker' : primary,
                    'bg-brandBorderDarker hover:bg-brandSurfaceDefault': secondary}"
        class="flex h-[45px] text-greyScaleSurfaceSubtle font-poppins rounded-md relative
                transition-[0.5s] items-center justify-center px-[1.5rem] py-[1rem] gap-2"
        (click)="function()">
    @if (icon != "") {
        <div class="flex justify-start items-center">
            <img src={{icon}} class="size-[20px] nav_menu_icon"/>
        </div>
    }
    <div class="flex justify-end items-center">
        {{text}}
    </div>
</button>  