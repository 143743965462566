import { Component, EventEmitter, Input, OnInit, Output, input } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';
import { Employee } from '../../models/employee';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignEmployeeModalComponent } from '../modals/assign-employee-modal/assign-employee-modal.component';
import { ProjectService } from '../../services/project.service';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CreatePositionModalComponent } from '../modals/create-position-modal/create-position-modal.component';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-position-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './position-card.component.html',
  styleUrl: './position-card.component.css'
})
export class PositionCardComponent implements OnInit{

  @Input() positionId : string = '';
  @Input() primarySkillId : string = '';
  @Input() requiredSkillLevelId : string = ''
  @Input() employee : string = '';
  @Input() cost : number = 0;
  @Input() assigned : boolean = false;
  @Input() professionId : string = '';
  @Input() projectId : string = '';
  @Input() requiresEditing : boolean = false;
  @Input() company : any;

  @Output() employeeAssigned = new EventEmitter<void>();
  @Output() positionDeleted = new EventEmitter<void>();

  public assignedEmployeeFirstName : string = '';
  public assignedEmployeeLastName : string = '';

  public professions : any[] = [];
  public techStacks : any[] = [];
  public experienceLevels : any[] = [];

  public positionName : string = '';
  public primarySkillName : string = '';
  public requiredSkillLevelName : string = '';


  constructor(private employeeService : EmployeeService,
    private projectService : ProjectService,
    private modalService : NgbModal,
  ){}

  ngOnInit(): void {
    if(this.assigned) {
      this.fetchEmployee();
    }
    this.fetchSessionStorageCompanyData();
    this.getPositionCardData();
  }

  public fetchSessionStorageCompanyData() : void {
    const techStackData = sessionStorage.getItem('techStack');
    const experienceLevelsData = sessionStorage.getItem('experienceLevels');
    const professionsData = sessionStorage.getItem('professions')
   
    if(techStackData && experienceLevelsData && professionsData){
      this.techStacks = JSON.parse(techStackData);
      this.experienceLevels = JSON.parse(experienceLevelsData);
      this.professions = JSON.parse(professionsData);
    } else {
      console.log("Error while parsing data from session storage!");
    }
  }

  public getPositionCardData() : void {
    this.techStacks.map((stack : any) => {
      if(stack.ID == this.primarySkillId) {
        this.primarySkillName = stack.Name;
      }
    });
    this.professions.map((profession : any) => {
      if(profession.ID === this.professionId) {
        this.positionName = profession.Name
      }
    });
    this.experienceLevels.map((level : any) => {
      if(level.ID == this.requiredSkillLevelId) {
        this.requiredSkillLevelName = level.Name
      }
    })
  }

  public fetchEmployee() : void {
    this.employeeService.getEmployee(this.employee).subscribe(
      (response : Employee) => {
        this.assignedEmployeeFirstName = response.FirstName;
        this.assignedEmployeeLastName = response.LastName;
      }
    )
  }

  openAssignEmployeeForm() {
    this.goToAssignEmployee();
  }

  public goToAssignEmployee(): void {
    const modalRef = this.modalService.open(
      AssignEmployeeModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.positionID = this.positionId;
    modalRef.componentInstance.primarySkillID = this.primarySkillId;
    modalRef.componentInstance.requiredSkillLevelID = this.requiredSkillLevelId;
    modalRef.componentInstance.cost = this.cost;
    modalRef.componentInstance.positionName = this.positionName;
    modalRef.componentInstance.employeeAssignedModal.subscribe(() => {
      this.fetchEmployee();
      this.employeeAssigned.emit();
    });

  }

  public deletePosition(selectedPositionId: string, event : Event): void {
    event.stopPropagation();

    console.log("selected position id: ", selectedPositionId);
    console.log("selected project id: ", this.projectId);

    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.projectService.deletePosition(this.projectId, selectedPositionId).subscribe(
          (response : any) => {
            console.log('Position deleted successfully!', response);
            this.positionDeleted.emit();
          },
          (error : HttpErrorResponse) => {
            console.log("Error while deleting position, ", error.message);
          }
        )
      }
    )
  }

  public editPosition() {
    const modalRef = this.modalService.open(
      CreatePositionModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.existingProject = true;
    modalRef.componentInstance.company = this.company;
    modalRef.componentInstance.editingExistiingPosition = true;
    modalRef.componentInstance.editingPositionId = this.positionId;
    modalRef.componentInstance.editingCurrentTechStackId = this.primarySkillId;
    modalRef.componentInstance.editingCurrentExperienceLevelId = this.requiredSkillLevelId;
    modalRef.componentInstance.editingCurrentProfessionId = this.professionId;
    modalRef.componentInstance.editingCurrentCost = this.cost;
    modalRef.componentInstance.projectId = this.projectId

    modalRef.componentInstance.positionEdited.subscribe(
      (res: string[]) => {
        const [techStackId, professionId, experienceLevelId] = res;
  
        this.primarySkillId = techStackId;
        this.professionId = professionId;
        this.requiredSkillLevelId = experienceLevelId;
  
        this.getPositionCardData();
        this.employeeAssigned.emit();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while editing position, ", error.message);
      }
    )
  }
}
