<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto py-2 px-4 gap-4">
    <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Plans']}}</p> 

    <div class="flex flex-col h-full gap-4 md:flex-row w-full h-full justify-evenly">   
        <app-plan-card
            planName="Boutique"
            subtitle="For up to 10 monthly active users"
            [availableOptions]="['Staff management', 'Project management', 'Teams management']"
            [cost]=0
            [billedMonthly]="false"
        />
        <app-plan-card
            planName="Standard"
            subtitle="For up to 30 monthly active users"
            footer="30 users included; additional users are $0.50 each per month, resetting monthly."
            [availableOptions]="['Staff management', 'Project management', 'Teams management', 'Inventory control']"
            [cost]=12
            [billedMonthly]="true"
        />
        <app-plan-card
            planName="Business"
            subtitle="For up to 60 monthly active users"
            footer="60 users included; additional users are $0.40 each per month, resetting monthly."
            [availableOptions]="['Staff management', 'Project management', 'Teams management', 'Inventory control', 'Employee activity tracking', 'Financial insights']"
            [cost]=21
            [billedMonthly]="true"
        />
    </div>
</div>