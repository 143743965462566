import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from "../../button/button.component";
import { Employee } from '../../../models/employee';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TeamsService } from '../../../services/teams.service';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-employee-to-team',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './add-employee-to-team.component.html',
  styleUrl: './add-employee-to-team.component.css'
})
export class AddEmployeeToTeamComponent implements OnInit{

  @Input() team : any;

  @Output() employeeAdded = new EventEmitter<void>();

  public fetchedEmployees : Employee[] = [];
  public availableEmployees : Employee[] = [];
  public selectedEmployeesId : string = '';

  constructor(public modalService : NgbActiveModal,
    private employeeService : EmployeeService,
    private teamService : TeamsService,
    private toast : NgToastService,
    private authService : AuthService
  ){}

  ngOnInit(): void {
    this.fetchEmployees();
  }

  public addEmployeeToTeam() : void {
    this.teamService.addEmployeeToTeam(this.selectedEmployeesId, this.team.ID).subscribe(
      (response : any) => {
        console.log(response);
        this.employeeAdded.emit();
        this.modalService.close();
      }
    )
  }

  public fetchEmployees() : void {
    this.employeeService.getAll().subscribe(
      (response : Employee[]) => {
        this.fetchedEmployees = response;
        console.log("Fetched employees: ", this.fetchedEmployees);

        this.availableEmployees = this.fetchedEmployees.filter(emp => 
          !this.team.Employees.some((teamEmp: string) => teamEmp === emp.EmployeeID)
        );
      }
    )
  }

  public setActive(employee : any) : void {
    this.selectedEmployeesId = employee.EmployeeID;
  }

  public isActive(employeeId: string): boolean {
    return this.selectedEmployeesId === employeeId;
  }

}
