import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { Observable, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { SignInComponent } from '../adminPages/sign-in/sign-in.component';
import { SignUpComponent } from '../adminPages/sign-up/sign-up.component';
import { TestimonialCardComponent } from 'src/app/components/testimonial-card/testimonial-card.component';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import { CommonModule } from '@angular/common';

Swiper.use([Navigation]);

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [RouterModule, TestimonialCardComponent, CommonModule],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.css'
})
export class LandingComponent implements OnInit, AfterViewInit{

  public loggedWithoutCompany : boolean = true;
  public loggedUser : any = false;
  public signIn : boolean = true;
  public signUp : boolean = false;

  public faqOpened : boolean = true;

  public testimonials : any[] = []

  constructor(public navigationService : NavigationService,
    private authService : AuthService
   ) {}

  ngOnInit(): void {
    this.fetchTestimonials();

  }

  ngAfterViewInit() : void {
    this.initSwiper();
  }

  public fetchTestimonials(): void {
    this.testimonials = [
      {
        Quote: "“I used to juggle three or four platforms each day. With this app, I have one place from which I control everything. I don't have to integrate with other apps whenever I want to use a feature.”",
        Testimonial: "Name Surname",
        Position: "Project Manager",
        Company: "ProdActive",
      },
      {
        Quote: "“I used to juggle three or four platforms each day. With this app, I have one place from which I control everything. I don't have to integrate with other apps whenever I want to use a feature.”",
        Testimonial: "Name Surname",
        Position: "Project Manager",
        Company: "ProdActive",
      },
      {
        Quote: "“I used to juggle three or four platforms each day. With this app, I have one place from which I control everything. I don't have to integrate with other apps whenever I want to use a feature.”",
        Testimonial: "Name Surname",
        Position: "Project Manager",
        Company: "ProdActive",
      },
      {
        Quote: "“I used to juggle three or four platforms each day. With this app, I have one place from which I control everything. I don't have to integrate with other apps whenever I want to use a feature.”",
        Testimonial: "Name Surname",
        Position: "Software developer",
        Company: "Bloxico",
      },
      {
        Quote: "“I used to juggle three or four platforms each day. With this app, I have one place from which I control everything. I don't have to integrate with other apps whenever I want to use a feature.”",
        Testimonial: "Name Surname",
        Position: "UI/UX Designer",
        Company: "BrandBorne",
      }
    ];
  }

  public initSwiper(): void {
    const swiper = new Swiper('.multiple-slide-carousel', {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 20,
      navigation: {
        nextEl: '.multiple-slide-carousel .swiper-button-next',
        prevEl: '.multiple-slide-carousel .swiper-button-prev',
      },
      breakpoints: {
        1920: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1028: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        990: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });
  }


  public navigateToSignUp() {
    this.signIn = false;
    this.signUp = true;
  }

  public navigateToSignIn() {
    this.signIn = true;
    this.signUp = false;
  }

  public toggleFAQ() : void {
    this.faqOpened === !this.faqOpened;
  }
}
