import { Component, Input, OnInit } from '@angular/core';
import { Office } from 'src/app/models/office';

@Component({
  selector: 'app-office-card',
  standalone: true,
  imports: [],
  templateUrl: './office-card.component.html',
  styleUrl: './office-card.component.css'
})
export class OfficeCardComponent implements OnInit{

  @Input() office! : Office;
  @Input() freeSeatsCounter : number = 0
  
  constructor(){}

  ngOnInit(): void {
    console.log("OFFICE: ", this.office);
  }

}
