<!-- <div [ngStyle]="{'background' : team.ColorHash}" 
    class="flex flex-col lg:size-[250px] 4xl:size-[600px] 3xl:size-[400px] 2xl:size-[280px] md:size-[220px] sm:size-[200px] xs:size-[150px] 
            items-center justify-center group hover:translate-y-[-6px] md:text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[14px] 3xl:text-[20px]
            rounded-md group cursor-pointer transition-[0.5s] gap-4 font-poppins hover:shadow-xl relative"
    (click)="navigationService.navigateToTeam(team.ID)">
    @if (displayedOnProject) {
        <div class="flex flex-row absolute top-2 right-2 group hover:opacity-100 opacity-40 transition-[0.5s] items-center gap-2"
            (click)="deleteTeamFromProject($event)">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[14px]"/>
            <p class="text-[12px]">Remove from project</p>
        </div>
    }
    <div class="flex flex-col items-center justify-center w-full">
        <div class="text-center word-break-all w-full px-2">
            <h1 class="sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] 3xl:text-[34px] 4xl:text-[44px] font-semibold text-balanced text-ellipsis">{{team.Name}}</h1>
        </div>
    </div>
    <div class="flex flex-row items-center absolute bottom-4 right-4 gap-2">
        <p class="text-[18px] font-semibold">{{team.Employees.length}}</p>
        @if (team.Employees.length === 1) {
            <img src="../../../assets/icons/UserIcon.png" class="size-[20px]"/>
        }
        @else if (team.Employees.length === 2 || team.Employees.length === 3) {
            <img src="../../../assets/icons/EmployeesIcon.png"/>
        }
        @else {
            <div class="flex relative">
                <img src="../../../assets/icons/EmployeesIcon.png"/>
                <p class="absolute top-[-8px] right-0 text-primaryBlack font-medium text-[12px]">+</p>
            </div>
        }
    </div>
</div> -->


<div [ngStyle]="{'background' : team.ColorHash}"
    class="w-full font-poppins justify-center items-center hover:translate-y-[-5px]
        flex h-full max-w-xl border border-gray-200 p-4 hover:shadow-lg rounded-lg overflow-y-auto relative transition-[0.5s] cursor-pointer"
    (click)="navigationService.navigateToTeam(team.ID)">
    @if (displayedOnProject) {
        <div class="flex flex-row absolute top-2 right-2 group hover:opacity-100 opacity-40 transition-[0.5s] items-center gap-2"
            (click)="deleteTeamFromProject($event)">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[14px]"/>
            <p class="text-[12px]">Remove from project</p>
        </div>
    }
    <div class="flex flex-col items-center">
        <div class="flex flex-col w-full items-center justify-center text-center">
            <p class="font-semibold text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{team.Name}}</p>
        </div>
        <div class="flex flex-row items-center absolute bottom-4 right-4 gap-2">
            <p class="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-semibold">{{team.Employees.length}}</p>
            @if (team.Employees.length === 1) {
                <img src="../../../assets/icons/UserIcon.png" class="size-[15px] sm:size-[17px] md:size-[19px] lg:size-[21px] xl:size-[24px]"/>
            }
            @else if (team.Employees.length === 2 || team.Employees.length === 3) {
                <img src="../../../assets/icons/EmployeesIcon.png" class="size-[15px] sm:size-[17px] md:size-[19px] lg:size-[21px] xl:size-[24px]"/>
            }
            @else {
                <div class="flex relative">
                    <img src="../../../assets/icons/EmployeesIcon.png"/>
                    <p class="absolute top-[-8px] right-0 text-primaryBlack font-medium text-[12px]">+</p>
                </div>
            }
        </div>
    </div>
</div>