import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-password-requestchange-confirmation',
  standalone: true,
  imports: [],
  templateUrl: './password-requestchange-confirmation.component.html',
  styleUrl: './password-requestchange-confirmation.component.css'
})
export class PasswordRequestchangeConfirmationComponent implements OnInit{

  constructor(
    private navigationService: NavigationService,
    private activatedRoute : ActivatedRoute
  ){}

  ngOnInit(): void {
    setTimeout(() => {
      this.navigationService.navigateToSignIn();
    }, 3000); 
  }
}
