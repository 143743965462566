<div class="flex flex-col w-full p-4 gap-2 rounded-md border-[1px] border-primaryBlack/20 bg-primaryWhite
            hover:translate-y-[-5px] hover:shadow-md hover:border-lightGreen transition-[0.5s] relative">
    <div [ngClass]="{'bg-paused/40' : task?.Priority === 'Top',
                    'bg-finished/40' : task?.Priority === 'Low',
                    'bg-active/40' : task?.Priority === 'Mid'}" 
        class="flex p-2 w-[100px] rounded-md">
        <p  [ngClass]="{'text-primaryBlack' : task?.Priority === 'Top',
                        'text-lightGreen' : task?.Priority === 'Low',
                        'text-[#0044CC]' : task?.Priority === 'Mid'}" 
            class="text-[12px] font-medium uppercase">{{task?.Priority}} Priority</p>
    </div>

    <button [id]="'dropdownButton' + task?.ID" [attr.data-dropdown-toggle]="'dropdown' + task?.ID" type="button" class="absolute top-8 right-8">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1" id="Capa_1" width="15px" height="15px" viewBox="0 0 342.382 342.382" xml:space="preserve">
            <g>
                <g>
                    <g>
                        <path d="M45.225,125.972C20.284,125.972,0,146.256,0,171.191c0,24.94,20.284,45.219,45.225,45.219     c24.926,0,45.219-20.278,45.219-45.219C90.444,146.256,70.151,125.972,45.225,125.972z"/>
                    </g>
                    <g>
                        <path d="M173.409,125.972c-24.938,0-45.225,20.284-45.225,45.219c0,24.94,20.287,45.219,45.225,45.219     c24.936,0,45.226-20.278,45.226-45.219C218.635,146.256,198.345,125.972,173.409,125.972z"/>
                    </g>
                    <g>
                        <path d="M297.165,125.972c-24.932,0-45.222,20.284-45.222,45.219c0,24.94,20.29,45.219,45.222,45.219     c24.926,0,45.217-20.278,45.217-45.219C342.382,146.256,322.091,125.972,297.165,125.972z"/>
                    </g>
                </g>
            </g>
        </svg>      
    </button>
      
    <div [id]="'dropdown' + task?.ID" class="z-[100] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" [attr.aria-labelledby]="'dropdownButton' + task?.ID">
            <li (click)="editTask()" class="cursor-pointer">
                <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</p>
            </li>
            <li (click)="deleteTask()" class="cursor-pointer">
                <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</p>
            </li>
        </ul>
    </div>
    <p class="text-primaryBlack text-[14px]">
        {{task?.Description}}
    </p>
    <hr class="w-full text-primaryBlack/50">  
    <div class="flex flex-row w-full items-center justify-between">
        <div class="flex w-[80%] justify-start">
            <p [attr.data-popover-target]="'popover-project-details' + task?.ID" class="cursor-pointer">{{project?.Name}}</p>
            <div data-popover [attr.id]="'popover-project-details' + task?.ID" role="tooltip" class="absolute z-[50] invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600">
                <div class="p-3">
                    <p class="text-base font-semibold leading-none text-gray-900 dark:text-white">
                        Project
                    </p>
                    <p class="mb-3 text-sm font-normal">
                        {{project?.Name}}
                    </p>
                    <div>
                        <button type="button" (click)="navigationService.navigateToProject(task?.ProjectID)"
                                class="text-white bg-primaryBlack hover:bg-lightGreen font-medium rounded-md text-xs px-3 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none">View details</button>
                    </div>
                </div>
                <div data-popper-arrow></div>
            </div>
        </div>
        <div class="flex flex-row justify-end w-[20%]">
            <div [attr.data-popover-target]="'popover-user-profile' + task?.ID" class="img-container rounded-full size-[30px] border-[1px] border-primaryBlack/40 cursor-pointer">
                <img class="rounded-full size-full" [src]="employeePictureLink">
            </div>
            <div data-popover [attr.id]="'popover-user-profile' + task?.ID" role="tooltip" class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600">
                <div class="p-3">
                    <div class="flex items-center justify-between mb-2">
                        <div class="size-10 rounded-full img-container border-[1px] border-primaryBlack/40">
                            <img class="rounded-full size-full" [src]="employeePictureLink">
                        </div>
                        <div>
                            <button type="button" (click)="navigationService.navigateToUserProfile(assignee?.EmployeeID)" 
                                    class="text-white bg-primaryBlack hover:bg-lightGreen font-medium rounded-md text-xs px-3 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none">View profile</button>
                        </div>
                    </div>
                    <p class="text-base font-semibold leading-none text-gray-900 dark:text-white">
                        Assignee
                    </p>
                    <p class="mb-3 text-sm font-normal">
                        {{assignee?.FirstName}} {{assignee?.LastName}}
                    </p>
                </div>
                <div data-popper-arrow></div>
            </div>
        </div>
    </div> 
</div>