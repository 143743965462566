<div class="flex flex-col w-full h-full items-center justify-center gap-4">
  <div
    [ngClass]="{
      'hover:translate-y-[-7px] bg-primaryWhite':
        !displayedOnProject && !requiresEditing,
      'hover:translate-y-[-6px] bg-lightYellow/20':
        !displayedOnProject && requiresEditing,
      'border-[1px] hover:border-lightGreen': displayedOnProject
    }"
    class="flex-grow flex w-full max-w-[100%] hover:shadow-xl px-4 overflow-hidden items-center justify-between gap-2 group rounded-md cursor-pointer transition-[0.5s] font-poppins relative"
    style="height: 7rem;"
    (click)="navigationService.navigateToUserProfile(userId)"
  >
  <div
    class="flex-shrink-0 flex size-20 img-container rounded-full border-[1px] border-primaryBlack/20"
  >
    @if (hasPicture) {
    <img [src]="employeePictureLink" class="rounded-full w-full h-full object-cover" />
    } @else {
    <img src="../../../assets/icons/UserIcon.png" class="rounded-full w-full h-full object-cover" />
    }
  </div>
  @if (!displayedOnProject) {
  <div class="flex flex-col w-full items-start justify-center text-left">
    <p
      class="font-medium text-primaryBlack text-lg"
    >
      {{ fullName }}
    </p>
    <p
      class="font-light text-primaryBlack uppercase text-sm tracking-widest"
    >
      {{ role }}
    </p>
  </div>
  } @else {
  <p
    class="font-medium text-primaryBlack text-lg"
  >
    {{ displayName }}
  </p>
  } @if (!displayedOnProject) { @if (requiresEditing) {
  <div class="flex flex-col items-center justify-center">
    <p class="text-[16px] font-semibold text-lightRed uppercase">{{translationService.translations?.variables['Update required']}}</p>
  </div>
  } @else {
  <div class="flex w-full flex-col items-center justify-end">
    <div class="flex flex-row w-full items-center justify-end">
      <p
        class="sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px] font-bold text-lightGreen"
      >
        ${{ grossSalary }}
      </p>
      <p
        class="text-lightGreen font-bold text-lg"
      >
        /{{translationService.translations?.variables['Month']}}
      </p>
    </div>
  </div>
  } } @else {
  <div class="flex flex-col items-center justify-center">
    <p class="text-primaryBlack font-bold text-lg">{{ profession }}</p>
  </div>
  }
</div>
