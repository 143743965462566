<div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Add project manager</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>

        <div class="flex flex-col w-full">
            <label for="teamName" class="text-primaryBlack font-regular text-[12px]">Project name</label>
            <p class="text-primaryBlack font-semibold text-[24px]">{{project.Name}}</p>
        </div>

        <div class="flex flex-col w-full border-[1px] rounded-md p-4 gap-2">
            <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">Employees</p>
            <p class="text-primaryBlack font-light text-[12px] mb-2">Select employees that you want to add to the new team</p>
            <div class="flex flex-row w-full flex-wrap justify-evenly items-center max-h-[300px] overflow-y-auto">
                @for (employee of availableEmployees; track $index) {
                    <div class="flex flex-row min-w-[300px] max-w-auto items-center justify-center p-4 border-[1px] rounded-md gap-4
                                hover:border-lightGreen hover:shadow-md hover:translate-y-[-5px] mt-[8px] transition-[0.5s] cursor-pointer"
                        [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(employee.EmployeeID),
                                'border-[1px]':!isActive(employee.EmployeeID)}"
                        (click)="setActive(employee)">
                        <!-- <div class="flex w-auto size-[65px] img-container rounded-full border-[1px] border-primaryBlack/20">
                            <img [src]="getEmployeePicture(employee)" class="rounded-full"/>
                        </div> -->
                        <div class="flex flex-col w-full">
                            <p class="font-medium text-[14px] text-primaryBlack">{{employee.FirstName}} {{employee.LastName}}</p>
                            <p class="font-medium text-[12px] text-lightGreen">${{employee.Financial.Gross/22/8 | number:'1.0-1'}} <span class="text-primaryBlack font-light"> per hour</span></p>
                        </div>
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-row w-full justify-end">
            <app-button 
                text="Save"
                [function]="addManager.bind(this)"
            />
        </div>
    </div>
</div>