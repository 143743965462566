<!-- <div [ngClass]="{'hover:translate-y-[-7px] bg-primaryWhite/95':!displayedOnProject && !requiresEditing,
                'hover:translate-y-[-6px]  bg-lightYellow/20':!displayedOnProject && requiresEditing,
                'border-[1px] bg-primaryWhite hover:border-lightGreen':displayedOnProject}"
    class="flex flex-col lg:size-[250px] hover:shadow-xl 4xl:size-[600px] 3xl:size-[400px] 2xl:size-[280px] md:size-[220px] sm:size-[200px] xs:size-[150px] overflow-y-auto items-center justify-center group
            rounded-md group cursor-pointer transition-[0.5s] gap-2 font-poppins relative hover:translate-y-[-5px]"
    (click)="navigationService.navigateToUserProfile(userId)">
    @if (displayOnTeam) {
        <div class="flex flex-row absolute top-2 right-2 group hover:opacity-100 opacity-40 transition-[0.5s] items-center gap-2"
            (click)="deleteEmployeeFromTeam($event)">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[14px]"/>
            <p class="text-[12px]">Remove from team</p>
        </div>
    }
    <div class="flex 4xl:size-[300px] 2xl:size-[130px] 3xl:size-[200px] sm:size-[70px] lg:size-[100px] xl:size-[120px] img-container rounded-full border-[1px] border-primaryBlack/20">
        <img [src]="employeePictureLink" class="rounded-full size-full"/>
    </div>
    @if (!displayedOnProject) {
        <div class="flex flex-col w-full items-center justify-center text-center">
            <p class="font-medium text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{fullName}}</p>
            <p class="font-light text-primaryBlack sm:text-[10px] md:text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] 4xl:text-[42px] uppercase tracking-widest">{{role}}</p>
        </div>
    } @else {
        <p class="font-medium text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{displayName}}</p>
        <div class="flex flex-row absolute top-2 right-2 group hover:opacity-100 opacity-40 transition-[0.5s] items-center gap-2"
            (click)="deleteEmployeeFromProject($event)">
            <img src="../../../assets/icons/TrashIcon.png" class="size-[14px]"/>
            <p class="text-[12px]">Remove from project</p>
        </div>
    }
    @if (!displayedOnProject) {
        @if (requiresEditing) {
            <div class="flex flex-col w-full items-center justify-center">
                <p class="text-[16px] font-semibold text-lightRed">UPDATE REQUIRED</p>
            </div>
        } @else {
            <div class="flex flex-col w-full items-center justify-center">
                <div class="flex flex-row w-full items-center justify-center">
                    <p class="sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px] font-bold text-lightGreen">${{grossSalary}}</p>
                    <p class="sm:text-[10px] md:text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[26px] font-light text-primaryBlack">/month (Gross)</p>
                </div> 
            </div>
        }
    } @else {
        <div class="flex flex-col w-full items-center justify-center">
            <p class="text-[20px] font-bold text-primaryBlack">{{profession}}</p>
        </div>
    }
</div> -->




<div [ngClass]="{'hover:translate-y-[-7px] bg-primaryWhite/95':!displayedOnProject && !requiresEditing,
                'hover:translate-y-[-6px]  bg-lightYellow/20': requiresEditing,
                'border-[1px] bg-primaryWhite hover:border-lightGreen':displayedOnProject}" 
    class="w-full h-full max-w-lg border border-gray-200 p-4 hover:shadow-lg rounded-lg overflow-y-auto relative transition-[0.5s] cursor-pointer"
    (click)="navigationService.navigateToUserProfile(userId)">


    <div class="flex flex-col items-center justify-center">
        <div class="img-container">
            <img class="w-24 h-24 mb-3 rounded-full" [src]="employeePictureLink"/>
        </div>

        @if (displayOnTeam || displayedOnProject) {
            <button [id]="'dropdownButton' + userId" [attr.data-dropdown-toggle]="'dropdown' + userId" type="button" class="absolute top-8 right-8" (click)="$event.stopPropagation()">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1" id="Capa_1" width="15px" height="15px" viewBox="0 0 342.382 342.382" xml:space="preserve">
                    <g>
                        <g>
                            <g>
                                <path d="M45.225,125.972C20.284,125.972,0,146.256,0,171.191c0,24.94,20.284,45.219,45.225,45.219     c24.926,0,45.219-20.278,45.219-45.219C90.444,146.256,70.151,125.972,45.225,125.972z"/>
                            </g>
                            <g>
                                <path d="M173.409,125.972c-24.938,0-45.225,20.284-45.225,45.219c0,24.94,20.287,45.219,45.225,45.219     c24.936,0,45.226-20.278,45.226-45.219C218.635,146.256,198.345,125.972,173.409,125.972z"/>
                            </g>
                            <g>
                                <path d="M297.165,125.972c-24.932,0-45.222,20.284-45.222,45.219c0,24.94,20.29,45.219,45.222,45.219     c24.926,0,45.217-20.278,45.217-45.219C342.382,146.256,322.091,125.972,297.165,125.972z"/>
                            </g>
                        </g>
                    </g>
                </svg>      
            </button>
              
            <div [id]="'dropdown' + userId" class="z-[100] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" [attr.aria-labelledby]="'dropdownButton' + userId">
                    @if (displayOnTeam) {
                        <li class="cursor-pointer"
                            (click)="deleteEmployeeFromTeam($event)">
                            <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Remove from team</p>
                        </li>
                    }
    
                    @if (displayedOnProject) {
                        <li class="cursor-pointer"
                            (click)="deleteEmployeeFromProject($event)">
                            <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Remove from project</p>
                    </li>
                    }
                </ul>
            </div>
        }

        @if (!displayedOnProject && !displayOnTeam) {
            <div class="flex flex-col w-full items-center justify-center text-center">
                <p class="font-medium text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{fullName}}</p>
                <span class="text-sm text-gray-500 dark:text-gray-400 tracking-widest uppercase">{{role}}</span>
            </div>
        } 

        @if (displayOnTeam || displayedOnProject || displayOnTeams) {
            <div class="flex flex-col w-full items-center justify-center text-center">
                <p class="font-medium text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{displayName}}</p>
            </div>
        }

        @if (requiresEditing) {
            <div class="flex flex-col w-full items-center justify-center">
                <p class="text-[16px] font-semibold text-lightRed">UPDATE REQUIRED</p>
            </div>
        }

        @if (!displayedOnProject && !displayOnTeam && !displayOnTeams) {
            @if (requiresEditing) {
                <div class="flex flex-col w-full items-center justify-center">
                    <p class="text-[16px] font-semibold text-lightRed">UPDATE REQUIRED</p>
                </div>
            } @else {
                <div class="flex flex-col md:flex-row w-full items-center justify-center">
                    <p class="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px] font-bold text-lightGreen">${{grossSalary}}</p>
                    <span class="text-sm tracking-widest uppercase">/month</span>
                </div> 
            }
        } 
    </div>
</div>
