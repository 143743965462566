<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-scroll font-poppins gap-4 overflow-y-auto p-2">
    <div class="flex flex-row w-full gap-2 items-center">
        <a [routerLink]="['/employees']">
            <img src="../../../../assets/icons/BackIcon.png"  class="size-[30px] cursor-pointer"/>
        </a>
        <p class="text-[24px] font-semibold text-primaryBlack">User profile</p> 
    </div>
    <div class="flex w-full">
        <app-user-info-card
            class="w-full"
            [firstName]="employee?.FirstName"
            [lastName]="employee?.LastName"
            [email]="employee?.Email"
            [emergencyContact]="employee?.EmergencyContact"
            [dateOfBirth]="employee.DateOfBirth"
            [bio]="employee?.Bio"
            [employeeId]="userId"
            [role]="employee.Role"
            [atEmployeeProfilePage] = false
            [defaultPicture]="employee.DefaultPicture"
            (bioEdited)="handleProfileChanges($event)"
            (imageChanged)="handleProfileChanges($event)"
        />
    </div>
    <div class="flex flex-col md:flex-row w-full gap-4">
        <app-tech-stack-and-experience class="flex w-full md:w-[40%]"
            [skills]="employee.Skills"
            [employeeId]="userId"
            (techStackAndExpLevelEdited)="handleProfileChanges($event)"
        />
        <app-user-projects class="w-full md:w-[60%] overflow-x-auto"/>
    </div>
    <div class="flex w-full">
        <app-finance-and-vacation-section class="w-full"
            [netSalary]="employee.Financial.Net"
            [grossSalary]="employee.Financial.Gross"
            [employeeId]="userId"
            [remainingDaysOff]="employee.RemainingDaysOff"
            (changes)="handleProfileChanges($event)"
        />
    </div>
    <div class="flex w-full">
        <app-employee-asset-history-section class="w-full"
            [employeeId]="userId"
        />
    </div>
    <div class="flex flex-col w-full gap-2">
        <p class="text-[24px] text-primaryBlack">Danger zone</p>
        <div class="flex flex-col w-full rounded-md border-[1px] border-lightRed p-4">
            <div class="flex flex-row w-full items-center justify-between">
                <div class="flex flex-col w-full">
                    <p class="text-primaryBlack font-medium text-[18px]">Delete employee</p>
                    <p class="text-primaryBlack text-[14px]">This action implies the complete deletion of the employee from the system as well as all references to the employee.</p>
                </div>
                <button class="flex px-4 py-2 items-center justify-center text-lightRed rounded-md border-[1px] border-lightRed w-[220px]
                                hover:bg-lightRed hover:text-primaryWhite transition-[0.5s]"
                        (click)="deleteEmployee()">
                    <p>Delete employee</p>
                </button>
            </div>
        </div>
    </div>
</div>