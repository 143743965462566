<div [ngClass]="{'hover:translate-y-[-7px] bg-primaryWhite/95':!displayedOnProject && !requiresEditing,
                'hover:translate-y-[-6px]  bg-lightYellow/20': requiresEditing,
                'border-[1px] bg-primaryWhite hover:border-lightGreen':displayedOnProject}" 
    class="flex flex-col min-w-lg h-full max-w-xl border border-greyScaleBorderDefault p-4 hover:shadow-lg rounded-lg overflow-y-auto relative transition-[0.5s] cursor-pointer"
    (click)="navigationService.navigateToUserProfile(userId)">


    <div class="flex flex-col items-center justify-center">
        <div class="img-container">
            <img class="w-24 h-24 mb-3 rounded-full" [src]="employeePictureLink"/>
        </div>

        @if (displayOnTeam || displayedOnProject) {
            <button [id]="'dropdownButton' + userId" [attr.data-dropdown-toggle]="'dropdown' + userId" type="button" class="absolute top-8 right-8" (click)="$event.stopPropagation()">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1" id="Capa_1" width="15px" height="15px" viewBox="0 0 342.382 342.382" xml:space="preserve">
                    <g>
                        <g>
                            <g>
                                <path d="M45.225,125.972C20.284,125.972,0,146.256,0,171.191c0,24.94,20.284,45.219,45.225,45.219     c24.926,0,45.219-20.278,45.219-45.219C90.444,146.256,70.151,125.972,45.225,125.972z"/>
                            </g>
                            <g>
                                <path d="M173.409,125.972c-24.938,0-45.225,20.284-45.225,45.219c0,24.94,20.287,45.219,45.225,45.219     c24.936,0,45.226-20.278,45.226-45.219C218.635,146.256,198.345,125.972,173.409,125.972z"/>
                            </g>
                            <g>
                                <path d="M297.165,125.972c-24.932,0-45.222,20.284-45.222,45.219c0,24.94,20.29,45.219,45.222,45.219     c24.926,0,45.217-20.278,45.217-45.219C342.382,146.256,322.091,125.972,297.165,125.972z"/>
                            </g>
                        </g>
                    </g>
                </svg>      
            </button>
              
            <div [id]="'dropdown' + userId" class="z-[100] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" [attr.aria-labelledby]="'dropdownButton' + userId">
                    @if (displayOnTeam) {
                        <li class="cursor-pointer"
                            (click)="deleteEmployeeFromTeam($event)">
                            <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{translationService.translations?.variables['Remove from team']}}</p>
                        </li>
                    }
    
                    @if (displayedOnProject) {
                        <li class="cursor-pointer"
                            (click)="deleteEmployeeFromProject($event)">
                            <p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{translationService.translations?.variables['Remove from project']}}</p>
                    </li>
                    }
                </ul>
            </div>
        }

        @if (!displayedOnProject && !displayOnTeam) {
            <div class="flex flex-col w-full items-center justify-center text-center">
                <p class="global-bold text-greyScaleTextTitle">{{fullName}}</p>
                <span class="small text-greyScaleTextSubtitle">{{role}}</span>
            </div>
        } 

        @if (displayOnTeam || displayedOnProject || displayOnTeams) {
            <div class="flex flex-col w-full items-center justify-center text-center">
                <p class="global-bold text-greyScaleTextTitle">{{displayName}}</p>
            </div>
        }

        @if (requiresEditing) {
            <div class="flex flex-col w-full items-center justify-center">
                <p class="text-[16px] font-semibold text-lightRed uppercase">{{translationService.translations?.variables['Update required']}}</p>
            </div>
        }

        @if (!displayedOnProject && !displayOnTeam && !displayOnTeams) {
            @if (requiresEditing) {
                <div class="flex flex-col w-full items-center justify-center">
                    <p class="text-[16px] font-semibold text-lightRed">{{translationService.translations?.variables['Update required']}}</p>
                </div>
            } @else {
                <div class="flex flex-col md:flex-row w-full items-center justify-center">
                    <p class="small-bold text-lightGreen">${{grossSalary}}</p>
                    <span class="small text-greyScaleTextTitle tracking-widest">/{{translationService.translations?.variables['Month']}}</span>
                </div> 
            }
        } 
    </div>
</div>
