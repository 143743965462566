import { Component } from '@angular/core';
import { PlanCardComponent } from "../../../components/plan-card/plan-card.component";

@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [PlanCardComponent],
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.css'
})
export class PlansComponent {

}
