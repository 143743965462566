import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { CreatePositionModalComponent } from '../create-position-modal/create-position-modal.component';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../../../services/project.service';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { SelectProjectManagerModalComponent } from '../select-project-manager-modal/select-project-manager-modal.component';
import { ErrorResponse } from '../../../dtos/ErrorResponse';
import { EmployeeService } from 'src/app/services/employee.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-create-project-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './create-project-modal.component.html',
  styleUrl: './create-project-modal.component.css'
})
export class CreateProjectModalComponent implements OnInit{

  @Output() projectCreated = new EventEmitter<void>();

  form! : FormGroup;
  
  public projectName : string = '';
  public managerId : string = '';
  public budget : number = 0;
  public startDate : string = '';
  public projectDuration : number = 0;
  public projectManagerSelected : boolean = false;
  public projectManager : any;
  public fetchedCompany : any;

  public employeePictureLink : any;


  constructor(private activeModalService : NgbActiveModal,
    private modalService : NgbModal,
    private authService : AuthService,
    private toast : NgToastService,
    private projectService : ProjectService,
    private fb : FormBuilder,
    private sanitizer : DomSanitizer,
    private employeeService : EmployeeService,
    private companyService : CompanyService,
    public translationService : TranslationService
  ){}

  ngOnInit(): void {
    this.fetchCompany();
    this.initializeFormGroup();
  }

  public initializeFormGroup() : void {
    this.form = this.fb.group({
      ProjectName: ['', Validators.required],
      Budget: [null, [Validators.required, Validators.min(0)]],
      StartDate: ['', Validators.required],
      Duration: [null, [Validators.required, Validators.min(0)]],
      Description : ''
    });
  }
 
  public fetchCompany() : void {
    const companyId = sessionStorage.getItem('company');

    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.fetchedCompany = response;
        console.log(this.fetchedCompany);
      }
    )
  }


  public selectProjectManager() : void {
    const modalRef = this.modalService.open(
      SelectProjectManagerModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.projectManagerSelected.subscribe(
      (res : any) => {
        this.projectManager = res;
        this.projectManagerSelected = true;
        this.getEmployeePicture();
        console.log("PROJECT MANAGER: ", this.projectManager)
      }
    );
  }

  openCreatePositionModal() {
    this.goToCreatePosition();
  }

  public goToCreatePosition(): void {
    const modalRef = this.modalService.open(
      CreatePositionModalComponent,
      { backdrop: 'static', keyboard: true },
    );

    modalRef.componentInstance.company = this.fetchedCompany;
  }

  public closeModal() : void {
    this.activeModalService.close()
  }


  public createProject() : void {
    if(this.form.valid){
      const formValue = this.form.value
      console.log("PROJECT MANAGER 2: ", this.projectManager)

      if(!this.projectManagerSelected) {
        this.managerId = ""
      } else {
        this.managerId = this.projectManager.EmployeeID;
      }
      var date = new Date(formValue.StartDate);
      var startDateInMillis = date.getTime();
      const body = {
        name : formValue.ProjectName,
        managerID : this.managerId,
        budget : formValue.Budget,
        startDate : startDateInMillis,
        projectDuration : formValue.Duration,
        description : formValue.Description,
      }

      console.log(body);
      console.log("Created project: ", body);
      this.projectService.create(body).subscribe(
        (response : any) => {
          console.log("Project created successfully", response);
          this.toast.success("Project successfully created!", "Success!", 3000);
          this.projectCreated.emit();
          this.closeModal();
        }
      )
    }
  }
  
  public isFormValid() : boolean {
    return this.form.valid;
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(this.projectManager.DefaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = this.projectManager.EmployeeID;
      companyId = company;
    }
    this.employeeService.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      }
    )  
  }
  
}
