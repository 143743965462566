
<div class="flex flex-col h-full w-full bg-white rounded-lg border-primaryBlack border-[1px] p-4 md:p-6">
    <div class="flex flex-col gap-4 border-gray-200 border-b dark:border-gray-700 pb-3">
        <p class="text-[12px] font-medium text-primaryBlack/60 tracking-wide uppercase">CashFlow</p>
        <div class="flex flex-row items-center justify-between">
            <dl>
                <dt class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1">Profit</dt>
                <dd class="leading-none text-3xl font-bold text-gray-900 dark:text-white">${{profit}}</dd>
            </dl>
            <!-- <div>
                <span class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
                <svg class="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4"/>
                </svg>
                Profit rate 23.5%
                </span>
            </div> -->
        </div>
    </div>
  
    <div class="grid grid-cols-2 py-3">
      <dl>
        <dt class="text-sm lg:text-base font-normal text-gray-500 pb-1">Income</dt>
        <dd class="leading-none text-md 2xl:text-xl font-bold text-green-500">${{totalIncome}}</dd>
      </dl>
      <div class="flex flex-col w-full">
        <p class="text-sm lg:text-base font-normal text-gray-500 pb-1">Expense</p>
        <p class="leading-none text-md 2xl:text-xl font-bold text-red-600">${{totalExpense}}<span class="text-primaryBlack/40 font-medium text-[12px]"> (${{totalSalariesExpense}} on salaries)</span></p>
      </div>
    </div>
  
    <div id="bar-chart" class="overflow-y-auto"></div>
      <div class="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
        <div class="flex justify-between items-center pt-5">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="lastDaysdropdown"
            data-dropdown-placement="bottom"
            class="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
            type="button">
            Last 6 months
            <svg class="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
            </svg>
          </button>
          <div id="lastDaysdropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Yesterday</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Today</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 7 days</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 30 days</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 90 days</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last 6 months</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Last year</a>
                </li>
              </ul>
          </div>
        </div>
      </div>
  </div>
  