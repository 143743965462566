import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Employee } from 'src/app/models/employee';
import { EmployeeService } from 'src/app/services/employee.service';
import { ButtonComponent } from '../../button/button.component';
import { ProjectService } from 'src/app/services/project.service';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-add-project-manager-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent],
  templateUrl: './add-project-manager-modal.component.html',
  styleUrl: './add-project-manager-modal.component.css'
})
export class AddProjectManagerModalComponent implements OnInit{

  @Input() project : any;

  @Output() managerAdded = new EventEmitter<void>();

  public fetchedEmployees : Employee[] = [];
  public availableEmployees : Employee[] = [];
  public selectedEmployeesId : string = '';

  constructor(public modalService : NgbActiveModal,
    private employeeService : EmployeeService,
    private projectService : ProjectService,
    private toast : NgToastService
  ){}
  ngOnInit(): void {
    this.fetchEmployees();
  }

  public addManager() : void {
    this.projectService.setManager(this.project.ID, this.selectedEmployeesId).subscribe(
      (response : any) => {
        console.log("Manager added successfully!", response);
        this.managerAdded.emit();
        this.modalService.close();
      }
    )
  }

  public fetchEmployees() : void {
    this.employeeService.getAll().subscribe(
      (response : Employee[]) => {
        this.availableEmployees = response;
        console.log("Fetched employees: ", this.availableEmployees);
      }
    )
  }

  public setActive(employee : any) : void {
    this.selectedEmployeesId = employee.EmployeeID;
  }

  public isActive(employeeId: string): boolean {
    return this.selectedEmployeesId === employeeId;
  }


}
