<form [formGroup]="form">
    <div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Create new asset']}}</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Equipment info']}}</p>
                    <div class="flex flex-col w-full">
                        @if (form.get('Name')?.touched && form.get('Name')?.invalid) {
                            <label for="Name" class="text-lightRed font-regular text-[12px]">{{translationService.translations?.variables['Name (Required)']}}</label>
                        } 
                        <input formControlName="Name" type="text" id="Name" [placeholder]="translationService.translations?.variables['Name']" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault">
                    </div>
                    <div class="flex flex-row items-center gap-2 w-full">
                        @if (form.get('NumberOfDaysRequested')?.touched && form.get('NumberOfDaysRequested')?.invalid) {
                            <label for="RequiredApproval" class="text-lightRed font-regular text-[12px]">{{translationService.translations?.variables['Approval required (Required)']}}</label>
                        } @else {
                            <label for="RequiredApproval" class="text-primaryBlack/70 font-regular text-[12px]">{{translationService.translations?.variables['Approval required']}}</label>
                        }
                        <input formControlName="RequiredApproval" type="checkbox"/>
                    </div>
                    <div class="flex flex-col w-full gap-4">
                        <hr class="w-full text-primaryBlack/40">
                        <div class="flex flex-col w-full gap-4">
                            <div class="flex flex-row w-full gap-2 items-center bg-greyScaleSurfaceDisabled border-[1px] border-greyScaleBorderDefault rounded-md px-4 py-2">
                                <img src="../../../../assets/icons/SupportIcon.png" class="size-[20px]"/>
                                <p class="small text-greyScaleBorderDarker">{{translationService.translations?.variables['Assets can only have serial number or quantity']}}.</p>
                            </div>
                            @if(form.value.RequiredApproval === true){
                                <div class="flex flex-col w-full">
                                    @if (form.get('SerialNumber')?.touched && form.get('SerialNumber')?.invalid) {
                                        <label for="SerialNumber" class="text-lightRed font-regular text-[12px]">{{translationService.translations?.variables['Serial Number (Required)']}}</label>
                                    }
                                    <input formControlName="SerialNumber" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Serial number']"/>
                                </div>
                            } @else {
                                <div class="flex flex-col w-full">
                                    @if (form.get('Counter')?.touched && form.get('Counter')?.invalid) {
                                        <label for="Counter" class="text-lightRed font-regular text-[12px]">{{translationService.translations?.variables['Quantity (Required, greater than 0)']}}</label>
                                    }
                                    <input formControlName="Counter" type="number" min="0" max="9999" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Quantity']"/>
                                </div>
                            }
                        </div>
                        <hr class="w-full text-primaryBlack/40">
                    </div>


                    <div class="flex flex-col w-full gap-2">
                        <label for="Image" class="tracking-wider global text-greyScaleTextCaption">{{translationService.translations?.variables['Upload image']}}</label>
                        <input type="file" (change)="onPictureSelected($event)" class="flex w-full shadow-md cursor-pointer rounded-[6px] bg-primaryBlack/20"/>
                    </div>

                    <div class="flex w-full items-center justify-center">
                        @if(imageSrc){
                            <div class="flex w-[220px] max-w-full max-h-[300px] img-container rounded-md border-[1px] border-primaryBlack/20">
                                <img [src]="imageSrc" class="w-full h-full" alt="Image Preview">
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="saveAsset()"
                        [ngClass]="{'bg-greyScaleBorderDisabled text-greyScaleTextBody': !form.valid, 'bg-brandSurfaceDefault hover:bg-bg-brandBorderDarker text-greyScaleSurfaceDefault': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s]">
                    {{translationService.translations?.variables['Save']}}
                </button>
            </div>
        </div>
    </div>
</form>
