import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectCardComponent } from '../../project-card/project-card.component';
import { NavigationService } from '../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../modals/warning-modal/warning-modal.component';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-projects-widget',
  standalone: true,
  imports: [ProjectCardComponent],
  templateUrl: './projects-widget.component.html',
  styleUrl: './projects-widget.component.css'
})
export class ProjectsWidgetComponent implements OnInit{
  
  @Input() paramsString : string = '';
  @Input() description : string = '';
  @Input() widgetId : string = '';

  @Output() widgetDeleted = new EventEmitter<void>();

  public projects : any[] = [];

  constructor(private adminService : AdminService,
    private modalService : NgbModal,
    public navigationService : NavigationService,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.getWidgetData();
  }

  public getWidgetData() : void {
    this.adminService.getWidget(this.paramsString).subscribe(
      (response : any[]) => {
        this.projects = response;
        console.log(this.projects);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching data for filtered projects widget, ", error.message);
      }
    )
  }
  
  public deleteWidget() : void {
    const modalRef = this.modalService.open(
      WarningModalComponent, 
      { backdrop : "static", keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.adminService.deleteWidget(this.widgetId).subscribe(
          (response : any) => {
            console.log(response);
            this.widgetDeleted.emit();
            this.toast.success("Widget successfully deleted!", "Success!", 3000);
          },
          (error : HttpErrorResponse) => {
            console.log(error);
            this.toast.danger("Error while deleting widget!", "Error!", 3000);
          }
        )
      }
    )
  }
}
