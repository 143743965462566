<div class="flex flex-row w-full items-center justify-center justify-between">
    <div class="flex flex-row items-center">
        @if (icon != '') {
            <img src="../../../assets/icons/{{icon}}" class="size-[20px] 2xl:size-[30px]"/>
        }
        <p class="text-[16px] 2xl:text-[20px] font-regular ml-2 text-primaryBlack">{{tehnologyName}}</p>
    </div>
    <div class="flex flex-row gap-1 items-center justify-end">
        @if (levelName != "") {
            <p class="text-[16px] 2xl:text-[20px] font-semibold text-lightGreen ml-2">{{levelName}}</p>
        } @else {
            <p class="text-[16px] 2xl:text-[20px] font-semibold text-lightRed ml-2">NOT ASSIGNED</p>
        }
    </div>
</div>