<form [formGroup]="form">
    <div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">{{translationService.translations?.variables['Add new income']}}</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="small text-greyScaleTextCaption tracking-wide uppercase">{{translationService.translations?.variables['Income details']}}</p>
                    <div class="flex flex-col w-full">
                        @if (form.get('name')?.touched && form.get('name')?.invalid) {
                            <label for="name" class="text-lightRed small">{{translationService.translations?.variables['Name (Required)']}}</label>
                        }
                        <input formControlName="name" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Income name']"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('description')?.touched && form.get('description')?.invalid) {
                            <label for="description" class="text-lightRed small">{{translationService.translations?.variables['Description (Required)']}}</label>
                        }
                        <textarea formControlName="description" id="description" class="placeholder:italic w-full max-h-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 min-h-[45px] text-primaryBlack focus:outline-lightGreen" [placeholder]="translationService.translations?.variables['Income description']"></textarea>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('amount')?.touched && form.get('amount')?.invalid) {
                            <label for="amount" class="text-lightRed small">{{translationService.translations?.variables['Amount (Required, greater than 0)']}}</label>
                        }
                        <input formControlName="amount" type="number" min="0" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Amount']"/>
                    </div>
        
                    <div class="flex flex-col w-full">
                        @if (form.get('payer')?.touched && form.get('payer')?.invalid) {
                            <label for="payer" class="text-lightRed small">{{translationService.translations?.variables['Payer (Required)']}}</label>
                        }
                        <input formControlName="payer" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Payer']"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('paymentDate')?.touched && form.get('paymentDate')?.invalid) {
                            <label for="paymentDate" class="text-lightRed small">{{translationService.translations?.variables['Payment date (Required)']}}</label>
                        } @else {
                            <label for="paymentDate" class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Payment date']}}</label>
                        }
                        <input formControlName="paymentDate" type="date" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Payment date']"/>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <p class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Select payment type']}}</p>
                        <div class="flex flex-row items-center gap-4">
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Single'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Single']}}</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Monthly'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Monthly']}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('cycles')?.touched && form.get('cycles')?.invalid) {
                            <label for="cycles" class="text-lightRed small">{{translationService.translations?.variables['Cycles (Required)']}}</label>
                        }
                        <input formControlName="cycles" type="number" min="0" class="placeholder:italic w-full rounded-1 bg-white border-[1px] border-primaryBlack/20 py-[1rem] px-4 text-primaryBlack focus:outline-brandBorderDefault" [placeholder]="translationService.translations?.variables['Cycles']"/>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <p class="small text-greyScaleTextSubtitle">{{translationService.translations?.variables['Select payment type']}}</p>
                        <div class="flex flex-row items-center gap-4">
                            <label class="flex items-center gap-2">
                                <input type="radio" (change)="toggleSection('Assets')" formControlName="bindingType" [value]="'Asset'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Equipment']}}</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" (change)="toggleSection('Projects')" formControlName="bindingType" [value]="'Project'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['Project']}}</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" (change)="toggleSection('None')" formControlName="bindingType" [value]="'None'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">{{translationService.translations?.variables['None']}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div> 
            @if (showAssets || showProjects) {
                <div class="flex flex-col w-full max-h-[300px] p-4 rounded-md border-[1px] border-primaryBlack/20">
                    @if (showAssets) {
                        <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">{{translationService.translations?.variables['Available equipment']}}</p>
                        <p class="text-primaryBlack/50 tracking-wide text-[12px] font-light">{{translationService.translations?.variables['Select the equipment for which the income is intended']}}</p>
                        <div class="flex flex-row flex-wrap w-full items-center gap-4 justify-evenly mt-4 max-h-[300px] overflow-y-auto pt-2">
                            @for (asset of assets; track $index) {
                                <div class="flex flex-row w-auto gap-2 items-center justify-center p-4 rounded-md border-[1px] hover:border-lightGreen transition-[0.5s]
                                            hover:translate-y-[-5px] hover:shadow-md cursor-pointer"
                                    [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveAsset(asset),
                                                'border-[1px]':!isActiveAsset(asset)}"
                                    (click)="onAssetSelected(asset)">
                                    <div class="flex size-[60px] img-container rounded-sm border-[1px] border-primaryBlack/20">
                                        <img [src]="asset.imageUrl" class="rounded-sm size-full"/>
                                    </div>
                                    <div class="flex flex-col w-full">
                                        <p class="text-[16px] font-medium text-primaryBlack">{{asset.Name}}</p>
                                        <p class="text-[12px] font-light text-primaryBlack">{{translationService.translations?.variables['Serial number']}}: <span class="font-medium">{{asset.SerialNumber}}</span></p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    @if (showProjects) {
                        <p class="text-primaryBlack/50 tracking-wide text-[14px] font-medium uppercase">{{translationService.translations?.variables['Available projects']}}</p>
                        <p class="text-primaryBlack/50 tracking-wide text-[12px] font-light mb-2">{{translationService.translations?.variables['Select the project for which the income is intended']}}</p>
                        <div class="flex flex-row flex-wrap w-full items-center gap-4 justify-evenly mt-4 max-h-[300px] overflow-y-auto pt-2">
                            @for (project of projects; track $index) {
                                <div class="flex flex-row w-auto gap-2 items-center justify-center p-4 rounded-md border-[1px] hover:border-lightGreen transition-[0.5s]
                                            hover:translate-y-[-5px] hover:shadow-md cursor-pointer"
                                    [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActiveProject(project),
                                                'border-[1px]':!isActiveProject(project)}"
                                    (click)="onSelectedProject(project)">
                                    <div class="flex flex-col w-full">
                                        <p class="text-[16px] font-medium text-primaryBlack">{{project.Name}}</p>
                                        <p class="text-[12px] font-light text-primaryBlack">
                                            {{translationService.translations?.variables['Status']}}: 
                                            <span class="font-medium"
                                                [ngClass]="{'text-active' : project.ProjectStatus === 'Active',
                                                'text-scheduled' : project.ProjectStatus === 'Scheduled',
                                                'text-paused' : project.ProjectStatus === 'Paused',
                                                'text-finished' : project.ProjectStatus === 'Finished'}">
                                                {{project.ProjectStatus}}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="saveIncome()"
                        [ngClass]="{'bg-greyScaleBorderDisabled text-greyScaleTextBody': !form.valid || selectedAsset === null || selectedProject === null, 
                                    'bg-brandSurfaceDefault hover:bg-brandBorderDarker text-greyScaleSurfaceDefault': form.valid && selectedAsset !== null && selectedProject !== null}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s]">
                    {{translationService.translations?.variables['Save']}}
                </button>
            </div>
        </div>
    </div>
</form>
